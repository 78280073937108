// Convert GeoJSON coordinates using the given projection.
const projectCoordinates = (coords, projection) =>
  coords.map(([lng, lat]) => {
    const [x, y] = projection([lng, lat]);
    return `${x},${y}`;
  });

export const renderFeature = (draw, feature, projection, fillColor, strokeColor, strokeWidth) => {
  const type = feature.geometry.type;

  if (type === "Polygon") {
    const pointsStr = projectCoordinates(feature.geometry.coordinates[0], projection).join(" ");
    draw.polygon(pointsStr)
      .id(feature.id)
      .fill(fillColor)
      .stroke({ color: strokeColor, width: strokeWidth });
      // console.log("Polygon", feature.id, pointsStr);
  }
  else if (type === "MultiPolygon") {
    const group = draw.group().id(feature.id);
    feature.geometry.coordinates.forEach((polygonCoords) => {
      const pointsStr = projectCoordinates(polygonCoords[0], projection).join(" ");
      group.polygon(pointsStr)
        .fill(fillColor)
        .stroke({ color: strokeColor, width: strokeWidth });
    });
  }
  else if (type === "Point") {
    const [x, y] = projection(feature.geometry.coordinates);
    draw.circle(6)
      .id(feature.id)
      .center(x, y)
      .fill(fillColor)
      .stroke({ color: strokeColor, width: strokeWidth });
    console.log("Point", feature.id, x, y);
  }
  else if (type === "MultiPoint") {
    const group = draw.group().id(feature.id);
    feature.geometry.coordinates.forEach(coord => {
      const [x, y] = projection(coord);
      group.circle(6)
        .center(x, y)
        .fill(fillColor)
        .stroke({ color: strokeColor, width: strokeWidth });
    });
  }
  else if (type === "LineString") {
    const pointsStr = projectCoordinates(feature.geometry.coordinates, projection).join(" ");
    draw.polyline(pointsStr)
      .id(feature.id)
      .fill(fillColor)
      .stroke({ color: strokeColor, width: strokeWidth });
  }
  else if (type === "MultiLineString") {
    const group = draw.group().id(feature.id);
    feature.geometry.coordinates.forEach((lineCoords) => {
      const pointsStr = projectCoordinates(lineCoords, projection).join(" ");
      group.polyline(pointsStr)
        .fill(fillColor)
        .stroke({ color: strokeColor, width: strokeWidth });
    });
  }
  else {
    const pathData = geoPath().projection(projection)(feature);
    if (pathData) {
      draw.path(pathData)
        .id(feature.id)
        .fill(fillColor)
        .stroke({ color: strokeColor, width: strokeWidth });
    }
  }
};

export const updateElementColor = (locationElement, fill) => {

  // Update status and find the floor rect
  // console.log("Location element", locationElement);
  // console.log("Location element children", locationElement.children);
  for (let i = 0; i < locationElement.children.length; i++) {
    const child = locationElement.children[i];
    const childName = child.getAttribute("id");
    const childType = child.tagName.toLowerCase();
    // console.log("Location child", childName, childType);
    if (childName && childName.toLowerCase().includes("floor")) {
      // console.log("Location child ok", childName, childType);

      if (childType === "rect") {
        // Update layer color
        child.style.fill = fill;
      }
      else if (childType === "polygon") {
        // Update layer color
        child.style.fill = fill;
      }
      else if (childType === "g") {
        // Update opacity to make sure the group is visible
        child.style.opacity = 1;

        for (let j = 0; j < child.children.length; j++) {
          const grandChild = child.children[j];
          const grandChildType = grandChild.tagName.toLowerCase();
          if (grandChildType === "rect") {
            // Update layer color
            grandChild.style.fill = fill;

          }
        }
      }

    }
    else if (childName && childName.toLowerCase().includes("furniture")) {
      recursiveUpdateFurnitureElement(child, fill);
    }
  }
};

const recursiveUpdateFurnitureElement = (furnitureElement, fill) => {
  for (let i = 0; i < furnitureElement.children.length; i++) {
    const child = furnitureElement.children[i];
    const childType = child.tagName.toLowerCase();
    if (childType === "g") {
      recursiveUpdateFurnitureElement(child, fill);
    }
    else {
      // Update layer color
      child.style.fill = fill;
      child.style.stroke = "#000";
    }
  }
};