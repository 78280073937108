import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SubNavigation from "../../components/SubNavigation";
import SearchLocations from "./searchLocations";
import SearchSensors from "./searchSensors";
import SearchGateways from "./searchGateways";
import NotFound from "../NotFound";
import WidgetsPage from "./widgetsPage";
import styled from "styled-components";

class HomeContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collection: ''
    };

    fetchCompleteCollection();
  }

  render() {
    // console.log("HomeContainer", this.props);
    const { companyId } = this.props.match.params;

    const subNavigationLinks = [];

    // Add query params to widgets if applicable
    const widgetQueryParams = this.props.widgetQueryParams ? { ...this.props.widgetQueryParams } : undefined;
    subNavigationLinks.push({ pathname: `/companies/${companyId}/home/widgets`, queryParams: widgetQueryParams, label: "Widgets" });

    // Add query params to locations if applicable
    const locationQueryParams = this.props.locationQueryParams ? { ...this.props.locationQueryParams } : undefined;
    subNavigationLinks.push({ pathname: `/companies/${companyId}/home/locations`, queryParams: locationQueryParams, label: "Locations" });

    // Only show sensors and gateways if the user has IT Admin role
    if (this.props.auth.hasITAdminRole) {
      const sensorQueryParams = this.props.sensorQueryParams ? { ...this.props.sensorQueryParams } : undefined;
      const gatewayQueryParams = this.props.gatewayQueryParams ? { ...this.props.gatewayQueryParams } : undefined;
    
      subNavigationLinks.push({ pathname: `/companies/${companyId}/home/sensors`, queryParams: sensorQueryParams, label: "Sensors" });
      subNavigationLinks.push({ pathname: `/companies/${companyId}/home/gateways`, queryParams: gatewayQueryParams, label: "Gateways" });
    }

    return (
      <Page>
        <SubNavBackground>
          <SubNavigation links={subNavigationLinks} slim normalMargin />
        </SubNavBackground>

        <Switch>
          <Redirect exact from="/companies/:companyId/home" to={`/companies/${companyId}/home/widgets`} />
          <Redirect exact from="/companies/:companyId/home/dash" to={`/companies/${companyId}/dashboard`} />
          <Route path="/companies/:companyId/home/widgets" component={WidgetsPage} />
          <Route path="/companies/:companyId/home/locations" component={SearchLocations} />
          <Route path="/companies/:companyId/home/sensors" component={SearchSensors} />
          <Route path="/companies/:companyId/home/gateways" component={SearchGateways} />
          <Route component={NotFound} />
        </Switch>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    widgetQueryParams: state.widgets.currentQueryParams,
    locationQueryParams: state.locations.currentQueryParams,
    sensorQueryParams: state.sensors.currentQueryParams,
    gatewayQueryParams: state.gateways.currentQueryParams
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
   }, dispatch);
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeContainer));

const Page = styled.div`
  height: calc(100vh - 65px);
  background-color: #f6f6f6;
`;

const SubNavBackground = styled.div`
  background-color: white;
`;

import axios from 'axios';

async function fetchCompleteCollection() {
  let completeList = '';
  let page = 1;
  
  while (true) {
    const url = `https://boardgamegeek.com/geekcollection.php?ajax=1&action=collectionpage&username=Spilloteket&userid=3813410&gallery=&sort=title&sortdir=&page=&pageID=${page}&ff=&hiddencolumns=&publisherid=&searchstr=&rankobjecttype=subtype&rankobjectid=1&columns[]=title&columns[]=thumbnail&columns[]=status&columns[]=bggrating&minrating=&rating=&minbggrating=&bggrating=&minplays=&maxplays=&searchfield=title&geekranks=Board%20Game%20Rank&subtype=boardgame&excludesubtype=&own=both&trade=both&want=both&wanttobuy=both&prevowned=both&comment=both&wishlist=both&rated=both&played=both&wanttoplay=both&preordered=both&hasparts=both&wantparts=both&wishlistpriority=&direct=1`;

    try {
      const response = await axios.get(url);
      // Check if the returned data is empty or only whitespace.
      if (!response.data || response.data.trim() === '') {
        console.log(`No more data on page ${page}. Exiting loop.`);
        break;
      }
      
      // Append the data from this page to the complete list.
      completeList += response.data;
      console.log(`Fetched page ${page}`);
      
      page++; // move on to the next page
    } catch (error) {
      console.error(`Error fetching page ${page}:`, error.message);
      break;
    }
  }
  
  // Log the complete combined list.
  console.log('Complete Collection:', completeList);
}