import React, { useRef, useState, useEffect, useMemo } from "react";
import TextBox from "../../../components/TextBox";
import SegmentedControl from "../../../components/SegmentedControl";
import SVGMap from ".";
import styled from "styled-components";
import { MapMode } from "../../../constants/stateTypes";
import { MapModeColors } from "../../../constants/colors";

const MapContainer = ({
  mode = MapMode.navigate,
  setMode = () => { },
  isFloorMap,
  currentLocationId,
  selection: { selectedFeatures, setSelectedFeatures, canSelectLocation, canSelectSensor, canSelectGateway } = {},
  highlight: { highlightedFeatureId, setHighlightedFeatureId, canHighlightLocation, canHighlightSensor, canHighlightGateway } = {},
  data: { geojsonData, imdfData, svgData, locations } = {},
  permissions: { canNavigate, canConnect, canEdit, canInspect } = {},
  createdFeatures = [],
  addCreatedFeature = () => { },
}) => {

  // Make the inspection box for the selected feature
  let inspectionBox = null;
  if (mode === MapMode.inspect && selectedFeatures && selectedFeatures.length === 1) {
    let selectedFeature = selectedFeatures[0];
    let description = "";
    if (geojsonData) {
      description = JSON.stringify(selectedFeature?.properties, null, 2);
    }
    else if (imdfData) {
      description = JSON.stringify(selectedFeature?.properties, null, 2);
    }
    else if (svgData) {
      // Turn selectedFeature.attributes into a more readable string
      const buildDescription = (attributes, level = 0) => {
        let description = "";

        if (attributes.children) {
          attributes.children.forEach(child => {
            description += "  ".repeat(level) + `${child.type}`;

            if (child.id) {
              description += `#${child.id}`;
            }

            child.classes.forEach(className => {
              description += `.${className}`;
            });

            if (child.children) {
              description += ":";
            }

            description += "\n";
            description += buildDescription(child, level + 1);
          });
        }

        return description;
      }

      description = buildDescription(selectedFeature.attributes);
    }

    inspectionBox = (
      <InspectionBox>
        <Header>
          <Title>{selectedFeature.changeType === "new" ? "New feature" : selectedFeature.id}</Title>
          {/* <InspectionIconButton
                color={"white"}
                hoverColor="#eee"
                onClick={() => this.deleteFeature(selectedFeature.id)}>
              <FontAwesomeIcon icon={faTrash} color="#333" />
            </InspectionIconButton> */}
        </Header>
        <TextBox
          value={description}
          size="180px"
          isTopRow
          disabled
        />
        {/* <ControlledDropdownSelection
            label="Overriden style"
            value={selectedFeature.properties?.style?.fillColor === MapColors.zone ? "zone" : "default"}
            options={[{ id: "default", name: "Default" }, { id: "zone", name: "Render as zone" }]}
            onChange={this.overridenStyleChanged}
          /> */}
      </InspectionBox>
    );
  }

  // Render the inspection box
  return (
    <SVGMap
      mode={mode}
      currentLocationId={currentLocationId}
      selectedFeatures={selectedFeatures}
      setSelectedFeatures={setSelectedFeatures}
      highlightedFeatureId={highlightedFeatureId}
      setHighlightedFeatureId={setHighlightedFeatureId}
      geojsonData={geojsonData}
      imdfData={imdfData}
      svgData={svgData}
      locations={locations}
      createdFeatures={createdFeatures}
      addCreatedFeature={addCreatedFeature}
      canNavigate={canNavigate}
      canConnect={canConnect}
      canEdit={canEdit}
      canInspect={canInspect}
      isFloorMap={isFloorMap}
      canSelectLocation={canSelectLocation}
      canSelectSensor={canSelectSensor}
      canSelectGateway={canSelectGateway}
      canHighlightLocation={canHighlightLocation}
      canHighlightSensor={canHighlightSensor}
      canHighlightGateway={canHighlightGateway}
    >
      {inspectionBox}
      <ModeButton>
        {canNavigate && canConnect && (
          <SegmentedControl
            name="mode"
            value={mode}
            onChange={(value) => setMode(value)}
            options={[
              { label: "View", value: MapMode.view, default: true },
              { label: "Connect", value: MapMode.connect, customColor: MapModeColors.connect.buttonColor },
            ]}
          />
        )}
        {canEdit && canInspect && (
          <SegmentedControl
            name="mode"
            value={mode}
            onChange={(value) => setMode(value)}
            options={[
              { label: "Inspect", value: MapMode.inspect, default: true },
              { label: "Edit", value: MapMode.edit, customColor: MapModeColors.connect.buttonColor },
            ]}
          />
        )}
      </ModeButton>
    </SVGMap>
  );
}

export default MapContainer;

const InspectionBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 360px;
  max-height: 600px;
  
  background-color: white;
  z-index: 100;
  border-radius: 5px;
  box-shadow: -1px 0 5px 0 rgba(0,0,0,0.2);
  
  padding: 15px 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const InspectionIconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.color};
  color: white;
  border-radius: 5px;
  padding: 7px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;

  &:hover {
    background-color: ${props => props.hoverColor};
  }
`;

const ModeButton = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
`;