import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty, set } from "lodash";
import semver from "semver";
import Loader from "../../components/Loader";
import MapboxMap from "../Map/MapboxMap";
import Freetext from "../../components/Freetext";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import InputGroup from "../../components/InputGroup";
import OptionFooter from "../../components/OptionFooter";
import { ControlledDropdownSelection } from "../../components/DropdownSelection";
import { VortoMapping } from "../../constants/vortoMapping";
import * as authActions from "../../actions/auth";
import * as locationActions from "../../actions/locations";
import * as sensorActions from "../../actions/sensors";
import * as jobActions from "../../actions/jobs";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";
import { MapMode } from "../../constants/stateTypes";
import { isClockwise } from "../../mapHelpers";
import MapContainer from "../Map/SVGMap/container";

class NewHonoSensor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      description: "",
      sensorId: "",
      id: props.match.params.id,
      msisdn: "",
      validity: "",
      vortoId: "",
      gateways: [],
      selectedGatewayId: null,
      disableGatewaySelection: false,
      disableSensorIdInput: false,
      hasLoadedState: false
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onGatewayToggle = this.onGatewayToggle.bind(this);
    this.onMSISDNChange = this.onMSISDNChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onSensorIdChange = this.onSensorIdChange.bind(this);
    this.onValidityChange = this.onValidityChange.bind(this);
    this.onVortoVendorChange = this.onVortoVendorChange.bind(this);
    this.onVortoModelChange = this.onVortoModelChange.bind(this);
    this.onSaveWithMap = this.onSaveWithMap.bind(this);
    this.onSaveWithoutMap = this.onSaveWithoutMap.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onViewJob = this.onViewJob.bind(this);
    this.onClose = this.onClose.bind(this);
    this.transformCode = this.transformCode.bind(this);

    if (props.match.params.id !== props.location.id) {
      props.getLocation(props.match.params.id);
      props.getFloorMap(props.match.params.id);
    }

    // Reset Created job for Modal
    this.props.clearCreatedJob();
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (prevState.hasLoadedState) {
      return null;
    }

    // Only copy props if they exist and if location id is correct
    if (!isEmpty(nextProps.location) && nextProps.match.params.id === nextProps.location.id) {

      const newState = {
        hasLoadedState: true
      };

      // Select vortoId
      let storedVortoId = localStorage.getItem("vortoId");
      if (nextProps.vortoIds.length > 0) {
        const vorto = nextProps.vortoIds.find(vortoObj => vortoObj.id === storedVortoId) ?? nextProps.vortoIds[0];

        // Find the newest version of the vortoId
        const vortoModelObject = nextProps.vendors[vorto.vendor][vorto.model];
        const allVersions = Object.keys(vortoModelObject);
        
        // Sort versionList by semver
        allVersions.sort((a, b) => semver.gt(a, b) ? -1 : 1);
        const newestVersion = allVersions[0];
    
        const vortoId = vortoModelObject[newestVersion];
        newState.vortoId = vortoId;
      }

      // If creating an unregistered sensor, set the gatewayId and the sensorId, and disable the input fields
      if (!isEmpty(nextProps.selectedUnregisteredSensors) && Object.keys(nextProps.selectedUnregisteredSensors).length === 1) {
        const unregisteredSensor = nextProps.selectedUnregisteredSensors[Object.keys(nextProps.selectedUnregisteredSensors)[0]];
        newState.sensorId = unregisteredSensor.id;
        newState.selectedGatewayId = unregisteredSensor.gateway.id;
        newState.gateways = [unregisteredSensor.gateway];
        newState.disableGatewaySelection = true;
        newState.disableSensorIdInput = true;
        return newState;
      }

      let gateways = nextProps.location.gateways.concat(nextProps.location.closestAncestorGateways);
      gateways = gateways.filter(gateway => {
        return !isEmpty(gateway.hono)
      });

      // Add selected gateways that are not in location.gateways or location.closestAncestorGateways
      const selectedGatewayIds = Object.keys(nextProps.selectedGateways);
      selectedGatewayIds.forEach(selectedGatewayId => {
        if (!gateways.find(gateway => (gateway.id === selectedGatewayId))) {
          gateways.push(nextProps.selectedGateways[selectedGatewayId]);
        }
      });

      newState.gateways = gateways;

      if (gateways.length === 1) {
        newState.selectedGatewayId = gateways[0].id
      }

      return newState;
    }

    return null;
  }

  onBackClick(id) {
    if (get(this.props.location, "state.canGoBack", false)) {
      this.props.history.goBack();
    }
    else {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${id}/sensors`);
    }
  }

  onViewJob() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/installation/job-groups/unknown/unknown/${this.props.createdJob.jobId}`);
  }

  onClose() {
    this.props.clearCreatedJob();
  }

  onMSISDNChange(event) {
    this.setState({ msisdn: event.target.value });
  }

  onDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  onSensorIdChange(event) {
    let sensorId = event.target.value;
    this.setState({ sensorId });
  }

  onValidityChange(event) {
    this.setState({ validity: event.target.value });
  }

  onVortoVendorChange(event) {
    const vortoVendor = event.target.value;
    
    // Find first vortoId with the same vendor
    const vortoVendorObject = this.props.vendors[vortoVendor];

    const models = Object.keys(vortoVendorObject);
    models.sort();
    const firstModel = models[0];

    const versions = Object.keys(vortoVendorObject[firstModel]);

    // Sort versionList by semver
    versions.sort((a, b) => semver.gt(a, b) ? -1 : 1);
    const newestVersion = versions[0];

    console.log("newestVersion", newestVersion);

    const vortoId = vortoVendorObject[firstModel][newestVersion];

    this.setState({ vortoId });
    localStorage.setItem("vortoId", vortoId);
  }

  onVortoModelChange(event) {
    const vortoModel = event.target.value;

    const oldVorto = this.props.vortoIds.find(vorto => vorto.id === this.state.vortoId);
    
    // Find first vortoId with the same vendor
    const vortoModelObject = this.props.vendors[oldVorto.vendor][vortoModel];

    const versions = Object.keys(vortoModelObject);
    
    // Sort versionList by semver
    versions.sort((a, b) => semver.gt(a, b) ? -1 : 1);
    const newestVersion = versions[0];

    console.log("newestVersion", newestVersion);

    const vortoId = vortoModelObject[newestVersion];

    this.setState({ vortoId });
    localStorage.setItem("vortoId", vortoId);
  }

  onGatewayToggle(row) {
    if (this.state.selectedGatewayId === row.original.id) {
      this.setState({ selectedGatewayId: null });
    }
    else {
      this.setState({ selectedGatewayId: row.original.id });
    }
  }

  onSaveWithMap() {
    const { push } = this.props.history;

    const body = {
      vortoId: this.state.vortoId,
      honoDeviceId: this.state.sensorId,
      locationIds: [this.props.location.id]
    };

    if (this.state.selectedGatewayId) {
      body.gatewayId = this.state.selectedGatewayId;
    }
    else {
      body.createCredentials = true;
    }
 
    if (!isEmpty(this.state.validity) && Number.isInteger(Number(this.state.validity)) && Number(this.state.validity) >= 0) {
      body.validityMs = Number(this.state.validity);
    }

    if (!isEmpty(this.state.msisdn)) {
      body.msisdn = this.state.msisdn;
    }

    if (!isEmpty(this.state.description)) {
      body.description = this.state.description;
    }

    if (this.props.createdFeature) {
      body.geoJsonFeature = this.props.createdFeature;
    }

    this.props.createSensor("hono", body, this.props.location.id, push);
  } 

  onSaveWithoutMap() {
    const { push } = this.props.history;

    const body = {
      vortoId: this.state.vortoId,
      honoDeviceId: this.state.sensorId,
      locationIds: [this.props.location.id]
    };

    if (this.state.selectedGatewayId) {
      body.gatewayId = this.state.selectedGatewayId;
    }
    else {
      body.createCredentials = true;
    }
 
    if (!isEmpty(this.state.validity) && Number.isInteger(Number(this.state.validity)) && Number(this.state.validity) >= 0) {
      body.validityMs = Number(this.state.validity);
    }

    if (!isEmpty(this.state.msisdn)) {
      body.msisdn = this.state.msisdn;
    }

    if (!isEmpty(this.state.description)) {
      body.description = this.state.description;
    }

    this.props.createSensor("hono", body, this.props.location.id, push);
  }

  onCancel() {
    this.onBackClick(this.state.id);
  }

  transformCode(event) {

    if (event.key != "Enter" && event.key != "Tab") {
      return;
    }

    let code = this.state.sensorId.toUpperCase();

    // Check if code follows the EnOcean standard
    // Ex. 30S00000597FEDC+1P003C10000024+30PPIR.WAL.BAT.WE.E868+2P1.2.1.0
    // 30S00000597FEDC+1P003C10000024+30PmappingTest+2P1.2.1.0
    // 30S00000597FEDC`1P003C10000024`30PPIR.WAL.BAT.WE.E868`2P1.2.1.0
    // 30S0000059F32BB+1P003C10000024+30PPIR.WAL.BAT.TR.E868+2P1.2.1.1
    if (!code.startsWith("30S")) { return }

    code = code.replaceAll("`","+")

    const standardArray = code.split("+");
    let sensorId = standardArray[0].replace("30S","");
    let sensorModel = standardArray.find(element => element.startsWith("30P"));

    if (isEmpty(sensorModel)) { return }

    sensorModel = sensorModel.replace("30P","").replaceAll("?",".").replaceAll("_",".").replaceAll(" ",".");
    console.log("sensorModel", sensorModel);
    const mappedVortoId = VortoMapping[sensorModel];

    if (isEmpty(mappedVortoId)) { return }

    console.log("mappedVortoId", mappedVortoId);
    console.log("mappedSensorId", sensorId);

    // Trim sensorId to only be 8 char long
    if (sensorId.length > 8) {
      sensorId = sensorId.substring(sensorId.length-8);
    }

    // Find the vorto object for the mapped vortoId
    const vorto = this.props.vortoIds.find(vortoObj => vortoObj.id === mappedVortoId);
        
    if (vorto) {
      this.setState({ sensorId: sensorId, vortoId: vorto.id });
    }
  }

  render() {
    // console.log("this.props", this.props);
    // console.log("this.state", this.state);
    if (this.props.isLoading || !this.state.hasLoadedState) {
      return <Loader fullScreen />;
    }

    // let gateways = this.props.location.gateways.concat(this.props.location.closestAncestorGateways);
    // gateways = gateways.filter(gateway => {
    //   return !isEmpty(gateway.hono)
    // });

    // // Add selected gateways that are not in location.gateways or location.closestAncestorGateways
    // const selectedGatewayIds = Object.keys(this.props.selectedGateways);
    // selectedGatewayIds.forEach(selectedGatewayId => {
    //   if (!gateways.find(gateway => (gateway.id === selectedGatewayId))) {
    //     gateways.push(this.props.selectedGateways[selectedGatewayId]);
    //   }
    // });

    // Get map data
    const svgData = this.props.location.floorSVGMap;
    let geojsonData = this.props.location.type.match(/^(root|region|building)$/) ? this.props.auth.map : this.props.location.floorMap;
    let imdfData = this.props.location.floorIMDFMap;
    
    if (imdfData) {
      // Clone and fix imdfData (e.g. correct polygon winding)
      imdfData = JSON.parse(JSON.stringify(imdfData));
      Object.keys(imdfData).forEach((key) => {
        if (imdfData[key]?.features) {
          imdfData[key].features = imdfData[key].features.map((feature) => {
            if (feature.geometry.type === "Polygon") {
              let exteriorRing = feature.geometry.coordinates[0];
              if (isClockwise(exteriorRing)) {
                exteriorRing = exteriorRing.reverse();
              }
              feature.geometry.coordinates[0] = exteriorRing;
            }
            return feature;
          });
        }
      });
    }

    if (geojsonData) {
      // Clone and fix geojsonData (e.g. correct polygon winding)
      geojsonData = JSON.parse(JSON.stringify(geojsonData));
      geojsonData.features = geojsonData.features.map((feature) => {
        if (feature.geometry.type === "Polygon") {
          let exteriorRing = feature.geometry.coordinates[0];
          if (isClockwise(exteriorRing)) {
            exteriorRing = exteriorRing.reverse();
          }
          feature.geometry.coordinates[0] = exteriorRing;
        }
        return feature;
      });

      // Remove features that are not children of the current location
      if (this.props.location?.type === "region") {
        const children = this.props.location?.children ?? [];
        geojsonData.features = geojsonData.features.map(feat => ({ ...feat, properties: { ...feat.properties, disabled: !children.includes(feat.properties?.locationId ?? "") } }));
      }
      else if (this.props.location?.type === "building") {
        geojsonData.features = geojsonData.features.map(feat => ({ ...feat, properties: { ...feat.properties, disabled: feat.properties?.locationId !== this.props.location.id } }));
      }

      // Sort features by disabled (draw disabled features first)
      geojsonData.features.sort((a, b) => (a.properties?.disabled === b.properties?.disabled) ? 0 : a.properties?.disabled ? -1 : 1);
    }

    const mapOptions = {
      mode: MapMode.draw_point,
      currentLocationId: this.props.location.id,
      createdFeatures: [this.props.createdFeature],
      addCreatedFeature: this.props.addCreatedFeature,
      data: {
        geojsonData: geojsonData,
        imdfData: imdfData,
        svgData: svgData,
      }
    };

    let mapElement = (
      <div className={style.mapPart}>
        <MapContainer
          key="draw_point"
          {...mapOptions}
        />
      </div>
    );

    // let mapElement;
    // if (this.props.location.floorMap) {
    //   mapElement = (
    //     <div className={style.mapPart}>
    //       <MapboxMap 
    //         mapId="new-sensor-map-container" 
    //         id={this.state.id} 
    //         company={this.props.company} 
    //         location={this.props.location} 
    //         map={this.props.location.floorMap} 
    //         drawMode="draw_point"
    //         showSensors
    //         showGateways
    //         drawSensor
    //         showMap
    //       />
    //       <div className={style.mapHelp}>
    //         <span>Sensors are </span>
    //         <span style={{ color: "#0080FF" }}>blue</span>
    //         <span>. Gateways are </span>
    //         <span style={{ color: "#82612F" }}>brown</span>
    //         <span>.</span>
    //       </div>
    //     </div>
    //   );
    // }
    // else if (this.props.companyMap) {
    //   mapElement = (
    //     <div className={style.mapPart}>
    //       <MapboxMap 
    //         mapId="new-sensor-map-container" 
    //         id={this.state.id} 
    //         company={this.props.company} 
    //         location={this.props.location} 
    //         map={this.props.companyMap} 
    //         drawMode="draw_point"
    //         showSensors
    //         showGateways
    //         drawSensor
    //         showMap
    //       />
    //       <div className={style.mapHelp}>
    //         <span>Sensors are </span>
    //         <span style={{ color: "#0080FF" }}>blue</span>
    //         <span>. Gateways are </span>
    //         <span style={{ color: "#82612F" }}>brown</span>
    //         <span>.</span>
    //       </div>
    //     </div>
    //   );
    // }

    const TheadComponent = props => null;

    // Options for saving
    const options = [];
    if (isEmpty(this.props.createdFeature)) {
      options.push({ 
          label: "Save without map", 
          callback: this.onSaveWithoutMap, 
          disabled: this.state.sensorId.length === 0 || this.state.vortoId === ""
        });
    }
    else {
      options.push({ 
        label: "Save with map", 
        callback: this.onSaveWithMap, 
        disabled: this.state.sensorId.length === 0 || this.state.vortoId === ""
      });
    }

    // Get selectedVortoVendor, selectedVortoModel and selectedVortoVersion from vortoIds.find() and match on this.state.vortoId
    const vorto = this.props.vortoIds.find(vorto => vorto.id === this.state.vortoId);
    const selectedVortoVendor = vorto ? vorto.vendor : null;
    const selectedVortoModel = vorto ? vorto.model : null;

    // Get list of vendors from vortoIds
    const vendorList = Object.keys(this.props.vendors);
    vendorList.sort((a, b) => a.localeCompare(b));
    const vendorOptions = vendorList.map(vendorName => ({ id: vendorName, name: vendorName }));

    // Get list of models for options
    let modelOptions = [];
    if (selectedVortoVendor) {
      const modelList = Object.keys(this.props.vendors[selectedVortoVendor]);
      modelList.sort((a, b) => a.localeCompare(b));
      modelOptions = modelList.map(modelName => ({ id: modelName, name: modelName }));
    }
    
    return (
      <>
        <div className={style.editContainer}>
          <div className={style.row}>
            <div className={style.col33}>
              <div className={style.scroll}>
                <div className={style.topRow}>
                  <Freetext header={`Add sensor to ${this.props.location.name}`} />
                </div>
                <h3 style={{ marginBottom: 0 }}>1. Select gateway (if applicable)</h3>
                { isEmpty(this.state.gateways) ? 
                  (
                    <p className={style.noGatewayText}>Will create credentials</p>
                  ) : (
                    <Table
                      data={this.state.gateways}
                      TheadComponent={TheadComponent}
                      columns={[
                        {
                          accessorKey: "id",
                          name: "isSelected",
                          cell: ({ row }) => (
                            <label className={this.state.disableGatewaySelection ? "disabledCheckboxContainer" : "checkboxContainer"} htmlFor={`editCheckbox-${row.original.id}`}>
                              <input
                                id={`editCheckbox-${row.original.id}`}
                                type="checkbox"
                                className="checkbox"
                                checked={this.state.selectedGatewayId === row.original.id}
                                onChange={() => this.onGatewayToggle(row)}
                                disabled={this.state.disableGatewaySelection}
                              />
                              <span className={this.state.disableGatewaySelection ? "disabledCheckmark" : "checkmark"} />
                            </label>
                          ),
                          width: 60
                        },
                        {
                          accessorKey: "name",
                          cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
                        }
                      ]}
                      className="setMaxHeigth"
                      noDataText="Will create credentials"
                    />
                  )
                  }
                
              </div>
            </div>
            <div className={style.col33}>
              <div className={style.scroll}>
                <h3>2. Enter sensor details</h3>
                <ControlledDropdownSelection 
                  name="sensorVendor"
                  label="Sensor vendor"
                  value={selectedVortoVendor}
                  options={vendorOptions}
                  onChange={this.onVortoVendorChange}
                  style={{ marginRight: "20px" }}
                />
                <ControlledDropdownSelection 
                  name="sensorModel"
                  label="Sensor model"
                  value={selectedVortoModel}
                  options={modelOptions}
                  onChange={this.onVortoModelChange}
                />
                <InputGroup autoFocus onChange={this.onSensorIdChange} onKeyDown={this.transformCode} label="Sensor ID" placeholder="e.g. 4bebf938-a6bf-44f9-b7d8-2dfe5475e6e8" value={this.state.selectedDiscoveredSensorId || this.state.sensorId} valid={this.state.selectedDiscoveredSensorId && /^[a-zA-Z0-9-_\\.\\:]+$/.test(this.state.selectedDiscoveredSensorId) || this.state.sensorId && /^[a-zA-Z0-9-_\\.\\:]+$/.test(this.state.sensorId)} required disabled={this.state.disableSensorIdInput}/>
                <InputGroup onChange={this.onDescriptionChange} label="Description (optional)" placeholder="e.g. Comfort Workplace 4" value={this.state.description} />
                <InputGroup onChange={this.onMSISDNChange} label="MSISDN (optional)" placeholder="e.g. 4780123456" value={this.state.msisdn} valid={this.state.msisdn}  />
                <InputGroup onChange={this.onValidityChange} label="Validity duration (in milliseconds)" placeholder="unlimited by default" value={this.state.validity} valid={Number.isInteger(Number(this.state.validity)) && Number(this.state.validity) >= 0} required/>
              </div>
            </div>
            <div className={style.col33}>
              <h3 className={style.mapHeader}>3. Place sensor in the map</h3>
              { mapElement }
            </div>
          </div>
          <OptionFooter 
            cancel={this.onCancel} 
            cancelButtonLabel="Cancel"
            options={options} 
          />
        </div>

        <Modal
          show={!isEmpty(this.props.createdJob)}
          title="Creating sensor"
          text={"The sensor creation request has been sent."}
          primaryBtn={{
            text: "Add another",
            onClick: this.onClose
          }}
          secondaryBtn={{
            text: "View job",
            onClick: this.onViewJob
          }}
          tertiaryBtn={{
            text: "Return",
            color: "blue", 
            onClick: this.onCancel
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.location,
    sensor: state.sensor,
    createdJob: state.sensors.createdJob,
    isLoading: state.loading.sensors,
    company: state.auth.selectedCompany,
    selectedGateways: state.selected.gateways,
    selectedUnregisteredSensors: state.selected.unregisteredSensors,
    createdFeature: state.selected.createdFeature,
    vortoIds: state.auth.vortoIds,
    vendors: state.auth.vendors
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getLocation: locationActions.getLocation,
    selectGateway: selectedActions.selectGateway,
    deselectGateway: selectedActions.deselectGateway,
    clearSelection: selectedActions.clearSelection,
    createSensor: sensorActions.createSensor,
    getFloorMap: locationActions.getFloorMap,
    getCompanyMap: authActions.getCompanyMap,
    clearCreatedJob: jobActions.clearCreatedJob,
    addCreatedFeature: selectedActions.addCreatedFeature,
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(NewHonoSensor);