import { gameData } from "./data";
import React, { Component } from "react";
import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import TopRowOptions from "../../components/TopRowOptions";
import styled from "styled-components";

class TestList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      activeSearchText: undefined,
      sortBy: "name",
      sortOrder: "asc"
    };
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  onSearchClick() {
    this.setState({ activeSearchText: this.state.searchText });
  }

  onSearchChange(event) {
    this.setState({ searchText: event.target.value === '' ? undefined : event.target.value });
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder });
  }

  onRowClick(column, rowInfo) {
    return {
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original.id != null && rowInfo.original.id === this.props.companyId ? "rgba(0,0,0,0.05)" : null
        )
      }
    }
  }

  render() {
    const { isLoading } = this.props;
  
    const options = (
      <SearchBox
        value={this.state.searchText}
        onSearchChanged={(value) => { this.setState({ searchText: value }) }}
        onClear={() => { this.setState({ searchText: "" }, this.onSearchClick) }}
        onSearchClick={this.onSearchClick}
        inListView
      />
    );

    // Filter data
    let data = JSON.parse(JSON.stringify(gameData));
    const search = this.state.searchText; // this.state.activeSearchText
    if (search) {
      data = data.filter((item) => {

        // All words in search must be found in either name, year or categories
        const searchWords = search.toLowerCase().split(" ");
        let found = true;
        searchWords.forEach(word => {
          let name = item.name.toLowerCase();
          let year = item.yearpublished;
          let categories = item.categories.join(" ").toLowerCase();
          if (name.indexOf(word) === -1 && year.indexOf(word) === -1 && categories.indexOf(word) === -1) {
            found = false;
          }
        });

        return found;
      });
    }

    // Sort data
    data = data.sort((a, b) => {
      let aValue = a[this.state.sortBy];
      let bValue = b[this.state.sortBy];
      if (this.state.sortBy === "name") {
        // Disregard upper/lower case when sorting by name
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (this.state.sortOrder === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });

    const tableElement = (
      <Table
        data={data}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        onSortedChange={this.onSortedChange}
        noDataText={isLoading ? "" : "No data found"}
        // selectedId={this.props.companyId}
        // useDeepCompareOnRows={true}
        columns={[
          {
            header: "Name",
            accessorKey: "name",
            resizable: false,
            width: 260,
          },
          { 
            header: "Year",
            accessorKey: "yearpublished",
            maxWidth: 100,
          },
          {
            header: "Recom. with",
            accessorKey: "recommendedWith",
            minWidth: 120,
            maxWidth: 120,
            cell: ({ row }) => {
              let recommendedWith = row.original?.recommendedWith;
              if (recommendedWith) {
                // Strip out the "Recommended with " prefix
                recommendedWith = recommendedWith.replace("Recommended with ", "")
                recommendedWith = recommendedWith.replace("players", "")
                recommendedWith = recommendedWith.replace(" ", "")
                recommendedWith = recommendedWith.replace("(novotes)", "")
                recommendedWith = recommendedWith.replace("(Undetermined)", "")
              }
              return <div>{recommendedWith}</div>;
            }
          },
          {
            header: "Best with",
            accessorKey: "bestWith",
            minWidth: 100,
            maxWidth: 100,
            cell: ({ row }) => {
              let bestWith = row.original?.bestWith;
              if (bestWith) {
                // Strip out the "Best with " prefix
                bestWith = bestWith.replace("Best with ", "")
                bestWith = bestWith.replace("players", "")
                bestWith = bestWith.replace(" ", "")
                bestWith = bestWith.replace("(novotes)", "")
                bestWith = bestWith.replace("(Undetermined)", "")
              }
              return <div>{bestWith}</div>;
            }
          },
          {
            header: "Rating",
            accessorKey: "ratingsAverage",
            minWidth: 100,
            maxWidth: 100,
          },
          {
            header: "Categories",
            accessorKey: "categories",
            cell: ({ row }) => (<div>{row.original?.categories?.join(", ")}</div>)
          },
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -highlight"
        loading={isLoading}
      />
    );

    return (
      <Page>
        { options }
        <Card>{ tableElement }</Card>
        <div style={{ paddingTop: "100px" }} />
      </Page>
    );
  }
}

export default TestList;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f6f6f6;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;

  // No scroll bounce on iPhone
  overscroll-behavior: none;
`;

const Card = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  min-width: 1000px;
  box-sizing: border-box;
`;