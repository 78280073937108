import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";

import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import InputBox from "../../../components/InputBox";
import TextBox from "../../../components/TextBox";
import OptionFooter from "../../../components/OptionFooter";
import * as authActions from "../../../actions/auth";
import * as accountActions from "../../../actions/account";
import * as widgetActions from "../../../actions/widgets";

const initialState = {
  id: "",
  name: "",
  mazemapCampusTag: "",
  mazemapApiKey: "",
  yanziAccountIds: "",
  inviteEmail: "",
  whitelistedAdmins: "",
  defaultPeopleCountCustomTagId: "",
  copiedConsentLink: false,
  hasLoadedState: false,
  applicationFeatures: {},
  dashboardFeatures: {},
  serviceFeatures: {},
};

const CompanySettings = () => {
  const { companyId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const company = useSelector((state) => state.sudo.company);
  const widgetProfile = useSelector((state) => state.widgets.profile);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    // Reset state when companyId changes
    dispatch(authActions.getSudoCompany(companyId));
    dispatch(widgetActions.getWidgetProfile(companyId));
  }, [companyId]);

  // Update local state when company/widgetProfile data are available (similar to getDerivedStateFromProps)
  useEffect(() => {
    // If company or widgetProfile is empty, reset state (e.g. when requesting new data)
    if (isEmpty(company) || widgetProfile === null) {
      console.log("CompanySettings resetState", company, widgetProfile);
      setState(initialState);
    }
    else if (!state.hasLoadedState && company._id === companyId && widgetProfile.companyId === companyId) {
      console.log("CompanySettings setState", company, widgetProfile);
      setState((prev) => ({
        ...prev,
        id: company._id,
        name: company.name || "",
        mazemapCampusTag: company.mazemapCampusTag || "",
        mazemapApiKey: company.mazemapApiKey || "",
        yanziAccountIds: company.yanziAccountIds || "",
        applicationFeatures: !isEmpty(company.applicationFeatures) ? { ...company.applicationFeatures } : {},
        dashboardFeatures: !isEmpty(company.dashboardFeatures) ? { ...company.dashboardFeatures } : {},
        serviceFeatures: !isEmpty(company.serviceFeatures) ? { ...company.serviceFeatures } : {},
        defaultPeopleCountCustomTagId: widgetProfile?.defaultPeopleCountCustomTagId || "",
        hasLoadedState: true,
      }));
      document.title = `BLDNG.ai - ${company.name}`;
    }
  }, [company, widgetProfile]);

  // Input change handlers
  const onNameChange = (e) =>
    setState((prev) => ({ ...prev, name: e.target.value }));
  const onMazemapCampusTagChanged = (e) =>
    setState((prev) => ({ ...prev, mazemapCampusTag: e.target.value }));
  const onMazemapApiKeyChanged = (e) =>
    setState((prev) => ({ ...prev, mazemapApiKey: e.target.value }));
  const onYanziAccountIDsChange = (e) =>
    setState((prev) => ({ ...prev, yanziAccountIds: e.target.value }));
  const onInviteEmailChange = (e) =>
    setState((prev) => ({ ...prev, inviteEmail: e.target.value }));
  const onWhitelistedAdminsChange = (e) =>
    setState((prev) => ({ ...prev, whitelistedAdmins: e.target.value }));
  const onDefaultPeopleCountCustomTagIdChange = (e) =>
    setState((prev) => ({ ...prev, defaultPeopleCountCustomTagId: e.target.value }));

  // Sync and action handlers
  const onSyncMazemap = () => dispatch(authActions.syncCompanyMap(state.id));
  const onSyncYanzi = () => dispatch(authActions.syncYanzi(state.id));
  const onForceReloadOfScreens = () => dispatch(accountActions.forceReloadOfScreens());
  const onSendInviteEmail = () => dispatch(authActions.sendConsentEmail(state.inviteEmail, state.whitelistedAdmins.split(",")));

  const onGetConsentLink = async () => {
    try {
      const data = await authActions.getConsentLink();
      // Assuming window.Clipboard.copy is available (or use navigator.clipboard.writeText)
      window.Clipboard.copy(data.consentLinkEncoded);
      setState((prev) => ({ ...prev, copiedConsentLink: true }));
      setTimeout(() => {
        setState((prev) => ({ ...prev, copiedConsentLink: false }));
      }, 2000);
    } catch (err) {
      console.error("Failed to fetch consent link:", err);
      setState((prev) => ({ ...prev, copiedConsentLink: false }));
    }
  };

  // Helper to toggle nested feature flags
  const toggleFeature = (group, key) => {
    setState((prev) => ({
      ...prev,
      [group]: {
        ...prev[group],
        [key]: !get(prev[group], key, false),
      },
    }));
  };

  // Feature toggle handlers
  const onEnableAppChange = () => toggleFeature("serviceFeatures", "enableApp");
  const onEnableScreenChange = () => toggleFeature("serviceFeatures", "enableScreen");
  const onEnableInsightsChange = () => toggleFeature("serviceFeatures", "enableInsights");
  const onEnableWorkplaceAnalysisChange = () =>
    toggleFeature("serviceFeatures", "enableWorkplaceAnalysis");
  const onEnableNoShowAnalysisChange = () =>
    toggleFeature("serviceFeatures", "enableNoShowAnalysis");
  const onEnableEnergyAnalysisChange = () =>
    toggleFeature("serviceFeatures", "enableEnergyAnalysis");
  const onEnableAdminInvitesChange = () =>
    toggleFeature("serviceFeatures", "enableAdminInvites");

  const onUseAzureApisChange = () => toggleFeature("applicationFeatures", "useAzureApis");
  const onUseDittoApisChange = () => toggleFeature("applicationFeatures", "useDittoApis");
  const onUseMSALChange = () => toggleFeature("applicationFeatures", "useMSAL");
  const onShowTeamChange = () => toggleFeature("applicationFeatures", "showTeam");

  const onShowAppChange = () => toggleFeature("dashboardFeatures", "showApp");
  const onShowMeetingChange = () => toggleFeature("dashboardFeatures", "showMeetingRooms");
  const onShowWorkplaceChange = () => toggleFeature("dashboardFeatures", "showWorkplace");

  const onUseGraphResourcesChanged = () =>
    toggleFeature("applicationFeatures", "useGraphResources");
  const onShowCapacityChanged = () =>
    toggleFeature("applicationFeatures", "showCapacity");

  // Check for changes before saving
  const hasCompanyUpdates = () => {
    if (!state.hasLoadedState) {
      return false;
    }

    // Print out each change
    if (state.name !== company.name) console.log("Change - name", state.name, company.name);
    if (state.mazemapCampusTag !== company.mazemapCampusTag) console.log("Change - mazemapCampusTag", state.mazemapCampusTag, company.mazemapCampusTag);
    if (state.mazemapApiKey !== company.mazemapApiKey) console.log("Change - mazemapApiKey", state.mazemapApiKey, company.mazemapApiKey);
    if (state.yanziAccountIds !== company.yanziAccountIds) console.log("Change - yanziAccountIds", state.yanziAccountIds, company.yanziAccountIds);
    if (JSON.stringify(state.applicationFeatures) !== JSON.stringify(company.applicationFeatures)) console.log("Change - applicationFeatures", state.applicationFeatures, company.applicationFeatures);
    if (JSON.stringify(state.dashboardFeatures) !== JSON.stringify(company.dashboardFeatures)) console.log("Change - dashboardFeatures", state.dashboardFeatures, company.dashboardFeatures);
    if (JSON.stringify(state.serviceFeatures) !== JSON.stringify(company.serviceFeatures)) console.log("Change - serviceFeatures", state.serviceFeatures, company.serviceFeatures);

    return (
      state.name.length > 0 &&
      (state.name !== company.name ||
        state.mazemapCampusTag !== company.mazemapCampusTag ||
        state.mazemapApiKey !== company.mazemapApiKey ||
        state.yanziAccountIds !== company.yanziAccountIds ||
        JSON.stringify(state.applicationFeatures) !==
          JSON.stringify(company.applicationFeatures) ||
        JSON.stringify(state.dashboardFeatures) !==
          JSON.stringify(company.dashboardFeatures) ||
        JSON.stringify(state.serviceFeatures) !== JSON.stringify(company.serviceFeatures))
    );
  };

  const hasWidgetProfileUpdates = () => {
    if (!state.hasLoadedState) {
      return false;
    }

    if (state.defaultPeopleCountCustomTagId !== (widgetProfile?.defaultPeopleCountCustomTagId || "")) {
      console.log("Change - defaultPeopleCountCustomTagId", state.defaultPeopleCountCustomTagId, widgetProfile?.defaultPeopleCountCustomTagId);
    }

    return (
      state.defaultPeopleCountCustomTagId !== (widgetProfile?.defaultPeopleCountCustomTagId || "")
    );
  }

  const onSave = () => {
    if (hasWidgetProfileUpdates()) {
      const body = {
        locationPeopleCountCustomTags: [],
        hiddenWidgets: [],
        overviewWidgets: [],
        ...widgetProfile,
        defaultPeopleCountCustomTagId: state.defaultPeopleCountCustomTagId,
      };

      // Remove companyId
      delete body.companyId;

      dispatch(widgetActions.setWidgetProfile(state.id, body));
    }

    if (hasCompanyUpdates()) {
      const body = {
        name: state.name,
        mazemapCampusTag: state.mazemapCampusTag || "",
        mazemapApiKey: state.mazemapApiKey || "",
        applicationFeatures: {
          useAzureApis: !!state.applicationFeatures.useAzureApis,
          useDittoApis: !!state.applicationFeatures.useDittoApis,
          useMSAL: !!state.applicationFeatures.useMSAL,
          showTeam: !!state.applicationFeatures.showTeam,
          useGraphResources: !!state.applicationFeatures.useGraphResources,
          showCapacity: !!state.applicationFeatures.showCapacity,
        },
        dashboardFeatures: {
          showApp: !!state.dashboardFeatures.showApp,
          showMeetingRooms: !!state.dashboardFeatures.showMeetingRooms,
          showWorkplace: !!state.dashboardFeatures.showWorkplace,
        },
        serviceFeatures: {
          enableApp: !!state.serviceFeatures.enableApp,
          enableScreen: !!state.serviceFeatures.enableScreen,
          enableInsights: !!state.serviceFeatures.enableInsights,
          enableWorkplaceAnalysis: !!state.serviceFeatures.enableWorkplaceAnalysis,
          enableNoShowAnalysis: !!state.serviceFeatures.enableNoShowAnalysis,
          enableEnergyAnalysis: !!state.serviceFeatures.enableEnergyAnalysis,
          enableAdminInvites: !!state.serviceFeatures.enableAdminInvites,
        },
      };

      const yanziAccountIdsArray = state.yanziAccountIds.split(",");
      const firstYanziAccountId = get(yanziAccountIdsArray, "[0]", null);
      if (!isEmpty(firstYanziAccountId)) {
        body.yanziAccountIds = yanziAccountIdsArray;
      }

      dispatch(authActions.updateCompany(state.id, body, history.push));
    }
  };

  const onCancel = () => {
    setState((prev) => ({
      ...prev,
      name: company.name || "",
      mazemapCampusTag: company.mazemapCampusTag || "",
      mazemapApiKey: company.mazemapApiKey || "",
      yanziAccountIds: company.yanziAccountIds || "",
      applicationFeatures: !isEmpty(company.applicationFeatures)
        ? { ...company.applicationFeatures }
        : {},
      dashboardFeatures: !isEmpty(company.dashboardFeatures)
        ? { ...company.dashboardFeatures }
        : {},
      serviceFeatures: !isEmpty(company.serviceFeatures)
        ? { ...company.serviceFeatures }
        : {},
      defaultPeopleCountCustomTagId: widgetProfile?.defaultPeopleCountCustomTagId || "",
    }));
  };

  const onDelete = () => {
    dispatch(authActions.deleteCompany(state.id, history.push));
  };

  const getOptionFooter = () => {
    if (hasCompanyUpdates() || hasWidgetProfileUpdates()) {
      return (
        <OptionFooter cancel={onCancel} options={[{ label: "Save", callback: onSave }]} />
      );
    }
    return null;
  };

  const {
    name,
    mazemapCampusTag,
    mazemapApiKey,
    yanziAccountIds,
    inviteEmail,
    whitelistedAdmins,
    defaultPeopleCountCustomTagId,
    copiedConsentLink,
    applicationFeatures,
    dashboardFeatures,
    serviceFeatures,
  } = state;

  // if (isLoading) {
  //   return <Loader fullScreen />;
  // }

  const validConsentEmail = inviteEmail
    .trim()
    .match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  const validWhitelistedEmails = whitelistedAdmins
    .split(",")
    .every((email) =>
      email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    );

  return (
    <OuterContainer $darkBackground>
      <InnerContainer>
        <Row size="xl" $paddingTop="15px">
          <Col>
            <h2>General</h2>
          </Col>
        </Row>
        <Row>
          <Col size="sm">
            <InputBox
              onChange={onNameChange}
              label="Name"
              valid={name}
              value={name}
              required
              showValidIcon
              style={{ paddingTop: 0 }}
            />
          </Col>
        </Row>
        <Row>
          <InputBox
            onChange={onMazemapCampusTagChanged}
            label="MazeMap campus tag"
            value={mazemapCampusTag}
          />
        </Row>
        <Row>
          <div style={{ width: "350px" }}>
            <InputBox
              onChange={onMazemapApiKeyChanged}
              label="MazeMap API key"
              value={mazemapApiKey}
            />
          </div>
          <div style={{ marginLeft: "10px" }}>
            <Button
              text="Sync with MazeMap"
              onClick={onSyncMazemap}
              paddingTop="44px"
              slim
            />
          </div>
        </Row>
        <Row>
          <InputBox
            onChange={onYanziAccountIDsChange}
            label="Yanzi account IDs"
            value={yanziAccountIds}
          />
          <div style={{ marginLeft: "10px" }}>
            <Button
              text="Sync with Yanzi"
              onClick={onSyncYanzi}
              paddingTop="44px"
              slim
            />
          </div>
        </Row>
        {auth.hasSudoRole && (
          <>
            <Row $paddingTop="20px">
              <p>
                Click this button to force all screens (in all companies) to restart
              </p>
            </Row>
            <Row>
              <Button
                text="Restart Screens"
                onClick={onForceReloadOfScreens}
                slim
                color="red"
              />
            </Row>
          </>
        )}
        <Row size="xl" $paddingTop="20px">
          <Col>
            <h2>Service features</h2>
            <p></p>
          </Col>
        </Row>
        <Row>
          <Checkbox
            label="App"
            description="(users can log in to the app and admins can create content)"
            isChecked={get(serviceFeatures, "enableApp", false)}
            onClick={onEnableAppChange}
          />
        </Row>
        <Row>
          <Checkbox
            label="Screens"
            description="(admins can create and use screens)"
            isChecked={get(serviceFeatures, "enableScreen", false)}
            onClick={onEnableScreenChange}
          />
        </Row>
        <Row>
          <Checkbox
            label="Insights"
            description="(give access to the deprecated reports)"
            isChecked={get(serviceFeatures, "enableInsights", false)}
            onClick={onEnableInsightsChange}
          />
        </Row>
        <Row>
          <Checkbox
            label="Workplace analysis"
            description="(give access to workplace reports)"
            isChecked={get(serviceFeatures, "enableWorkplaceAnalysis", false)}
            onClick={onEnableWorkplaceAnalysisChange}
          />
        </Row>
        <Row>
          <Checkbox
            label="No-show analysis"
            description="(give access to no-show reports)"
            isChecked={get(serviceFeatures, "enableNoShowAnalysis", false)}
            onClick={onEnableNoShowAnalysisChange}
          />
        </Row>
        <Row>
          <Checkbox
            label="Energy analysis"
            description="(give access to energy reports)"
            isChecked={get(serviceFeatures, "enableEnergyAnalysis", false)}
            onClick={onEnableEnergyAnalysisChange}
          />
        </Row>
        <Row>
          <Checkbox
            label="Admin invites"
            description="(allow admins to invite other admins)"
            isChecked={get(serviceFeatures, "enableAdminInvites", false)}
            onClick={onEnableAdminInvitesChange}
          />
        </Row>
        <Row size="xl" $paddingTop="20px">
          <Col>
            <h2>Application features</h2>
            <p></p>
          </Col>
        </Row>
        <Row>
          <Checkbox
            label="Use Azure APIs"
            description="(show meetings and booking from M365)"
            isChecked={get(applicationFeatures, "useAzureApis", false)}
            onClick={onUseAzureApisChange}
          />
        </Row>
        <Row>
          <Checkbox
            label="Use Ditto APIs"
            description="(show live data to users)"
            isChecked={get(applicationFeatures, "useDittoApis", false)}
            onClick={onUseDittoApisChange}
          />
        </Row>
        <Row>
          <Checkbox
            label="Use MSAL"
            description="(force users to log in with MSAL - for Intune)"
            isChecked={get(applicationFeatures, "useMSAL", false)}
            onClick={onUseMSALChange}
          />
        </Row>
        <Row>
          <Checkbox
            label="Show Team"
            description="(enable the team view in the app)"
            isChecked={get(applicationFeatures, "showTeam", true)}
            onClick={onShowTeamChange}
          />
        </Row>
        <Row size="xl" $paddingTop="20px">
          <Col>
            <h2>Dashboard features</h2>
            <p></p>
          </Col>
        </Row>
        <Row>
          <Checkbox
            label="App statistics"
            description="(active and total users)"
            isChecked={get(dashboardFeatures, "showApp", false)}
            onClick={onShowAppChange}
          />
        </Row>
        <Row size="xl" $paddingTop="20px">
          <Col>
            <h2>Resources</h2>
            <p></p>
          </Col>
        </Row>
        <Row size="md">
          <Checkbox
            label="Use Graph resources"
            isChecked={get(applicationFeatures, "useGraphResources", false)}
            onClick={onUseGraphResourcesChanged}
          />
        </Row>
        <Row size="md">
          <Checkbox
            label="Show resource capacity"
            description="(when using graph resources)"
            isChecked={get(applicationFeatures, "showCapacity", false)}
            onClick={onShowCapacityChanged}
          />
        </Row>
        <Row size="xl" $paddingTop="20px">
          <Col>
            <h2>Widget profile</h2>
            <p>
              The people count widgets on the front page requires a custom tag that the data is fetched from. The default will be used for all locations without a defined custom tag id.
            </p>
          </Col>
        </Row>
        <Row>
          <Col size="sm">
            <InputBox
              onChange={onDefaultPeopleCountCustomTagIdChange}
              label="Default people count custom tag id"
              value={defaultPeopleCountCustomTagId}
            />
          </Col>
        </Row>
        <Row size="xl" $paddingTop="20px">
          <Col>
            <h2>Azure/Entra ID consent-flow</h2>
            <p>
              Send a consent e-mail to the Azure admin to start the Entra ID sync. You can also pre-register BLDNG.ai admins to define who will get access when the Entra ID sync is done.
            </p>
          </Col>
        </Row>
        <Row>
          <Col size="sm">
            <InputBox
              onChange={onWhitelistedAdminsChange}
              label="BLDNG.ai admins"
              placeholder="comma-separated e-mails"
              value={whitelistedAdmins}
              valid={validWhitelistedEmails}
              required
              showValidIcon
            />
          </Col>
        </Row>
        <Row>
          <div style={{ width: "350px" }}>
            <InputBox
              onChange={onInviteEmailChange}
              label="Azure admin"
              placeholder="e-mail"
              value={inviteEmail}
              valid={validConsentEmail}
              required
              showValidIcon
            />
          </div>
          <div style={{ marginLeft: "10px" }}>
            <Button
              text="Send consent e-mail"
              onClick={onSendInviteEmail}
              paddingTop="44px"
              slim
              disabled={!validConsentEmail}
            />
          </div>
          <div style={{ marginLeft: "10px" }}>
            <Button
              text={copiedConsentLink ? "Copied" : "Copy consent link"}
              onClick={onGetConsentLink}
              paddingTop="44px"
              slim
              disabled={copiedConsentLink}
            />
          </div>
        </Row>
        <Row>
          <Col size="md">
            <TextBox
              label="SCIM token"
              value={get(company, "scimToken", "")}
              disabled
              showCopy
            />
          </Col>
        </Row>
      </InnerContainer>
      {getOptionFooter()}
    </OuterContainer>
  );
};

export default CompanySettings;

export const OuterContainer = styled.div`
  overflow: auto;
  height: 100%;

  background-color: ${props => {
    if (props.$darkBackground) {
      return "#f6f6f6";
    }
    return "white";
  }};
  overflow: visible;
`;

export const InnerContainer = styled.div`

`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: ${props => props.$paddingTop || "0px"};
  
  justify-content: ${props => {
    if (props.$justifyContent) {
      return props.$justifyContent;
    }
  }};

  width: 100%;
  max-width: ${props => {
    
    if (props.width) {
      return props.width;
    }

    switch (props.size) {
      case "xs":
        return "380px";
      case "sm":
        return "540px";
      case "md":
        return "720px";
      case "lg":
        return "960px";
      case "xl":
        return "1140px";
      default:
        return "100%";
    }}
  };
`;

export const Col = styled.div`
  display: inline-block;
  // float: left;
  width: 100%;
  margin-right: ${props => props.$noMargin ? "" : "15px"};
  max-width: ${props => {

    if (props.width) {
      return props.width;
    }

    switch (props.size) {
      case "xs":
        return "380px";
      case "sm":
        return "540px";
      case "md":
        return "720px";
      case "lg":
        return "960px";
      case "xl":
        return "1140px";
      default:
        return "100%";
    }}
  };
`;