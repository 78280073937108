import React, { Component } from "react";
import { Container, Col, Row, Hidden, Visible } from "react-grid-system";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty, map } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { QRCodeSVG } from "qrcode.react";
import Loader from "../../components/Loader";
import Locations from "./locations";
import Sensors from "./sensors";
import Gateways from "./gateways";
import Locationtags from "./locationTags";
import Resources from "./resources";
import Dashboard from "./dashboard";
import Content from "./content";
import UserGroups from "./groups";
import CustomTags from "./customTags";
import LocationSettings from "./settings";
import NotFound from "../NotFound";
import MapboxMap from "../Map/MapboxMap";
import DrawMap from "../Map/DrawMap";
import Legend from "../../components/Legend";
import { MapMode } from "../../constants/stateTypes";
import { isClockwise } from "../../mapHelpers";
import Tag from "../../components/Tag";
import SubNavigation from "../../components/SubNavigation";
import BreadCrumb from "../../components/BreadCrumb";
import Freetext from "../../components/Freetext";
import Title from "../../components/Title";
import MapContainer from "../Map/SVGMap/container";
import * as adminActions from "../../actions/admins";
import * as locationActions from "../../actions/locations";
import * as sensorActions from "../../actions/sensors";
import * as selectedActions from "../../actions/selected";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";

class HierarchyContainer extends Component {
  
  constructor(props) {
    // console.log("HierarchyContainer constructor", props);
    super(props);
    this.state = {
      highlightedLocationId: null,
      highlightedSensorId: null,
      highlightedGatewayId: null,
      highlightedFeatureId: null,
    }
    this.onBreadCrumbClick = this.onBreadCrumbClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onConnectDisplayClick = this.onConnectDisplayClick.bind(this);
    this.setHighlightedLocation = this.setHighlightedLocation.bind(this);
    this.setHighlightedSensor = this.setHighlightedSensor.bind(this);
    this.setHighlightedGateway = this.setHighlightedGateway.bind(this);
    this.setHighlightedFeature = this.setHighlightedFeature.bind(this);
    this.getMapElement = this.getMapElement.bind(this);
    this.setSelectedFeatures = this.setSelectedFeatures.bind(this);
    
    // Only load data if location change
    if (props.locationId !== props.location.id || props.location.id === undefined) {
      if (props.locationId !== undefined && props.locationId !== "root") {
        props.getLocation(props.locationId);
      }
      else {
        props.getRootLocation();
      }
    }

    // Only load sensors if not loaded before
    if (props.locationId !== undefined && props.locationId !== "root") {
      const sensorsQueryHash = `${props.locationId}-{"offset":0,"limit":20,"sortBy":"name","sortOrder":"asc"}`;
      if (props.sensors.sensorsQueryHash !== sensorsQueryHash) {
        props.getSensors(props.locationId);
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("HierarchyContainer componentDidUpdate");
    
    // Only load data if location change
    if (prevProps.locationId !== this.props.locationId) {
      if (this.props.locationId !== undefined && this.props.locationId !== "root") {
        this.props.getLocation(this.props.locationId);
        this.props.getSensors(this.props.locationId);
      }
      else {
        this.props.getRootLocation();
      }
    }
    
    if (!isEmpty(this.props.locationHierarchy) && this.props.locationId !== "root" && this.props.locationId !== undefined) {

      const breadcrumbs = this.props.breadcrumbs[this.props.locationId] ?? [];

      // If breadcrumbs include a floor, load the floor map
      const floor = breadcrumbs.find(breadcrumb => breadcrumb.type === "floor");
      if (floor && floor.id !== this.props.location.floorMapId && !this.props.isMapLoading) {
        this.props.getFloorMap(floor.id);
      }
    }
  }
  
  onBackClick(id) {
    const location = get(this.props.location, "parents[0]", null);
    if (location) {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${location}/locations`);
    }
    else {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/locations`);
    }
  }

  onBreadCrumbClick(id) {
    if (id === "root") {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/locations`);
    }
    else {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${id}/locations`);
    }
  }

  onEditClick() {
    if (this.props.locationId === "root") {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/settings`);
    }
    else {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/edit-location`);
    }
  }

  onConnectDisplayClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/display-location`);
  }

  setHighlightedLocation(id) {
    if (this.state.highlightedLocationId !== id) {

      const geojsonData = this.props.location.type.match(/^(root|region|building)$/) ? this.props.auth.map : this.props.location.floorMap;
      const svgData = this.props.location.floorSVGMap;
      const imdfData = this.props.location.floorIMDFMap;

      if (svgData) {
        // featureId is the same as locationId
        this.setState({ highlightedLocationId: id, highlightedFeatureId: id, highlightedSensorId: null, highlightedGatewayId: null });
      }
      else if (geojsonData) {
        // Get featureId from locationId
        const feature = geojsonData.features.find(feature => feature.properties?.locationId === id);

        if (feature) {
          this.setState({ highlightedLocationId: id, highlightedFeatureId: feature.id, highlightedSensorId: null, highlightedGatewayId: null });
        }
        else {
          this.setState({ highlightedLocationId: id, highlightedFeatureId: null, highlightedSensorId: null, highlightedGatewayId: null });
        }
      }
      else if (imdfData) {
        // Get featureId from locationId
        const feature = Object.values(imdfData).reduce((acc, val) => acc.concat(val.features), []).find(feature => feature.properties?.locationId === id);

        if (feature) {
          this.setState({ highlightedLocationId: id, highlightedFeatureId: feature.id, highlightedSensorId: null, highlightedGatewayId: null });
        }
        else {
          this.setState({ highlightedLocationId: id, highlightedFeatureId: null, highlightedSensorId: null, highlightedGatewayId: null });
        }
      }
    }
  }

  setHighlightedSensor(id) {
    if (this.state.highlightedSensorId !== id) {

      const geojsonData = this.props.location.type.match(/^(root|region|building)$/) ? this.props.auth.map : this.props.location.floorMap;
      const svgData = this.props.location.floorSVGMap;
      const imdfData = this.props.location.floorIMDFMap;

      if (svgData) {
        // featureId is the same as sensorId
        this.setState({ highlightedSensorId: id, highlightedFeatureId: id, highlightedLocationId: null, highlightedGatewayId: null });
      }
      else if (geojsonData) {
        // Get featureId from sensorId
        const feature = geojsonData.features.find(feature => feature.properties?.sensor?._id === id);

        if (feature) {
          this.setState({ highlightedSensorId: id, highlightedFeatureId: feature.id, highlightedLocationId: null, highlightedGatewayId: null });
        }
        else {
          this.setState({ highlightedSensorId: id, highlightedFeatureId: null, highlightedLocationId: null, highlightedGatewayId: null });
        }
      }
      else if (imdfData) {
        // Get featureId from sensorId
        const feature = Object.values(imdfData).reduce((acc, val) => acc.concat(val.features), []).find(feature => feature.properties?.sensor?._id === id);

        if (feature) {
          this.setState({ highlightedSensorId: id, highlightedFeatureId: feature.id, highlightedLocationId: null, highlightedGatewayId: null });
        }
        else {
          this.setState({ highlightedSensorId: id, highlightedFeatureId: null, highlightedLocationId: null, highlightedGatewayId: null });
        }
      }
    }
  }

  setHighlightedGateway(id) {
    if (this.state.highlightedGatewayId !== id) {

      const geojsonData = this.props.location.type.match(/^(root|region|building)$/) ? this.props.auth.map : this.props.location.floorMap;
      const svgData = this.props.location.floorSVGMap;
      const imdfData = this.props.location.floorIMDFMap;

      if (svgData) {
        // featureId is the same as gatewayId
        this.setState({ highlightedGatewayId: id, highlightedFeatureId: id, highlightedLocationId: null, highlightedSensorId: null });
      }
      else if (geojsonData) {
        // Get featureId from gatewayId
        const feature = geojsonData.features.find(feature => feature.properties?.gateway?._id === id);

        if (feature) {
          this.setState({ highlightedGatewayId: id, highlightedFeatureId: feature.id, highlightedLocationId: null, highlightedSensorId: null });
        }
        else {
          this.setState({ highlightedGatewayId: id, highlightedFeatureId: null, highlightedLocationId: null, highlightedSensorId: null });
        }
      }
      else if (imdfData) {
        // Get featureId from gatewayId
        const feature = Object.values(imdfData).reduce((acc, val) => acc.concat(val.features), []).find(feature => feature.properties?.gateway?._id === id);

        if (feature) {
          this.setState({ highlightedGatewayId: id, highlightedFeatureId: feature.id, highlightedLocationId: null, highlightedSensorId: null });
        }
        else {
          this.setState({ highlightedGatewayId: id, highlightedFeatureId: null, highlightedLocationId: null, highlightedSensorId: null });
        }
      }
    }
  }

  setHighlightedFeature(id) {
    if (this.state.highlightedFeatureId !== id) {

      const geojsonData = this.props.location.type.match(/^(root|region|building)$/) ? this.props.auth.map : this.props.location.floorMap;
      const svgData = this.props.location.floorSVGMap;
      const imdfData = this.props.location.floorIMDFMap;

      if (svgData) {
        // featureId is the same as locationId
        this.setState({ highlightedFeatureId: id, highlightedLocationId: id, highlightedSensorId: id, highlightedGatewayId: id });
      }
      else if (geojsonData) {
        // Get locationId from featureId
        const feature = geojsonData.features.find(feature => feature.id === id);

        if (feature) {
          const locationId = feature.properties?.locationId;
          const sensorId = feature.properties?.sensor?._id;
          const gatewayId = feature.properties?.gateway?._id;
          this.setState({ highlightedFeatureId: id, highlightedLocationId: locationId, highlightedSensorId: sensorId, highlightedGatewayId: gatewayId });
        }
        else {
          this.setState({ highlightedFeatureId: id, highlightedLocationId: null, highlightedSensorId: null, highlightedGatewayId: null });
        }
      }
      else if (imdfData) {
        // Get locationId from featureId
        const feature = Object.values(imdfData).reduce((acc, val) => acc.concat(val.features), []).find(feature => feature.id === id);

        if (feature) {
          const locationId = feature.properties?.locationId;
          const sensorId = feature.properties?.sensor?._id;
          const gatewayId = feature.properties?.gateway?._id;
          this.setState({ highlightedFeatureId: id, highlightedLocationId: locationId, highlightedSensorId: sensorId, highlightedGatewayId: gatewayId });
        }
        else {
          this.setState({ highlightedFeatureId: id, highlightedLocationId: null, highlightedSensorId: null, highlightedGatewayId: null });
        }
      }
    }
  }

  setSelectedFeatures(features) {
    // console.log("Features selected", features, this.props.mapMode);

    // Change default map mode in each view type
    const path = window.location.pathname;
    const inEditMapView = path.includes("/edit-map");
    const mapMode = inEditMapView ? this.props.editMapMode : this.props.viewMapMode;

    // Trigger different actions based on map mode
    switch (mapMode) {
      case MapMode.view:
        if (features.length === 1) {
          const feature = features[0];
          if (feature.properties?.locationId) {
            this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${feature.properties.locationId}/locations`);
          }
          else if (feature.properties?.sensor?._id) {
            this.props.history.push(`/companies/${this.props.match.params.companyId}/sensors/${feature.properties.sensor._id}`, { canGoBack: true });
          }
          else if (feature.properties?.gateway?._id) {
            this.props.history.push(`/companies/${this.props.match.params.companyId}/gateways/${feature.properties.gateway._id}`, { canGoBack: true });
          }
        }
        break;
      case MapMode.inspect:
      case MapMode.connect:
      case MapMode.edit:
        this.props.setSelectedFeatures(features);
        break;
      default:
        break;
    }
  }

  downloadSVG() {
    const svg = document.querySelector('svg');
    svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    svg.setAttribute("width", "200");
    svg.setAttribute("height", "200");
    const base64doc = Buffer.from(decodeURIComponent(encodeURIComponent(svg.outerHTML))).toString('base64');
    const a = document.createElement('a');
    const e = new MouseEvent('click');
    a.download = 'qr-code.svg';
    a.href = 'data:image/svg+xml;base64,' + base64doc;
    a.dispatchEvent(e);
    svg.setAttribute("width", "80");
    svg.setAttribute("height", "80");
  }

  getMapElement() {
    const path = window.location.pathname;
    const inEditMapView = path.includes("/edit-map");
    const inLocationsView = path.endsWith("/locations/") || path.endsWith("/locations") || path.endsWith("/locations/root");
    const inSensorsView = path.includes("/sensors");
    const inGatewaysView = path.includes("/gateways");
    const inLocationTagsView = path.includes("/location-tags");
  
    // const showMap = inLocationsView || inSensorsView || inGatewaysView || inLocationTagsView || inEditMapView;
  
    // Check if user has permission to edit map
    if (inEditMapView) {
      if (!this.props.auth.hasITSupportRole) {
        return null;
      }
    }
  
    // Get map data
    const svgData = this.props.location.floorSVGMap;
    let geojsonData = this.props.location.type.match(/^(root|region|building)$/) ? this.props.auth.map : this.props.location.floorMap;
    let imdfData = this.props.location.floorIMDFMap;

    if (imdfData) {
      // Clone and fix imdfData (e.g. correct polygon winding)
      imdfData = JSON.parse(JSON.stringify(imdfData));
      Object.keys(imdfData).forEach((key) => {
        if (imdfData[key]?.features) {
          imdfData[key].features = imdfData[key].features.map((feature) => {
            if (feature.geometry.type === "Polygon") {
              let exteriorRing = feature.geometry.coordinates[0];
              if (isClockwise(exteriorRing)) {
                exteriorRing = exteriorRing.reverse();
              }
              feature.geometry.coordinates[0] = exteriorRing;
            }
            return feature;
          });
        }
      });
    }

    if (geojsonData) {
      // Clone and fix geojsonData (e.g. correct polygon winding)
      geojsonData = JSON.parse(JSON.stringify(geojsonData));
      geojsonData.features = geojsonData.features.map((feature) => {
        if (feature.geometry.type === "Polygon") {
          let exteriorRing = feature.geometry.coordinates[0];
          if (isClockwise(exteriorRing)) {
            exteriorRing = exteriorRing.reverse();
          }
          feature.geometry.coordinates[0] = exteriorRing;
        }
        return feature;
      });

      // Remove features that are not children of the current location
      if (this.props.location?.type === "region") {
        const children = this.props.location?.children ?? [];
        geojsonData.features = geojsonData.features.map(feat => ({ ...feat, properties: { ...feat.properties, disabled: !children.includes(feat.properties?.locationId ?? "") } }));
      }
      else if (this.props.location?.type === "building") {
        geojsonData.features = geojsonData.features.map(feat => ({ ...feat, properties: { ...feat.properties, disabled: feat.properties?.locationId !== this.props.location.id } }));
      }

      // Sort features by disabled (draw disabled features first)
      geojsonData.features.sort((a, b) => (a.properties?.disabled === b.properties?.disabled) ? 0 : a.properties?.disabled ? -1 : 1);
    }

    const mapOptions = {
      mode: inEditMapView ? this.props.editMapMode : this.props.viewMapMode,
      setMode: inEditMapView ? this.props.setEditMapMode : this.props.setViewMapMode,
      currentLocationId: this.props.locationId,
      highlight: {
        highlightedFeatureId: this.state.highlightedFeatureId,
        setHighlightedFeatureId: this.setHighlightedFeature,
        canHighlightLocation: inLocationsView || inLocationTagsView,
        canHighlightSensor: inSensorsView,
        canHighlightGateway: inGatewaysView
      },
      selection: {
        selectedFeatures: this.props.selectedFeatures,
        setSelectedFeatures: this.setSelectedFeatures,
        canSelectLocation: inLocationsView || inLocationTagsView,
        canSelectSensor: inSensorsView,
        canSelectGateway: inGatewaysView,
      },
      data: {
        geojsonData: geojsonData,
        imdfData: imdfData,
        svgData: svgData,
        locations: this.props.flatLocationHierarchy,
      },
      permissions: {
        canNavigate: !inEditMapView,
        canConnect: !inEditMapView,
        canEdit: inEditMapView,
        canInspect: inEditMapView
      }
    };

    return (
      <MapContainer
        key={`${inEditMapView ? "edit-" : "general-"}${this.props.locationId}`}
        {...mapOptions}
      />
    );

    if (inEditMapView) {
      if (!this.props.auth.hasITSupportRole) {
        return null;
      }
      // mapElement = (
      //   <DrawMap
      //     id={props.locationId}
      //     company={props.selectedCompany}
      //     location={props.location}
      //     sensors={props.sensors}
      //     map={props.location.type.match(/^(root|region|building)$/) ? props.auth.map : props.location.floorMap}
      //     drawMode={props.mapMode}
      //     />
      // );
    }
    else {
        // if (props.auth.map) {
        //   mapElement = (
        //     <MapboxMap 
        //       mapId="map-container" 
        //       id={props.locationId} 
        //       company={props.selectedCompany} 
        //       location={props.location}
        //       map={props.auth.map} 
        //       highlightedId={state.highlightedId} 
        //       onGotoLocation={(locationId) => {props.history.push(`/companies/${props.match.params.companyId}/locations/${locationId}/locations/`)}}
        //       onGotoSensor={(sensorId) => (props.history.push({ pathname: `/companies/${props.match.params.companyId}/sensors/${sensorId}`, state: { canGoBack: true } }))}
        //       onGotoGateway={(gatewayId) => (props.history.push({ pathname: `/companies/${props.match.params.companyId}/gateways/${gatewayId}`, state: { canGoBack: true }}))}
        //       showConnectMode={inLocationsView}
        //       showSensors={inSensorsView}
        //       showGateways={inGatewaysView}
        //       showMap={showMap}
        //     />
        //   );
        // }

      // else {
      //   if (props.location.floorSVGMap) {
      //     mapElement = (
      //       <SVGMap
      //         key={"general-" + props.locationId}
      //         svgData={props.location.floorSVGMap}
      //         locations={props.flatLocationHierarchy}
      //         />
      //     );
      //   }
      //   else if (props.location.floorMap) {
  
      //     // Clone and fix geojsonData (e.g. correct polygon winding)
      //     const fixedGeojsonData = JSON.parse(JSON.stringify(geojsonData));
      //     fixedGeojsonData.features = fixedGeojsonData.features.map((feature) => {
      //       if (feature.geometry.type === "Polygon") {
      //         let exteriorRing = feature.geometry.coordinates[0];
      //         if (isClockwise(exteriorRing)) {
      //           exteriorRing = exteriorRing.reverse();
      //         }
      //         feature.geometry.coordinates[0] = exteriorRing;
      //       }
      //       return feature;
      //     });
  
      //     mapElement = (
      //       <SVGMap
      //         key={"general-" + props.locationId}
      //         geojsonData={fixedGeojsonData}
      //         locations={props.flatLocationHierarchy}
      //         />
      //     );
  
          // mapElement = (
          //   <>
          //     <MapboxMap 
          //       mapId="map-container" 
          //       id={props.locationId} 
          //       company={props.selectedCompany} 
          //       location={props.location} 
          //       map={props.location.floorMap} 
          //       highlightedId={state.highlightedId}
          //       sensors={props.sensors.data.sensors}
          //       gateways={props.gateways.data.gateways}
          //       onGotoLocation={(locationId) => {props.history.push(`/companies/${props.match.params.companyId}/locations/${locationId}/locations/`)}} 
          //       onGotoSensor={(sensorId) => (props.history.push({ pathname: `/companies/${props.match.params.companyId}/sensors/${sensorId}`, state: { canGoBack: true } }))}
          //       onGotoGateway={(gatewayId) => (props.history.push({ pathname: `/companies/${props.match.params.companyId}/gateways/${gatewayId}`, state: { canGoBack: true }}))}
          //       showConnectMode={inLocationsView && props.location.id !== "root" && props.location.type !== "building"}
          //       showSensors={inSensorsView}
          //       showGateways={inGatewaysView}
          //       showMap={showMap}
          //     />
          //     { (inSensorsView || inGatewaysView) && <Legend mapMode={MapMode.Status} /> }
          //   </>
          // );
        // }
      // }
    }
    
    return mapElement;
  }

  render() {
    // console.log("Container.state", this.state);

    if (!isEmpty(this.props.location.name)) {
      document.title = `BLDNG.ai - ${this.props.location.name}`;
    }
    else {
      document.title = `BLDNG.ai - Locations`;
    }

    if (this.props.isAuthLoading || this.props.isLocationLoading) {
      return <Loader fullScreen />;
    }

    if (!get(this.props.auth, "user.companies", null)) {
      return (
        <Container fluid style={{ paddingLeft: "60px", paddingRight: "60px" }}>
          <Row className={style.topRow}>
            <Col>
              <Freetext header="No access" content="You don't have administration privileges for any company in BLDNG.ai." />
            </Col>
          </Row>
        </Container>
      );
    }

    if (this.props.selectedCompany === null) {
      return (
        <Container fluid style={{ paddingLeft: "60px", paddingRight: "60px" }}>
          <Row className={style.topRow}>
            <Col>
              <Freetext header="No company is selected" />
            </Col>
          </Row>
        </Container>
      );
    }

    if (!get(this.props.location, "id", false)) {
      return <Loader fullScreen />;
    }

    const subNavigationLinks = [];

    if (this.props.locationId === "root") {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/locations`, label: `Locations` });

      if (this.props.auth.hasSupportRole) {
        subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/location-tags`, label: `Location tags` });
      }

      if (!get(this.props.selectedCompany, "applicationFeatures.useGraphResources", false) || this.props.auth.hasITSupportRole) {
        subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/resources`, label: `Resources`, hiddenForAdmins: get(this.props.selectedCompany, "applicationFeatures.useGraphResources", false) });
      }
      
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/root/custom-tags`, label: `Custom tags` });

      if (this.props.auth.hasITSupportRole) {
        subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/edit-map`, label: `Map`, hiddenForAdmins: true });
      }
    }
    else {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/locations`, label: `Locations`, notifications: this.props.location.locationCount });

      if (this.props.auth.hasAdminRole) {
        subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/sensors`, label: `Sensors`, notifications: this.props.location.sensorCount || 0 });
      }

      if (this.props.auth.hasAdminRole) {
        subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/gateways`, label: `Gateways`, notifications: this.props.location.gatewayCount });
      }

      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/groups`, label: `Groups`, notifications: this.props.location.usergroupCount });

      if (get(this.props.selectedCompany, "serviceFeatures.enableApp", false) || this.props.auth.hasITSupportRole) {
        subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/content`, label: `Content`, notifications: this.props.location.contentCount, hiddenForAdmins: !get(this.props.selectedCompany, "serviceFeatures.enableApp", false) });
      }

      if (!get(this.props.selectedCompany, "applicationFeatures.useGraphResources", false) || this.props.auth.hasITSupportRole) {
        subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/resources`, label: `Resources`, notifications: this.props.location.resourceCount, hiddenForAdmins: get(this.props.selectedCompany, "applicationFeatures.useGraphResources", false) });
      }
      
      if (!isEmpty(this.props.location.atomicSensors)) {
        subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/history`, label: `Dashboard` });
      }
      
      if (this.props.location.type === "floor" && this.props.auth.hasITSupportRole) {
        subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/edit-map`, label: `Map`, hiddenForAdmins: true });
      }
    }

    if (this.props.auth.hasAdminRole) {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/settings`, label: `QR codes` });
    }

    let header;
    if (this.props.locationId === "root") {
      header = this.props.selectedCompany ? this.props.selectedCompany.name : "";
    }
    else {
      header = this.props.location.name;
    }

    let breadcrumbs;
    if (this.props.locationId !== "root" && this.props.selectedCompany) {
      // Add company name as root in hierarchy
      const root = [{ id: "root", name: this.props.selectedCompany.name }];
      
      if (isEmpty(this.props.location.breadcrumbs)) {
        breadcrumbs = [root];
      }
      else {
        breadcrumbs = [root.concat(this.props.location.breadcrumbs)];
      }
    }

    // Hide settings if not SUDO
    var showEditButton;
    if (this.props.locationId !== "root") {
      showEditButton = this.props.auth.hasSupportRole;
    }

    const headerContent = (
      <div className={style.topRow}>
        <Title text={header} />
        <div role="button" tabIndex="0" className={style.editPencil} onClick={this.onEditClick} onKeyDown={this.onEditClick}>
          { (showEditButton) && <FontAwesomeIcon icon={faPencilAlt} size="lg" color="#1C4D82" /> }
        </div>
        {
          breadcrumbs && (
            <Hidden xs sm md>
              <BreadCrumb crumbs={breadcrumbs} onClick={this.onBreadCrumbClick} />
            </Hidden>
          )
        }
        {
          this.props.location.customTags && this.props.location.customTags.length > 0 && (
            <Hidden xs sm md>
              <div style={{ display: "inline-block", width: "20px" }} />
              { this.props.location.customTags.map(tag => <Tag key={tag.id} text={tag.name} color={tag.colorTheme} />) }
            </Hidden>
          )
        }
      </div>
    );

    const routeSwitch = (
      <Switch>
        <Redirect exact from="/companies/:companyId/locations/:locationId" to={{ pathname: `/companies/:companyId/locations/${this.props.locationId}/locations` }} />
        <Route path="/companies/:companyId/locations/:locationId/locations" children={(props) => <Locations {...props} locationId={this.props.locationId} highlightedLocationId={this.state.highlightedLocationId} setHighlightedLocation={this.setHighlightedLocation} />} />
        <Route path="/companies/:companyId/locations/:locationId/sensors" children={(props) => <Sensors {...props} locationId={this.props.locationId} highlightedSensorId={this.state.highlightedSensorId} setHighlightedSensor={this.setHighlightedSensor} />} />
        <Route path="/companies/:companyId/locations/:locationId/gateways" children={(props) => <Gateways {...props} locationId={this.props.locationId} highlighteGatewayId={this.state.highlighteGatewayId} setHighlightedGateway={this.setHighlightedGateway} />} />
        { this.props.auth.hasSupportRole && <Route path="/companies/:companyId/locations/:locationId/location-tags" children={(props) => <Locationtags {...props} locationId={this.props.locationId} />} /> }
        <Route path="/companies/:companyId/locations/:locationId/edit-map" children={() => <div /> /* Do not render a side view */} />
        <Route path="/companies/:companyId/locations/:locationId/content/:contentId?" children={(props) => <Content {...props} locationId={this.props.locationId} contentId={props.match.params.contentId}/>} />
        <Route path="/companies/:companyId/locations/:locationId/groups/:groupId?" children={(props) => <UserGroups {...props} locationId={this.props.locationId} groupId={props.match.params.groupId} />} />
        <Route path="/companies/:companyId/locations/:locationId/resources" children={(props) => <Resources {...props} locationId={this.props.locationId} />} />
        <Route path="/companies/:companyId/locations/:locationId/history" children={(props) => <Dashboard {...props} locationId={this.props.locationId} />} />
        <Route path="/companies/:companyId/locations/root/custom-tags/:tagId?" children={(props) => <CustomTags {...props} locationId={this.props.locationId} tagId={props.match.params.tagId} />} />
        <Route path="/companies/:companyId/locations/:locationId/settings" children={(props) => <LocationSettings {...props} locationId={this.props.locationId} />} />
        <Route>
          <NotFound />
        </Route>
      </Switch>
    );

    const path = window.location.pathname;
    const inLocationsView = path.endsWith("/locations/") || path.endsWith("/locations") || path.endsWith("/locations/root");
    const inSensorsView = path.includes("/sensors");
    const inGatewaysView = path.includes("/gateways");
    const inLocationTagsView = path.includes("/location-tags");
    const inEditMapView = path.includes("/edit-map");
    const showMap = inLocationsView || inSensorsView || inGatewaysView || inLocationTagsView || inEditMapView;

    let hasRelevantFeatureSelection = false;
    if (this.props.viewMapMode === MapMode.connect) {
      hasRelevantFeatureSelection = !isEmpty(this.props.selectedFeatures);
    }

    const hasSelection = !isEmpty(this.props.selectedLocations)|| 
      !isEmpty(this.props.selectedSensors) || 
      !isEmpty(this.props.selectedGateways) || 
      !isEmpty(this.props.selectedUnregisteredSensors) ||
      hasRelevantFeatureSelection;

    const mapElement = this.getMapElement();
    
    // Add map to side view here to prevent rerendering of map when switching between views
    const contentView = (
      <>
        <Hidden xs sm md>
          <div className={hasSelection && !inEditMapView ? style.listContainer : style.listContainerHidden}>
            <div className={style.row}>
              <div className={inEditMapView ? undefined : (showMap ? style.col50 : style.col)}>
                {!inEditMapView && routeSwitch}
              </div>
              <div className={inEditMapView ? style.col : style.mapPart40} style={showMap ? {} : { display: "none" }}>
                {mapElement}
              </div>
              {inEditMapView && <div>{routeSwitch}</div>}
            </div>
          </div>
        </Hidden>

        <Visible xs sm md>
          <div className={hasSelection && !inEditMapView ? style.listContainerMobile : style.listContainerHiddenMobile}>
            <div className={style.row}>
              <div className={style.col}>
                {routeSwitch}
              </div>
            </div>
          </div>
        </Visible>
      </>
    );

    return (
      <div className={style.page}>
        <Hidden xs sm md>
          { 
            this.props.locationId !== "root" && this.props.location.type !== "building" && (
              <div className={style.roomMapButton} onClick={this.downloadSVG}>
                <QRCodeSVG value={`${this.props.auth.webAppUrl}room?companyId=${this.props.match.params.companyId}&locationId=${this.props.location.id}`} size={80} />
              </div>
            )
          }
          <div className={style.largeHeader}>
            { headerContent }
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.smallHeader}>
            { headerContent }
          </div>
        </Visible>
        <SubNavigation links={subNavigationLinks} />
        
        { contentView }

      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    location: state.location,
    locationHierarchy: state.locations.hierarchy,
    flatLocationHierarchy: state.locations.flatHierarchy,
    breadcrumbs: state.locations.breadcrumbs,
    sensors: state.sensors,
    gateways: state.gateways,
    selectedCompany: state.auth.selectedCompany,
    selectedLocations: state.selected.locations,
    selectedSensors: state.selected.sensors,
    selectedGateways: state.selected.gateways,
    selectedUnregisteredSensors: state.selected.unregisteredSensors,
    selectedFeatures: state.selected.features,
    viewMapMode: state.selected.viewMapMode,
    editMapMode: state.selected.editMapMode,
    isAuthLoading: state.loading.auth,
    isLocationLoading: state.loading[API.GET_LOCATION],
    isMapLoading: state.loading[API.GET_FLOOR_MAP]
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getLocation: locationActions.getLocation,
    getRootLocation: locationActions.getRootLocation,
    getFloorMap: locationActions.getFloorMap,
    getCompanyMapUrl: adminActions.getCompanyMapUrl,
    getSensors: sensorActions.getSensors,

    setSelectedFeatures: selectedActions.setSelectedFeatures,
    setViewMapMode: selectedActions.setViewMapMode,
    setEditMapMode: selectedActions.setEditMapMode
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HierarchyContainer));