export const gameData = [
  {
    "id": "396070",
    "name": "0-100 Familj",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "99",
    "playingtime": "15",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-13 players",
    "ratingsAverage": "7.25",
    "categories": [
      "Trivia"
    ]
  },
  {
    "id": "297206",
    "name": "0-100 Vit",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "99",
    "playingtime": "15",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 3-15 players",
    "ratingsAverage": "6.29592",
    "categories": [
      "Trivia"
    ]
  },
  {
    "id": "396073",
    "name": "0-100 Vit: Mini",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "99",
    "playingtime": "10",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.7",
    "categories": [
      "Trivia"
    ]
  },
  {
    "id": "7865",
    "name": "10 Days in Africa",
    "yearpublished": "2003",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.56768",
    "categories": [
      "Educational",
      "Travel"
    ]
  },
  {
    "id": "22398",
    "name": "10 Days in Asia",
    "yearpublished": "2007",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "25",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.67753",
    "categories": [
      "Educational",
      "Travel"
    ]
  },
  {
    "id": "5867",
    "name": "10 Days in Europe",
    "yearpublished": "2002",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.60602",
    "categories": [
      "Educational",
      "Travel"
    ]
  },
  {
    "id": "64956",
    "name": "10 Days in the Americas",
    "yearpublished": "2010",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.6683",
    "categories": [
      "Educational",
      "Travel"
    ]
  },
  {
    "id": "7866",
    "name": "10 Days in the USA",
    "yearpublished": "2003",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.48603",
    "categories": [
      "Educational",
      "Travel"
    ]
  },
  {
    "id": "276431",
    "name": "20 Second Showdown",
    "yearpublished": "2019",
    "minPlayers": "5",
    "maxPlayers": "20",
    "playingtime": "0",
    "bestWith": "Best with 6-7 players",
    "recommendedWith": "Recommended with 5, 7-13 players",
    "ratingsAverage": "6.55622",
    "categories": [
      "Party Game",
      "Real-time"
    ]
  },
  {
    "id": "86073",
    "name": "5 Second Rule",
    "yearpublished": "2010",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 3-6+ players",
    "ratingsAverage": "5.47968",
    "categories": [
      "Card Game",
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "339741",
    "name": "50 Clues: Dead or Alive",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "6.47102",
    "categories": [
      "Horror",
      "Mature / Adult",
      "Murder / Mystery",
      "Puzzle"
    ]
  },
  {
    "id": "393304",
    "name": "50 Clues: Down in the Depths",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "6.72917",
    "categories": [
      "Adventure",
      "Card Game",
      "Exploration",
      "Murder / Mystery",
      "Puzzle"
    ]
  },
  {
    "id": "339743",
    "name": "50 Clues: Keepers of Evil",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-2 players",
    "ratingsAverage": "6.37578",
    "categories": [
      "Horror",
      "Mature / Adult",
      "Murder / Mystery",
      "Puzzle"
    ]
  },
  {
    "id": "391213",
    "name": "50 Clues: No Sign of Life",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "6.53226",
    "categories": [
      "Adventure",
      "Card Game",
      "Exploration",
      "Puzzle"
    ]
  },
  {
    "id": "282008",
    "name": "50 Clues: The Fate of Leopold",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "6.46251",
    "categories": [
      "Card Game",
      "Deduction",
      "Horror",
      "Mature / Adult",
      "Murder / Mystery",
      "Puzzle"
    ]
  },
  {
    "id": "393305",
    "name": "50 Clues: The Last Journey",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "6.45652",
    "categories": [
      "Adventure",
      "Card Game",
      "Exploration",
      "Puzzle"
    ]
  },
  {
    "id": "282006",
    "name": "50 Clues: The Pendulum of the Dead",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "6.3866",
    "categories": [
      "Card Game",
      "Deduction",
      "Horror",
      "Mature / Adult",
      "Murder / Mystery",
      "Puzzle"
    ]
  },
  {
    "id": "339742",
    "name": "50 Clues: The Secret of the Mark",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "6.42444",
    "categories": [
      "Horror",
      "Mature / Adult",
      "Murder / Mystery",
      "Puzzle"
    ]
  },
  {
    "id": "282007",
    "name": "50 Clues: White Sleep",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "6.5465",
    "categories": [
      "Card Game",
      "Deduction",
      "Horror",
      "Mature / Adult",
      "Murder / Mystery",
      "Puzzle"
    ]
  },
  {
    "id": "268586",
    "name": "6 nimmt! 25 Jahre",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "45",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 3-8 players",
    "ratingsAverage": "7.29633",
    "categories": [
      "Card Game",
      "Number"
    ]
  },
  {
    "id": "68448",
    "name": "7 Wonders",
    "yearpublished": "2010",
    "minPlayers": "2",
    "maxPlayers": "7",
    "playingtime": "30",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-7 players",
    "ratingsAverage": "7.67343",
    "categories": [
      "Ancient",
      "Card Game",
      "City Building",
      "Civilization",
      "Economic"
    ]
  },
  {
    "id": "316377",
    "name": "7 Wonders (Second Edition)",
    "yearpublished": "2020",
    "minPlayers": "3",
    "maxPlayers": "7",
    "playingtime": "30",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-7 players",
    "ratingsAverage": "7.83806",
    "categories": [
      "Ancient",
      "Card Game",
      "City Building",
      "Civilization",
      "Economic"
    ]
  },
  {
    "id": "173346",
    "name": "7 Wonders Duel",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "8.08969",
    "categories": [
      "Ancient",
      "Card Game",
      "City Building",
      "Civilization",
      "Economic"
    ]
  },
  {
    "id": "346703",
    "name": "7 Wonders: Architects",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "7",
    "playingtime": "25",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-7 players",
    "ratingsAverage": "7.04248",
    "categories": [
      "Ancient"
    ]
  },
  {
    "id": "378356",
    "name": "The A.R.T. Project",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "40",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.15469",
    "categories": [
      "Dice",
      "Travel"
    ]
  },
  {
    "id": "5",
    "name": "Acquire",
    "yearpublished": "1964",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.35069",
    "categories": [
      "Economic",
      "Territory Building"
    ]
  },
  {
    "id": "5130",
    "name": "Afrikan tähti",
    "yearpublished": "1951",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5+ players",
    "ratingsAverage": "4.71592",
    "categories": [
      "Children's Game",
      "Exploration"
    ]
  },
  {
    "id": "383179",
    "name": "Age of Innovation",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "200",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "8.56077",
    "categories": [
      "Civilization",
      "Fantasy",
      "Territory Building"
    ]
  },
  {
    "id": "4098",
    "name": "Age of Steam",
    "yearpublished": "2002",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.85321",
    "categories": [
      "Economic",
      "Post-Napoleonic",
      "Trains",
      "Transportation"
    ]
  },
  {
    "id": "200680",
    "name": "Agricola (Revised Edition)",
    "yearpublished": "2016",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.95115",
    "categories": [
      "Animals",
      "Economic",
      "Farming"
    ]
  },
  {
    "id": "359999",
    "name": "Agricola 15",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.67547",
    "categories": [
      "Animals",
      "Economic",
      "Farming"
    ]
  },
  {
    "id": "359402",
    "name": "Ahoy",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "75",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.29858",
    "categories": [
      "Fantasy",
      "Nautical",
      "Pirates"
    ]
  },
  {
    "id": "32116",
    "name": "Airships",
    "yearpublished": "2007",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.36329",
    "categories": [
      "Aviation / Flight",
      "Dice",
      "Industry / Manufacturing"
    ]
  },
  {
    "id": "357563",
    "name": "Akropolis",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.47321",
    "categories": [
      "Ancient",
      "City Building",
      "Territory Building"
    ]
  },
  {
    "id": "3818",
    "name": "Alias",
    "yearpublished": "1990",
    "minPlayers": "4",
    "maxPlayers": "12",
    "playingtime": "60",
    "bestWith": "Best with 4, 6 players",
    "recommendedWith": "Recommended with 4-9 players",
    "ratingsAverage": "5.93518",
    "categories": [
      "Party Game",
      "Trivia",
      "Word Game"
    ]
  },
  {
    "id": "368267",
    "name": "American Psycho: A Killer Game",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.67021",
    "categories": [
      "Card Game",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "277670",
    "name": "Among Cultists: A Social Deduction Thriller",
    "yearpublished": "2023",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "90",
    "bestWith": "Best with 5-8 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "7.15085",
    "categories": [
      "Deduction",
      "Horror"
    ]
  },
  {
    "id": "285967",
    "name": "Ankh: Gods of Egypt",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.76821",
    "categories": [
      "Ancient",
      "Fantasy",
      "Fighting",
      "Miniatures",
      "Mythology"
    ]
  },
  {
    "id": "156266",
    "name": "Apex Theropod Deck-Building Game",
    "yearpublished": "2015",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "290",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.20002",
    "categories": [
      "Animals",
      "Card Game",
      "Prehistoric"
    ]
  },
  {
    "id": "400314",
    "name": "Apiary",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.78826",
    "categories": [
      "Animals",
      "Science Fiction"
    ]
  },
  {
    "id": "367662",
    "name": "Applejack",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "6.7753",
    "categories": [
      "Farming"
    ]
  },
  {
    "id": "403468",
    "name": "AQUA: Biodiversity in the Oceans",
    "yearpublished": "2024",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.24657",
    "categories": [
      "Nautical",
      "Puzzle"
    ]
  },
  {
    "id": "371077",
    "name": "Arborea",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.57094",
    "categories": [
      "Animals",
      "Fantasy"
    ]
  },
  {
    "id": "140934",
    "name": "Arboretum",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.27447",
    "categories": [
      "Card Game",
      "Number"
    ]
  },
  {
    "id": "380938",
    "name": "Archeos Society",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "7.12212",
    "categories": [
      "Exploration"
    ]
  },
  {
    "id": "236457",
    "name": "Architects of the West Kingdom",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "80",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.72205",
    "categories": [
      "City Building",
      "Medieval"
    ]
  },
  {
    "id": "342942",
    "name": "Ark Nova",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "8.53459",
    "categories": [
      "Animals",
      "Economic",
      "Environmental"
    ]
  },
  {
    "id": "368966",
    "name": "Ark Nova: Marine Worlds",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.91928",
    "categories": [
      "Expansion for Base-game",
      "Animals",
      "Economic",
      "Environmental"
    ]
  },
  {
    "id": "15987",
    "name": "Arkham Horror",
    "yearpublished": "2005",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "240",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "7.23776",
    "categories": [
      "Adventure",
      "Fighting",
      "Horror",
      "Novel-based"
    ]
  },
  {
    "id": "257499",
    "name": "Arkham Horror (Third Edition)",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "180",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.68972",
    "categories": [
      "Adventure",
      "Fighting",
      "Horror",
      "Novel-based"
    ]
  },
  {
    "id": "205637",
    "name": "Arkham Horror: The Card Game",
    "yearpublished": "2016",
    "minPlayers": "1",
    "maxPlayers": "2",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-2+ players",
    "ratingsAverage": "8.12869",
    "categories": [
      "Adventure",
      "Card Game",
      "Collectible Components",
      "Exploration",
      "Horror",
      "Novel-based"
    ]
  },
  {
    "id": "359609",
    "name": "Arkham Horror: The Card Game (Revised Edition)",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "180",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.63406",
    "categories": [
      "Adventure",
      "Card Game",
      "Collectible Components",
      "Exploration",
      "Fantasy",
      "Horror",
      "Novel-based"
    ]
  },
  {
    "id": "395375",
    "name": "Art Society",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.56082",
    "categories": [
      "Puzzle"
    ]
  },
  {
    "id": "362452",
    "name": "Atiwa",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.47154",
    "categories": [
      "Animals",
      "Economic",
      "Educational",
      "Environmental",
      "Farming",
      "Territory Building"
    ]
  },
  {
    "id": "383459",
    "name": "Aurum",
    "yearpublished": "2023",
    "minPlayers": "3",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "7.02894",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "231581",
    "name": "AuZtralia",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.34699",
    "categories": [
      "Economic",
      "Exploration",
      "Fantasy",
      "Horror"
    ]
  },
  {
    "id": "123185",
    "name": "Axis & Allies: 1941",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "180",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.37422",
    "categories": [
      "Wargame",
      "World War II"
    ]
  },
  {
    "id": "41863",
    "name": "Axis & Allies: 1942",
    "yearpublished": "2009",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "240",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.92605",
    "categories": [
      "Economic",
      "Wargame",
      "World War II"
    ]
  },
  {
    "id": "61692",
    "name": "Axis & Allies: Europe 1940",
    "yearpublished": "2010",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "360",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.58276",
    "categories": [
      "Economic",
      "Wargame",
      "World War II"
    ]
  },
  {
    "id": "55829",
    "name": "Axis & Allies: Pacific 1940",
    "yearpublished": "2009",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "360",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 2-3 players",
    "ratingsAverage": "7.53624",
    "categories": [
      "Economic",
      "Fighting",
      "Wargame",
      "World War II"
    ]
  },
  {
    "id": "133956",
    "name": "Axis & Allies: WWI 1914",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "180",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-7 players",
    "ratingsAverage": "7.27578",
    "categories": [
      "Economic",
      "Wargame",
      "World War I"
    ]
  },
  {
    "id": "230802",
    "name": "Azul",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.73309",
    "categories": [
      "Abstract Strategy",
      "Puzzle",
      "Renaissance"
    ]
  },
  {
    "id": "346965",
    "name": "Azul: Queen's Garden",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.46104",
    "categories": [
      "Abstract Strategy",
      "Puzzle",
      "Renaissance"
    ]
  },
  {
    "id": "287954",
    "name": "Azul: Summer Pavilion",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.58896",
    "categories": [
      "Abstract Strategy",
      "Puzzle"
    ]
  },
  {
    "id": "204281",
    "name": "Baby Clues",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.56663",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction"
    ]
  },
  {
    "id": "2397",
    "name": "Backgammon",
    "yearpublished": "1630",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.56728",
    "categories": [
      "Abstract Strategy",
      "Dice"
    ]
  },
  {
    "id": "340677",
    "name": "Bad Company",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "7.26491",
    "categories": []
  },
  {
    "id": "203661",
    "name": "Bad People",
    "yearpublished": "2016",
    "minPlayers": "3",
    "maxPlayers": "10",
    "playingtime": "60",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-10 players",
    "ratingsAverage": "5.39371",
    "categories": [
      "Humor",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "27225",
    "name": "Bananagrams",
    "yearpublished": "2006",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 3-5 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.40702",
    "categories": [
      "Real-time",
      "Word Game"
    ]
  },
  {
    "id": "3955",
    "name": "BANG!",
    "yearpublished": "2002",
    "minPlayers": "4",
    "maxPlayers": "7",
    "playingtime": "40",
    "bestWith": "Best with 7 players",
    "recommendedWith": "Recommended with 5-7 players",
    "ratingsAverage": "6.48899",
    "categories": [
      "American West",
      "Bluffing",
      "Card Game",
      "Deduction",
      "Fighting"
    ]
  },
  {
    "id": "143741",
    "name": "BANG! The Dice Game",
    "yearpublished": "2013",
    "minPlayers": "3",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 6-7 players",
    "recommendedWith": "Recommended with 5-8 players",
    "ratingsAverage": "6.88771",
    "categories": [
      "American West",
      "Bluffing",
      "Deduction",
      "Dice",
      "Fighting",
      "Party Game"
    ]
  },
  {
    "id": "383607",
    "name": "Barcelona",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.7509",
    "categories": [
      "City Building"
    ]
  },
  {
    "id": "220478",
    "name": "BARPIG",
    "yearpublished": "2017",
    "minPlayers": "3",
    "maxPlayers": "7",
    "playingtime": "60",
    "bestWith": "Best with 7 players",
    "recommendedWith": "Recommended with 4-7 players",
    "ratingsAverage": "6.33093",
    "categories": [
      "Animals",
      "Card Game",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "251247",
    "name": "Barrage",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "8.15458",
    "categories": [
      "Economic",
      "Environmental",
      "Industry / Manufacturing"
    ]
  },
  {
    "id": "2425",
    "name": "Battleship",
    "yearpublished": "1931",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "4.73165",
    "categories": [
      "Children's Game",
      "Nautical"
    ]
  },
  {
    "id": "369880",
    "name": "Beer & Bread",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.39561",
    "categories": [
      "Card Game",
      "Farming",
      "Medieval"
    ]
  },
  {
    "id": "228660",
    "name": "Betrayal at Baldur's Gate",
    "yearpublished": "2017",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.08216",
    "categories": [
      "Adventure",
      "Exploration",
      "Fantasy",
      "Miniatures"
    ]
  },
  {
    "id": "10547",
    "name": "Betrayal at House on the Hill",
    "yearpublished": "2004",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.01805",
    "categories": [
      "Adventure",
      "Exploration",
      "Horror",
      "Miniatures"
    ]
  },
  {
    "id": "317985",
    "name": "Beyond the Sun",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.89091",
    "categories": [
      "Civilization",
      "Science Fiction",
      "Space Exploration"
    ]
  },
  {
    "id": "32441",
    "name": "Bezzerwizzer",
    "yearpublished": "2007",
    "minPlayers": "2",
    "maxPlayers": "12",
    "playingtime": "45",
    "bestWith": "Best with 4, 8 players",
    "recommendedWith": "Recommended with 3-6, 8-10, 12 players",
    "ratingsAverage": "6.42325",
    "categories": [
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "133337",
    "name": "Bezzerwizzer Mini",
    "yearpublished": "2011",
    "minPlayers": "2",
    "maxPlayers": "0",
    "playingtime": "5",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.54316",
    "categories": [
      "Trivia"
    ]
  },
  {
    "id": "321602",
    "name": "Bezzerwizzer Ord",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "0",
    "playingtime": "60",
    "bestWith": "(Undetermined)",
    "recommendedWith": "(Undetermined)",
    "ratingsAverage": "6.52917",
    "categories": [
      "Trivia"
    ]
  },
  {
    "id": "337617",
    "name": "Bezzerwizzer Timeline",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "99",
    "playingtime": "40",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "6.82346",
    "categories": [
      "Card Game",
      "Educational",
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "255664",
    "name": "The Binding of Isaac: Four Souls",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.98887",
    "categories": [
      "Card Game",
      "Collectible Components",
      "Horror",
      "Negotiation",
      "Video Game Theme"
    ]
  },
  {
    "id": "342343",
    "name": "Birdwatcher",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.12321",
    "categories": [
      "Animals",
      "Card Game",
      "Educational",
      "Environmental"
    ]
  },
  {
    "id": "323612",
    "name": "Bitoku",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.80378",
    "categories": [
      "Fantasy",
      "Mythology"
    ]
  },
  {
    "id": "96345",
    "name": "Black Stories: Funny Death Edition",
    "yearpublished": "2011",
    "minPlayers": "2",
    "maxPlayers": "15",
    "playingtime": "20",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 3-10 players",
    "ratingsAverage": "6.02413",
    "categories": [
      "Card Game",
      "Deduction",
      "Horror",
      "Humor",
      "Murder / Mystery",
      "Party Game"
    ]
  },
  {
    "id": "57052",
    "name": "Black Stories: Real Crime Edition",
    "yearpublished": "2009",
    "minPlayers": "2",
    "maxPlayers": "15",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-8 players",
    "ratingsAverage": "5.67371",
    "categories": [
      "Card Game",
      "Deduction",
      "Horror",
      "Murder / Mystery",
      "Party Game"
    ]
  },
  {
    "id": "311930",
    "name": "Block and Key",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "40",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "6.97334",
    "categories": [
      "Abstract Strategy",
      "Puzzle"
    ]
  },
  {
    "id": "379300",
    "name": "Block Party",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.70505",
    "categories": [
      "Action / Dexterity",
      "Party Game"
    ]
  },
  {
    "id": "206504",
    "name": "The Blood of an Englishman",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.53919",
    "categories": [
      "Abstract Strategy",
      "Card Game",
      "Novel-based"
    ]
  },
  {
    "id": "170216",
    "name": "Blood Rage",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4+ players",
    "ratingsAverage": "7.91508",
    "categories": [
      "Fantasy",
      "Fighting",
      "Miniatures",
      "Mythology"
    ]
  },
  {
    "id": "351605",
    "name": "Bohnanza: 25th Anniversary Edition",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "7",
    "playingtime": "45",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.60056",
    "categories": [
      "Card Game",
      "Farming"
    ]
  },
  {
    "id": "413246",
    "name": "Bomb Busters",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.97125",
    "categories": [
      "Deduction",
      "Spies / Secret Agents"
    ]
  },
  {
    "id": "304420",
    "name": "Bonfire",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "100",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.62851",
    "categories": [
      "Fantasy",
      "Puzzle"
    ]
  },
  {
    "id": "372631",
    "name": "Books of Time",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "7.14384",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "343905",
    "name": "Boonlake",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "160",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.63571",
    "categories": [
      "American West",
      "Civilization",
      "Economic",
      "Exploration",
      "Farming",
      "Industry / Manufacturing",
      "Territory Building"
    ]
  },
  {
    "id": "398052",
    "name": "Boonlake: Artifacts",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "160",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-3 players",
    "ratingsAverage": "7.68921",
    "categories": [
      "Expansion for Base-game",
      "Civilization",
      "Economic",
      "Exploration"
    ]
  },
  {
    "id": "355433",
    "name": "boop.",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.08991",
    "categories": [
      "Abstract Strategy",
      "Animals"
    ]
  },
  {
    "id": "386660",
    "name": "Boss Monster: 10th Anniversary Edition",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.16615",
    "categories": [
      "Card Game",
      "Fantasy",
      "Video Game Theme"
    ]
  },
  {
    "id": "131835",
    "name": "Boss Monster: The Dungeon Building Card Game",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.27192",
    "categories": [
      "Card Game",
      "Fantasy",
      "Video Game Theme"
    ]
  },
  {
    "id": "328124",
    "name": "Bot Factory",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.40805",
    "categories": [
      "Industry / Manufacturing",
      "Science Fiction"
    ]
  },
  {
    "id": "240562",
    "name": "Box Quiz: Animerad Film",
    "yearpublished": "0",
    "minPlayers": "2",
    "maxPlayers": "20",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.5",
    "categories": [
      "Trivia"
    ]
  },
  {
    "id": "240563",
    "name": "Box Quiz: Flag",
    "yearpublished": "0",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "0",
    "categories": [
      "Trivia"
    ]
  },
  {
    "id": "240518",
    "name": "Box Quiz: Kunst",
    "yearpublished": "0",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "0",
    "categories": [
      "Trivia"
    ]
  },
  {
    "id": "240519",
    "name": "Box Quiz: Ord",
    "yearpublished": "0",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "0",
    "categories": [
      "Trivia",
      "Word Game"
    ]
  },
  {
    "id": "224517",
    "name": "Brass: Birmingham",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "8.57941",
    "categories": [
      "Age of Reason",
      "Economic",
      "Industry / Manufacturing",
      "Post-Napoleonic",
      "Trains",
      "Transportation"
    ]
  },
  {
    "id": "28720",
    "name": "Brass: Lancashire",
    "yearpublished": "2007",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "8.19562",
    "categories": [
      "Economic",
      "Industry / Manufacturing",
      "Post-Napoleonic",
      "Transportation"
    ]
  },
  {
    "id": "308989",
    "name": "Bristol 1350",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "9",
    "playingtime": "40",
    "bestWith": "Best with 6-7 players",
    "recommendedWith": "Recommended with 4-9 players",
    "ratingsAverage": "6.91809",
    "categories": [
      "Deduction",
      "Dice",
      "Medieval",
      "Party Game"
    ]
  },
  {
    "id": "172308",
    "name": "Broom Service",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "75",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.16217",
    "categories": [
      "Fantasy"
    ]
  },
  {
    "id": "59149",
    "name": "Bunny Bunny Moose Moose",
    "yearpublished": "2009",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 4-6 players",
    "ratingsAverage": "6.04408",
    "categories": [
      "Action / Dexterity",
      "Animals",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "353905",
    "name": "Bureau of Investigation: Investigations in Arkham & Elsewhere",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "240",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.26844",
    "categories": [
      "Deduction",
      "Horror",
      "Murder / Mystery"
    ]
  },
  {
    "id": "358572",
    "name": "Bureau of Investigation: The Disappearance of a Student",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "90",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.794",
    "categories": [
      "Deduction",
      "Horror",
      "Murder / Mystery",
      "Novel-based",
      "Print & Play"
    ]
  },
  {
    "id": "286537",
    "name": "Burgle Bros 2: The Casino Capers",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "70",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.29207",
    "categories": [
      "Exploration",
      "Maze",
      "Spies / Secret Agents"
    ]
  },
  {
    "id": "155362",
    "name": "Ca$h 'n Guns: Second Edition",
    "yearpublished": "2014",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "30",
    "bestWith": "Best with 6-8 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "6.81751",
    "categories": [
      "Bluffing",
      "Fighting",
      "Humor",
      "Mafia",
      "Negotiation",
      "Party Game"
    ]
  },
  {
    "id": "214",
    "name": "Café International",
    "yearpublished": "1989",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.22131",
    "categories": [
      "Abstract Strategy"
    ]
  },
  {
    "id": "283155",
    "name": "Calico",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.50831",
    "categories": [
      "Animals",
      "Puzzle"
    ]
  },
  {
    "id": "238992",
    "name": "Call to Adventure",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.06365",
    "categories": [
      "Card Game",
      "Fantasy",
      "Novel-based"
    ]
  },
  {
    "id": "260605",
    "name": "Camel Up (Second Edition)",
    "yearpublished": "2018",
    "minPlayers": "3",
    "maxPlayers": "8",
    "playingtime": "45",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 3-8 players",
    "ratingsAverage": "7.56724",
    "categories": [
      "Animals",
      "Arabian",
      "Dice",
      "Racing"
    ]
  },
  {
    "id": "335271",
    "name": "Camel Up: Off Season",
    "yearpublished": "2021",
    "minPlayers": "3",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "6.87127",
    "categories": [
      "Animals",
      "Arabian",
      "Card Game"
    ]
  },
  {
    "id": "290236",
    "name": "Canvas",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "30",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.22318",
    "categories": [
      "Abstract Strategy",
      "Card Game",
      "Puzzle"
    ]
  },
  {
    "id": "232824",
    "name": "Caper",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.98956",
    "categories": [
      "Card Game",
      "City Building",
      "Mafia"
    ]
  },
  {
    "id": "328565",
    "name": "Caper: Europe",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "35",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.67299",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "316343",
    "name": "Capital Lux 2: Pocket",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "40",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.55623",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "393325",
    "name": "Captain Flip",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "20",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.11042",
    "categories": [
      "Pirates"
    ]
  },
  {
    "id": "423533",
    "name": "Captain Obvious",
    "yearpublished": "2024",
    "minPlayers": "3",
    "maxPlayers": "10",
    "playingtime": "60",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 4-10 players",
    "ratingsAverage": "6.43529",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "171131",
    "name": "Captain Sonar",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "60",
    "bestWith": "Best with 8 players",
    "recommendedWith": "Recommended with 6-8 players",
    "ratingsAverage": "7.5097",
    "categories": [
      "Deduction",
      "Fighting",
      "Modern Warfare",
      "Nautical",
      "Real-time"
    ]
  },
  {
    "id": "822",
    "name": "Carcassonne",
    "yearpublished": "2000",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.41252",
    "categories": [
      "Medieval",
      "Territory Building"
    ]
  },
  {
    "id": "4390",
    "name": "Carcassonne: Hunters and Gatherers",
    "yearpublished": "2002",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "35",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.13368",
    "categories": [
      "Prehistoric",
      "Territory Building"
    ]
  },
  {
    "id": "333515",
    "name": "Cards Against Disney",
    "yearpublished": "2018",
    "minPlayers": "4",
    "maxPlayers": "20",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.65185",
    "categories": [
      "Card Game",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "50381",
    "name": "Cards Against Humanity",
    "yearpublished": "2009",
    "minPlayers": "4",
    "maxPlayers": "30",
    "playingtime": "30",
    "bestWith": "Best with 6-8 players",
    "recommendedWith": "Recommended with 4-10 players",
    "ratingsAverage": "5.73498",
    "categories": [
      "Card Game",
      "Humor",
      "Mature / Adult",
      "Party Game",
      "Print & Play"
    ]
  },
  {
    "id": "265853",
    "name": "Cards Against Humanity: Absurd Box",
    "yearpublished": "2018",
    "minPlayers": "4",
    "maxPlayers": "30",
    "playingtime": "30",
    "bestWith": "Best with 6-8 players",
    "recommendedWith": "Recommended with 3-10 players",
    "ratingsAverage": "6.71034",
    "categories": [
      "Expansion for Base-game",
      "Card Game",
      "Mature / Adult"
    ]
  },
  {
    "id": "357180",
    "name": "Cards Against Marvel",
    "yearpublished": "2020",
    "minPlayers": "4",
    "maxPlayers": "25",
    "playingtime": "90",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "4.25",
    "categories": [
      "Card Game",
      "Humor",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "357179",
    "name": "Cards Against Muggles",
    "yearpublished": "2018",
    "minPlayers": "4",
    "maxPlayers": "20",
    "playingtime": "90",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.82778",
    "categories": [
      "Card Game",
      "Humor",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "90305",
    "name": "Cargo Noir",
    "yearpublished": "2011",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.44477",
    "categories": [
      "Economic"
    ]
  },
  {
    "id": "310873",
    "name": "Carnegie",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.99226",
    "categories": [
      "Economic",
      "Industry / Manufacturing",
      "Post-Napoleonic"
    ]
  },
  {
    "id": "5072",
    "name": "Carrom",
    "yearpublished": "0",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2, 4 players",
    "ratingsAverage": "7.09147",
    "categories": [
      "Action / Dexterity"
    ]
  },
  {
    "id": "263918",
    "name": "Cartographers",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "100",
    "playingtime": "45",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-9 players",
    "ratingsAverage": "7.57985",
    "categories": [
      "Fantasy",
      "Territory Building"
    ]
  },
  {
    "id": "315767",
    "name": "Cartographers Heroes",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "100",
    "playingtime": "45",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-10 players",
    "ratingsAverage": "7.74759",
    "categories": [
      "Fantasy",
      "Territory Building"
    ]
  },
  {
    "id": "399215",
    "name": "Cartographers: Map Pack Collection",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "100",
    "playingtime": "45",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "7.93524",
    "categories": [
      "Expansion for Base-game",
      "Fantasy",
      "Territory Building"
    ]
  },
  {
    "id": "295947",
    "name": "Cascadia",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.92117",
    "categories": [
      "Animals",
      "Environmental",
      "Puzzle"
    ]
  },
  {
    "id": "407318",
    "name": "Cascadia: Rolling Rivers",
    "yearpublished": "2024",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.27903",
    "categories": [
      "Abstract Strategy",
      "American West",
      "Animals",
      "Dice",
      "Environmental",
      "Puzzle",
      "Territory Building"
    ]
  },
  {
    "id": "271320",
    "name": "The Castles of Burgundy",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.44824",
    "categories": [
      "Dice",
      "Medieval",
      "Territory Building"
    ]
  },
  {
    "id": "155426",
    "name": "Castles of Mad King Ludwig",
    "yearpublished": "2014",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.5122",
    "categories": [
      "Post-Napoleonic",
      "Puzzle"
    ]
  },
  {
    "id": "300327",
    "name": "The Castles of Tuscany",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.2152",
    "categories": [
      "Industry / Manufacturing",
      "Renaissance",
      "Territory Building"
    ]
  },
  {
    "id": "345972",
    "name": "Cat in the Box: Deluxe Edition",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "40",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.49594",
    "categories": [
      "Animals",
      "Card Game"
    ]
  },
  {
    "id": "228504",
    "name": "Cat Lady",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.99675",
    "categories": [
      "Animals",
      "Card Game",
      "Humor"
    ]
  },
  {
    "id": "13",
    "name": "CATAN",
    "yearpublished": "1995",
    "minPlayers": "3",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "7.09434",
    "categories": [
      "Economic",
      "Negotiation"
    ]
  },
  {
    "id": "27710",
    "name": "Catan Dice Game",
    "yearpublished": "2007",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "5.72547",
    "categories": [
      "Dice"
    ]
  },
  {
    "id": "338697",
    "name": "CATAN: 3D Edition",
    "yearpublished": "2021",
    "minPlayers": "3",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "7.97975",
    "categories": [
      "Economic",
      "Negotiation"
    ]
  },
  {
    "id": "2807",
    "name": "Catan: 5-6 Player Extension",
    "yearpublished": "1996",
    "minPlayers": "5",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 4-6 players",
    "ratingsAverage": "6.97772",
    "categories": [
      "Expansion for Base-game",
      "Negotiation"
    ]
  },
  {
    "id": "926",
    "name": "CATAN: Cities & Knights",
    "yearpublished": "1998",
    "minPlayers": "3",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "7.44923",
    "categories": [
      "Expansion for Base-game",
      "Medieval",
      "Negotiation"
    ]
  },
  {
    "id": "358858",
    "name": "CATAN: Dawn of Humankind",
    "yearpublished": "2022",
    "minPlayers": "3",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "7.31243",
    "categories": [
      "Civilization",
      "Prehistoric"
    ]
  },
  {
    "id": "408727",
    "name": "CATAN: New Energies",
    "yearpublished": "2024",
    "minPlayers": "3",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "7.2765",
    "categories": [
      "Civilization",
      "Economic",
      "Environmental",
      "Territory Building"
    ]
  },
  {
    "id": "220520",
    "name": "Caverna: Cave vs Cave",
    "yearpublished": "2017",
    "minPlayers": "1",
    "maxPlayers": "2",
    "playingtime": "40",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-2 players",
    "ratingsAverage": "7.08602",
    "categories": [
      "Economic",
      "Fantasy",
      "Farming"
    ]
  },
  {
    "id": "330021",
    "name": "Caverna: Cave vs Cave - The Big Box",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "2",
    "playingtime": "40",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-2 players",
    "ratingsAverage": "7.35401",
    "categories": [
      "Economic",
      "Fantasy",
      "Farming"
    ]
  },
  {
    "id": "102794",
    "name": "Caverna: The Cave Farmers",
    "yearpublished": "2013",
    "minPlayers": "1",
    "maxPlayers": "7",
    "playingtime": "210",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.93477",
    "categories": [
      "Animals",
      "Economic",
      "Fantasy",
      "Farming"
    ]
  },
  {
    "id": "312318",
    "name": "Century: Golem Edition - An Endless World",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.51826",
    "categories": [
      "Fantasy"
    ]
  },
  {
    "id": "283619",
    "name": "Century: Golem Edition - Eastern Mountains",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.34009",
    "categories": [
      "Fantasy"
    ]
  },
  {
    "id": "209685",
    "name": "Century: Spice Road",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.29839",
    "categories": [
      "Card Game",
      "Economic",
      "Medieval"
    ]
  },
  {
    "id": "359970",
    "name": "Challengers!",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "45",
    "bestWith": "Best with 4, 6, 8 players",
    "recommendedWith": "Recommended with 2, 4, 6, 8 players",
    "ratingsAverage": "7.0919",
    "categories": [
      "Animals",
      "Card Game",
      "Sports"
    ]
  },
  {
    "id": "227072",
    "name": "The Chameleon",
    "yearpublished": "2017",
    "minPlayers": "3",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 6-7 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "6.52615",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Humor",
      "Party Game",
      "Word Game"
    ]
  },
  {
    "id": "398059",
    "name": "The Chameleon: Picture Edition",
    "yearpublished": "2023",
    "minPlayers": "3",
    "maxPlayers": "8",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 4 players",
    "ratingsAverage": "7.11918",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "172287",
    "name": "Champions of Midgard",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.71506",
    "categories": [
      "Adventure",
      "Dice",
      "Fantasy",
      "Fighting",
      "Medieval",
      "Mythology"
    ]
  },
  {
    "id": "2083",
    "name": "Checkers",
    "yearpublished": "1150",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "4.93132",
    "categories": [
      "Abstract Strategy",
      "Comic Book / Strip"
    ]
  },
  {
    "id": "171",
    "name": "Chess",
    "yearpublished": "1475",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "0",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.22583",
    "categories": [
      "Abstract Strategy"
    ]
  },
  {
    "id": "2386",
    "name": "Chinese Checkers",
    "yearpublished": "1893",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4, 6 players",
    "ratingsAverage": "5.20014",
    "categories": [
      "Abstract Strategy"
    ]
  },
  {
    "id": "239188",
    "name": "Chronicles of Crime",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "7.56107",
    "categories": [
      "Adventure",
      "Deduction",
      "Murder / Mystery"
    ]
  },
  {
    "id": "205398",
    "name": "Citadels",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "60",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 3-7 players",
    "ratingsAverage": "7.24657",
    "categories": [
      "Bluffing",
      "Card Game",
      "City Building",
      "Medieval"
    ]
  },
  {
    "id": "263895",
    "name": "City of the Great Machine",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 1, 4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.7513",
    "categories": [
      "Science Fiction"
    ]
  },
  {
    "id": "233247",
    "name": "Civilization: A New Dawn",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.24924",
    "categories": [
      "Civilization",
      "Exploration"
    ]
  },
  {
    "id": "154022",
    "name": "Clairvoyance",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "15",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "5.54688",
    "categories": [
      "Card Game",
      "Fantasy",
      "Humor"
    ]
  },
  {
    "id": "233371",
    "name": "Clank! In! Space!: A Deck-Building Adventure",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.77469",
    "categories": [
      "Science Fiction"
    ]
  },
  {
    "id": "299659",
    "name": "Clash of Cultures: Monumental Edition",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "240",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "8.30116",
    "categories": [
      "Ancient",
      "City Building",
      "Civilization",
      "Economic",
      "Territory Building"
    ]
  },
  {
    "id": "265784",
    "name": "Cleopatra and the Society of Architects: Deluxe Edition",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.35141",
    "categories": [
      "Ancient",
      "City Building",
      "Mythology"
    ]
  },
  {
    "id": "366162",
    "name": "Clever 4Ever",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.66088",
    "categories": [
      "Dice",
      "Number"
    ]
  },
  {
    "id": "316546",
    "name": "Clever Cubed",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.47658",
    "categories": [
      "Dice",
      "Number"
    ]
  },
  {
    "id": "272739",
    "name": "Clinic: Deluxe Edition",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.60259",
    "categories": [
      "City Building",
      "Economic",
      "Medical",
      "Territory Building",
      "Transportation"
    ]
  },
  {
    "id": "262211",
    "name": "Cloudspire",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "180",
    "bestWith": "Best with 1-2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.17988",
    "categories": [
      "Exploration",
      "Fantasy",
      "Fighting",
      "Video Game Theme"
    ]
  },
  {
    "id": "374342",
    "name": "Clue",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "40",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "5.95148",
    "categories": [
      "Deduction",
      "Murder / Mystery"
    ]
  },
  {
    "id": "294320",
    "name": "CLUE: Dungeons & Dragons",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.3",
    "categories": [
      "Deduction",
      "Fantasy",
      "Memory",
      "Murder / Mystery"
    ]
  },
  {
    "id": "345582",
    "name": "Clue: Liars Edition",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "0",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.59778",
    "categories": [
      "Bluffing",
      "Deduction",
      "Murder / Mystery"
    ]
  },
  {
    "id": "352782",
    "name": "Cluedo Batman Edition",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.9",
    "categories": [
      "Children's Game",
      "Deduction",
      "Murder / Mystery"
    ]
  },
  {
    "id": "11971",
    "name": "Cockroach Poker",
    "yearpublished": "2004",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.80402",
    "categories": [
      "Bluffing",
      "Card Game",
      "Party Game"
    ]
  },
  {
    "id": "178900",
    "name": "Codenames",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 6, 8 players",
    "recommendedWith": "Recommended with 4-8+ players",
    "ratingsAverage": "7.53508",
    "categories": [
      "Card Game",
      "Deduction",
      "Party Game",
      "Spies / Secret Agents",
      "Word Game"
    ]
  },
  {
    "id": "205158",
    "name": "Codenames: Deep Undercover",
    "yearpublished": "2016",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 6, 8 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "6.5694",
    "categories": [
      "Card Game",
      "Deduction",
      "Mature / Adult",
      "Party Game",
      "Spies / Secret Agents",
      "Word Game"
    ]
  },
  {
    "id": "220775",
    "name": "Codenames: Disney - Family Edition",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 4, 6 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "6.60751",
    "categories": [
      "Card Game",
      "Deduction",
      "Movies / TV / Radio theme",
      "Party Game",
      "Word Game"
    ]
  },
  {
    "id": "224037",
    "name": "Codenames: Duet",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.42288",
    "categories": [
      "Card Game",
      "Deduction",
      "Spies / Secret Agents",
      "Word Game"
    ]
  },
  {
    "id": "249821",
    "name": "Codenames: Harry Potter",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "15",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.49644",
    "categories": [
      "Deduction",
      "Movies / TV / Radio theme",
      "Novel-based",
      "Word Game"
    ]
  },
  {
    "id": "220774",
    "name": "Codenames: Marvel",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4, 6-8 players",
    "ratingsAverage": "6.16093",
    "categories": [
      "Card Game",
      "Comic Book / Strip",
      "Deduction",
      "Party Game"
    ]
  },
  {
    "id": "198773",
    "name": "Codenames: Pictures",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-8+ players",
    "ratingsAverage": "7.18228",
    "categories": [
      "Card Game",
      "Deduction",
      "Party Game",
      "Spies / Secret Agents"
    ]
  },
  {
    "id": "377061",
    "name": "Coffee Rush",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.16195",
    "categories": []
  },
  {
    "id": "280694",
    "name": "Color Field",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.09485",
    "categories": [
      "Abstract Strategy",
      "Puzzle"
    ]
  },
  {
    "id": "158899",
    "name": "Colt Express",
    "yearpublished": "2014",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "40",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.09509",
    "categories": [
      "American West",
      "Fighting",
      "Trains"
    ]
  },
  {
    "id": "217949",
    "name": "A Column of Fire",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "6.51026",
    "categories": [
      "Dice",
      "Novel-based",
      "Religious",
      "Renaissance"
    ]
  },
  {
    "id": "361025",
    "name": "Come Together",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "7.39075",
    "categories": [
      "Music"
    ]
  },
  {
    "id": "147151",
    "name": "Concept",
    "yearpublished": "2013",
    "minPlayers": "4",
    "maxPlayers": "12",
    "playingtime": "40",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 3-12 players",
    "ratingsAverage": "6.75299",
    "categories": [
      "Deduction",
      "Party Game",
      "Word Game"
    ]
  },
  {
    "id": "124361",
    "name": "Concordia",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "100",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "8.08456",
    "categories": [
      "Ancient",
      "Economic",
      "Nautical"
    ]
  },
  {
    "id": "2719",
    "name": "Connect Four",
    "yearpublished": "1974",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "10",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "4.98287",
    "categories": [
      "Abstract Strategy",
      "Children's Game"
    ]
  },
  {
    "id": "269595",
    "name": "Copenhagen",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "40",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.95394",
    "categories": [
      "Puzzle"
    ]
  },
  {
    "id": "288606",
    "name": "Coraline: Beware the Other Mother",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "6.21127",
    "categories": [
      "Card Game",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "39463",
    "name": "Cosmic Encounter",
    "yearpublished": "2008",
    "minPlayers": "3",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 4-5+ players",
    "ratingsAverage": "7.51337",
    "categories": [
      "Bluffing",
      "Negotiation",
      "Science Fiction",
      "Space Exploration"
    ]
  },
  {
    "id": "298572",
    "name": "Cosmic Encounter Duel",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.62618",
    "categories": [
      "Bluffing",
      "Card Game",
      "Science Fiction",
      "Space Exploration"
    ]
  },
  {
    "id": "362020",
    "name": "Cosmoctopus",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.22411",
    "categories": [
      "Adventure",
      "Fantasy",
      "Horror"
    ]
  },
  {
    "id": "131357",
    "name": "Coup",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "15",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.98016",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Party Game",
      "Political"
    ]
  },
  {
    "id": "283642",
    "name": "Cowboy Bebop: Space Serenade",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.35235",
    "categories": [
      "Card Game",
      "Novel-based",
      "Science Fiction",
      "Space Exploration"
    ]
  },
  {
    "id": "891",
    "name": "Cranium",
    "yearpublished": "1998",
    "minPlayers": "4",
    "maxPlayers": "99",
    "playingtime": "60",
    "bestWith": "Best with 8 players",
    "recommendedWith": "Recommended with 4-12, 14, 16, 18-20 players",
    "ratingsAverage": "5.68548",
    "categories": [
      "Party Game",
      "Puzzle",
      "Trivia",
      "Word Game"
    ]
  },
  {
    "id": "324856",
    "name": "The Crew: Mission Deep Sea",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "8.10523",
    "categories": [
      "Adventure",
      "Card Game",
      "Deduction",
      "Mythology",
      "Nautical"
    ]
  },
  {
    "id": "284083",
    "name": "The Crew: The Quest for Planet Nine",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.78119",
    "categories": [
      "Card Game",
      "Science Fiction",
      "Space Exploration"
    ]
  },
  {
    "id": "521",
    "name": "Crokinole",
    "yearpublished": "1876",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2, 4 players",
    "ratingsAverage": "8.05184",
    "categories": [
      "Action / Dexterity"
    ]
  },
  {
    "id": "300753",
    "name": "Cross Clues",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "10",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "7.22136",
    "categories": [
      "Deduction",
      "Party Game",
      "Real-time",
      "Word Game"
    ]
  },
  {
    "id": "246784",
    "name": "Cryptid",
    "yearpublished": "2018",
    "minPlayers": "3",
    "maxPlayers": "5",
    "playingtime": "50",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.43646",
    "categories": [
      "Deduction"
    ]
  },
  {
    "id": "122159",
    "name": "Cthulhu Fluxx",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "40",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.10403",
    "categories": [
      "Card Game",
      "Horror"
    ]
  },
  {
    "id": "253344",
    "name": "Cthulhu: Death May Die",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.19058",
    "categories": [
      "Fantasy",
      "Fighting",
      "Horror",
      "Miniatures",
      "Novel-based"
    ]
  },
  {
    "id": "344338",
    "name": "Cuphead: Fast Rolling Dice Game",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.1917",
    "categories": [
      "Fighting",
      "Real-time",
      "Video Game Theme"
    ]
  },
  {
    "id": "420091",
    "name": "DaDaDa",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "99",
    "playingtime": "30",
    "bestWith": "Best with 4-8 players",
    "recommendedWith": "Recommended with 2-10 players",
    "ratingsAverage": "7.10442",
    "categories": [
      "Card Game",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "411403",
    "name": "Danger Danger",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "10",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2, 4, 6 players",
    "ratingsAverage": "6.813",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "322289",
    "name": "Darwin's Journey",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.13581",
    "categories": [
      "Exploration",
      "Travel"
    ]
  },
  {
    "id": "366790",
    "name": "Dawn of Ulos",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.17276",
    "categories": [
      "Economic",
      "Fantasy",
      "Territory Building"
    ]
  },
  {
    "id": "334986",
    "name": "Daybreak",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.68282",
    "categories": [
      "Environmental"
    ]
  },
  {
    "id": "141423",
    "name": "Dead Men Tell No Tales",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "75",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.10594",
    "categories": [
      "Exploration",
      "Fantasy",
      "Fighting",
      "Nautical",
      "Pirates"
    ]
  },
  {
    "id": "193037",
    "name": "Dead of Winter: The Long Night",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.51937",
    "categories": [
      "Bluffing",
      "Deduction",
      "Horror",
      "Mature / Adult",
      "Science Fiction",
      "Zombies"
    ]
  },
  {
    "id": "367379",
    "name": "Deal with the Devil",
    "yearpublished": "2022",
    "minPlayers": "4",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 4 players",
    "ratingsAverage": "7.05208",
    "categories": [
      "City Building",
      "Deduction",
      "Fantasy",
      "Medieval"
    ]
  },
  {
    "id": "227026",
    "name": "Debatable",
    "yearpublished": "2017",
    "minPlayers": "3",
    "maxPlayers": "16",
    "playingtime": "120",
    "bestWith": "Best with 5, 7 players",
    "recommendedWith": "Recommended with 4-16 players",
    "ratingsAverage": "6.53926",
    "categories": [
      "Card Game",
      "Party Game",
      "Political"
    ]
  },
  {
    "id": "156129",
    "name": "Deception: Murder in Hong Kong",
    "yearpublished": "2014",
    "minPlayers": "4",
    "maxPlayers": "12",
    "playingtime": "20",
    "bestWith": "Best with 6-8 players",
    "recommendedWith": "Recommended with 5-12 players",
    "ratingsAverage": "7.43458",
    "categories": [
      "Bluffing",
      "Deduction",
      "Murder / Mystery",
      "Party Game"
    ]
  },
  {
    "id": "285570",
    "name": "Decipher",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.57189",
    "categories": [
      "Deduction",
      "Word Game"
    ]
  },
  {
    "id": "137179",
    "name": "Deck of Thieves",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 4 players",
    "ratingsAverage": "5.8963",
    "categories": [
      "Card Game",
      "Print & Play"
    ]
  },
  {
    "id": "225694",
    "name": "Decrypto",
    "yearpublished": "2018",
    "minPlayers": "3",
    "maxPlayers": "8",
    "playingtime": "45",
    "bestWith": "Best with 4, 6 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "7.76823",
    "categories": [
      "Deduction",
      "Party Game",
      "Spies / Secret Agents",
      "Word Game"
    ]
  },
  {
    "id": "376747",
    "name": "Deep Dive",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.64757",
    "categories": [
      "Animals",
      "Nautical"
    ]
  },
  {
    "id": "169654",
    "name": "Deep Sea Adventure",
    "yearpublished": "2014",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.88982",
    "categories": [
      "Dice",
      "Exploration",
      "Nautical",
      "Party Game"
    ]
  },
  {
    "id": "256788",
    "name": "Detective Club",
    "yearpublished": "2018",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "45",
    "bestWith": "Best with 6-7 players",
    "recommendedWith": "Recommended with 5-8 players",
    "ratingsAverage": "7.37069",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "67026",
    "name": "Diamoniak",
    "yearpublished": "2009",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "15",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "5.33034",
    "categories": [
      "Card Game",
      "Children's Game"
    ]
  },
  {
    "id": "354866",
    "name": "Dice Conquest",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "6.94618",
    "categories": [
      "Dice",
      "Fantasy",
      "Fighting"
    ]
  },
  {
    "id": "368517",
    "name": "Dice Manor",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "6.8362",
    "categories": [
      "Dice"
    ]
  },
  {
    "id": "310632",
    "name": "Dice Miner",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.30118",
    "categories": [
      "Dice",
      "Fantasy"
    ]
  },
  {
    "id": "288080",
    "name": "Dice Realms",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.1352",
    "categories": [
      "Dice"
    ]
  },
  {
    "id": "410706",
    "name": "Ding!",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "7",
    "playingtime": "10",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.88571",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "483",
    "name": "Diplomacy",
    "yearpublished": "1959",
    "minPlayers": "2",
    "maxPlayers": "7",
    "playingtime": "360",
    "bestWith": "Best with 7 players",
    "recommendedWith": "Recommended with 6-7 players",
    "ratingsAverage": "7.04042",
    "categories": [
      "Bluffing",
      "Negotiation",
      "Political",
      "Post-Napoleonic",
      "Wargame"
    ]
  },
  {
    "id": "1219",
    "name": "Labyrinth",
    "yearpublished": "1986",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.40995",
    "categories": [
      "Children's Game",
      "Maze",
      "Puzzle"
    ]
  },
  {
    "id": "368541",
    "name": "Disney Tim Burton's The Nightmare Before Christmas: Merry Madness",
    "yearpublished": "2022",
    "minPlayers": "3",
    "maxPlayers": "5",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "6.92093",
    "categories": [
      "Dice",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "284760",
    "name": "Disney Villainous: Evil Comes Prepared",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "3",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-3+ players",
    "ratingsAverage": "7.50249",
    "categories": [
      "Card Game",
      "Fantasy",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "256382",
    "name": "Disney Villainous: The Worst Takes it All",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.93389",
    "categories": [
      "Card Game",
      "Fantasy",
      "Medieval",
      "Movies / TV / Radio theme",
      "Mythology",
      "Novel-based",
      "Pirates"
    ]
  },
  {
    "id": "271518",
    "name": "Disney Villainous: Wicked to the Core",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "3",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-3+ players",
    "ratingsAverage": "7.50671",
    "categories": [
      "Card Game",
      "Fantasy",
      "Movies / TV / Radio theme",
      "Mythology"
    ]
  },
  {
    "id": "173075",
    "name": "Disturbed Friends",
    "yearpublished": "2015",
    "minPlayers": "4",
    "maxPlayers": "10",
    "playingtime": "90",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 4-10 players",
    "ratingsAverage": "5.08216",
    "categories": [
      "Card Game",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "39856",
    "name": "Dixit",
    "yearpublished": "2008",
    "minPlayers": "3",
    "maxPlayers": "8",
    "playingtime": "30",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "7.19231",
    "categories": [
      "Card Game",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "381308",
    "name": "Dixit: Disney Edition",
    "yearpublished": "2023",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-6+ players",
    "ratingsAverage": "7.41192",
    "categories": [
      "Card Game",
      "Party Game"
    ]
  },
  {
    "id": "92828",
    "name": "Dixit: Odyssey",
    "yearpublished": "2011",
    "minPlayers": "3",
    "maxPlayers": "12",
    "playingtime": "30",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-12 players",
    "ratingsAverage": "7.39158",
    "categories": [
      "Card Game",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "320753",
    "name": "Do or Drink",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "99",
    "playingtime": "30",
    "bestWith": "(Undetermined)",
    "recommendedWith": "(Undetermined)",
    "ratingsAverage": "5.44444",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "344839",
    "name": "Dog Lover",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.74364",
    "categories": [
      "Animals",
      "Card Game"
    ]
  },
  {
    "id": "331401",
    "name": "Dog Park",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "80",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.93349",
    "categories": [
      "Animals",
      "Card Game"
    ]
  },
  {
    "id": "62219",
    "name": "Dominant Species",
    "yearpublished": "2010",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "240",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.80701",
    "categories": [
      "Animals",
      "Environmental",
      "Prehistoric"
    ]
  },
  {
    "id": "36218",
    "name": "Dominion",
    "yearpublished": "2008",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.59792",
    "categories": [
      "Card Game",
      "Medieval"
    ]
  },
  {
    "id": "216849",
    "name": "Dominion (Second Edition) Big Box",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "8.15693",
    "categories": [
      "Card Game",
      "Medieval"
    ]
  },
  {
    "id": "142131",
    "name": "Dominion: Big Box",
    "yearpublished": "2010",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.92675",
    "categories": [
      "Card Game",
      "Fantasy",
      "Medieval"
    ]
  },
  {
    "id": "334379",
    "name": "Doodle Dash",
    "yearpublished": "2021",
    "minPlayers": "3",
    "maxPlayers": "7",
    "playingtime": "20",
    "bestWith": "Best with 5-7 players",
    "recommendedWith": "Recommended with 4-7 players",
    "ratingsAverage": "7.3068",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "324413",
    "name": "Doomlings",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.8114",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "370591",
    "name": "Dorfromantik: The Board Game",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 1-2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "7.63304",
    "categories": [
      "City Building",
      "Environmental",
      "Puzzle",
      "Territory Building",
      "Video Game Theme"
    ]
  },
  {
    "id": "395364",
    "name": "Dorfromantik: The Duel",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.08376",
    "categories": [
      "City Building",
      "Environmental",
      "Puzzle"
    ]
  },
  {
    "id": "246701",
    "name": "DOS",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "4.8742",
    "categories": [
      "Card Game",
      "Number"
    ]
  },
  {
    "id": "193840",
    "name": "The Dragon & Flagon",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "60",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "6.39439",
    "categories": [
      "Fantasy",
      "Fighting",
      "Humor"
    ]
  },
  {
    "id": "310636",
    "name": "Drakborgen",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.24245",
    "categories": [
      "Adventure",
      "Exploration",
      "Fantasy"
    ]
  },
  {
    "id": "247473",
    "name": "Drawing Without Dignity: An Adult Party Game of Uncensored Sketches",
    "yearpublished": "2016",
    "minPlayers": "4",
    "maxPlayers": "12",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.31544",
    "categories": [
      "Humor",
      "Mature / Adult"
    ]
  },
  {
    "id": "211996",
    "name": "Drinking Fluxx",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "40",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.24906",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "261537",
    "name": "Drinking Games for People Who Never Drink Except When They Do",
    "yearpublished": "0",
    "minPlayers": "0",
    "maxPlayers": "0",
    "playingtime": "0",
    "bestWith": "(Undetermined)",
    "recommendedWith": "(Undetermined)",
    "ratingsAverage": "4.66667",
    "categories": [
      "Card Game",
      "Humor",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "244916",
    "name": "Drop It",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.8872",
    "categories": [
      "Action / Dexterity"
    ]
  },
  {
    "id": "193295",
    "name": "Drunk Stoned or Stupid: A Party Game",
    "yearpublished": "2014",
    "minPlayers": "4",
    "maxPlayers": "99",
    "playingtime": "30",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-11 players",
    "ratingsAverage": "4.28507",
    "categories": [
      "Card Game",
      "Humor",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "312463",
    "name": "Duck",
    "yearpublished": "2020",
    "minPlayers": "3",
    "maxPlayers": "5",
    "playingtime": "15",
    "bestWith": "Best with 3-5 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "6.59964",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "283355",
    "name": "Dune",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "180",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 5-6 players",
    "ratingsAverage": "7.93225",
    "categories": [
      "Bluffing",
      "Fighting",
      "Negotiation",
      "Novel-based",
      "Political",
      "Science Fiction",
      "Wargame"
    ]
  },
  {
    "id": "341165",
    "name": "Dune: A Game of Conquest and Diplomacy",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.72145",
    "categories": [
      "Novel-based",
      "Science Fiction",
      "Wargame"
    ]
  },
  {
    "id": "343696",
    "name": "Dune: Betrayal",
    "yearpublished": "2021",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "40",
    "bestWith": "Best with 7 players",
    "recommendedWith": "Recommended with 5-8 players",
    "ratingsAverage": "6.26039",
    "categories": [
      "Card Game",
      "Movies / TV / Radio theme",
      "Novel-based",
      "Party Game",
      "Science Fiction",
      "Spies / Secret Agents"
    ]
  },
  {
    "id": "397598",
    "name": "Dune: Imperium - Uprising",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-4, 6 players",
    "ratingsAverage": "8.72005",
    "categories": [
      "Movies / TV / Radio theme",
      "Novel-based",
      "Political",
      "Science Fiction"
    ]
  },
  {
    "id": "260300",
    "name": "Dungeon Mayhem",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "10",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.88088",
    "categories": [
      "Card Game",
      "Fantasy",
      "Fighting"
    ]
  },
  {
    "id": "280809",
    "name": "Dungeon Mayhem: Battle for Baldur's Gate",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "10",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4+ players",
    "ratingsAverage": "7.3118",
    "categories": [
      "Card Game",
      "Expansion for Base-game",
      "Fantasy",
      "Fighting"
    ]
  },
  {
    "id": "295577",
    "name": "Dungeon Mayhem: Monster Madness",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "10",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "7.45365",
    "categories": [
      "Card Game",
      "Fantasy",
      "Fighting"
    ]
  },
  {
    "id": "359835",
    "name": "Dungeons & Dragons: Onslaught",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "0",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.50706",
    "categories": [
      "Fantasy",
      "Miniatures"
    ]
  },
  {
    "id": "207062",
    "name": "Dungeons & Dragons: Rock Paper Wizard",
    "yearpublished": "2016",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.55625",
    "categories": [
      "Fantasy"
    ]
  },
  {
    "id": "91872",
    "name": "Dungeons & Dragons: The Legend of Drizzt Board Game",
    "yearpublished": "2011",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.20489",
    "categories": [
      "Adventure",
      "Exploration",
      "Fantasy",
      "Fighting",
      "Miniatures",
      "Novel-based"
    ]
  },
  {
    "id": "367820",
    "name": "Dungeons & Dragons: The Yawning Portal",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "0",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.19941",
    "categories": [
      "Fantasy"
    ]
  },
  {
    "id": "350184",
    "name": "Earth",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.64732",
    "categories": [
      "Animals",
      "Card Game",
      "Environmental"
    ]
  },
  {
    "id": "354132",
    "name": "East India Companies",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "7.46912",
    "categories": [
      "Economic",
      "Transportation"
    ]
  },
  {
    "id": "246900",
    "name": "Eclipse: Second Dawn for the Galaxy",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "200",
    "bestWith": "Best with 4, 6 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "8.42577",
    "categories": [
      "Civilization",
      "Exploration",
      "Science Fiction",
      "Space Exploration",
      "Wargame"
    ]
  },
  {
    "id": "39564",
    "name": "ego",
    "yearpublished": "2007",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "45",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "5.30194",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "253678",
    "name": "EGO Family",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "5.67742",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "225731",
    "name": "ego: emotions",
    "yearpublished": "2011",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "40",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "5.65625",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "146021",
    "name": "Eldritch Horror",
    "yearpublished": "2013",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "240",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "7.74513",
    "categories": [
      "Adventure",
      "Fighting",
      "Horror",
      "Novel-based",
      "Travel"
    ]
  },
  {
    "id": "144388",
    "name": "Elevenses",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.21311",
    "categories": [
      "Card Game",
      "Print & Play"
    ]
  },
  {
    "id": "183405",
    "name": "Ember: The Magical Card Game",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "30",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.68226",
    "categories": [
      "Card Game",
      "Fantasy"
    ]
  },
  {
    "id": "387201",
    "name": "Emerge",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "75",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.87795",
    "categories": [
      "Animals",
      "Dice",
      "Environmental",
      "Exploration"
    ]
  },
  {
    "id": "351526",
    "name": "Encyclopedia",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.36359",
    "categories": [
      "Animals",
      "Exploration"
    ]
  },
  {
    "id": "305096",
    "name": "Endless Winter: Paleoamericans",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.67877",
    "categories": [
      "Adventure",
      "Prehistoric",
      "Territory Building"
    ]
  },
  {
    "id": "112686",
    "name": "Epic Spell Wars of the Battle Wizards: Duel at Mt. Skullzfyre",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "6.44744",
    "categories": [
      "Fantasy",
      "Fighting",
      "Humor"
    ]
  },
  {
    "id": "221366",
    "name": "Epic Spell Wars of the Battle Wizards: Melee at Murdershroom Marsh",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.50695",
    "categories": [
      "Fantasy",
      "Fighting",
      "Humor"
    ]
  },
  {
    "id": "248075",
    "name": "Epic Spell Wars of the Battle Wizards: Panic at the Pleasure Palace",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.60736",
    "categories": [
      "Card Game",
      "Fantasy",
      "Fighting",
      "Humor"
    ]
  },
  {
    "id": "173200",
    "name": "Epic Spell Wars of the Battle Wizards: Rumble at Castle Tentakill",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "45",
    "bestWith": "Best with 3-5 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.54429",
    "categories": [
      "Fantasy",
      "Fighting",
      "Humor"
    ]
  },
  {
    "id": "133848",
    "name": "Euphoria: Build a Better Dystopia",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "7.17196",
    "categories": [
      "Science Fiction"
    ]
  },
  {
    "id": "254127",
    "name": "Europa Universalis: The Price of Power",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "300",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-4+ players",
    "ratingsAverage": "8.63611",
    "categories": [
      "Age of Reason",
      "Civilization",
      "Economic",
      "Exploration",
      "Napoleonic",
      "Negotiation",
      "Pike and Shot",
      "Political",
      "Renaissance",
      "Video Game Theme",
      "Wargame"
    ]
  },
  {
    "id": "370448",
    "name": "Europa Universalis: The Price of Power (Deluxe Edition)",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "300",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "9.03524",
    "categories": [
      "Age of Reason",
      "Civilization",
      "Economic",
      "Exploration",
      "Napoleonic",
      "Negotiation",
      "Pike and Shot",
      "Political",
      "Renaissance",
      "Video Game Theme",
      "Wargame"
    ]
  },
  {
    "id": "199792",
    "name": "Everdell",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "80",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.00646",
    "categories": [
      "Animals",
      "Card Game",
      "City Building",
      "Fantasy"
    ]
  },
  {
    "id": "394106",
    "name": "Everdell Farshore",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "80",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.89747",
    "categories": [
      "Animals",
      "Card Game",
      "City Building",
      "Fantasy"
    ]
  },
  {
    "id": "391086",
    "name": "Everything Ever",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "60",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 2-9 players",
    "ratingsAverage": "6.2093",
    "categories": [
      "Card Game",
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "201825",
    "name": "Ex Libris",
    "yearpublished": "2017",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.11745",
    "categories": [
      "Card Game",
      "Fantasy"
    ]
  },
  {
    "id": "393000",
    "name": "Ex Libris: Revised Edition",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 1, 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.3874",
    "categories": [
      "Card Game",
      "Fantasy"
    ]
  },
  {
    "id": "172225",
    "name": "Exploding Kittens",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "15",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "6.06436",
    "categories": [
      "Animals",
      "Card Game",
      "Comic Book / Strip",
      "Humor"
    ]
  },
  {
    "id": "395906",
    "name": "Exploding Kittens: Good Vs Evil",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "15",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5+ players",
    "ratingsAverage": "6.72665",
    "categories": [
      "Animals",
      "Card Game",
      "Movies / TV / Radio theme",
      "Party Game"
    ]
  },
  {
    "id": "172242",
    "name": "Exploding Kittens: NSFW Deck",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "20",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "6.23263",
    "categories": [
      "Animals",
      "Card Game",
      "Comic Book / Strip",
      "Humor",
      "Mature / Adult"
    ]
  },
  {
    "id": "341164",
    "name": "Exploding Minions",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "15",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.39112",
    "categories": [
      "Animals",
      "Card Game",
      "Comic Book / Strip",
      "Humor",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "135779",
    "name": "A Fake Artist Goes to New York",
    "yearpublished": "2011",
    "minPlayers": "5",
    "maxPlayers": "10",
    "playingtime": "20",
    "bestWith": "Best with 6-7 players",
    "recommendedWith": "Recommended with 5-10 players",
    "ratingsAverage": "7.12771",
    "categories": [
      "Bluffing",
      "Deduction",
      "Party Game"
    ]
  },
  {
    "id": "15614",
    "name": "Fantasi",
    "yearpublished": "1989",
    "minPlayers": "3",
    "maxPlayers": "12",
    "playingtime": "45",
    "bestWith": "Best with 6-9 players",
    "recommendedWith": "Recommended with 4-12 players",
    "ratingsAverage": "5.71277",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "223040",
    "name": "Fantasy Realms",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.51115",
    "categories": [
      "Card Game",
      "Fantasy"
    ]
  },
  {
    "id": "360992",
    "name": "Farmen: Kampen for tilværelsen",
    "yearpublished": "0",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "0",
    "categories": [
      "Movies / TV / Radio theme",
      "Party Game"
    ]
  },
  {
    "id": "7805",
    "name": "Fearsome Floors",
    "yearpublished": "2003",
    "minPlayers": "2",
    "maxPlayers": "7",
    "playingtime": "60",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 2-7 players",
    "ratingsAverage": "6.66602",
    "categories": [
      "Horror"
    ]
  },
  {
    "id": "177736",
    "name": "A Feast for Odin",
    "yearpublished": "2016",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.16685",
    "categories": [
      "Economic",
      "Farming",
      "Industry / Manufacturing",
      "Medieval",
      "Puzzle"
    ]
  },
  {
    "id": "159675",
    "name": "Fields of Arle",
    "yearpublished": "2014",
    "minPlayers": "1",
    "maxPlayers": "2",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-2 players",
    "ratingsAverage": "8.02913",
    "categories": [
      "Economic",
      "Farming",
      "Industry / Manufacturing"
    ]
  },
  {
    "id": "193",
    "name": "Fill or Bust",
    "yearpublished": "1981",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-8 players",
    "ratingsAverage": "5.79845",
    "categories": [
      "Dice"
    ]
  },
  {
    "id": "352574",
    "name": "Fit to Print",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "7.36284",
    "categories": [
      "Abstract Strategy",
      "Animals",
      "Puzzle",
      "Real-time"
    ]
  },
  {
    "id": "322564",
    "name": "Fjords",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.96223",
    "categories": [
      "Territory Building"
    ]
  },
  {
    "id": "336986",
    "name": "Flamecraft",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.41112",
    "categories": [
      "Animals",
      "Card Game",
      "City Building",
      "Fantasy"
    ]
  },
  {
    "id": "199478",
    "name": "Flamme Rouge",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.48035",
    "categories": [
      "Racing",
      "Sports"
    ]
  },
  {
    "id": "169124",
    "name": "Flick 'em Up!",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "45",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.8509",
    "categories": [
      "Action / Dexterity",
      "American West",
      "Fighting"
    ]
  },
  {
    "id": "420087",
    "name": "Flip 7",
    "yearpublished": "2024",
    "minPlayers": "3",
    "maxPlayers": "99",
    "playingtime": "20",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 3-10 players",
    "ratingsAverage": "7.2999",
    "categories": [
      "Card Game",
      "Number"
    ]
  },
  {
    "id": "175324",
    "name": "Fog of Love",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.63397",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction"
    ]
  },
  {
    "id": "386271",
    "name": "Footprints",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "6.94272",
    "categories": [
      "Prehistoric"
    ]
  },
  {
    "id": "318632",
    "name": "For the Girls",
    "yearpublished": "2019",
    "minPlayers": "3",
    "maxPlayers": "20",
    "playingtime": "90",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "5.71053",
    "categories": [
      "Card Game",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "391163",
    "name": "Forest Shuffle",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.69864",
    "categories": [
      "Animals",
      "Card Game",
      "Educational",
      "Environmental"
    ]
  },
  {
    "id": "317859",
    "name": "Forests of Pangaia",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.34553",
    "categories": [
      "Fantasy",
      "Territory Building"
    ]
  },
  {
    "id": "296912",
    "name": "Fort",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "40",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.08683",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "368432",
    "name": "The Fox Experiment",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-4+ players",
    "ratingsAverage": "7.34744",
    "categories": [
      "Animals",
      "Dice"
    ]
  },
  {
    "id": "221965",
    "name": "The Fox in the Forest",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.07827",
    "categories": [
      "Animals",
      "Card Game",
      "Fantasy"
    ]
  },
  {
    "id": "288169",
    "name": "The Fox in the Forest Duet",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.99949",
    "categories": [
      "Animals",
      "Card Game",
      "Fantasy"
    ]
  },
  {
    "id": "139991",
    "name": "Fresco: Big Box",
    "yearpublished": "2014",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.67033",
    "categories": [
      "Renaissance"
    ]
  },
  {
    "id": "311988",
    "name": "Frostpunk: The Board Game",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1-2 players",
    "ratingsAverage": "8.19575",
    "categories": [
      "City Building",
      "Economic",
      "Exploration",
      "Science Fiction",
      "Video Game Theme"
    ]
  },
  {
    "id": "344408",
    "name": "Full Throttle!",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "40",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.52244",
    "categories": [
      "Card Game",
      "Humor",
      "Racing",
      "Sports"
    ]
  },
  {
    "id": "370164",
    "name": "Fun Facts",
    "yearpublished": "2022",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "30",
    "bestWith": "Best with 6-8 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "7.04907",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "142296",
    "name": "Funemployed",
    "yearpublished": "2013",
    "minPlayers": "3",
    "maxPlayers": "20",
    "playingtime": "30",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 4-7 players",
    "ratingsAverage": "6.63682",
    "categories": [
      "Card Game",
      "Educational",
      "Humor",
      "Party Game",
      "Word Game"
    ]
  },
  {
    "id": "318084",
    "name": "Furnace",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.40107",
    "categories": [
      "Card Game",
      "Economic",
      "Industry / Manufacturing",
      "Post-Napoleonic"
    ]
  },
  {
    "id": "369993",
    "name": "FUSE Countdown",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "10",
    "bestWith": "Best with 1-2, 4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.56075",
    "categories": [
      "Dice",
      "Real-time",
      "Science Fiction"
    ]
  },
  {
    "id": "314421",
    "name": "The Fuzzies",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "15",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.47687",
    "categories": [
      "Action / Dexterity",
      "Children's Game"
    ]
  },
  {
    "id": "220308",
    "name": "Gaia Project",
    "yearpublished": "2017",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.36432",
    "categories": [
      "Civilization",
      "Economic",
      "Miniatures",
      "Science Fiction",
      "Space Exploration",
      "Territory Building"
    ]
  },
  {
    "id": "336794",
    "name": "Galaxy Trucker (Second Edition)",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.44174",
    "categories": [
      "Action / Dexterity",
      "Adventure",
      "Aviation / Flight",
      "Dice",
      "Humor",
      "Real-time",
      "Science Fiction",
      "Space Exploration",
      "Transportation"
    ]
  },
  {
    "id": "173090",
    "name": "The Game",
    "yearpublished": "2015",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "20",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "6.71179",
    "categories": [
      "Card Game",
      "Number"
    ]
  },
  {
    "id": "179701",
    "name": "Game of Phones",
    "yearpublished": "2015",
    "minPlayers": "3",
    "maxPlayers": "99",
    "playingtime": "15",
    "bestWith": "Best with 5-7 players",
    "recommendedWith": "Recommended with 3-9 players",
    "ratingsAverage": "5.29434",
    "categories": [
      "Card Game",
      "Electronic",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "103343",
    "name": "A Game of Thrones: The Board Game (Second Edition)",
    "yearpublished": "2011",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "240",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-6 players",
    "ratingsAverage": "7.53261",
    "categories": [
      "Bluffing",
      "Fantasy",
      "Negotiation",
      "Novel-based",
      "Political",
      "Wargame"
    ]
  },
  {
    "id": "411567",
    "name": "The Gang",
    "yearpublished": "2024",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 4-6 players",
    "ratingsAverage": "7.57711",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "3085",
    "name": "Gang of Four",
    "yearpublished": "1990",
    "minPlayers": "3",
    "maxPlayers": "4",
    "playingtime": "40",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "6.62152",
    "categories": [
      "Card Game",
      "Political"
    ]
  },
  {
    "id": "416059",
    "name": "Gardlings",
    "yearpublished": "2024",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 1-2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.22634",
    "categories": [
      "Fantasy",
      "Farming",
      "Puzzle"
    ]
  },
  {
    "id": "193592",
    "name": "Gas Out",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "0",
    "bestWith": "Best with 4-6+ players",
    "recommendedWith": "Recommended with 2-6+ players",
    "ratingsAverage": "4.56873",
    "categories": [
      "Card Game",
      "Children's Game",
      "Electronic",
      "Humor"
    ]
  },
  {
    "id": "346630",
    "name": "Gay Sauna: The Board Game",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "5.74868",
    "categories": [
      "Adventure",
      "Mature / Adult"
    ]
  },
  {
    "id": "141430",
    "name": "Geek Out!",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "99",
    "playingtime": "30",
    "bestWith": "Best with 6, 8 players",
    "recommendedWith": "Recommended with 4-10 players",
    "ratingsAverage": "5.66538",
    "categories": [
      "Card Game",
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "285028",
    "name": "Geek Out! Disney",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "99",
    "playingtime": "30",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.67011",
    "categories": [
      "Card Game",
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "347013",
    "name": "Get on Board: New York & London",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.95471",
    "categories": [
      "Transportation"
    ]
  },
  {
    "id": "380105",
    "name": "Get on Board: Paris & Roma",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "6.94763",
    "categories": [
      "Transportation"
    ]
  },
  {
    "id": "393458",
    "name": "Get the Ick",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "20",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.5",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "83195",
    "name": "Ghost Blitz",
    "yearpublished": "2010",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "20",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.70762",
    "categories": [
      "Action / Dexterity",
      "Card Game",
      "Children's Game",
      "Real-time"
    ]
  },
  {
    "id": "357674",
    "name": "Gimme That!",
    "yearpublished": "2022",
    "minPlayers": "3",
    "maxPlayers": "8",
    "playingtime": "10",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.31863",
    "categories": [
      "Action / Dexterity",
      "Children's Game",
      "Party Game"
    ]
  },
  {
    "id": "356230",
    "name": "Globetrotting",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "6.82684",
    "categories": [
      "Travel"
    ]
  },
  {
    "id": "12692",
    "name": "Gloom",
    "yearpublished": "2005",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.32575",
    "categories": [
      "Card Game",
      "Horror",
      "Humor"
    ]
  },
  {
    "id": "332885",
    "name": "Gnome Hollow",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-3 players",
    "ratingsAverage": "6.97297",
    "categories": [
      "Fantasy",
      "Farming"
    ]
  },
  {
    "id": "188",
    "name": "Go",
    "yearpublished": "-2200",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "180",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.65478",
    "categories": [
      "Abstract Strategy"
    ]
  },
  {
    "id": "7682",
    "name": "Go Fish",
    "yearpublished": "0",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "3.69843",
    "categories": [
      "Card Game",
      "Children's Game",
      "Deduction",
      "Memory"
    ]
  },
  {
    "id": "334652",
    "name": "Goblin Vaults",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.19478",
    "categories": [
      "Card Game",
      "Fantasy"
    ]
  },
  {
    "id": "278120",
    "name": "God of War: The Card Game",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "180",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "6.5457",
    "categories": [
      "Card Game",
      "Fantasy",
      "Video Game Theme"
    ]
  },
  {
    "id": "153064",
    "name": "Good Cop Bad Cop",
    "yearpublished": "2014",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "20",
    "bestWith": "Best with 6, 8 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "6.54587",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Party Game",
      "Spies / Secret Agents"
    ]
  },
  {
    "id": "253756",
    "name": "Gorus Maximus",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "45",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 1, 3-6, 8 players",
    "ratingsAverage": "6.37801",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "382127",
    "name": "Gourmet Popcorn Dice",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "15",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1, 3-5 players",
    "ratingsAverage": "6.41379",
    "categories": [
      "Dice"
    ]
  },
  {
    "id": "182874",
    "name": "Grand Austria Hotel",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-3 players",
    "ratingsAverage": "7.95325",
    "categories": [
      "Economic"
    ]
  },
  {
    "id": "320780",
    "name": "Grand Austria Hotel: Let's Waltz!",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.35295",
    "categories": [
      "Economic",
      "Expansion for Base-game"
    ]
  },
  {
    "id": "93",
    "name": "El Grande",
    "yearpublished": "1995",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.76384",
    "categories": [
      "Medieval",
      "Renaissance"
    ]
  },
  {
    "id": "366752",
    "name": "The Great Split",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "7",
    "playingtime": "45",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 3-7 players",
    "ratingsAverage": "7.02218",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "364011",
    "name": "Great Western Trail: Argentina",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.28405",
    "categories": [
      "Animals",
      "Economic"
    ]
  },
  {
    "id": "380607",
    "name": "Great Western Trail: New Zealand",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.54679",
    "categories": [
      "Animals",
      "Economic"
    ]
  },
  {
    "id": "341169",
    "name": "Great Western Trail: Second Edition",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.29925",
    "categories": [
      "American West",
      "Animals",
      "Economic"
    ]
  },
  {
    "id": "359451",
    "name": "Great Western Trail: Second Edition - Rails To The North",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.67799",
    "categories": [
      "Expansion for Base-game",
      "American West",
      "Animals",
      "Economic"
    ]
  },
  {
    "id": "4143",
    "name": "Guess Who?",
    "yearpublished": "1979",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "4.84656",
    "categories": [
      "Children's Game",
      "Deduction",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "350933",
    "name": "The Guild of Merchant Explorers",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.66278",
    "categories": [
      "Adventure",
      "Exploration",
      "Fantasy",
      "Nautical"
    ]
  },
  {
    "id": "300322",
    "name": "Hallertau",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "140",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.85159",
    "categories": [
      "Animals",
      "Economic",
      "Farming",
      "Industry / Manufacturing",
      "Post-Napoleonic"
    ]
  },
  {
    "id": "336131",
    "name": "Halls of Hegra",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "1",
    "playingtime": "90",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1 players",
    "ratingsAverage": "8.17836",
    "categories": [
      "Economic",
      "Wargame",
      "World War II"
    ]
  },
  {
    "id": "276086",
    "name": "Hamlet: The Village Building Game",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "100",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.85508",
    "categories": [
      "City Building",
      "Economic",
      "Medieval"
    ]
  },
  {
    "id": "98778",
    "name": "Hanabi",
    "yearpublished": "2010",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "25",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.03601",
    "categories": [
      "Card Game",
      "Deduction",
      "Memory"
    ]
  },
  {
    "id": "356909",
    "name": "Hand-to-Hand Wombat",
    "yearpublished": "2022",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "15",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 4-6 players",
    "ratingsAverage": "6.23151",
    "categories": [
      "Action / Dexterity",
      "Party Game"
    ]
  },
  {
    "id": "286749",
    "name": "Hansa Teutonica: Big Box",
    "yearpublished": "2020",
    "minPlayers": "3",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "8.38189",
    "categories": [
      "Medieval"
    ]
  },
  {
    "id": "325414",
    "name": "Happy Little Dinosaurs",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "6.18764",
    "categories": [
      "Animals",
      "Card Game",
      "Humor",
      "Number",
      "Party Game",
      "Prehistoric"
    ]
  },
  {
    "id": "370444",
    "name": "Hard to Get",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "12",
    "playingtime": "15",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 2-9 players",
    "ratingsAverage": "7.0699",
    "categories": [
      "Deduction",
      "Party Game",
      "Word Game"
    ]
  },
  {
    "id": "414317",
    "name": "Harmonies",
    "yearpublished": "2024",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.01761",
    "categories": [
      "Animals",
      "Environmental"
    ]
  },
  {
    "id": "5750",
    "name": "Harry Potter and the Sorcerer's Stone: Trivia Game",
    "yearpublished": "2000",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "5.30446",
    "categories": [
      "Children's Game",
      "Fantasy",
      "Movies / TV / Radio theme",
      "Novel-based",
      "Trivia"
    ]
  },
  {
    "id": "199042",
    "name": "Harry Potter: Hogwarts Battle",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.35999",
    "categories": [
      "Card Game",
      "Fantasy",
      "Movies / TV / Radio theme",
      "Novel-based"
    ]
  },
  {
    "id": "254192",
    "name": "Harry Potter: Hogwarts Battle - Defence Against the Dark Arts",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.15074",
    "categories": [
      "Card Game",
      "Fantasy",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "304297",
    "name": "Harry Potter: Hogwarts Battle - The Charms and Potions Expansion",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.79691",
    "categories": [
      "Expansion for Base-game",
      "Card Game",
      "Fantasy",
      "Movies / TV / Radio theme",
      "Novel-based"
    ]
  },
  {
    "id": "223494",
    "name": "Harry Potter: Hogwarts Battle - The Monster Box of Monsters Expansion",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.60713",
    "categories": [
      "Expansion for Base-game",
      "Card Game",
      "Fantasy",
      "Movies / TV / Radio theme",
      "Novel-based"
    ]
  },
  {
    "id": "303006",
    "name": "Harry Potter: House Cup Competition",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "75",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.7207",
    "categories": [
      "Fantasy",
      "Movies / TV / Radio theme",
      "Novel-based"
    ]
  },
  {
    "id": "366013",
    "name": "Heat: Pedal to the Metal",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "8.028",
    "categories": [
      "Racing",
      "Sports"
    ]
  },
  {
    "id": "227789",
    "name": "Heaven & Ale",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.5353",
    "categories": [
      "Economic",
      "Farming",
      "Industry / Manufacturing"
    ]
  },
  {
    "id": "1855",
    "name": "Hedbanz for Adults!",
    "yearpublished": "1991",
    "minPlayers": "3",
    "maxPlayers": "10",
    "playingtime": "60",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 2-8 players",
    "ratingsAverage": "5.37355",
    "categories": [
      "Deduction",
      "Party Game"
    ]
  },
  {
    "id": "321608",
    "name": "Hegemony: Lead Your Class to Victory",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "180",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "8.42621",
    "categories": [
      "Economic",
      "Educational",
      "Negotiation",
      "Political"
    ]
  },
  {
    "id": "311322",
    "name": "Herd Mentality",
    "yearpublished": "2020",
    "minPlayers": "4",
    "maxPlayers": "20",
    "playingtime": "30",
    "bestWith": "Best with 7-8 players",
    "recommendedWith": "Recommended with 5-14 players",
    "ratingsAverage": "6.63537",
    "categories": [
      "Animals",
      "Party Game"
    ]
  },
  {
    "id": "299252",
    "name": "Here to Slay",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.89093",
    "categories": [
      "Animals",
      "Card Game",
      "Dice",
      "Fantasy",
      "Fighting"
    ]
  },
  {
    "id": "198994",
    "name": "Hero Realms",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.50779",
    "categories": [
      "Card Game",
      "Fantasy",
      "Fighting"
    ]
  },
  {
    "id": "355326",
    "name": "Heroes of Might and Magic III: The Board Game",
    "yearpublished": "2024",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "7.87981",
    "categories": [
      "Adventure",
      "Exploration",
      "Fantasy",
      "Fighting",
      "Video Game Theme"
    ]
  },
  {
    "id": "8203",
    "name": "Hey, That's My Fish!",
    "yearpublished": "2003",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "20",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.70045",
    "categories": [
      "Abstract Strategy",
      "Animals"
    ]
  },
  {
    "id": "165628",
    "name": "HINT",
    "yearpublished": "2014",
    "minPlayers": "4",
    "maxPlayers": "10",
    "playingtime": "60",
    "bestWith": "Best with 6, 8 players",
    "recommendedWith": "Recommended with 4-8, 10 players",
    "ratingsAverage": "6.66768",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "287473",
    "name": "HINT Junior",
    "yearpublished": "2019",
    "minPlayers": "4",
    "maxPlayers": "99",
    "playingtime": "0",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.8",
    "categories": [
      "Children's Game",
      "Word Game"
    ]
  },
  {
    "id": "318243",
    "name": "HITSTER",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 2-10 players",
    "ratingsAverage": "7.615",
    "categories": [
      "Card Game",
      "Music",
      "Party Game",
      "Print & Play",
      "Trivia"
    ]
  },
  {
    "id": "397736",
    "name": "HITSTER: Guilty Pleasures",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 4, 6 players",
    "recommendedWith": "Recommended with 2-10 players",
    "ratingsAverage": "7.4763",
    "categories": [
      "Card Game",
      "Music",
      "Party Game"
    ]
  },
  {
    "id": "2655",
    "name": "Hive",
    "yearpublished": "2001",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.31895",
    "categories": [
      "Abstract Strategy",
      "Animals"
    ]
  },
  {
    "id": "2932",
    "name": "Hnefatafl",
    "yearpublished": "400",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.66843",
    "categories": [
      "Abstract Strategy",
      "Ancient"
    ]
  },
  {
    "id": "370621",
    "name": "Hollywood 1947",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "9",
    "playingtime": "40",
    "bestWith": "Best with 8-9 players",
    "recommendedWith": "Recommended with 5-9 players",
    "ratingsAverage": "7.17706",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Dice",
      "Party Game",
      "Political",
      "Spies / Secret Agents"
    ]
  },
  {
    "id": "284742",
    "name": "Honey Buzz",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.53783",
    "categories": [
      "Animals",
      "Economic"
    ]
  },
  {
    "id": "367498",
    "name": "Horizons of Spirit Island",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "3",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "8.1621",
    "categories": [
      "Age of Reason",
      "Environmental",
      "Fantasy",
      "Fighting",
      "Mythology",
      "Renaissance",
      "Territory Building"
    ]
  },
  {
    "id": "282524",
    "name": "Horrified",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.61678",
    "categories": [
      "Horror",
      "Miniatures",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "134253",
    "name": "Hostage Negotiator",
    "yearpublished": "2015",
    "minPlayers": "1",
    "maxPlayers": "1",
    "playingtime": "20",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1 players",
    "ratingsAverage": "6.78879",
    "categories": [
      "Card Game",
      "Dice"
    ]
  },
  {
    "id": "386436",
    "name": "House of Cats",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "6.81576",
    "categories": [
      "Animals"
    ]
  },
  {
    "id": "387763",
    "name": "How Dare You?",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "15",
    "bestWith": "Best with 4-7 players",
    "recommendedWith": "Recommended with 2-9, 11+ players",
    "ratingsAverage": "7.00286",
    "categories": [
      "Bluffing",
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "302520",
    "name": "Hues and Cues",
    "yearpublished": "2020",
    "minPlayers": "3",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 6-8 players",
    "recommendedWith": "Recommended with 3-10 players",
    "ratingsAverage": "6.5343",
    "categories": [
      "Deduction",
      "Party Game"
    ]
  },
  {
    "id": "339906",
    "name": "The Hunger",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.04504",
    "categories": [
      "Card Game",
      "Horror"
    ]
  },
  {
    "id": "137167",
    "name": "...I should have known that!",
    "yearpublished": "2011",
    "minPlayers": "2",
    "maxPlayers": "99",
    "playingtime": "45",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "5.16427",
    "categories": [
      "Card Game",
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "318984",
    "name": "I Wish I Didn't Know!",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "99",
    "playingtime": "20",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "4.88462",
    "categories": [
      "Humor",
      "Mature / Adult",
      "Trivia"
    ]
  },
  {
    "id": "371972",
    "name": "Ierusalem: Anno Domini",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.56871",
    "categories": [
      "Religious"
    ]
  },
  {
    "id": "244995",
    "name": "Illusion",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "15",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.71576",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "255674",
    "name": "Imhotep: The Duel",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.06035",
    "categories": [
      "Ancient"
    ]
  },
  {
    "id": "338760",
    "name": "Imperial Steam",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.76696",
    "categories": [
      "Economic",
      "Industry / Manufacturing",
      "Post-Napoleonic",
      "Trains"
    ]
  },
  {
    "id": "295681",
    "name": "In Too Deep",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.35525",
    "categories": [
      "Adventure",
      "Science Fiction",
      "Spies / Secret Agents"
    ]
  },
  {
    "id": "15512",
    "name": "Incan Gold",
    "yearpublished": "2005",
    "minPlayers": "3",
    "maxPlayers": "8",
    "playingtime": "30",
    "bestWith": "Best with 6-8 players",
    "recommendedWith": "Recommended with 3-8 players",
    "ratingsAverage": "6.84234",
    "categories": [
      "Adventure",
      "Bluffing",
      "Exploration"
    ]
  },
  {
    "id": "367476",
    "name": "Inheritors",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.12847",
    "categories": [
      "Animals",
      "Card Game",
      "Number"
    ]
  },
  {
    "id": "369258",
    "name": "Isle of Skye: Big Box",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.90592",
    "categories": [
      "Economic",
      "Territory Building"
    ]
  },
  {
    "id": "251219",
    "name": "Istanbul: Big Box",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "8.00881",
    "categories": [
      "Economic"
    ]
  },
  {
    "id": "327711",
    "name": "It's a Wonderful Kingdom",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "2",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-2 players",
    "ratingsAverage": "7.11115",
    "categories": [
      "Card Game",
      "Fantasy",
      "Medieval"
    ]
  },
  {
    "id": "271324",
    "name": "It's a Wonderful World",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.65172",
    "categories": [
      "Card Game",
      "Civilization",
      "Economic",
      "Science Fiction"
    ]
  },
  {
    "id": "324635",
    "name": "It's Kind of a Fun Story",
    "yearpublished": "2021",
    "minPlayers": "3",
    "maxPlayers": "10",
    "playingtime": "45",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 3-10 players",
    "ratingsAverage": "7.52273",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "327778",
    "name": "ito",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "10",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-8 players",
    "ratingsAverage": "7.26144",
    "categories": [
      "Card Game",
      "Number"
    ]
  },
  {
    "id": "200292",
    "name": "Jag har en kompis som",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "0",
    "playingtime": "0",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "4.76889",
    "categories": [
      "Humor",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "54043",
    "name": "Jaipur",
    "yearpublished": "2009",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.48511",
    "categories": [
      "Card Game",
      "Economic"
    ]
  },
  {
    "id": "272738",
    "name": "Jaws",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.07509",
    "categories": [
      "Animals",
      "Fighting",
      "Movies / TV / Radio theme",
      "Nautical"
    ]
  },
  {
    "id": "2452",
    "name": "Jenga",
    "yearpublished": "1983",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "5.65349",
    "categories": [
      "Action / Dexterity",
      "Party Game"
    ]
  },
  {
    "id": "193621",
    "name": "Joking Hazard",
    "yearpublished": "2016",
    "minPlayers": "3",
    "maxPlayers": "10",
    "playingtime": "90",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 3-7 players",
    "ratingsAverage": "6.24038",
    "categories": [
      "Card Game",
      "Comic Book / Strip",
      "Humor",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "325698",
    "name": "Juicy Fruits",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "50",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.01637",
    "categories": [
      "Economic",
      "Farming"
    ]
  },
  {
    "id": "388327",
    "name": "Jungle Speed Collector",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "15",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "7.06667",
    "categories": [
      "Action / Dexterity",
      "Card Game",
      "Party Game"
    ]
  },
  {
    "id": "254640",
    "name": "Just One",
    "yearpublished": "2018",
    "minPlayers": "3",
    "maxPlayers": "7",
    "playingtime": "60",
    "bestWith": "Best with 6-7 players",
    "recommendedWith": "Recommended with 4-7+ players",
    "ratingsAverage": "7.60014",
    "categories": [
      "Party Game",
      "Word Game"
    ]
  },
  {
    "id": "358502",
    "name": "Just Tell Me What to Do",
    "yearpublished": "2022",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.66667",
    "categories": [
      "Card Game",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "320390",
    "name": "Kabuto Sumo",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "15",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.74987",
    "categories": [
      "Action / Dexterity",
      "Animals",
      "Fighting"
    ]
  },
  {
    "id": "2448",
    "name": "Kalah",
    "yearpublished": "700",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "10",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "5.99628",
    "categories": [
      "Abstract Strategy"
    ]
  },
  {
    "id": "399806",
    "name": "Karvi",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "180",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.25157",
    "categories": [
      "Adventure",
      "Dice",
      "Exploration"
    ]
  },
  {
    "id": "299684",
    "name": "Khôra: Rise of an Empire",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "75",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.39922",
    "categories": [
      "Ancient",
      "City Building",
      "Economic"
    ]
  },
  {
    "id": "160499",
    "name": "King of New York",
    "yearpublished": "2014",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "40",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.87059",
    "categories": [
      "Dice",
      "Fighting",
      "Movies / TV / Radio theme",
      "Science Fiction"
    ]
  },
  {
    "id": "70323",
    "name": "King of Tokyo",
    "yearpublished": "2011",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.1251",
    "categories": [
      "Dice",
      "Fighting",
      "Movies / TV / Radio theme",
      "Science Fiction"
    ]
  },
  {
    "id": "170431",
    "name": "King's Kilt",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.03987",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "107529",
    "name": "Kingdom Builder",
    "yearpublished": "2011",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.93789",
    "categories": [
      "Medieval",
      "Territory Building"
    ]
  },
  {
    "id": "204583",
    "name": "Kingdomino",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "25",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.29921",
    "categories": [
      "City Building",
      "Medieval",
      "Territory Building"
    ]
  },
  {
    "id": "348096",
    "name": "Kites",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "10",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.92348",
    "categories": [
      "Real-time"
    ]
  },
  {
    "id": "302477",
    "name": "Kittin",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.20417",
    "categories": [
      "Action / Dexterity",
      "Animals",
      "Real-time"
    ]
  },
  {
    "id": "165722",
    "name": "KLASK",
    "yearpublished": "2014",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "10",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.63527",
    "categories": [
      "Action / Dexterity",
      "Real-time"
    ]
  },
  {
    "id": "277458",
    "name": "Kluster",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "20",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.6205",
    "categories": [
      "Abstract Strategy",
      "Action / Dexterity",
      "Party Game"
    ]
  },
  {
    "id": "408383",
    "name": "Kluster Duo",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "2",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.45268",
    "categories": [
      "Abstract Strategy",
      "Action / Dexterity",
      "Party Game"
    ]
  },
  {
    "id": "15471",
    "name": "Kokkelimonke Jubileum",
    "yearpublished": "2000",
    "minPlayers": "3",
    "maxPlayers": "8",
    "playingtime": "60",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 3-7 players",
    "ratingsAverage": "6.33985",
    "categories": [
      "Bluffing",
      "Humor",
      "Trivia",
      "Word Game"
    ]
  },
  {
    "id": "108396",
    "name": "Konsensus",
    "yearpublished": "2011",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 4-6 players",
    "ratingsAverage": "5.49074",
    "categories": [
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "385610",
    "name": "Kutná Hora: The City of Silver",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.77242",
    "categories": [
      "City Building",
      "Economic",
      "Medieval"
    ]
  },
  {
    "id": "1219",
    "name": "Labyrinth",
    "yearpublished": "1986",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.40995",
    "categories": [
      "Children's Game",
      "Maze",
      "Puzzle"
    ]
  },
  {
    "id": "210052",
    "name": "Lazer Ryderz",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.26979",
    "categories": [
      "Maze",
      "Racing",
      "Science Fiction",
      "Video Game Theme"
    ]
  },
  {
    "id": "368676",
    "name": "Leaf",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.41831",
    "categories": [
      "Animals",
      "Environmental"
    ]
  },
  {
    "id": "127398",
    "name": "Legends of Andor",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.09812",
    "categories": [
      "Adventure",
      "Fantasy",
      "Fighting",
      "Puzzle"
    ]
  },
  {
    "id": "368173",
    "name": "Let's Go! To Japan",
    "yearpublished": "2024",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.67343",
    "categories": [
      "Card Game",
      "Travel"
    ]
  },
  {
    "id": "423729",
    "name": "Let's Hit Each Other with Fake Swords",
    "yearpublished": "2024",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "15",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "5.88508",
    "categories": [
      "Action / Dexterity",
      "Party Game"
    ]
  },
  {
    "id": "63975",
    "name": "Mountain Goats",
    "yearpublished": "2010",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.79738",
    "categories": [
      "Abstract Strategy",
      "Animals",
      "Dice",
      "Number"
    ]
  },
  {
    "id": "140620",
    "name": "Lewis & Clark: The Expedition",
    "yearpublished": "2013",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.44353",
    "categories": [
      "American West",
      "Exploration"
    ]
  },
  {
    "id": "943",
    "name": "Ligretto",
    "yearpublished": "1988",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "10",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4+ players",
    "ratingsAverage": "6.19493",
    "categories": [
      "Card Game",
      "Real-time"
    ]
  },
  {
    "id": "328479",
    "name": "Living Forest",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "40",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.25502",
    "categories": [
      "Animals",
      "Card Game",
      "Environmental",
      "Fantasy",
      "Mythology",
      "Territory Building"
    ]
  },
  {
    "id": "240855",
    "name": "Lockup: A Roll Player Tale",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.34248",
    "categories": [
      "Fantasy"
    ]
  },
  {
    "id": "295374",
    "name": "Long Shot: The Dice Game",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "25",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 1-8 players",
    "ratingsAverage": "7.58553",
    "categories": [
      "Dice",
      "Racing",
      "Sports"
    ]
  },
  {
    "id": "327",
    "name": "Loopin' Louie",
    "yearpublished": "1992",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "10",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2, 4 players",
    "ratingsAverage": "6.74006",
    "categories": [
      "Action / Dexterity",
      "Animals",
      "Aviation / Flight",
      "Children's Game",
      "Electronic",
      "Movies / TV / Radio theme",
      "Real-time"
    ]
  },
  {
    "id": "421006",
    "name": "The Lord of the Rings: Duel for Middle-earth",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "8.42004",
    "categories": [
      "Card Game",
      "Fantasy",
      "Novel-based"
    ]
  },
  {
    "id": "269385",
    "name": "The Lord of the Rings: Journeys in Middle-Earth",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.9014",
    "categories": [
      "Adventure",
      "Exploration",
      "Fantasy",
      "Fighting",
      "Miniatures",
      "Novel-based"
    ]
  },
  {
    "id": "77423",
    "name": "The Lord of the Rings: The Card Game",
    "yearpublished": "2011",
    "minPlayers": "1",
    "maxPlayers": "2",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-2+ players",
    "ratingsAverage": "7.66097",
    "categories": [
      "Adventure",
      "Card Game",
      "Collectible Components",
      "Fantasy",
      "Novel-based"
    ]
  },
  {
    "id": "1426",
    "name": "Lord of the Rings: The Search",
    "yearpublished": "2001",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "5.05424",
    "categories": [
      "Adventure",
      "Exploration",
      "Fantasy",
      "Novel-based"
    ]
  },
  {
    "id": "110327",
    "name": "Lords of Waterdeep",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.7312",
    "categories": [
      "City Building",
      "Fantasy"
    ]
  },
  {
    "id": "134342",
    "name": "Lords of Waterdeep: Scoundrels of Skullport",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "8.20569",
    "categories": [
      "City Building",
      "Expansion for Base-game",
      "Fantasy"
    ]
  },
  {
    "id": "203993",
    "name": "Lorenzo il Magnifico",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.84161",
    "categories": [
      "Renaissance"
    ]
  },
  {
    "id": "338476",
    "name": "Lorenzo il Magnifico: Big Box",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "8.39279",
    "categories": [
      "Renaissance"
    ]
  },
  {
    "id": "50",
    "name": "Lost Cities",
    "yearpublished": "1999",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.2492",
    "categories": [
      "Card Game",
      "Exploration",
      "Math"
    ]
  },
  {
    "id": "312484",
    "name": "Lost Ruins of Arnak",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.06815",
    "categories": [
      "Adventure",
      "Exploration",
      "Fantasy"
    ]
  },
  {
    "id": "341254",
    "name": "Lost Ruins of Arnak: Expedition Leaders",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.69262",
    "categories": [
      "Expansion for Base-game",
      "Adventure",
      "Ancient",
      "Exploration",
      "Travel"
    ]
  },
  {
    "id": "382350",
    "name": "Lost Ruins of Arnak: The Missing Expedition",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 1-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.66466",
    "categories": [
      "Expansion for Base-game",
      "Adventure",
      "Ancient",
      "Exploration",
      "Travel"
    ]
  },
  {
    "id": "277085",
    "name": "Love Letter",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.51543",
    "categories": [
      "Card Game",
      "Deduction",
      "Renaissance"
    ]
  },
  {
    "id": "129622",
    "name": "Love Letter",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "7.22534",
    "categories": [
      "Card Game",
      "Deduction",
      "Renaissance"
    ]
  },
  {
    "id": "143884",
    "name": "Machi Koro",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.60008",
    "categories": [
      "City Building",
      "Dice"
    ]
  },
  {
    "id": "5791",
    "name": "Maelstrom",
    "yearpublished": "2001",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.58404",
    "categories": [
      "Collectible Components",
      "Fantasy"
    ]
  },
  {
    "id": "96848",
    "name": "Mage Knight Board Game",
    "yearpublished": "2011",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "240",
    "bestWith": "Best with 1-2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "8.08172",
    "categories": [
      "Adventure",
      "Exploration",
      "Fantasy",
      "Fighting",
      "Miniatures"
    ]
  },
  {
    "id": "209778",
    "name": "Magic Maze",
    "yearpublished": "2017",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.94758",
    "categories": [
      "Exploration",
      "Fantasy",
      "Maze",
      "Real-time"
    ]
  },
  {
    "id": "167698",
    "name": "Magic: The Gathering - Arena of the Planeswalkers",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.14998",
    "categories": [
      "Fantasy",
      "Fighting",
      "Miniatures",
      "Wargame"
    ]
  },
  {
    "id": "2093",
    "name": "Mahjong",
    "yearpublished": "1850",
    "minPlayers": "3",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 4 players",
    "ratingsAverage": "7.05319",
    "categories": [
      "Abstract Strategy"
    ]
  },
  {
    "id": "230080",
    "name": "Majesty: For the Realm",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.92333",
    "categories": [
      "Card Game",
      "Medieval"
    ]
  },
  {
    "id": "317504",
    "name": "Mandala Stones",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.86492",
    "categories": [
      "Abstract Strategy"
    ]
  },
  {
    "id": "83330",
    "name": "Mansions of Madness",
    "yearpublished": "2011",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "180",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.2548",
    "categories": [
      "Adventure",
      "Exploration",
      "Fantasy",
      "Fighting",
      "Horror",
      "Miniatures",
      "Murder / Mystery",
      "Puzzle"
    ]
  },
  {
    "id": "205059",
    "name": "Mansions of Madness: Second Edition",
    "yearpublished": "2016",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "180",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.93147",
    "categories": [
      "Adventure",
      "Exploration",
      "Fantasy",
      "Fighting",
      "Horror",
      "Miniatures",
      "Murder / Mystery",
      "Mythology",
      "Puzzle"
    ]
  },
  {
    "id": "358690",
    "name": "MANTIS",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "10",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.6754",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "276025",
    "name": "Maracaibo",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.98288",
    "categories": [
      "Economic",
      "Exploration",
      "Nautical",
      "Pirates"
    ]
  },
  {
    "id": "338013",
    "name": "Maracaibo: The Uprising",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "160",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.26069",
    "categories": [
      "Expansion for Base-game",
      "Adventure",
      "Card Game",
      "Economic",
      "Exploration",
      "Nautical",
      "Pirates"
    ]
  },
  {
    "id": "297978",
    "name": "Mariposas",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "75",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.76595",
    "categories": [
      "Animals",
      "Environmental"
    ]
  },
  {
    "id": "342810",
    "name": "Marrakesh",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "8.13041",
    "categories": [
      "City Building"
    ]
  },
  {
    "id": "383242",
    "name": "Marvel D.A.G.G.E.R.",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "180",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.09446",
    "categories": [
      "Adventure",
      "Comic Book / Strip",
      "Dice"
    ]
  },
  {
    "id": "348406",
    "name": "Marvel Dice Throne",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "40",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "8.0053",
    "categories": [
      "Card Game",
      "Comic Book / Strip",
      "Dice",
      "Fighting",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "354342",
    "name": "Marvel Mayhem",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "10",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.15193",
    "categories": [
      "Card Game",
      "Comic Book / Strip",
      "Fighting"
    ]
  },
  {
    "id": "302336",
    "name": "Marvel Villainous: Infinite Power",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "80",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.75337",
    "categories": [
      "Card Game",
      "Comic Book / Strip",
      "Fantasy"
    ]
  },
  {
    "id": "351817",
    "name": "Marvel Zombies: A Zombicide Game",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "7.95534",
    "categories": [
      "Comic Book / Strip",
      "Fighting",
      "Horror",
      "Miniatures",
      "Movies / TV / Radio theme",
      "Zombies"
    ]
  },
  {
    "id": "359152",
    "name": "Marvel: Remix",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.35979",
    "categories": [
      "Card Game",
      "Comic Book / Strip"
    ]
  },
  {
    "id": "338570",
    "name": "Mästarnas Mästare",
    "yearpublished": "2016",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "4",
    "categories": [
      "Movies / TV / Radio theme",
      "Party Game"
    ]
  },
  {
    "id": "360377",
    "name": "Master Dater",
    "yearpublished": "2022",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "45",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-6+ players",
    "ratingsAverage": "6.33387",
    "categories": [
      "Card Game",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "2392",
    "name": "Mastermind",
    "yearpublished": "1971",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "5.65048",
    "categories": [
      "Abstract Strategy",
      "Deduction"
    ]
  },
  {
    "id": "283159",
    "name": "Matchify Card Game",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "60",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 2-7 players",
    "ratingsAverage": "5.19286",
    "categories": [
      "Card Game",
      "Children's Game",
      "Educational",
      "Party Game"
    ]
  },
  {
    "id": "60574",
    "name": "Mausgeflippt",
    "yearpublished": "2009",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "15",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 2-6+ players",
    "ratingsAverage": "5.88669",
    "categories": [
      "Card Game",
      "Real-time"
    ]
  },
  {
    "id": "20182",
    "name": "Maxi Yatzy",
    "yearpublished": "0",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "5.32749",
    "categories": [
      "Dice"
    ]
  },
  {
    "id": "314491",
    "name": "Meadow",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.72096",
    "categories": [
      "Animals",
      "Card Game",
      "Environmental"
    ]
  },
  {
    "id": "46",
    "name": "Medici",
    "yearpublished": "1995",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 4-6 players",
    "ratingsAverage": "7.18219",
    "categories": [
      "Renaissance"
    ]
  },
  {
    "id": "10630",
    "name": "Memoir '44",
    "yearpublished": "2004",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.53209",
    "categories": [
      "Miniatures",
      "Wargame",
      "World War II"
    ]
  },
  {
    "id": "306040",
    "name": "Merv: The Heart of the Silk Road",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.49077",
    "categories": [
      "Arabian",
      "City Building",
      "Economic",
      "Medieval"
    ]
  },
  {
    "id": "11634",
    "name": "Mexican Train",
    "yearpublished": "1994",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-8 players",
    "ratingsAverage": "5.80906",
    "categories": [
      "Abstract Strategy",
      "Trains"
    ]
  },
  {
    "id": "318977",
    "name": "MicroMacro: Crime City",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "7.47762",
    "categories": [
      "Deduction",
      "Murder / Mystery"
    ]
  },
  {
    "id": "244992",
    "name": "The Mind",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4+ players",
    "ratingsAverage": "6.74128",
    "categories": [
      "Card Game",
      "Number"
    ]
  },
  {
    "id": "287607",
    "name": "The Mind Extreme",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.90232",
    "categories": [
      "Card Game",
      "Deduction",
      "Number"
    ]
  },
  {
    "id": "345584",
    "name": "Mindbug: First Contact",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "25",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.64211",
    "categories": [
      "Card Game",
      "Fantasy",
      "Fighting",
      "Science Fiction"
    ]
  },
  {
    "id": "358124",
    "name": "Mists over Carcassonne",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "35",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.30899",
    "categories": [
      "Medieval"
    ]
  },
  {
    "id": "387378",
    "name": "MLEM: Space Agency",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.196",
    "categories": [
      "Animals",
      "Dice",
      "Space Exploration"
    ]
  },
  {
    "id": "172386",
    "name": "Mombasa",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.84454",
    "categories": [
      "Economic",
      "Post-Napoleonic"
    ]
  },
  {
    "id": "156546",
    "name": "Monikers",
    "yearpublished": "2015",
    "minPlayers": "4",
    "maxPlayers": "16",
    "playingtime": "60",
    "bestWith": "Best with 6, 8, 10 players",
    "recommendedWith": "Recommended with 4-12, 14, 16 players",
    "ratingsAverage": "7.77745",
    "categories": [
      "Card Game",
      "Humor",
      "Mature / Adult",
      "Party Game",
      "Print & Play"
    ]
  },
  {
    "id": "412911",
    "name": "Monkey Palace",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.2354",
    "categories": []
  },
  {
    "id": "1406",
    "name": "Monopoly",
    "yearpublished": "1935",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "180",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "4.36364",
    "categories": [
      "Economic"
    ]
  },
  {
    "id": "323910",
    "name": "Monopoly Arcade: Pac-Man",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.1981",
    "categories": [
      "Economic",
      "Video Game Theme"
    ]
  },
  {
    "id": "356071",
    "name": "Monopoly Crooked Cash",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.72174",
    "categories": [
      "Economic",
      "Negotiation"
    ]
  },
  {
    "id": "327299",
    "name": "Monopoly for Sore Losers",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "0",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "5.80556",
    "categories": [
      "Economic"
    ]
  },
  {
    "id": "230408",
    "name": "Monopoly Gamer",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.3427",
    "categories": [
      "Economic",
      "Video Game Theme"
    ]
  },
  {
    "id": "385660",
    "name": "Monopoly Travel World Tour",
    "yearpublished": "0",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "0",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "5.97368",
    "categories": [
      "Economic"
    ]
  },
  {
    "id": "341308",
    "name": "Monopoly:  Builder",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "5.66795",
    "categories": [
      "Economic"
    ]
  },
  {
    "id": "187127",
    "name": "Monopoly: Back to the Future",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "0",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.66744",
    "categories": [
      "Economic",
      "Movies / TV / Radio theme",
      "Negotiation"
    ]
  },
  {
    "id": "254617",
    "name": "Monopoly: Cheaters Edition",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "180",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "5.04437",
    "categories": [
      "Economic",
      "Negotiation"
    ]
  },
  {
    "id": "350663",
    "name": "Monopoly: Dungeons & Dragons",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.90323",
    "categories": [
      "Economic",
      "Fantasy"
    ]
  },
  {
    "id": "400854",
    "name": "Monopoly: Fortnite Collector's Edition",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "7",
    "playingtime": "90",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.23077",
    "categories": [
      "Children's Game",
      "Movies / TV / Radio theme",
      "Video Game Theme"
    ]
  },
  {
    "id": "268948",
    "name": "Monopoly: Friends - The TV Series",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "180",
    "bestWith": "(Undetermined)",
    "recommendedWith": "(Undetermined)",
    "ratingsAverage": "5.62651",
    "categories": [
      "Economic",
      "Movies / TV / Radio theme",
      "Negotiation"
    ]
  },
  {
    "id": "386442",
    "name": "Monopoly: Marvel Spider-Man",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6",
    "categories": [
      "Children's Game",
      "Negotiation"
    ]
  },
  {
    "id": "402561",
    "name": "Monopoly: Marvel Studios' Eternals Edition",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "0",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "10",
    "categories": [
      "Movies / TV / Radio theme",
      "Science Fiction"
    ]
  },
  {
    "id": "100666",
    "name": "Monopoly: Metallica Collector's Edition",
    "yearpublished": "2011",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 4 players",
    "ratingsAverage": "5.56756",
    "categories": [
      "Economic",
      "Music",
      "Negotiation"
    ]
  },
  {
    "id": "283835",
    "name": "Monopoly: One Piece Edition",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "180",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.88214",
    "categories": [
      "Comic Book / Strip",
      "Economic",
      "Movies / TV / Radio theme",
      "Negotiation"
    ]
  },
  {
    "id": "5758",
    "name": "Monopoly: Pokémon",
    "yearpublished": "1995",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "5.43287",
    "categories": [
      "Economic",
      "Movies / TV / Radio theme",
      "Negotiation",
      "Video Game Theme"
    ]
  },
  {
    "id": "265210",
    "name": "Monopoly: Sailor Moon",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "0",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "6.33537",
    "categories": [
      "Economic",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "142041",
    "name": "Monopoly: South Park Collector's Edition",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.42857",
    "categories": [
      "Economic",
      "Movies / TV / Radio theme",
      "Negotiation"
    ]
  },
  {
    "id": "23671",
    "name": "Monopoly: SpongeBob SquarePants",
    "yearpublished": "2005",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "5.34481",
    "categories": [
      "Economic",
      "Movies / TV / Radio theme",
      "Negotiation"
    ]
  },
  {
    "id": "1298",
    "name": "Monopoly: Star Wars",
    "yearpublished": "1997",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "4.93812",
    "categories": [
      "Economic",
      "Movies / TV / Radio theme",
      "Negotiation"
    ]
  },
  {
    "id": "367148",
    "name": "Monopoly: Star Wars - Boba Fett",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "240",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.16667",
    "categories": [
      "Movies / TV / Radio theme",
      "Territory Building"
    ]
  },
  {
    "id": "323911",
    "name": "Monopoly: Super Mario Celebration!",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.69367",
    "categories": [
      "Economic",
      "Video Game Theme"
    ]
  },
  {
    "id": "24764",
    "name": "Monopoly: The Mega Edition",
    "yearpublished": "2006",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.01817",
    "categories": [
      "Economic",
      "Negotiation"
    ]
  },
  {
    "id": "72170",
    "name": "Monopoly: The Office Collector's Edition",
    "yearpublished": "2010",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.55556",
    "categories": [
      "Economic",
      "Movies / TV / Radio theme",
      "Negotiation"
    ]
  },
  {
    "id": "213862",
    "name": "Monster Trap",
    "yearpublished": "2017",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "45",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.08",
    "categories": [
      "Children's Game"
    ]
  },
  {
    "id": "358386",
    "name": "Moon",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.35485",
    "categories": [
      "Science Fiction",
      "Space Exploration"
    ]
  },
  {
    "id": "378001",
    "name": "Moon River",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.60606",
    "categories": [
      "American West",
      "Territory Building"
    ]
  },
  {
    "id": "382957",
    "name": "Moon: Valkyrie Expansion",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "0",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.63014",
    "categories": [
      "Expansion for Base-game"
    ]
  },
  {
    "id": "80006",
    "name": "Mord im Arosa",
    "yearpublished": "2010",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "45",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.53042",
    "categories": [
      "Action / Dexterity",
      "Murder / Mystery",
      "Party Game"
    ]
  },
  {
    "id": "286735",
    "name": "Muffin Time",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "40",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.23143",
    "categories": [
      "Card Game",
      "Party Game"
    ]
  },
  {
    "id": "1927",
    "name": "Munchkin",
    "yearpublished": "2001",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "5.87249",
    "categories": [
      "Card Game",
      "Fantasy",
      "Fighting",
      "Humor"
    ]
  },
  {
    "id": "303981",
    "name": "Munchkin Critical Role",
    "yearpublished": "2021",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "6.79763",
    "categories": [
      "Adventure",
      "Card Game",
      "Fantasy",
      "Humor"
    ]
  },
  {
    "id": "312346",
    "name": "Munchkin Disney",
    "yearpublished": "2020",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "6.44306",
    "categories": [
      "Adventure",
      "Animals",
      "Card Game",
      "Fantasy",
      "Fighting",
      "Humor",
      "Pirates",
      "Space Exploration"
    ]
  },
  {
    "id": "249824",
    "name": "Munchkin Harry Potter Deluxe",
    "yearpublished": "2018",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "6.53799",
    "categories": [
      "Card Game",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "224134",
    "name": "Munchkin Rick and Morty",
    "yearpublished": "2017",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 3-4, 6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.3166",
    "categories": [
      "Card Game",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "380110",
    "name": "Munchkin: South Park",
    "yearpublished": "2023",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.51607",
    "categories": [
      "Card Game",
      "Fighting",
      "Humor",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "340042",
    "name": "Munchkin: SpongeBob SquarePants",
    "yearpublished": "2021",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.98718",
    "categories": [
      "Adventure",
      "Card Game",
      "Humor",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "128475",
    "name": "Murder of Crows",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "20",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "6.04188",
    "categories": [
      "Card Game",
      "Murder / Mystery",
      "Word Game"
    ]
  },
  {
    "id": "33581",
    "name": "Mwahahaha!",
    "yearpublished": "2008",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "5.68578",
    "categories": [
      "Card Game",
      "Humor"
    ]
  },
  {
    "id": "351476",
    "name": "My City: Roll & Build",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-6+ players",
    "ratingsAverage": "7.08337",
    "categories": [
      "City Building",
      "Dice"
    ]
  },
  {
    "id": "270636",
    "name": "My Farm Shop",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.97628",
    "categories": [
      "Farming"
    ]
  },
  {
    "id": "328866",
    "name": "My Father's Work",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "180",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.86299",
    "categories": [
      "Fantasy",
      "Horror"
    ]
  },
  {
    "id": "358558",
    "name": "My Little Pony: Adventures in Equestria Deck-Building Game",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.22981",
    "categories": [
      "Card Game",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "181304",
    "name": "Mysterium",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "7",
    "playingtime": "42",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 3-7 players",
    "ratingsAverage": "7.20527",
    "categories": [
      "Deduction",
      "Horror",
      "Murder / Mystery",
      "Party Game"
    ]
  },
  {
    "id": "301767",
    "name": "Mysterium Park",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "7.03368",
    "categories": [
      "Deduction",
      "Murder / Mystery",
      "Party Game"
    ]
  },
  {
    "id": "275215",
    "name": "Namiji",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.08657",
    "categories": [
      "Travel"
    ]
  },
  {
    "id": "157809",
    "name": "Nations: The Dice Game",
    "yearpublished": "2014",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "40",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "6.93437",
    "categories": [
      "Ancient",
      "Civilization",
      "Dice",
      "Medieval",
      "Renaissance"
    ]
  },
  {
    "id": "167355",
    "name": "Nemesis",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "180",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "8.25801",
    "categories": [
      "Adventure",
      "Horror",
      "Miniatures",
      "Science Fiction"
    ]
  },
  {
    "id": "208895",
    "name": "New York Slice",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.6744",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "353545",
    "name": "Next Station: London",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2-4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.44129",
    "categories": [
      "City Building",
      "Trains"
    ]
  },
  {
    "id": "380165",
    "name": "Next Station: Tokyo",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.14815",
    "categories": [
      "City Building",
      "Trains"
    ]
  },
  {
    "id": "293014",
    "name": "Nidavellir",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.57331",
    "categories": [
      "Card Game",
      "Fantasy",
      "Mythology"
    ]
  },
  {
    "id": "361850",
    "name": "Nimalia",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.05916",
    "categories": [
      "Animals",
      "Environmental",
      "Territory Building"
    ]
  },
  {
    "id": "12942",
    "name": "No Thanks!",
    "yearpublished": "2004",
    "minPlayers": "3",
    "maxPlayers": "7",
    "playingtime": "20",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-7 players",
    "ratingsAverage": "7.08032",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "343990",
    "name": "Not Enough Mana",
    "yearpublished": "2022",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.81017",
    "categories": [
      "Card Game",
      "Fantasy",
      "Humor",
      "Mature / Adult",
      "Party Game",
      "Print & Play"
    ]
  },
  {
    "id": "314745",
    "name": "Now or Never",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "180",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "7.69541",
    "categories": [
      "City Building",
      "Exploration",
      "Fantasy",
      "Science Fiction"
    ]
  },
  {
    "id": "713",
    "name": "Nuclear War",
    "yearpublished": "1965",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.21683",
    "categories": [
      "Card Game",
      "Humor",
      "Modern Warfare",
      "Negotiation",
      "Political"
    ]
  },
  {
    "id": "396790",
    "name": "Nucleum",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.15028",
    "categories": [
      "Economic",
      "Industry / Manufacturing",
      "Science Fiction"
    ]
  },
  {
    "id": "183840",
    "name": "Oh My Goods!",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.99342",
    "categories": [
      "Card Game",
      "Economic",
      "Medieval"
    ]
  },
  {
    "id": "5259",
    "name": "Oh Snap!",
    "yearpublished": "1965",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "5.3869",
    "categories": [
      "Action / Dexterity",
      "Children's Game"
    ]
  },
  {
    "id": "313463",
    "name": "OK Boomer!",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "60",
    "bestWith": "Best with 4, 6 players",
    "recommendedWith": "Recommended with 4, 6, 8 players",
    "ratingsAverage": "4.71446",
    "categories": [
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "289018",
    "name": "On a Scale of One to T-Rex",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 5-8+ players",
    "ratingsAverage": "5.76973",
    "categories": [
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "1234",
    "name": "Once Upon a Time: The Storytelling Card Game",
    "yearpublished": "1993",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.35989",
    "categories": [
      "Card Game",
      "Humor",
      "Party Game",
      "Real-time"
    ]
  },
  {
    "id": "204431",
    "name": "One Night Ultimate Alien",
    "yearpublished": "2017",
    "minPlayers": "4",
    "maxPlayers": "10",
    "playingtime": "10",
    "bestWith": "Best with 6-7 players",
    "recommendedWith": "Recommended with 4-10 players",
    "ratingsAverage": "6.87564",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Horror",
      "Party Game"
    ]
  },
  {
    "id": "286927",
    "name": "One Night Ultimate Super Heroes",
    "yearpublished": "2019",
    "minPlayers": "3",
    "maxPlayers": "10",
    "playingtime": "10",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.93649",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Party Game"
    ]
  },
  {
    "id": "255293",
    "name": "One Night Ultimate Super Villains",
    "yearpublished": "2019",
    "minPlayers": "3",
    "maxPlayers": "10",
    "playingtime": "10",
    "bestWith": "Best with 6-8 players",
    "recommendedWith": "Recommended with 4-10 players",
    "ratingsAverage": "6.75926",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Horror",
      "Party Game"
    ]
  },
  {
    "id": "180956",
    "name": "One Night Ultimate Vampire",
    "yearpublished": "2016",
    "minPlayers": "3",
    "maxPlayers": "10",
    "playingtime": "10",
    "bestWith": "Best with 6-7 players",
    "recommendedWith": "Recommended with 4-9 players",
    "ratingsAverage": "6.64472",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Horror",
      "Party Game"
    ]
  },
  {
    "id": "147949",
    "name": "One Night Ultimate Werewolf",
    "yearpublished": "2014",
    "minPlayers": "3",
    "maxPlayers": "10",
    "playingtime": "10",
    "bestWith": "Best with 6-8 players",
    "recommendedWith": "Recommended with 4-10 players",
    "ratingsAverage": "7.04348",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Horror",
      "Party Game"
    ]
  },
  {
    "id": "163166",
    "name": "One Night Ultimate Werewolf: Daybreak",
    "yearpublished": "2015",
    "minPlayers": "3",
    "maxPlayers": "10",
    "playingtime": "10",
    "bestWith": "Best with 5-8 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "7.28225",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Horror",
      "Party Game"
    ]
  },
  {
    "id": "282227",
    "name": "One Small Step",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.94384",
    "categories": [
      "Aviation / Flight",
      "Educational",
      "Space Exploration"
    ]
  },
  {
    "id": "252197",
    "name": "One Week Ultimate Werewolf",
    "yearpublished": "2018",
    "minPlayers": "3",
    "maxPlayers": "7",
    "playingtime": "60",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 3-7 players",
    "ratingsAverage": "5.7605",
    "categories": [
      "Bluffing",
      "Deduction",
      "Horror",
      "Party Game"
    ]
  },
  {
    "id": "424294",
    "name": "Oopsie",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "90",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "0",
    "categories": [
      "Card Game",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "3737",
    "name": "Operation",
    "yearpublished": "1965",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "10",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "4.16786",
    "categories": [
      "Action / Dexterity",
      "Children's Game",
      "Medical"
    ]
  },
  {
    "id": "165477",
    "name": "Orcs Orcs Orcs",
    "yearpublished": "2014",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.34126",
    "categories": [
      "Fantasy"
    ]
  },
  {
    "id": "322588",
    "name": "Origins: First Builders",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.05363",
    "categories": [
      "City Building",
      "Dice",
      "Mythology"
    ]
  },
  {
    "id": "164928",
    "name": "Orléans",
    "yearpublished": "2014",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "8.05089",
    "categories": [
      "Medieval",
      "Religious",
      "Travel"
    ]
  },
  {
    "id": "1147",
    "name": "Outrage! Steal the Crown Jewels",
    "yearpublished": "1992",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "45",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "4.95432",
    "categories": [
      "Murder / Mystery"
    ]
  },
  {
    "id": "310192",
    "name": "Overboss: A Boss Monster Adventure",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.31086",
    "categories": [
      "Fantasy",
      "Puzzle",
      "Video Game Theme"
    ]
  },
  {
    "id": "311330",
    "name": "P for Pizza",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "20",
    "bestWith": "Best with 4+ players",
    "recommendedWith": "Recommended with 2-4+ players",
    "ratingsAverage": "6.3512",
    "categories": [
      "Word Game"
    ]
  },
  {
    "id": "2136",
    "name": "Pachisi",
    "yearpublished": "400",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "4.51934",
    "categories": [
      "Children's Game"
    ]
  },
  {
    "id": "266810",
    "name": "Paladins of the West Kingdom",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "7.9685",
    "categories": [
      "Medieval"
    ]
  },
  {
    "id": "300531",
    "name": "Paleo",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.67615",
    "categories": [
      "Card Game",
      "Exploration",
      "Prehistoric"
    ]
  },
  {
    "id": "30549",
    "name": "Pandemic",
    "yearpublished": "2008",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.52678",
    "categories": [
      "Medical"
    ]
  },
  {
    "id": "266524",
    "name": "PARKS",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.64657",
    "categories": [
      "Animals",
      "Economic",
      "Environmental",
      "Travel"
    ]
  },
  {
    "id": "15110",
    "name": "Partners",
    "yearpublished": "1998",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 4 players",
    "ratingsAverage": "5.94236",
    "categories": [
      "Card Game",
      "Party Game",
      "Racing"
    ]
  },
  {
    "id": "356559",
    "name": "Partners Duo",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "45",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.66667",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "246822",
    "name": "Partners+",
    "yearpublished": "2018",
    "minPlayers": "6",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 6 players",
    "ratingsAverage": "6.1029",
    "categories": [
      "Party Game",
      "Racing"
    ]
  },
  {
    "id": "338902",
    "name": "Passengers",
    "yearpublished": "2023",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "50",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 5-8 players",
    "ratingsAverage": "6.40709",
    "categories": [
      "Bluffing",
      "Deduction",
      "Negotiation"
    ]
  },
  {
    "id": "163412",
    "name": "Patchwork",
    "yearpublished": "2014",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.58838",
    "categories": [
      "Abstract Strategy",
      "Economic",
      "Puzzle"
    ]
  },
  {
    "id": "385680",
    "name": "Path of Civilization",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.26632",
    "categories": [
      "Civilization"
    ]
  },
  {
    "id": "256960",
    "name": "Pax Pamir: Second Edition",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "8.15652",
    "categories": [
      "Economic",
      "Educational",
      "Negotiation",
      "Political",
      "Post-Napoleonic",
      "Spies / Secret Agents"
    ]
  },
  {
    "id": "312804",
    "name": "Pendulum",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.40199",
    "categories": [
      "Card Game",
      "Economic",
      "Real-time"
    ]
  },
  {
    "id": "400995",
    "name": "Perspectives",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.72861",
    "categories": [
      "Deduction",
      "Murder / Mystery",
      "Puzzle"
    ]
  },
  {
    "id": "31516",
    "name": "Phenomena",
    "yearpublished": "2007",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.57681",
    "categories": [
      "Adventure",
      "Fantasy",
      "Novel-based"
    ]
  },
  {
    "id": "6424",
    "name": "Pick Up Sticks",
    "yearpublished": "1850",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "4.36065",
    "categories": [
      "Action / Dexterity",
      "Children's Game",
      "Party Game"
    ]
  },
  {
    "id": "212310",
    "name": "Pick Your Poison",
    "yearpublished": "2016",
    "minPlayers": "3",
    "maxPlayers": "10",
    "playingtime": "60",
    "bestWith": "Best with 6-8 players",
    "recommendedWith": "Recommended with 5-10 players",
    "ratingsAverage": "5.40895",
    "categories": [
      "Card Game",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "250718",
    "name": "Pick Your Poison: NSFW Edition",
    "yearpublished": "2016",
    "minPlayers": "3",
    "maxPlayers": "16",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.12997",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "284108",
    "name": "Pictures",
    "yearpublished": "2019",
    "minPlayers": "3",
    "maxPlayers": "5",
    "playingtime": "30",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.22658",
    "categories": [
      "Deduction",
      "Party Game"
    ]
  },
  {
    "id": "415054",
    "name": "Piña Coladice",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "20",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.96566",
    "categories": [
      "Dice"
    ]
  },
  {
    "id": "256730",
    "name": "Pipeline",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.57492",
    "categories": [
      "Economic",
      "Industry / Manufacturing"
    ]
  },
  {
    "id": "258779",
    "name": "Planet Unknown",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "80",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "7.79778",
    "categories": [
      "City Building",
      "Industry / Manufacturing",
      "Puzzle",
      "Science Fiction",
      "Space Exploration",
      "Territory Building"
    ]
  },
  {
    "id": "312786",
    "name": "Poetry for Neanderthals",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "12",
    "playingtime": "15",
    "bestWith": "Best with 6, 8 players",
    "recommendedWith": "Recommended with 4-12 players",
    "ratingsAverage": "6.65928",
    "categories": [
      "Humor",
      "Party Game",
      "Prehistoric",
      "Real-time",
      "Word Game"
    ]
  },
  {
    "id": "368017",
    "name": "Point City",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.18717",
    "categories": [
      "Card Game",
      "City Building"
    ]
  },
  {
    "id": "274960",
    "name": "Point Salad",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "7.12286",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "24694",
    "name": "Politiskt korrekt?",
    "yearpublished": "2006",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "0",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6",
    "categories": [
      "Educational",
      "Humor",
      "Political",
      "Trivia"
    ]
  },
  {
    "id": "327062",
    "name": "Popcorn Dice",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 2-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.09981",
    "categories": [
      "Dice",
      "Party Game"
    ]
  },
  {
    "id": "331265",
    "name": "Port Royal: Big Box",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "50",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.55103",
    "categories": [
      "Card Game",
      "Economic",
      "Nautical",
      "Pirates"
    ]
  },
  {
    "id": "180974",
    "name": "Potion Explosion",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.12341",
    "categories": [
      "Fantasy",
      "Puzzle"
    ]
  },
  {
    "id": "2651",
    "name": "Power Grid",
    "yearpublished": "2004",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.80343",
    "categories": [
      "Economic",
      "Industry / Manufacturing"
    ]
  },
  {
    "id": "411404",
    "name": "Power Hungry Pets",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "15",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.01402",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "308765",
    "name": "Praga Caput Regni",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.87111",
    "categories": [
      "City Building",
      "Medieval",
      "Puzzle"
    ]
  },
  {
    "id": "234260",
    "name": "Prey Another Day",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "20",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "6.88814",
    "categories": [
      "Animals",
      "Bluffing",
      "Card Game",
      "Deduction",
      "Print & Play"
    ]
  },
  {
    "id": "390606",
    "name": "Printing Press",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.03707",
    "categories": [
      "Abstract Strategy",
      "Industry / Manufacturing"
    ]
  },
  {
    "id": "262479",
    "name": "Pucket",
    "yearpublished": "2011",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "0",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.3568",
    "categories": [
      "Action / Dexterity",
      "Real-time"
    ]
  },
  {
    "id": "266830",
    "name": "QE",
    "yearpublished": "2019",
    "minPlayers": "3",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.36261",
    "categories": [
      "Economic"
    ]
  },
  {
    "id": "244521",
    "name": "Quacks",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.81103",
    "categories": [
      "Medieval"
    ]
  },
  {
    "id": "316597",
    "name": "The Quacks of Quedlinburg: The Alchemists",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 3-5 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.89226",
    "categories": [
      "Expansion for Base-game",
      "Medieval"
    ]
  },
  {
    "id": "269259",
    "name": "The Quacks of Quedlinburg: The Herb Witches",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 3-5 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "8.01",
    "categories": [
      "Expansion for Base-game",
      "Medieval"
    ]
  },
  {
    "id": "232043",
    "name": "Queendomino",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "25",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.14842",
    "categories": [
      "City Building",
      "Fantasy",
      "Medieval",
      "Territory Building"
    ]
  },
  {
    "id": "217372",
    "name": "The Quest for El Dorado",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.70226",
    "categories": [
      "Adventure",
      "Exploration"
    ]
  },
  {
    "id": "396655",
    "name": "Quicksand",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "7",
    "playingtime": "15",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.70692",
    "categories": [
      "Real-time"
    ]
  },
  {
    "id": "411617",
    "name": "Quoridor Pac-Man",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "15",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2, 5 players",
    "ratingsAverage": "6.45786",
    "categories": [
      "Abstract Strategy",
      "Maze",
      "Video Game Theme"
    ]
  },
  {
    "id": "131260",
    "name": "Qwixx",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "15",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.87278",
    "categories": [
      "Dice",
      "Math",
      "Number"
    ]
  },
  {
    "id": "12",
    "name": "Ra",
    "yearpublished": "1999",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.6651",
    "categories": [
      "Ancient",
      "Mythology"
    ]
  },
  {
    "id": "28143",
    "name": "Race for the Galaxy",
    "yearpublished": "2007",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.74205",
    "categories": [
      "Card Game",
      "Civilization",
      "Economic",
      "Science Fiction",
      "Space Exploration"
    ]
  },
  {
    "id": "301880",
    "name": "Raiders of Scythia",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "80",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.7229",
    "categories": [
      "Ancient"
    ]
  },
  {
    "id": "251678",
    "name": "Railroad Ink: Blazing Red Edition",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "7.07196",
    "categories": [
      "Dice",
      "Trains"
    ]
  },
  {
    "id": "60435",
    "name": "Rallyman",
    "yearpublished": "2009",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.17634",
    "categories": [
      "Racing",
      "Sports"
    ]
  },
  {
    "id": "312959",
    "name": "Rallyman: DIRT",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 1-3 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "7.45705",
    "categories": [
      "Racing",
      "Sports"
    ]
  },
  {
    "id": "2629",
    "name": "Ramses II",
    "yearpublished": "1997",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "5.78102",
    "categories": [
      "Ancient",
      "Children's Game",
      "Exploration",
      "Memory",
      "Mythology"
    ]
  },
  {
    "id": "387780",
    "name": "Rats of Wistar",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.55421",
    "categories": [
      "Animals"
    ]
  },
  {
    "id": "351040",
    "name": "Ready Set Bet",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "9",
    "playingtime": "60",
    "bestWith": "Best with 6-8 players",
    "recommendedWith": "Recommended with 4-9 players",
    "ratingsAverage": "7.54537",
    "categories": [
      "Animals",
      "Party Game",
      "Racing",
      "Real-time",
      "Sports"
    ]
  },
  {
    "id": "126",
    "name": "The Really Nasty Horse Racing Game",
    "yearpublished": "1989",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-6 players",
    "ratingsAverage": "6.2771",
    "categories": [
      "Animals",
      "Racing",
      "Sports"
    ]
  },
  {
    "id": "227224",
    "name": "The Red Cathedral",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "80",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.68128",
    "categories": [
      "Dice",
      "Economic"
    ]
  },
  {
    "id": "24310",
    "name": "The Red Dragon Inn",
    "yearpublished": "2007",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4+ players",
    "ratingsAverage": "6.54884",
    "categories": [
      "Card Game",
      "Fantasy",
      "Humor",
      "Medieval",
      "Party Game"
    ]
  },
  {
    "id": "189062",
    "name": "Red Flags",
    "yearpublished": "2015",
    "minPlayers": "3",
    "maxPlayers": "20",
    "playingtime": "15",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "6.13932",
    "categories": [
      "Card Game",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "239435",
    "name": "Red HINT",
    "yearpublished": "2017",
    "minPlayers": "4",
    "maxPlayers": "10",
    "playingtime": "45",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "6.79092",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "36946",
    "name": "Red November",
    "yearpublished": "2008",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "6.28971",
    "categories": [
      "Fantasy",
      "Humor",
      "Nautical"
    ]
  },
  {
    "id": "262712",
    "name": "Res Arcana",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.63502",
    "categories": [
      "Card Game",
      "Fantasy"
    ]
  },
  {
    "id": "41114",
    "name": "The Resistance",
    "yearpublished": "2009",
    "minPlayers": "5",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 7 players",
    "recommendedWith": "Recommended with 5-10 players",
    "ratingsAverage": "7.20348",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Negotiation",
      "Party Game",
      "Print & Play",
      "Science Fiction",
      "Spies / Secret Agents"
    ]
  },
  {
    "id": "128882",
    "name": "The Resistance: Avalon",
    "yearpublished": "2012",
    "minPlayers": "5",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 7-8 players",
    "recommendedWith": "Recommended with 5-10 players",
    "ratingsAverage": "7.50474",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Fantasy",
      "Medieval",
      "Negotiation",
      "Party Game",
      "Spies / Secret Agents"
    ]
  },
  {
    "id": "256680",
    "name": "Return to Dark Tower",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.23965",
    "categories": [
      "Adventure",
      "Electronic",
      "Fantasy",
      "Fighting"
    ]
  },
  {
    "id": "332772",
    "name": "Revive",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.10982",
    "categories": [
      "Civilization",
      "Industry / Manufacturing",
      "Science Fiction"
    ]
  },
  {
    "id": "385685",
    "name": "Revive: Call of the Abyss",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.31902",
    "categories": [
      "Expansion for Base-game"
    ]
  },
  {
    "id": "297486",
    "name": "Ride the Rails",
    "yearpublished": "2020",
    "minPlayers": "3",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.13699",
    "categories": [
      "Economic",
      "Trains",
      "Transportation"
    ]
  },
  {
    "id": "369282",
    "name": "Rise",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.08347",
    "categories": [
      "City Building",
      "Industry / Manufacturing"
    ]
  },
  {
    "id": "181",
    "name": "Risk",
    "yearpublished": "1959",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "5.60021",
    "categories": [
      "Territory Building",
      "Wargame"
    ]
  },
  {
    "id": "15045",
    "name": "Risk: Star Wars - Clone Wars Edition",
    "yearpublished": "2005",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2, 4 players",
    "ratingsAverage": "5.99733",
    "categories": [
      "Movies / TV / Radio theme",
      "Science Fiction",
      "Wargame"
    ]
  },
  {
    "id": "4324",
    "name": "Risk: The Lord of the Rings",
    "yearpublished": "2002",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "5.93973",
    "categories": [
      "Adventure",
      "Fantasy",
      "Movies / TV / Radio theme",
      "Novel-based",
      "Territory Building",
      "Wargame"
    ]
  },
  {
    "id": "27961",
    "name": "Risk: Transformers - Cybertron Battle Edition",
    "yearpublished": "2007",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "5.36758",
    "categories": [
      "Dice",
      "Movies / TV / Radio theme",
      "Science Fiction",
      "Territory Building",
      "Wargame"
    ]
  },
  {
    "id": "66056",
    "name": "Rivals for Catan",
    "yearpublished": "2010",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.93704",
    "categories": [
      "Card Game",
      "City Building",
      "Medieval",
      "Territory Building"
    ]
  },
  {
    "id": "280132",
    "name": "Robin of Locksley",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.92715",
    "categories": [
      "Economic",
      "Medieval"
    ]
  },
  {
    "id": "121921",
    "name": "Robinson Crusoe: Adventures on the Cursed Island",
    "yearpublished": "2012",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 1-2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.73321",
    "categories": [
      "Adventure",
      "Exploration",
      "Fighting",
      "Novel-based",
      "Territory Building"
    ]
  },
  {
    "id": "381327",
    "name": "Robo Rally",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "Best with 4, 6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.5175",
    "categories": [
      "Maze",
      "Miniatures",
      "Racing",
      "Science Fiction"
    ]
  },
  {
    "id": "298102",
    "name": "Roll Camera!: The Filmmaking Board Game",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.43338",
    "categories": [
      "Dice",
      "Humor",
      "Movies / TV / Radio theme",
      "Puzzle"
    ]
  },
  {
    "id": "169426",
    "name": "Roll Player",
    "yearpublished": "2016",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.43254",
    "categories": [
      "Dice",
      "Fantasy",
      "Medieval"
    ]
  },
  {
    "id": "255601",
    "name": "Roll Player: Fiends & Familiars",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.8438",
    "categories": [
      "Dice",
      "Expansion for Base-game",
      "Fantasy"
    ]
  },
  {
    "id": "213360",
    "name": "Roll Player: Monsters & Minions",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "8.01744",
    "categories": [
      "Dice",
      "Expansion for Base-game",
      "Fantasy"
    ]
  },
  {
    "id": "237182",
    "name": "Root",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "8.07166",
    "categories": [
      "Animals",
      "Fantasy",
      "Negotiation",
      "Territory Building",
      "Wargame"
    ]
  },
  {
    "id": "272638",
    "name": "Root: The Exiles and Partisans Deck",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "0",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "8.6783",
    "categories": [
      "Animals",
      "Expansion for Base-game",
      "Wargame"
    ]
  },
  {
    "id": "330149",
    "name": "Root: The Marauder Expansion",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "8.8438",
    "categories": [
      "Expansion for Base-game",
      "Animals",
      "Fantasy",
      "Wargame"
    ]
  },
  {
    "id": "811",
    "name": "Rummikub",
    "yearpublished": "1977",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.4933",
    "categories": [
      "Abstract Strategy",
      "Card Game",
      "Number"
    ]
  },
  {
    "id": "285265",
    "name": "Rune Stones",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.25617",
    "categories": [
      "Fantasy"
    ]
  },
  {
    "id": "9220",
    "name": "Saboteur",
    "yearpublished": "2004",
    "minPlayers": "3",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 7-8 players",
    "recommendedWith": "Recommended with 5-10 players",
    "ratingsAverage": "6.54332",
    "categories": [
      "Bluffing",
      "Card Game",
      "Exploration",
      "Fantasy",
      "Party Game"
    ]
  },
  {
    "id": "199561",
    "name": "Sagrada",
    "yearpublished": "2017",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.47286",
    "categories": [
      "Dice",
      "Puzzle"
    ]
  },
  {
    "id": "174524",
    "name": "Saloon Tycoon",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.12026",
    "categories": [
      "American West"
    ]
  },
  {
    "id": "422674",
    "name": "Saltfjord",
    "yearpublished": "2024",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.89962",
    "categories": [
      "City Building",
      "Dice",
      "Farming"
    ]
  },
  {
    "id": "402669",
    "name": "Sand",
    "yearpublished": "2024",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "6.7897",
    "categories": [
      "Transportation"
    ]
  },
  {
    "id": "229220",
    "name": "Santa Maria",
    "yearpublished": "2017",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.46758",
    "categories": [
      "Civilization",
      "Farming"
    ]
  },
  {
    "id": "194655",
    "name": "Santorini",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-3 players",
    "ratingsAverage": "7.35005",
    "categories": [
      "Abstract Strategy",
      "Ancient",
      "Mythology"
    ]
  },
  {
    "id": "369142",
    "name": "Savernake Forest",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "40",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.09477",
    "categories": []
  },
  {
    "id": "367041",
    "name": "Scholars of the South Tigris",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.04081",
    "categories": [
      "Arabian",
      "Medieval"
    ]
  },
  {
    "id": "372",
    "name": "Schotten Totten",
    "yearpublished": "1999",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.36083",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "300930",
    "name": "Schotten Totten 2",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.09443",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "302344",
    "name": "Scooby-Doo: Escape from the Haunted Mansion",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "99",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.12993",
    "categories": [
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "310067",
    "name": "Scooby-Doo! The Board Game",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "30",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.2088",
    "categories": [
      "Horror",
      "Humor",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "291453",
    "name": "SCOUT",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.8195",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "320",
    "name": "Scrabble",
    "yearpublished": "1948",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.26934",
    "categories": [
      "Word Game"
    ]
  },
  {
    "id": "169786",
    "name": "Scythe",
    "yearpublished": "2016",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "115",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "8.12347",
    "categories": [
      "Economic",
      "Fighting",
      "Science Fiction",
      "Territory Building"
    ]
  },
  {
    "id": "382016",
    "name": "Sea of Thieves: Voyage of Legends",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.48575",
    "categories": [
      "Pirates"
    ]
  },
  {
    "id": "279537",
    "name": "The Search for Planet X",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "75",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.92642",
    "categories": [
      "Deduction",
      "Puzzle",
      "Space Exploration"
    ]
  },
  {
    "id": "265683",
    "name": "Second Chance",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "15",
    "bestWith": "Best with 2-4 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "6.65214",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "188834",
    "name": "Secret Hitler",
    "yearpublished": "2016",
    "minPlayers": "5",
    "maxPlayers": "10",
    "playingtime": "45",
    "bestWith": "Best with 8, 10 players",
    "recommendedWith": "Recommended with 6-10 players",
    "ratingsAverage": "7.49739",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Humor",
      "Party Game",
      "Political",
      "Print & Play",
      "Spies / Secret Agents"
    ]
  },
  {
    "id": "2375",
    "name": "Sequence",
    "yearpublished": "1982",
    "minPlayers": "2",
    "maxPlayers": "12",
    "playingtime": "30",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4, 6 players",
    "ratingsAverage": "6.14866",
    "categories": [
      "Abstract Strategy",
      "Card Game"
    ]
  },
  {
    "id": "270847",
    "name": "Set & Match",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2, 4 players",
    "ratingsAverage": "7.36436",
    "categories": [
      "Action / Dexterity",
      "Sports"
    ]
  },
  {
    "id": "379110",
    "name": "SETUP",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "25",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.13485",
    "categories": [
      "Abstract Strategy",
      "Card Game",
      "Children's Game",
      "Number",
      "Puzzle"
    ]
  },
  {
    "id": "359764",
    "name": "Shake That City",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "40",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.10293",
    "categories": [
      "City Building",
      "Puzzle"
    ]
  },
  {
    "id": "298638",
    "name": "Sheriff of Nottingham: 2nd Edition",
    "yearpublished": "2020",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 4-6 players",
    "ratingsAverage": "7.24731",
    "categories": [
      "Bluffing",
      "Card Game",
      "Humor",
      "Medieval",
      "Negotiation",
      "Novel-based",
      "Party Game"
    ]
  },
  {
    "id": "223931",
    "name": "Sherlock Holmes Consulting Detective: Carlton House & Queen's Park",
    "yearpublished": "2017",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.77241",
    "categories": [
      "Deduction",
      "Murder / Mystery",
      "Novel-based",
      "Post-Napoleonic",
      "Puzzle",
      "Spies / Secret Agents"
    ]
  },
  {
    "id": "296345",
    "name": "Sherlock Holmes Consulting Detective: The Baker Street Irregulars",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.20768",
    "categories": [
      "Deduction",
      "Murder / Mystery",
      "Novel-based"
    ]
  },
  {
    "id": "196379",
    "name": "Shit Happens",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "60",
    "bestWith": "Best with 4, 6 players",
    "recommendedWith": "Recommended with 3-8 players",
    "ratingsAverage": "5.34753",
    "categories": [
      "Card Game",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "176103",
    "name": "Shogun Big Box",
    "yearpublished": "2015",
    "minPlayers": "3",
    "maxPlayers": "5",
    "playingtime": "150",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.97046",
    "categories": [
      "Economic",
      "Territory Building"
    ]
  },
  {
    "id": "359892",
    "name": "Shoot for the Stars",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "30",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 3-8+ players",
    "ratingsAverage": "6.76203",
    "categories": [
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "420948",
    "name": "Side Quest: Frostpunk",
    "yearpublished": "2024",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-3 players",
    "ratingsAverage": "6.60714",
    "categories": [
      "Deduction",
      "Puzzle",
      "Science Fiction",
      "Video Game Theme"
    ]
  },
  {
    "id": "202426",
    "name": "Sidereal Confluence",
    "yearpublished": "2017",
    "minPlayers": "4",
    "maxPlayers": "9",
    "playingtime": "180",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-9 players",
    "ratingsAverage": "7.77714",
    "categories": [
      "Economic",
      "Negotiation",
      "Real-time",
      "Science Fiction",
      "Space Exploration"
    ]
  },
  {
    "id": "198044",
    "name": "Sign",
    "yearpublished": "2017",
    "minPlayers": "4",
    "maxPlayers": "9",
    "playingtime": "5",
    "bestWith": "Best with 4, 6, 8-9 players",
    "recommendedWith": "Recommended with 4-9 players",
    "ratingsAverage": "6.24929",
    "categories": [
      "Abstract Strategy",
      "Bluffing",
      "Card Game"
    ]
  },
  {
    "id": "268620",
    "name": "Similo",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.81633",
    "categories": [
      "Card Game",
      "Children's Game",
      "Deduction",
      "Fantasy",
      "Mythology"
    ]
  },
  {
    "id": "366322",
    "name": "Skoventyr",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1-2 players",
    "ratingsAverage": "7.38225",
    "categories": [
      "Mythology"
    ]
  },
  {
    "id": "92415",
    "name": "Skull",
    "yearpublished": "2011",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "45",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.18479",
    "categories": [
      "Bluffing",
      "Card Game",
      "Party Game"
    ]
  },
  {
    "id": "150145",
    "name": "Skull King",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "30",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.54122",
    "categories": [
      "Card Game",
      "Nautical",
      "Pirates"
    ]
  },
  {
    "id": "373106",
    "name": "Sky Team",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "15",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "8.17374",
    "categories": [
      "Aviation / Flight"
    ]
  },
  {
    "id": "204135",
    "name": "Skyjo",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.67948",
    "categories": [
      "Card Game",
      "Math"
    ]
  },
  {
    "id": "359438",
    "name": "Skymines",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.97826",
    "categories": [
      "Economic",
      "Science Fiction"
    ]
  },
  {
    "id": "3804",
    "name": "Slamwich",
    "yearpublished": "1994",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "5.14607",
    "categories": [
      "Card Game",
      "Children's Game"
    ]
  },
  {
    "id": "375853",
    "name": "Sling Puck Hockey",
    "yearpublished": "0",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "20",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.60185",
    "categories": [
      "Sports"
    ]
  },
  {
    "id": "310957",
    "name": "SlingPuck",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "0",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.94317",
    "categories": [
      "Action / Dexterity",
      "Sports"
    ]
  },
  {
    "id": "40692",
    "name": "Small World",
    "yearpublished": "2009",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "80",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.19987",
    "categories": [
      "Fantasy",
      "Fighting",
      "Territory Building"
    ]
  },
  {
    "id": "122522",
    "name": "Smash Up",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.69846",
    "categories": [
      "Card Game",
      "Fantasy",
      "Humor",
      "Pirates",
      "Science Fiction",
      "Zombies"
    ]
  },
  {
    "id": "358515",
    "name": "Smash Up: Disney Edition",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.43834",
    "categories": [
      "Card Game",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "306697",
    "name": "Smash Up: Marvel",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-3 players",
    "ratingsAverage": "7.28136",
    "categories": [
      "Card Game",
      "Comic Book / Strip"
    ]
  },
  {
    "id": "337389",
    "name": "Snakesss",
    "yearpublished": "2021",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "20",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 5-8 players",
    "ratingsAverage": "6.31412",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Humor",
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "329839",
    "name": "So Clover!",
    "yearpublished": "2021",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "7.59139",
    "categories": [
      "Party Game",
      "Word Game"
    ]
  },
  {
    "id": "259809",
    "name": "Sonar Family",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2, 4 players",
    "ratingsAverage": "6.82827",
    "categories": [
      "Deduction",
      "Fighting",
      "Nautical",
      "Real-time"
    ]
  },
  {
    "id": "365188",
    "name": "Soulmates",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "30",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.92105",
    "categories": [
      "Card Game",
      "Party Game"
    ]
  },
  {
    "id": "359893",
    "name": "Sounds Fishy",
    "yearpublished": "2022",
    "minPlayers": "4",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 7-8 players",
    "recommendedWith": "Recommended with 5-10 players",
    "ratingsAverage": "6.79494",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "242302",
    "name": "Space Base",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.54383",
    "categories": [
      "Dice",
      "Science Fiction",
      "Space Exploration"
    ]
  },
  {
    "id": "349810",
    "name": "Space Base: The Mysteries of Terra Proxima",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.55314",
    "categories": [
      "Expansion for Base-game",
      "Space Exploration"
    ]
  },
  {
    "id": "71721",
    "name": "Space Hulk: Death Angel - The Card Game",
    "yearpublished": "2010",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.00324",
    "categories": [
      "Card Game",
      "Fighting",
      "Science Fiction"
    ]
  },
  {
    "id": "419125",
    "name": "Sparks",
    "yearpublished": "2024",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.15918",
    "categories": [
      "Dice",
      "Industry / Manufacturing"
    ]
  },
  {
    "id": "415147",
    "name": "Spectacular",
    "yearpublished": "2024",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "7.31417",
    "categories": [
      "Animals",
      "Dice",
      "Puzzle"
    ]
  },
  {
    "id": "2003",
    "name": "Spectrangle",
    "yearpublished": "1989",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "5.75252",
    "categories": [
      "Abstract Strategy"
    ]
  },
  {
    "id": "162886",
    "name": "Spirit Island",
    "yearpublished": "2017",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.33959",
    "categories": [
      "Environmental",
      "Fantasy",
      "Fighting",
      "Mythology",
      "Territory Building"
    ]
  },
  {
    "id": "148228",
    "name": "Splendor",
    "yearpublished": "2014",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.41837",
    "categories": [
      "Card Game",
      "Economic",
      "Renaissance"
    ]
  },
  {
    "id": "364073",
    "name": "Splendor Duel",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.90472",
    "categories": [
      "Card Game",
      "Economic",
      "Renaissance"
    ]
  },
  {
    "id": "293296",
    "name": "Splendor: Marvel",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.57334",
    "categories": [
      "Card Game",
      "Comic Book / Strip",
      "Economic"
    ]
  },
  {
    "id": "63268",
    "name": "Spot it!",
    "yearpublished": "2009",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 2-7 players",
    "ratingsAverage": "6.54504",
    "categories": [
      "Card Game",
      "Children's Game",
      "Educational",
      "Party Game",
      "Real-time",
      "Sports"
    ]
  },
  {
    "id": "372559",
    "name": "Spots",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.15863",
    "categories": [
      "Animals",
      "Dice"
    ]
  },
  {
    "id": "367512",
    "name": "Squid Game: Let the Games Begin",
    "yearpublished": "2022",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "45",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 3-6+ players",
    "ratingsAverage": "5.4236",
    "categories": [
      "Bluffing",
      "Card Game",
      "Children's Game",
      "Movies / TV / Radio theme",
      "Party Game"
    ]
  },
  {
    "id": "347029",
    "name": "Sriracha: The Game",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "15",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "5.45397",
    "categories": [
      "Action / Dexterity",
      "Card Game"
    ]
  },
  {
    "id": "147020",
    "name": "Star Realms",
    "yearpublished": "2014",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.55667",
    "categories": [
      "Card Game",
      "Fighting",
      "Science Fiction"
    ]
  },
  {
    "id": "347117",
    "name": "Star Trek: Missions",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.75041",
    "categories": [
      "Card Game",
      "Movies / TV / Radio theme",
      "Science Fiction"
    ]
  },
  {
    "id": "358800",
    "name": "Star Wars Villainous: Power of the Dark Side",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "50",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.07373",
    "categories": [
      "Card Game",
      "Movies / TV / Radio theme",
      "Science Fiction"
    ]
  },
  {
    "id": "410655",
    "name": "Star Wars: Bounty Hunters",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.68062",
    "categories": [
      "Card Game",
      "Space Exploration"
    ]
  },
  {
    "id": "160964",
    "name": "Star Wars: Empire vs. Rebellion",
    "yearpublished": "2014",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "6.26506",
    "categories": [
      "Bluffing",
      "Card Game",
      "Movies / TV / Radio theme",
      "Science Fiction"
    ]
  },
  {
    "id": "353470",
    "name": "Star Wars: Jabba's Palace - A Love Letter Game",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "20",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.22135",
    "categories": [
      "Card Game",
      "Movies / TV / Radio theme",
      "Science Fiction"
    ]
  },
  {
    "id": "187645",
    "name": "Star Wars: Rebellion",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "240",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "8.41827",
    "categories": [
      "Civil War",
      "Miniatures",
      "Movies / TV / Radio theme",
      "Science Fiction",
      "Space Exploration",
      "Wargame"
    ]
  },
  {
    "id": "374173",
    "name": "Star Wars: The Deckbuilding Game",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.8574",
    "categories": [
      "Card Game",
      "Fighting",
      "Movies / TV / Radio theme",
      "Science Fiction"
    ]
  },
  {
    "id": "312267",
    "name": "Star Wars: Unlock!",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "7.05057",
    "categories": [
      "Card Game",
      "Movies / TV / Radio theme",
      "Puzzle"
    ]
  },
  {
    "id": "332290",
    "name": "Stardew Valley: The Board Game",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "200",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "7.4144",
    "categories": [
      "Animals",
      "Farming",
      "Video Game Theme"
    ]
  },
  {
    "id": "363369",
    "name": "Starship Captains",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "100",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.15606",
    "categories": [
      "Science Fiction",
      "Space Exploration"
    ]
  },
  {
    "id": "223770",
    "name": "Startups",
    "yearpublished": "2017",
    "minPlayers": "3",
    "maxPlayers": "7",
    "playingtime": "20",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.19207",
    "categories": [
      "Card Game",
      "Economic"
    ]
  },
  {
    "id": "329845",
    "name": "Stella: Dixit Universe",
    "yearpublished": "2021",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.29735",
    "categories": [
      "Card Game",
      "Party Game"
    ]
  },
  {
    "id": "179835",
    "name": "Stellar Conflict",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "10",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.46035",
    "categories": [
      "Action / Dexterity",
      "Card Game",
      "Fighting",
      "Real-time",
      "Science Fiction"
    ]
  },
  {
    "id": "34635",
    "name": "Stone Age",
    "yearpublished": "2008",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.51942",
    "categories": [
      "Dice",
      "Economic",
      "Prehistoric"
    ]
  },
  {
    "id": "387514",
    "name": "Stonespine Architects",
    "yearpublished": "2024",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.5297",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "130841",
    "name": "Stratego Card Game",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "5.70966",
    "categories": [
      "Bluffing",
      "Card Game",
      "Deduction",
      "Fighting",
      "Memory"
    ]
  },
  {
    "id": "279720",
    "name": "Streets",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "6.62709",
    "categories": [
      "City Building"
    ]
  },
  {
    "id": "369519",
    "name": "Stupefy!",
    "yearpublished": "2022",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "30",
    "bestWith": "Best with 4, 6, 8 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "6.31082",
    "categories": [
      "Fantasy"
    ]
  },
  {
    "id": "302524",
    "name": "Super-Skill Pinball: 4-Cade",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "7.05941",
    "categories": [
      "Dice"
    ]
  },
  {
    "id": "294047",
    "name": "Superclub: The football manager board game",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "240",
    "bestWith": "Best with 5+ players",
    "recommendedWith": "Recommended with 3-4+ players",
    "ratingsAverage": "7.73219",
    "categories": [
      "Sports"
    ]
  },
  {
    "id": "139443",
    "name": "Superfight",
    "yearpublished": "2013",
    "minPlayers": "3",
    "maxPlayers": "20",
    "playingtime": "15",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 3-8, 12-15 players",
    "ratingsAverage": "5.82755",
    "categories": [
      "Card Game",
      "Comic Book / Strip",
      "Fighting",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "192291",
    "name": "Sushi Go Party!",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "20",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 2-8 players",
    "ratingsAverage": "7.38425",
    "categories": [
      "Card Game",
      "Party Game"
    ]
  },
  {
    "id": "133473",
    "name": "Sushi Go!",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "15",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "6.99393",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "143405",
    "name": "Sylvion",
    "yearpublished": "2015",
    "minPlayers": "1",
    "maxPlayers": "2",
    "playingtime": "30",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1-2 players",
    "ratingsAverage": "7.03895",
    "categories": [
      "Animals",
      "Card Game",
      "Environmental",
      "Fantasy"
    ]
  },
  {
    "id": "253664",
    "name": "Taco Cat Goat Cheese Pizza",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "30",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 3-8 players",
    "ratingsAverage": "6.55065",
    "categories": [
      "Action / Dexterity",
      "Card Game",
      "Children's Game",
      "Party Game",
      "Real-time"
    ]
  },
  {
    "id": "70919",
    "name": "Takenoko",
    "yearpublished": "2011",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.20545",
    "categories": [
      "Animals",
      "Environmental",
      "Farming",
      "Territory Building"
    ]
  },
  {
    "id": "340898",
    "name": "Talisman: Harry Potter",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.31612",
    "categories": [
      "Adventure",
      "Exploration",
      "Fighting",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "273976",
    "name": "Talisman: Kingdom Hearts",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 4-6 players",
    "ratingsAverage": "6.50217",
    "categories": [
      "Adventure",
      "Exploration",
      "Fantasy",
      "Fighting",
      "Video Game Theme"
    ]
  },
  {
    "id": "415550",
    "name": "Talisman: The Magical Quest Game - 5th Edition",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "0",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "7.52934",
    "categories": [
      "Adventure",
      "Exploration",
      "Fantasy",
      "Fighting"
    ]
  },
  {
    "id": "286096",
    "name": "Tapestry",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.44112",
    "categories": [
      "Civilization"
    ]
  },
  {
    "id": "118048",
    "name": "Targi",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "2",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "7.60221",
    "categories": [
      "Arabian",
      "Card Game"
    ]
  },
  {
    "id": "146278",
    "name": "Tash-Kalar: Arena of Legends",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.15447",
    "categories": [
      "Abstract Strategy",
      "Card Game",
      "Fantasy",
      "Fighting"
    ]
  },
  {
    "id": "46213",
    "name": "Telestrations",
    "yearpublished": "2009",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "30",
    "bestWith": "Best with 8 players",
    "recommendedWith": "Recommended with 5-8+ players",
    "ratingsAverage": "7.38169",
    "categories": [
      "Humor",
      "Party Game",
      "Real-time"
    ]
  },
  {
    "id": "173761",
    "name": "Telestrations After Dark",
    "yearpublished": "2015",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "30",
    "bestWith": "Best with 7-8 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "7.10915",
    "categories": [
      "Humor",
      "Mature / Adult",
      "Party Game",
      "Real-time"
    ]
  },
  {
    "id": "354214",
    "name": "Tell Me Without Telling Me",
    "yearpublished": "2021",
    "minPlayers": "4",
    "maxPlayers": "99",
    "playingtime": "15",
    "bestWith": "Best with 8-10 players",
    "recommendedWith": "Recommended with 6-12 players",
    "ratingsAverage": "5.17949",
    "categories": [
      "Party Game",
      "Word Game"
    ]
  },
  {
    "id": "229853",
    "name": "Teotihuacan: City of Gods",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.84969",
    "categories": [
      "Ancient"
    ]
  },
  {
    "id": "181289",
    "name": "Terra Mystica: Big Box",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "150",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "8.53727",
    "categories": [
      "Civilization",
      "Economic",
      "Fantasy",
      "Territory Building"
    ]
  },
  {
    "id": "167791",
    "name": "Terraforming Mars",
    "yearpublished": "2016",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.3509",
    "categories": [
      "Economic",
      "Environmental",
      "Industry / Manufacturing",
      "Science Fiction",
      "Space Exploration",
      "Territory Building"
    ]
  },
  {
    "id": "328871",
    "name": "Terraforming Mars: Ares Expedition",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.65221",
    "categories": [
      "Card Game",
      "Science Fiction",
      "Space Exploration"
    ]
  },
  {
    "id": "255681",
    "name": "Terraforming Mars: Colonies",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "8.10505",
    "categories": [
      "Expansion for Base-game",
      "Economic",
      "Environmental",
      "Industry / Manufacturing",
      "Science Fiction",
      "Territory Building"
    ]
  },
  {
    "id": "247030",
    "name": "Terraforming Mars: Prelude",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "8.84743",
    "categories": [
      "Expansion for Base-game",
      "Economic",
      "Environmental",
      "Industry / Manufacturing",
      "Science Fiction"
    ]
  },
  {
    "id": "296108",
    "name": "Terraforming Mars: The Dice Game",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "0",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.32762",
    "categories": [
      "Dice",
      "Economic",
      "Science Fiction"
    ]
  },
  {
    "id": "273473",
    "name": "Terraforming Mars: Turmoil",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "150",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.48735",
    "categories": [
      "Economic",
      "Environmental",
      "Expansion for Base-game",
      "Industry / Manufacturing",
      "Science Fiction",
      "Space Exploration",
      "Territory Building"
    ]
  },
  {
    "id": "231965",
    "name": "Terraforming Mars: Venus Next",
    "yearpublished": "2017",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.56856",
    "categories": [
      "Expansion for Base-game",
      "Economic",
      "Educational",
      "Environmental",
      "Industry / Manufacturing",
      "Science Fiction"
    ]
  },
  {
    "id": "244522",
    "name": "That's Pretty Clever!",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.56301",
    "categories": [
      "Dice",
      "Number"
    ]
  },
  {
    "id": "151847",
    "name": "That's What She Said Game",
    "yearpublished": "2016",
    "minPlayers": "4",
    "maxPlayers": "10",
    "playingtime": "60",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "5.24678",
    "categories": [
      "Card Game",
      "Humor",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "12063",
    "name": "There's a Moose in the House",
    "yearpublished": "2004",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "15",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5+ players",
    "ratingsAverage": "5.2629",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "182028",
    "name": "Through the Ages: A New Story of Civilization",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "8.26917",
    "categories": [
      "Card Game",
      "Civilization",
      "Economic"
    ]
  },
  {
    "id": "280833",
    "name": "Through the Ages: New Leaders and Wonders",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "8.89153",
    "categories": [
      "Expansion for Base-game",
      "Card Game",
      "Civilization",
      "Economic"
    ]
  },
  {
    "id": "503",
    "name": "Through the Desert",
    "yearpublished": "1998",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.14929",
    "categories": [
      "Abstract Strategy",
      "Animals",
      "Arabian"
    ]
  },
  {
    "id": "9209",
    "name": "Ticket to Ride",
    "yearpublished": "2004",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.3897",
    "categories": [
      "Trains"
    ]
  },
  {
    "id": "388036",
    "name": "Ticket To Ride: Berlin",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "15",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.2383",
    "categories": [
      "Trains"
    ]
  },
  {
    "id": "14996",
    "name": "Ticket to Ride: Europe",
    "yearpublished": "2005",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.5269",
    "categories": [
      "Trains"
    ]
  },
  {
    "id": "329841",
    "name": "Ticket to Ride: Europe - 15th Anniversary",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "8.19837",
    "categories": [
      "Trains",
      "Travel"
    ]
  },
  {
    "id": "225244",
    "name": "Ticket to Ride: Germany",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.58782",
    "categories": [
      "Trains",
      "Transportation"
    ]
  },
  {
    "id": "276894",
    "name": "Ticket to Ride: London",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "15",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.06973",
    "categories": [
      "Trains"
    ]
  },
  {
    "id": "253284",
    "name": "Ticket to Ride: New York",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "15",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.89707",
    "categories": [
      "Trains"
    ]
  },
  {
    "id": "371140",
    "name": "Ticket to Ride: Northern Lights",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.8102",
    "categories": [
      "Trains"
    ]
  },
  {
    "id": "202670",
    "name": "Ticket to Ride: Rails & Sails",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.43309",
    "categories": [
      "Nautical",
      "Trains"
    ]
  },
  {
    "id": "351913",
    "name": "Tiletum",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "100",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "7.87735",
    "categories": [
      "Renaissance"
    ]
  },
  {
    "id": "256089",
    "name": "TimeWinder",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "10",
    "categories": [
      "Card Game",
      "Science Fiction"
    ]
  },
  {
    "id": "295192",
    "name": "Tinderblox",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "15",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "6.77214",
    "categories": [
      "Action / Dexterity",
      "Adventure",
      "Party Game"
    ]
  },
  {
    "id": "265736",
    "name": "Tiny Towns",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.21396",
    "categories": [
      "Abstract Strategy",
      "Animals",
      "City Building",
      "Puzzle"
    ]
  },
  {
    "id": "232040",
    "name": "Tipsy Tower",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "35",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "4.75",
    "categories": [
      "Action / Dexterity"
    ]
  },
  {
    "id": "147733",
    "name": "Tjejmiddag",
    "yearpublished": "2010",
    "minPlayers": "3",
    "maxPlayers": "99",
    "playingtime": "45",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "7",
    "categories": [
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "186375",
    "name": "Tokaido: Deluxe Edition",
    "yearpublished": "2015",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.77499",
    "categories": [
      "Travel"
    ]
  },
  {
    "id": "261901",
    "name": "Tokyo Highway",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.81409",
    "categories": [
      "Action / Dexterity",
      "Transportation"
    ]
  },
  {
    "id": "292974",
    "name": "Tournament at Avalon",
    "yearpublished": "2020",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6+ players",
    "ratingsAverage": "7.28543",
    "categories": [
      "Card Game",
      "Medieval"
    ]
  },
  {
    "id": "216632",
    "name": "Tournament at Camelot",
    "yearpublished": "2017",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "7.12798",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "283864",
    "name": "Trails of Tucana",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "15",
    "bestWith": "Best with 3-5 players",
    "recommendedWith": "Recommended with 1-8+ players",
    "ratingsAverage": "7.3747",
    "categories": [
      "Exploration"
    ]
  },
  {
    "id": "288513",
    "name": "Tranquility",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "20",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "6.95936",
    "categories": [
      "Card Game",
      "Nautical",
      "Number"
    ]
  },
  {
    "id": "337961",
    "name": "Transformers Deck-Building Game",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "6.93549",
    "categories": [
      "Card Game",
      "Comic Book / Strip"
    ]
  },
  {
    "id": "27518",
    "name": "Travel Trivia Challenge",
    "yearpublished": "2004",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "45",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.12",
    "categories": [
      "Party Game",
      "Travel",
      "Trivia"
    ]
  },
  {
    "id": "353288",
    "name": "Trekking Through History",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.57099",
    "categories": [
      "Adventure"
    ]
  },
  {
    "id": "4040",
    "name": "Tri-Ominos",
    "yearpublished": "1965",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "5.42282",
    "categories": [
      "Abstract Strategy",
      "Movies / TV / Radio theme"
    ]
  },
  {
    "id": "282171",
    "name": "Trial by Trolley",
    "yearpublished": "2020",
    "minPlayers": "3",
    "maxPlayers": "13",
    "playingtime": "90",
    "bestWith": "Best with 5, 7 players",
    "recommendedWith": "Recommended with 3-10 players",
    "ratingsAverage": "6.04178",
    "categories": [
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "4492",
    "name": "Trivial Pursuit: 20th Anniversary Edition",
    "yearpublished": "2002",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "180",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6+ players",
    "ratingsAverage": "5.22447",
    "categories": [
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "248702",
    "name": "Trivial Pursuit: Classic Edition",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "5.99543",
    "categories": [
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "352164",
    "name": "Trivial Pursuit: Decades - 2010 to 2020",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "0",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "5.52062",
    "categories": [
      "Trivia"
    ]
  },
  {
    "id": "216557",
    "name": "Trivial Pursuit: Dinosaurs",
    "yearpublished": "2014",
    "minPlayers": "2",
    "maxPlayers": "0",
    "playingtime": "90",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.03037",
    "categories": [
      "Animals",
      "Children's Game",
      "Party Game",
      "Prehistoric",
      "Trivia"
    ]
  },
  {
    "id": "151947",
    "name": "Trivial Pursuit: Doctor Who",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "0",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.56136",
    "categories": [
      "Movies / TV / Radio theme",
      "Science Fiction",
      "Trivia"
    ]
  },
  {
    "id": "256214",
    "name": "Trivial Pursuit: Friends",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "99",
    "playingtime": "0",
    "bestWith": "Best with 2, 4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "5.77652",
    "categories": [
      "Movies / TV / Radio theme",
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "268195",
    "name": "Trivial Pursuit: Rick and Morty",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "99",
    "playingtime": "0",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.67725",
    "categories": [
      "Movies / TV / Radio theme",
      "Trivia"
    ]
  },
  {
    "id": "192017",
    "name": "Trivial Pursuit: The Big Bang Theory Edition",
    "yearpublished": "2014",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "60",
    "bestWith": "Best with 2-10+ players",
    "recommendedWith": "Recommended with 2-10+ players",
    "ratingsAverage": "5.98571",
    "categories": [
      "Movies / TV / Radio theme",
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "257073",
    "name": "Trollfjord",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "5.85586",
    "categories": [
      "Action / Dexterity",
      "Fantasy",
      "Mythology"
    ]
  },
  {
    "id": "298060",
    "name": "Truffle Shuffle",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.85745",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "16992",
    "name": "Tsuro",
    "yearpublished": "2005",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "20",
    "bestWith": "Best with 4, 6 players",
    "recommendedWith": "Recommended with 2-8 players",
    "ratingsAverage": "6.66755",
    "categories": [
      "Fantasy"
    ]
  },
  {
    "id": "124172",
    "name": "Tsuro of the Seas",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "40",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-8 players",
    "ratingsAverage": "6.51347",
    "categories": [
      "Abstract Strategy",
      "Nautical"
    ]
  },
  {
    "id": "386273",
    "name": "Tucana Builders",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "30",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "6.62434",
    "categories": [
      "Animals",
      "Puzzle"
    ]
  },
  {
    "id": "269210",
    "name": "Twice as Clever!",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.432",
    "categories": [
      "Dice",
      "Number"
    ]
  },
  {
    "id": "233078",
    "name": "Twilight Imperium: Fourth Edition",
    "yearpublished": "2017",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "480",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "8.58051",
    "categories": [
      "Civilization",
      "Economic",
      "Exploration",
      "Negotiation",
      "Political",
      "Science Fiction",
      "Space Exploration",
      "Wargame"
    ]
  },
  {
    "id": "361545",
    "name": "Twilight Inscription",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "8",
    "playingtime": "120",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "7.45118",
    "categories": [
      "Science Fiction"
    ]
  },
  {
    "id": "222887",
    "name": "Twin It!",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "10",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-6+ players",
    "ratingsAverage": "6.74764",
    "categories": [
      "Action / Dexterity",
      "Card Game",
      "Party Game"
    ]
  },
  {
    "id": "126163",
    "name": "Tzolk'in: The Mayan Calendar",
    "yearpublished": "2012",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.85146",
    "categories": [
      "Ancient",
      "Civilization",
      "Economic",
      "Farming",
      "Mythology"
    ]
  },
  {
    "id": "16986",
    "name": "Ubongo",
    "yearpublished": "2003",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "25",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.68199",
    "categories": [
      "Puzzle",
      "Real-time"
    ]
  },
  {
    "id": "266421",
    "name": "Ubongo! Fun-Size Edition",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "15",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.32704",
    "categories": [
      "Action / Dexterity",
      "Puzzle"
    ]
  },
  {
    "id": "323206",
    "name": "Uk'otoa",
    "yearpublished": "2021",
    "minPlayers": "3",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-4 players",
    "ratingsAverage": "6.34075",
    "categories": [
      "Nautical"
    ]
  },
  {
    "id": "329591",
    "name": "Ultimate Railroads",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.3853",
    "categories": [
      "Industry / Manufacturing",
      "Trains"
    ]
  },
  {
    "id": "306735",
    "name": "Under Falling Skies",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "1",
    "playingtime": "40",
    "bestWith": "Best with 1 players",
    "recommendedWith": "Recommended with 1 players",
    "ratingsAverage": "7.62862",
    "categories": [
      "Dice",
      "Science Fiction"
    ]
  },
  {
    "id": "247763",
    "name": "Underwater Cities",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "8.04791",
    "categories": [
      "Card Game",
      "City Building",
      "Civilization",
      "Industry / Manufacturing",
      "Nautical",
      "Science Fiction"
    ]
  },
  {
    "id": "276104",
    "name": "Underwater Cities: New Discoveries",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "180",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.4671",
    "categories": [
      "Card Game",
      "City Building",
      "Civilization",
      "Expansion for Base-game",
      "Industry / Manufacturing",
      "Nautical",
      "Science Fiction"
    ]
  },
  {
    "id": "340466",
    "name": "Unfathomable",
    "yearpublished": "2021",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "240",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 4-6 players",
    "ratingsAverage": "7.49109",
    "categories": [
      "Bluffing",
      "Deduction",
      "Fantasy",
      "Horror",
      "Novel-based"
    ]
  },
  {
    "id": "213460",
    "name": "Unlock!: Escape Adventures",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "75",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.34538",
    "categories": [
      "Card Game",
      "Puzzle"
    ]
  },
  {
    "id": "229598",
    "name": "Unlock!: Escape Adventures - Doo-Arann Dungeon",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "30",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "6.53032",
    "categories": [
      "Puzzle",
      "Card Game",
      "Print & Play"
    ]
  },
  {
    "id": "350636",
    "name": "Unlock!: Game Adventures",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "60",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.92685",
    "categories": [
      "Deduction",
      "Puzzle",
      "Real-time"
    ]
  },
  {
    "id": "368039",
    "name": "Unlock!: Short Adventures - Secret Recipes of Yore",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "0",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-3 players",
    "ratingsAverage": "6.54191",
    "categories": [
      "Card Game",
      "Puzzle",
      "Real-time"
    ]
  },
  {
    "id": "368037",
    "name": "Unlock!: Short Adventures - The Awakening of the Mummy",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "45",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "6.74991",
    "categories": [
      "Card Game",
      "Puzzle",
      "Real-time"
    ]
  },
  {
    "id": "294484",
    "name": "Unmatched: Cobble & Fog",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "40",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2, 4 players",
    "ratingsAverage": "7.94957",
    "categories": [
      "Card Game",
      "Fantasy",
      "Fighting",
      "Horror",
      "Novel-based"
    ]
  },
  {
    "id": "2223",
    "name": "UNO",
    "yearpublished": "1971",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 4-6 players",
    "recommendedWith": "Recommended with 2-8 players",
    "ratingsAverage": "5.46628",
    "categories": [
      "Card Game",
      "Children's Game",
      "Comic Book / Strip",
      "Movies / TV / Radio theme",
      "Number"
    ]
  },
  {
    "id": "271460",
    "name": "UNO Flip!",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-7 players",
    "ratingsAverage": "6.27948",
    "categories": [
      "Card Game",
      "Number"
    ]
  },
  {
    "id": "372973",
    "name": "UNO Party!",
    "yearpublished": "2022",
    "minPlayers": "6",
    "maxPlayers": "16",
    "playingtime": "0",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.29762",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "352997",
    "name": "UNO: All Wild!",
    "yearpublished": "2021",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-8 players",
    "ratingsAverage": "4.57323",
    "categories": [
      "Card Game"
    ]
  },
  {
    "id": "5585",
    "name": "UNO: Harry Potter",
    "yearpublished": "2000",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "5.56755",
    "categories": [
      "Card Game",
      "Children's Game",
      "Fantasy",
      "Movies / TV / Radio theme",
      "Novel-based"
    ]
  },
  {
    "id": "244691",
    "name": "UNO: Minecraft",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "60",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "5.81122",
    "categories": [
      "Card Game",
      "Video Game Theme"
    ]
  },
  {
    "id": "37330",
    "name": "UNO: Pokémon",
    "yearpublished": "2006",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.517",
    "categories": [
      "Card Game",
      "Children's Game",
      "Fantasy",
      "Movies / TV / Radio theme",
      "Video Game Theme"
    ]
  },
  {
    "id": "330568",
    "name": "UNO: Star Wars",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "5.92647",
    "categories": [
      "Card Game",
      "Number"
    ]
  },
  {
    "id": "209095",
    "name": "UNO: Super Mario",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "0",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.16944",
    "categories": [
      "Card Game",
      "Video Game Theme"
    ]
  },
  {
    "id": "234190",
    "name": "Unstable Unicorns",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "8",
    "playingtime": "60",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-6 players",
    "ratingsAverage": "6.23548",
    "categories": [
      "Card Game",
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "173387",
    "name": "Urban Dictionary Game",
    "yearpublished": "2015",
    "minPlayers": "3",
    "maxPlayers": "8",
    "playingtime": "0",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "4.47742",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "354486",
    "name": "Varulv",
    "yearpublished": "2021",
    "minPlayers": "6",
    "maxPlayers": "22",
    "playingtime": "90",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "7.55556",
    "categories": [
      "Bluffing",
      "Card Game",
      "Murder / Mystery",
      "Party Game"
    ]
  },
  {
    "id": "192334",
    "name": "Vikings on Board",
    "yearpublished": "2016",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.16211",
    "categories": [
      "Nautical"
    ]
  },
  {
    "id": "241724",
    "name": "Villagers",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.12963",
    "categories": [
      "Card Game",
      "City Building",
      "Industry / Manufacturing",
      "Medieval"
    ]
  },
  {
    "id": "312543",
    "name": "Villagers: Playmat & Promo Cards",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "7.96296",
    "categories": [
      "Card Game",
      "City Building",
      "Expansion for Base-game",
      "Industry / Manufacturing",
      "Medieval"
    ]
  },
  {
    "id": "333503",
    "name": "Villagers: Shifting Seasons",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "0",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.83101",
    "categories": [
      "Expansion for Base-game",
      "City Building",
      "Medieval"
    ]
  },
  {
    "id": "296151",
    "name": "Viscounts of the West Kingdom",
    "yearpublished": "2020",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.81797",
    "categories": [
      "Medieval"
    ]
  },
  {
    "id": "183394",
    "name": "Viticulture Essential Edition",
    "yearpublished": "2015",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.97576",
    "categories": [
      "Economic",
      "Farming"
    ]
  },
  {
    "id": "202174",
    "name": "Viticulture: Tuscany Essential Edition",
    "yearpublished": "2016",
    "minPlayers": "1",
    "maxPlayers": "6",
    "playingtime": "150",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 1-6 players",
    "ratingsAverage": "8.54496",
    "categories": [
      "Expansion for Base-game",
      "Farming"
    ]
  },
  {
    "id": "172007",
    "name": "The Voting Game",
    "yearpublished": "2014",
    "minPlayers": "5",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 6-10 players",
    "recommendedWith": "Recommended with 5-10 players",
    "ratingsAverage": "5.39784",
    "categories": [
      "Card Game",
      "Humor",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "198038",
    "name": "Vuxenpoäng",
    "yearpublished": "2011",
    "minPlayers": "2",
    "maxPlayers": "99",
    "playingtime": "45",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "4.75",
    "categories": [
      "Trivia"
    ]
  },
  {
    "id": "115746",
    "name": "War of the Ring: Second Edition",
    "yearpublished": "2011",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "180",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 2 players",
    "ratingsAverage": "8.5482",
    "categories": [
      "Fantasy",
      "Fighting",
      "Novel-based",
      "Territory Building",
      "Wargame"
    ]
  },
  {
    "id": "362944",
    "name": "War of the Ring: The Card Game",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2, 4 players",
    "ratingsAverage": "7.65618",
    "categories": [
      "Card Game",
      "Fantasy",
      "Novel-based"
    ]
  },
  {
    "id": "396618",
    "name": "Waterfall Park",
    "yearpublished": "2023",
    "minPlayers": "3",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 5 players",
    "recommendedWith": "Recommended with 4-5 players",
    "ratingsAverage": "7.07497",
    "categories": [
      "Negotiation"
    ]
  },
  {
    "id": "262543",
    "name": "Wavelength",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "12",
    "playingtime": "45",
    "bestWith": "Best with 6, 8 players",
    "recommendedWith": "Recommended with 4-12 players",
    "ratingsAverage": "7.24121",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "350316",
    "name": "Wayfarers of the South Tigris",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 2 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.79698",
    "categories": [
      "Arabian",
      "Exploration",
      "Medieval"
    ]
  },
  {
    "id": "237179",
    "name": "Weather Machine",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "150",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.74586",
    "categories": [
      "Environmental",
      "Science Fiction"
    ]
  },
  {
    "id": "254419",
    "name": "Weird Things Humans Search For",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "20",
    "playingtime": "30",
    "bestWith": "Best with 8 players",
    "recommendedWith": "Recommended with 2-6, 8 players",
    "ratingsAverage": "5.2548",
    "categories": [
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "150312",
    "name": "Welcome to the Dungeon",
    "yearpublished": "2013",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "30",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.5354",
    "categories": [
      "Bluffing",
      "Card Game",
      "Fantasy",
      "Fighting"
    ]
  },
  {
    "id": "233867",
    "name": "Welcome To...",
    "yearpublished": "2018",
    "minPlayers": "1",
    "maxPlayers": "100",
    "playingtime": "25",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 1-12 players",
    "ratingsAverage": "7.52131",
    "categories": [
      "City Building"
    ]
  },
  {
    "id": "25821",
    "name": "The Werewolves of Miller's Hollow",
    "yearpublished": "2001",
    "minPlayers": "8",
    "maxPlayers": "18",
    "playingtime": "30",
    "bestWith": "Best with 10-15 players",
    "recommendedWith": "Recommended with 8-18+ players",
    "ratingsAverage": "6.68566",
    "categories": [
      "Bluffing",
      "Deduction",
      "Horror",
      "Murder / Mystery",
      "Party Game"
    ]
  },
  {
    "id": "245422",
    "name": "Werewords Deluxe Edition",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "20",
    "playingtime": "10",
    "bestWith": "Best with 6, 8 players",
    "recommendedWith": "Recommended with 4-12 players",
    "ratingsAverage": "7.54078",
    "categories": [
      "Deduction",
      "Party Game",
      "Real-time",
      "Word Game"
    ]
  },
  {
    "id": "232405",
    "name": "Western Legends",
    "yearpublished": "2018",
    "minPlayers": "2",
    "maxPlayers": "6",
    "playingtime": "90",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "7.60622",
    "categories": [
      "Adventure",
      "American West",
      "Miniatures"
    ]
  },
  {
    "id": "226610",
    "name": "What Do You Meme?",
    "yearpublished": "2016",
    "minPlayers": "3",
    "maxPlayers": "20",
    "playingtime": "90",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-9 players",
    "ratingsAverage": "5.095",
    "categories": [
      "Card Game",
      "Humor",
      "Mature / Adult",
      "Party Game"
    ]
  },
  {
    "id": "342443",
    "name": "What Next?",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "60",
    "bestWith": "Best with 2-3 players",
    "recommendedWith": "Recommended with 1-3, 5+ players",
    "ratingsAverage": "6.48355",
    "categories": [
      "Action / Dexterity",
      "Adventure",
      "Party Game"
    ]
  },
  {
    "id": "198454",
    "name": "When I Dream",
    "yearpublished": "2016",
    "minPlayers": "4",
    "maxPlayers": "10",
    "playingtime": "40",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-10 players",
    "ratingsAverage": "6.93627",
    "categories": [
      "Deduction",
      "Party Game",
      "Real-time",
      "Word Game"
    ]
  },
  {
    "id": "221318",
    "name": "Whistle Stop",
    "yearpublished": "2017",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "75",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "7.04744",
    "categories": [
      "American West",
      "Trains"
    ]
  },
  {
    "id": "371942",
    "name": "The White Castle",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "80",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.99246",
    "categories": [
      "Dice",
      "Economic"
    ]
  },
  {
    "id": "10559",
    "name": "Why Did the Chicken...?",
    "yearpublished": "2004",
    "minPlayers": "4",
    "maxPlayers": "8",
    "playingtime": "60",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 4-8 players",
    "ratingsAverage": "6.12758",
    "categories": [
      "Humor",
      "Party Game"
    ]
  },
  {
    "id": "266192",
    "name": "Wingspan",
    "yearpublished": "2019",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "70",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.02521",
    "categories": [
      "Animals",
      "Card Game",
      "Educational"
    ]
  },
  {
    "id": "123239",
    "name": "Wits & Wagers Party",
    "yearpublished": "2012",
    "minPlayers": "4",
    "maxPlayers": "18",
    "playingtime": "25",
    "bestWith": "Best with 5-6 players",
    "recommendedWith": "Recommended with 4-10, 12 players",
    "ratingsAverage": "6.89956",
    "categories": [
      "Party Game",
      "Trivia"
    ]
  },
  {
    "id": "368058",
    "name": "The Wolves",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "75",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "7.27943",
    "categories": [
      "Animals",
      "Territory Building"
    ]
  },
  {
    "id": "355093",
    "name": "Woodcraft",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "120",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.61874",
    "categories": [
      "Fantasy",
      "Industry / Manufacturing"
    ]
  },
  {
    "id": "400508",
    "name": "Word Traveler",
    "yearpublished": "2024",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "45",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-5 players",
    "ratingsAverage": "6.44992",
    "categories": [
      "Party Game",
      "Travel",
      "Word Game"
    ]
  },
  {
    "id": "342848",
    "name": "World of Warcraft: Wrath of the Lich King",
    "yearpublished": "2021",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "60",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-5 players",
    "ratingsAverage": "7.39095",
    "categories": [
      "Fantasy",
      "Video Game Theme"
    ]
  },
  {
    "id": "365258",
    "name": "World Wonders",
    "yearpublished": "2023",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "70",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.62483",
    "categories": [
      "Ancient",
      "Territory Building"
    ]
  },
  {
    "id": "315877",
    "name": "WWE Legends Royal Rumble Card Game",
    "yearpublished": "2020",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "45",
    "bestWith": "Best with 6 players",
    "recommendedWith": "Recommended with 3-10 players",
    "ratingsAverage": "6.41446",
    "categories": [
      "Card Game",
      "Sports"
    ]
  },
  {
    "id": "410201",
    "name": "Wyrmspan",
    "yearpublished": "2024",
    "minPlayers": "1",
    "maxPlayers": "5",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "8.01745",
    "categories": [
      "Animals",
      "Card Game",
      "Fantasy"
    ]
  },
  {
    "id": "303054",
    "name": "Yacht Rock",
    "yearpublished": "2020",
    "minPlayers": "3",
    "maxPlayers": "6",
    "playingtime": "25",
    "bestWith": "Best with 4 players",
    "recommendedWith": "Recommended with 4-6 players",
    "ratingsAverage": "5.54918",
    "categories": [
      "Card Game",
      "Party Game"
    ]
  },
  {
    "id": "2243",
    "name": "Yahtzee",
    "yearpublished": "1956",
    "minPlayers": "2",
    "maxPlayers": "10",
    "playingtime": "30",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-6 players",
    "ratingsAverage": "5.51773",
    "categories": [
      "Children's Game",
      "Comic Book / Strip",
      "Dice",
      "Movies / TV / Radio theme",
      "Music",
      "Travel"
    ]
  },
  {
    "id": "269146",
    "name": "Yōkai",
    "yearpublished": "2019",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "35",
    "bestWith": "Best with 3-4 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.61786",
    "categories": [
      "Memory",
      "Mythology",
      "Puzzle"
    ]
  },
  {
    "id": "397376",
    "name": "You Can't Say \"Umm\"",
    "yearpublished": "2023",
    "minPlayers": "4",
    "maxPlayers": "12",
    "playingtime": "20",
    "bestWith": "",
    "recommendedWith": "(no votes)",
    "ratingsAverage": "6.62568",
    "categories": [
      "Party Game"
    ]
  },
  {
    "id": "330510",
    "name": "Yucatan",
    "yearpublished": "2023",
    "minPlayers": "2",
    "maxPlayers": "4",
    "playingtime": "90",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 2-4 players",
    "ratingsAverage": "6.60315",
    "categories": [
      "Ancient",
      "Fighting",
      "Mythology"
    ]
  },
  {
    "id": "322589",
    "name": "Zapotec",
    "yearpublished": "2022",
    "minPlayers": "1",
    "maxPlayers": "4",
    "playingtime": "75",
    "bestWith": "Best with 3 players",
    "recommendedWith": "Recommended with 1-4 players",
    "ratingsAverage": "7.14511",
    "categories": [
      "Ancient",
      "Territory Building"
    ]
  },
  {
    "id": "362202",
    "name": "Zombie Kittens",
    "yearpublished": "2022",
    "minPlayers": "2",
    "maxPlayers": "5",
    "playingtime": "15",
    "bestWith": "Best with 4-5 players",
    "recommendedWith": "Recommended with 3-5 players",
    "ratingsAverage": "6.55173",
    "categories": [
      "Animals",
      "Card Game",
      "Horror",
      "Humor",
      "Zombies"
    ]
  }
];