
// Handle click and drag
let dragStart = null;
const dragThreshold = 2;

// Helper function to attach event handlers and return a cleanup function.
export const attachEventHandlers = ({
  draw,
  projection,
  geojsonData,
  imdfData,
  svgData,
  selectedFeatures,
  setSelectedFeatures,
  canMultiSelect,
  setHighlightedFeatureId,
  canSelectLocation,
  canSelectSensor,
  canSelectGateway,
  canSelectUndefined,
  canSelectAny,
  canHighlightLocation,
  canHighlightSensor,
  canHighlightGateway,
  canHighlightUndefined,
  canHighlightAny,
  drawPoint,
  drawPolygon,
  createdFeatures,
  addCreatedFeature,
}) => {

  // Remove existing event listeners.
  draw.off("mousedown", handleMouseDown);
  draw.off("mousemove");
  draw.off("mouseup");

  if (drawPoint) {
    // Attach event listeners.
    draw.on("mousedown", handleMouseDown);
    draw.on("mouseup", (event) =>
      handleDrawPoint({
        event,
        draw,
        projection,
        geojsonData,
        imdfData,
        svgData,
        createdFeatures,
        addCreatedFeature,
      })
    );
  }
  else if (drawPolygon) {
    // Attach event listeners.
    draw.on("mousedown", handleMouseDown);
  }
  else {
    // Attach event listeners.
    draw.on("mousedown", handleMouseDown);
    draw.on("mousemove", (event) => 
      handleHover({
        event,
        draw,
        projection,
        geojsonData,
        imdfData,
        svgData,
        setHighlightedFeatureId,
        canHighlightLocation,
        canHighlightSensor,
        canHighlightGateway,
        canHighlightUndefined,
        canHighlightAny,
      })
    );
    draw.on("mouseup", (event) =>
      handleClick({
        event,
        draw,
        projection,
        geojsonData,
        imdfData,
        svgData,
        selectedFeatures,
        setSelectedFeatures,
        canSelectLocation,
        canSelectSensor,
        canSelectGateway,
        canSelectUndefined,
        canSelectAny,
        canMultiSelect,
      })
    );
  }


  // Return a cleanup function that removes these listeners.
  return () => {
    // console.log("Cleaning up event handlers");
    draw.off("mousedown", handleMouseDown);
    draw.off("mouseup");
    draw.off("mousemove");
  };
};

// Handle mouse down and move - to cancel clicks when dragging
const handleMouseDown = (event) => {
  dragStart = { x: event.clientX, y: event.clientY };
}

const isDragging = (event) => {
  if (!dragStart) return false;
  const dx = Math.abs(event.clientX - dragStart.x);
  const dy = Math.abs(event.clientY - dragStart.y);
  return (dx > dragThreshold || dy > dragThreshold);
}

const getSVGHits = (event) => {
  // Get the hit test elements in stacking order.
  const hitElements = Array.from(document.elementsFromPoint(event.clientX, event.clientY));

  // Filter to only those that are inside the "#Status" group.
  const statusHit = hitElements.filter(el => el.closest('#Status'));

  // This array will hold the final ordered list.
  const ordered = [];

  statusHit.forEach(el => {
    // Build an array of ancestor <g> elements that are within "#Status".
    const ancestors = [];
    let parent = el.parentElement;
    while (parent && parent.id !== 'Status') {
      if (parent.tagName.toLowerCase() === 'g' && parent.closest('#Status')) {
        ancestors.push(parent);
      }
      parent = parent.parentElement;
    }
    // Reverse the ancestors so the outermost comes first.
    ancestors.reverse();

    // For each ancestor, if it's not already in 'ordered', insert it.
    ancestors.forEach(anc => {
      if (!ordered.includes(anc)) {
        ordered.push(anc);
      }
    });
    // Then add the hit element itself (if not already added).
    if (!ordered.includes(el)) {
      ordered.push(el);
    }
  });

  return ordered;
}

const getFeatureHits = ({ event, draw, projection, geojsonData, imdfData }) => {

  if (!projection) return;

  // Create an SVGPoint in the coordinate system of your SVG element
  const pt = draw.node.createSVGPoint();
  pt.x = event.clientX;
  pt.y = event.clientY;

  let foundFeatures = [];

  // Hit test function
  const hitTest = (feature) => {
    const element = document.getElementById(feature.id);
    if (!element) return;

    // Transform the point to the element's local coordinate space
    const localPt = pt.matrixTransform(element.getScreenCTM().inverse());

    // Make sure the element supports the hit-test methods.
    if (typeof element.isPointInFill !== "function" ||
      typeof element.isPointInStroke !== "function") {
      return;
    }

    // Check if the hover was on the fill or stroke.
    if (element.isPointInFill(localPt) || element.isPointInStroke(localPt)) {
      return true;
    }

    return false;
  };

  if (geojsonData) {
    foundFeatures = geojsonData.features.filter(hitTest);
  }

  if (imdfData) {
    foundFeatures = imdfData.unit?.features.filter(hitTest);
  }

  // Highlight the found feature
  if (foundFeatures.length > 0) {

    // Sort points first then based on zIndex
    foundFeatures.sort((a, b) => {
      const aIsPoint = a.geometry.type === "Point";
      const bIsPoint = b.geometry.type === "Point";

      // Prioritize "Point" types first.
      if (aIsPoint !== bIsPoint) {
        return aIsPoint ? -1 : 1;
      }

      // For features of the same type, sort by zIndex in descending order.
      return (b.properties.zIndex || 0) - (a.properties.zIndex || 0);
    });
  }

  return foundFeatures;
}

const handleHover = ({ 
  event,
  draw,
  projection,
  geojsonData,
  imdfData,
  svgData,
  setHighlightedFeatureId = () => { },
  canHighlightLocation,
  canHighlightSensor,
  canHighlightGateway,
  canHighlightUndefined,
  canHighlightAny,
}) => {

  // Check if we are dragging
  if (dragStart) return;

  // console.log("Hover detected", event);

  if (geojsonData || imdfData) {
    // Get the hit feature at the hover position
    let foundFeatures = getFeatureHits({ event, draw, projection, geojsonData, imdfData });

    // Highlight the found feature
    if (foundFeatures.length > 0) {

      // Check if we are hovering over a feature that should not be highlighted
      const firstFeature = foundFeatures[0];
      let canHighlight = !!canHighlightAny;
      if (!canHighlight) {
        if (firstFeature.properties?.sensor && canHighlightSensor) {
          canHighlight = true;
        }
        else if (firstFeature.properties?.gateway && canHighlightGateway) {
          canHighlight = true;
        }
        else if (firstFeature.properties?.locationId && canHighlightLocation) {
          canHighlight = true;
        }
        else if (!firstFeature.properties?.sensor && !firstFeature.properties?.gateway && !firstFeature.properties?.locationId && canHighlightUndefined) {
          canHighlight = true;
        }
      }

      // Return if we are not allowed to highlight
      if (!canHighlight) {
        setHighlightedFeatureId(null);
        return;
      }

      // Only highlight the first found feature
      const element = document.getElementById(firstFeature.id);
      if (element) {
        setHighlightedFeatureId(firstFeature.id);
      }
      else {
        setHighlightedFeatureId(null);
      }
    }
    else {
      setHighlightedFeatureId(null);
    }
  }
  else if (svgData) {
    // Get the topmost element at the hover position
    const hits = getSVGHits(event);

    if (hits.length > 0) {
      const topGroup = hits[0];
      const topGroupId = topGroup.id;
      setHighlightedFeatureId(topGroupId);
    }
    else {
      setHighlightedFeatureId(null);
    }
  }
}

const toggleSelection = (features, newFeature) =>
  features.some(feature => feature.id === newFeature.id)
    ? features.filter(feature => feature.id !== newFeature.id)
    : [...features, newFeature];

const handleClick = ({
  event,
  draw,
  projection,
  geojsonData,
  imdfData,
  svgData,
  selectedFeatures = [],
  setSelectedFeatures = () => { },
  canSelectLocation,
  canSelectSensor,
  canSelectGateway,
  canSelectUndefined,
  canSelectAny,
  canMultiSelect,
}) => {

  // Check if we are dragging and cancel the click
  const dragging = isDragging(event);
  dragStart = null;
  if (dragging) return;

  // If the user tries to multi-select, check if we are allowed to
  const multiSelect = canMultiSelect && (event.shiftKey || event.metaKey || event.ctrlKey);

  // If multi-selecting, keep the current selection
  let newSelection = [];
  if (multiSelect) {
    newSelection.push(...selectedFeatures);
  }

  if (geojsonData || imdfData) {
    // Get the hit feature at the click position
    let foundFeatures = getFeatureHits({ event, draw, projection, geojsonData, imdfData });

    // Update selected feature
    if (foundFeatures.length > 0) {

      // Check if clicked feature should not be selected
      const firstFeature = foundFeatures[0];
      let canSelect = !!canSelectAny;
      if (!canSelect) {
        if (firstFeature.properties?.sensor && canSelectSensor) {
          canSelect = true;
        }
        else if (firstFeature.properties?.gateway && canSelectGateway) {
          canSelect = true;
        }
        else if (firstFeature.properties?.locationId && canSelectLocation) {
          canSelect = true;
        }
        else if (!firstFeature.properties?.sensor && !firstFeature.properties?.gateway && !firstFeature.properties?.locationId && canSelectUndefined) {
          canSelect = true;
        }
      }

      // Return if we are not allowed to select
      if (!canSelect) {
        return;
      }

      // Select or deselect the first found feature
      if (multiSelect) {
        newSelection = toggleSelection(newSelection, firstFeature);
      }
      else {
        const isSelected = selectedFeatures.some(feature => feature.id === firstFeature.id);
        newSelection = isSelected ? [] : [firstFeature];
      }
    }
  }
  else if (svgData) {
    // Get the topmost element at the click position
    const hits = getSVGHits(event);

    // Recursively build a hierarchical json of the selected feature attributes
    const getAttributes = (element) => {
      const attributes = {
        type: element.tagName,
        id: element.getAttribute("id"),
        classes: Array.from(element.classList)
      };
    
      if (element.children && element.children.length > 0) {
        attributes.children = Array.from(element.children).map(child => getAttributes(child));
      }
    
      return attributes;
    };

    if (hits.length > 0) {
      const topGroup = hits[0];
      const featureGroup = {
        id: topGroup.id,
        geometry: { type: "g" },
        attributes: getAttributes(topGroup)
      };

      // Select or deselect the first found feature
      if (multiSelect) {
        newSelection = toggleSelection(newSelection, featureGroup);
      }
      else {
        const isSelected = selectedFeatures.some(feature => feature.id === featureGroup.id);
        newSelection = isSelected ? [] : [featureGroup];
      }
    }
  }

  // Update selected features
  setSelectedFeatures(newSelection);

}

const handleDrawPoint = ({
  event,
  draw,
  projection,
  geojsonData,
  imdfData,
  svgData,
  createdFeatures,
  addCreatedFeature,
}) => {
  
  // Check if we are dragging and cancel the click
  const dragging = isDragging(event);
  dragStart = null;
  if (dragging) return;

  let newFeature = null;
  if (geojsonData || imdfData) {
    // Create a new feature at the click position
    const pt = draw.node.createSVGPoint();
    pt.x = event.clientX;
    pt.y = event.clientY;

    // Transform the point to the element's local coordinate space
    const localPt = pt.matrixTransform(draw.node.getScreenCTM().inverse());

    console.log("event client", event.clientX, event.clientY);
    console.log("pt", pt);
    console.log("localPt", localPt);

    const coords = projection.invert([localPt.x, localPt.y]);
    console.log("Point coords", coords);

    newFeature = {
      id: `point-${createdFeatures.length + 1}`,
      geometry: {
        type: "Point",
        coordinates: coords
      },
      properties: {
        zIndex: 100
      }
    };
  }
  else if (svgData) {
    
  }

  // Add the created feature
  if (newFeature) {
    addCreatedFeature(newFeature);
  }
}