import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import LargeHeader from "../../components/LargeHeader";
import NewCompany from "../Companies/newCompany";
import NotFound from "../NotFound";
import StatusContainer from "./Alarms";
import CompaniesContainer from "./Companies";
import * as alarmActions from "../../actions/alarms";

class SupportSwitch extends Component {

  render() {
    const { isAuthLoading } = this.props;

    if (isAuthLoading) {
      return <Loader />;
    }

    return (
      <Switch>
        <Redirect exact from="/support" to="/support/overview" />
        <Route path="/support/overview" component={StatusContainer} />
        <Route path="/support/settings" component={CompaniesContainer} />
        <Route path="/support/new-company" component={NewCompany} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthLoading: state.loading.auth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(alarmActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportSwitch);