export const DebugColors = {
  oldSample: "rgb(138, 46, 148)", // purple
  noSample: "rgb(255, 138, 247)"   // pink
};

export const StateColors = {
  disabled: "rgb(240, 240, 240)", // grey
  unknown: "rgb(250, 214, 72)",   // yellow rgb(245, 227, 85)
  connected: "rgb(119, 207, 58)",    // green  rgb(75, 204, 58)
  disconnected: "rgb(209, 86, 86)" ,   // red    rgb(227, 64, 64)
  stale: "rgb(255, 153, 67)"    // orange    rgb(255, 116, 0)
};

export const BLDNGColors = {
  darkBlue: "#1C4D82",
  lightBlue: "#6AE6E5",
  darkGreen: "#009584",
  lightGreen: "#3EB68A",
};

// Light and dark colors
// const blue = ["#2E9AD9", "#2274A5"];
// const orange = ["#F78239", "#DE671D"];
// const green = ["#67B853", "#569945"];
// const purple = ["#9E4784", "#7B1E7A"];
// const red = ["#DB2727", "#B31717"];
// const yellow = ["#FAC13C", "#D4A12A"];
// const brown = ["#A86242", "#7D451B"];
// const pink = ["#DB5A67", "#B83946"];

const blue = ["#2E9AD9", "#0f6799"]; 
const red = ["#DB2727",  "#AD0909"];
const orange = ["#F5782A", "#C7520A"];
const green = ["#59B524", "#448733"];
const brown = ["#A85832", "#753F23"];
const pink = ["#DB5A67", "#B82E3C"];
const purple = ["#9E4784", "#7B1E7A"];
const yellow = ["#F0B732", "#CC930D"];
const emerald = ["#0DCC83", "#02955C"];
const cyan = ["#00B3C7", "#00829A"];
const olive = ["#A5A500", "#7A7A00"];

export const SafeTwinColors = [blue, red, orange, green, brown, pink, purple, emerald, yellow, cyan, olive];

const tripleBlue = ["#164A64", "#216F97", "#2B91C4"];
const tripleRed = ["#8C1818", "#C02121", "#D43737"];
const tripleOrange = ["#9B4308", "#D75E0B", "#F47B2A"];
const tripleGreen = ["#2D5A1D", "#448733", "#59B143"];
const tripleBrown = ["#4F2917", "#7E4126", "#A85832"];
const triplePink = ["#A72532", "#D33646", "#DB5A67"];
const triplePurple = ["#542647", "#7F396A", "#9E4784"];
const tripleYellow = ["#A77A11", "#DFA316", "#ECB83C"];
const tripleMint = ["#176446", "#229669", "#2CBF87"];
const tripleCyan = ["#0E5857", "#168D8B", "#1DB8B5"];
const tripleOlive = ["#3D3D00", "#7A7A00", "#B8B800"];

export const SafeTripleColors = [tripleBlue, tripleRed, tripleOrange, tripleGreen, tripleBrown, triplePink, triplePurple, tripleMint, tripleYellow, tripleCyan, tripleOlive];
// export const SafeTripleColors = [tripleBlue, tripleMint, tripleOlive];

export const MapModeColors = {
  view: {
    selectionFillColor: "#30639A",
    highlightFillColor: "#5483B5"
  },
  navigate: {
    selectionFillColor: "#30639A",
    highlightFillColor: "#5483B5"
  },
  inspect: {
    selectionFillColor: "#30639A",
    highlightFillColor: "#5483B5"
  },
  connect: {
    buttonColor: "#5FB053",
    selectionFillColor: "#79C16E",
    highlightFillColor: "#8FC486"
  }
}