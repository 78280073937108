import React from "react";
import Tag from "../Tag";
import isEmpty from "lodash/isEmpty";
import { Visible, Hidden } from "react-grid-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import styled, { createGlobalStyle } from "styled-components";

const FrontPageSearchBox = ({ query = { customTags: [], text: "", placeholder: "Search" }, onQueryClick = () => {}, onQueryChange = () => {}, onKeyDown = () => {}, onSearchClick = () => {}, onRemoveTagClick = () => {}, onClear = () => {} }) => (
  <>
    <GlobalStyle />
    <SearchContainer>
      <Box>
        <QueryContainer>
          <InnerBox onClick={() => onQueryClick()}>
            {query.customTags.map(tag => <div key={tag.id}><Tag text={tag.name} color={tag.colorTheme} onRemoveClick={() => onRemoveTagClick(tag)} /></div>)}
            <SearchField 
              name={`search`} 
              placeholder={query.customTags.length === 0 ? query.placeholder : ""} 
              onChange={(event) => onQueryChange(event.target.value)}
              onKeyDown={(event) => onKeyDown(event)}
              value={query.text}
              autoComplete="off"
              />
            { // button to clear the search field  style={{ marginRight: "12px" }}
              (!isEmpty(query.text) || !isEmpty(query.customTags)) && (
                <ClearButton onClick={() => onClear()}>
                  <FontAwesomeIcon icon={faXmark} color={"#222"} />
                </ClearButton>
              )
            }
          </InnerBox>
        </QueryContainer>
      </Box>
      <Hidden xs sm>
        <SearchButton onClick={() => onSearchClick()}>
          <SearchButtonText>Search</SearchButtonText>
        </SearchButton>
      </Hidden>
    </SearchContainer>

    <Visible xs sm>
      <SearchButton onClick={() => onSearchClick()} style={{ width: "100%", marginLeft: 0, marginTop: "10px" }}>
        <SearchButtonText>Search</SearchButtonText>
      </SearchButton>
    </Visible>
  </>
);

export default FrontPageSearchBox;

// Global style for focus outlines
export const GlobalStyle = createGlobalStyle`
  *:focus {
    outline: none;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
`;

export const DropDownContainer = styled.div`
  margin-right: 20px;
  min-width: 360px;
`;

export const DropDownRow = styled.div`
  margin-top: 1px;
`;

export const Box = styled.div`
  display: block;
  flex-grow: 1;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border: 1px solid rgb(221, 221, 221);
  box-sizing: border-box;
  overflow: hidden;
`;

export const QueryContainer = styled.div`
  display: flex;
  min-height: 40px;
`;

export const InnerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-around;
  padding-left: 10px;
  width: 100%;
  min-width: 200px;
`;

export const SearchField = styled.input`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 17px;
  border: 0;
  padding-left: 5px;
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 100px;
  background: transparent;

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  &[data-autocompleted] {
    background-color: transparent !important;
  }
`;

export const QueryOptions = styled.div`
  display: flex;
  height: 100%;
`;

export const QueryAddButton = styled.div`
  height: 100%;
  width: 50px;
  cursor: pointer;
`;

export const QueryRemoveButton = styled.div`
  height: 100%;
  width: 50px;
  cursor: pointer;
`;

export const QueryIcon = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;
  line-height: 30px;
`;

export const SearchButton = styled.div`
  display: flex;
  height: 41px;
  width: 120px;
  min-width: 100px;
  margin-left: 20px;
  cursor: pointer;
  background-color: #1c4d82;
  border-radius: 6px;
  align-self: center;
`;

export const SearchButtonText = styled.span`
  display: inline-block;
  width: 100%;
  color: white;
  text-align: center;
  line-height: 40px;
  font-size: 17px;
  white-space: nowrap;
`;

// A generic styled input that inherits font and size properties
export const Input = styled.input`
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 90%;
`;

export const Separator = styled.div`
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px dashed #ccc;
`;

export const SeparatorText = styled.div`
  display: block;
  position: absolute;
  color: #999;
  background-color: white;
  width: 40px;
  text-align: center;
  user-select: none;
`;

export const SeparatorMiddle = styled.div`
  display: block;
  position: relative;
  left: calc(50% - 20px);
  top: -10px;
`;

export const ClearButton = styled.div`
  height: 100%;
  width: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
