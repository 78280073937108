import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRouter, faGlobe, faMobileButton, faUserPlus, faPersonShelter } from "@fortawesome/pro-solid-svg-icons";
import SearchBox from "../../../components/SearchBox";
import Table from "../../../components/Table";
import TopRowOptions from "../../../components/TopRowOptions";
import CheckboxBlock from "../../../components/CheckboxBlock";
import * as alarmActions from "../../../actions/alarms";

class WarningsSummaryList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
      includeSilencedInCount: false
    };
    this.fetchData = this.fetchData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSearchClick() {
    this.setState({ offset: 0 }, this.fetchData);
  }

  onSearchChange(event) {
    this.setState({ searchText: event.target.value === '' ? undefined : event.target.value });
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {
          let link = `/support/overview/${rowInfo.original.id}/alarms`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original.id != null && rowInfo.original.id === this.props.companyId ? "rgba(0,0,0,0.05)" : null
        )
      }
    }
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getWarningsSummary(queryParams);
  }

  render() {
    const { isLoading } = this.props;
  
    let optionButtons = [];
    optionButtons.push(
      <CheckboxBlock
        key="segmentedControl"
        label="Include silenced"
        isChecked={this.state.includeSilencedInCount}
        onClick={() => this.setState({ includeSilencedInCount: !this.state.includeSilencedInCount })}
        style={{ height: "40px" }}
      />
    );

    const options = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={(value) => { this.setState({ searchText: value }) }}
            onClear={() => { this.setState({ searchText: "" }, this.onSearchClick) }}
            onSearchClick={this.onSearchClick}
            inListView
          />
        )}
        buttons={optionButtons}
      />
    );

    // Modify table data to reflect includeSilencedInCount
    const data = this.props.companies?.map(c => {
      return {
        ...c,
        integrations: this.state.includeSilencedInCount ? c.integrations.total : c.integrations.active,
        gateways: this.state.includeSilencedInCount ? c.gateways.total : c.gateways.active,
        people: this.state.includeSilencedInCount ? c.people.total : c.people.active,
        presence: this.state.includeSilencedInCount ? c.presence.total : c.presence.active,
        other: this.state.includeSilencedInCount ? c.other.total : c.other.active
      };
    });

    const tableElement = (
      <Table
        data={data}
        offset={this.state.offset}
        limit={this.state.limit}
        onOffsetChange={(offset) => {
          this.setState({ offset }, this.fetchData);
        }}
        noDataText={isLoading ? "" : "No companies found"}
        selectedId={this.props.companyId}
        useDeepCompareOnRows={true}
        columns={[
          {
            header: "Name",
            accessorKey: "name",
            resizable: false,
            minWidth: 150,
          },
          {
            header: () => (
              <div style={{ display: "flex", justifyContent: "flex-start", gap: "8px" }}>
                <div style={{ fontSize: "15px" }}>
                  <FontAwesomeIcon icon={faGlobe} size="lg" />
                </div>
                <div style={{ fontSize: "15px" }}>Integr.</div>
              </div>
            ),
            accessorKey: "integrations",
            width: 100,
            resizable: false
          },
          {
            header: () => (
              <div style={{ display: "flex", justifyContent: "flex-start", gap: "8px" }}>
                <div style={{ fontSize: "15px" }}>
                  <FontAwesomeIcon icon={faRouter} size="lg" />
                </div>
                <div style={{ fontSize: "15px" }}>GW</div>
              </div>
            ),
            accessorKey: "gateways",
            width: 100,
            resizable: false
          },
          {
            header: () => (
              <div style={{ display: "flex", justifyContent: "flex-start", gap: "8px" }}>
                <div style={{ fontSize: "15px" }}>
                  <FontAwesomeIcon icon={faUserPlus} size="lg" />
                </div>
                <div style={{ fontSize: "15px" }}>People</div>
              </div>
            ),
            accessorKey: "people",
            width: 110,
            resizable: false
          },
          {
            header: () => (
              <div style={{ display: "flex", justifyContent: "flex-start", gap: "8px" }}>
                <div style={{ fontSize: "15px" }}>
                  <FontAwesomeIcon icon={faPersonShelter} size="lg" />
                </div>
                <div style={{ fontSize: "15px" }}>Presence</div>
              </div>
            ),
            accessorKey: "presence",
            width: 115,
            resizable: false
          },
          {
            header: () => (
              <div style={{ display: "flex", justifyContent: "flex-start", gap: "8px" }}>
                <div style={{ fontSize: "15px" }}>
                  <FontAwesomeIcon icon={faMobileButton} size="lg" />
                </div>
                <div style={{ fontSize: "15px" }}>Other</div>
              </div>
            ),
            accessorKey: "other",
            width: 90,
            resizable: false
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 40,
            cell: () => (<div><div className="arrow" /></div>)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={isLoading}
      />
    );

    return (
      <>
        <div>
          { options }
          { tableElement }
          <div style={{ paddingTop: "40px" }} />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    companies: state.alarms.companies.results,
    isLoading: state.loading.alarms
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getWarningsSummary: alarmActions.getWarningsSummary
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WarningsSummaryList);

