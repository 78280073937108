import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Hidden, Visible } from "react-grid-system";
import { get, isEmpty } from "lodash";
import { faSitemap, faHdd, faMicrochip, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { RowIcon, GREEN, BROWN, BLUE, GREY, PURPLE } from "../../components/RowIcon";
import { DropdownSelection } from "../../components/DropdownSelection";
import SmallButton from "../../components/SmallButton";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import InputBox from "../../components/InputBox";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import { getSupportedLocationTypes, locationTypes } from "../../locationHelpers";
import * as locationActions from "../../actions/locations";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class Locations extends Component {
  
  constructor(props) {
    // console.log("Locations.constructor", props);
    super(props);
    this.state = {
      searchText: "",
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
      showBulkCreateModal: false
    };
    this.onUploadClick = this.onUploadClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);
    this.onShowBulkCreate = this.onShowBulkCreate.bind(this);
    this.onBulkCreateClick = this.onBulkCreateClick.bind(this);
    this.onNewLocationClick = this.onNewLocationClick.bind(this);

    // Reset highlighted feature
    this.props.setHighlightedLocation(null);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    if (this.props.locationId === "root") {
      this.props.getRootLocations(queryParams);
    }
    else {
      this.props.getLocationChildren(this.props.locationId, queryParams);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("Locations.componentDidUpdate.prevProps", prevProps);
    // console.log("Locations.componentDidUpdate.props", this.props);

    if (this.props.locationId === "root" && this.props.locationId !== prevProps.locationId) {
      const queryParams = {};
      if (this.state.searchText !== null && this.state.searchText !== "") {
        queryParams.search = this.state.searchText;
      }

      queryParams.sortBy = this.state.sortBy;
      queryParams.sortOrder = this.state.sortOrder;

      this.props.getRootLocations(queryParams);
    }
  }

  onSearchClick() {
    const queryParams = {};
    if (this.state.searchText !== null && this.state.searchText !== "") {
      queryParams.search = this.state.searchText;
    }
    
    queryParams.sortBy = this.state.sortBy;
    queryParams.sortOrder = this.state.sortOrder;

    if (this.props.locationId === "root") {
      this.props.getRootLocations(queryParams);
    }
    else {
      this.props.getLocationChildren(this.props.locationId, queryParams);
    }
  }

  onSearchChange(value) {
    this.setState({ searchText: value });
  }

  onRowClick(column, rowInfo) {
    // console.log("Locations.onRowClick", column, rowInfo);
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {

          let link = "";
          if (rowInfo.original.children !== undefined) {
            link = `/companies/${this.props.match.params.companyId}/locations/${rowInfo.original._id}/locations/`;
          }
          else if (rowInfo.original.sensors !== undefined) {
            link = `/companies/${this.props.match.params.companyId}/locations/${rowInfo.original._id}/sensors/`;
          }
          else {
            link = `/companies/${this.props.match.params.companyId}/locations/${rowInfo.original._id}/locations/`;
          }

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }

        }
      },
      onMouseOver: e => {
        // Clear any pending leave timeout
        if (this.leaveTimeout) {
          clearTimeout(this.leaveTimeout);
          this.leaveTimeout = null;
        }
        if (rowInfo?.original?._id) {
          this.onHover(rowInfo.original._id);
        }
      },
      onMouseLeave: e => {
        // Delay the clearing of hover state
        this.leaveTimeout = setTimeout(() => {
          this.onHover(null);
        }, 100);
      },
      style: {
        cursor: "pointer",
        background: (rowInfo && this.props.highlightedLocationId && rowInfo.original._id === this.props.highlightedLocationId ? "rgba(0,0,0,0.05)" : null),
        position: "relative"
      }
    }
  }

  onHover(id) {
    this.props.setHighlightedLocation(id);
  }

  onToggle(row) {
    if (this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id]) {
      this.props.deselectLocation(row.original._id);
    }
    else {
      this.props.selectLocation(row.original);
    }
  }
  
  onUploadClick() {
    // this.props.setSourceMapFeatures(this.props.locationId, this.props.floorMap);
  }

  onShowBulkCreate() {
    this.setState({ showBulkCreateModal: true });
  }

  onBulkCreateClick() {
    
    if (this.state.bulkLocationCount <= 0 || this.state.bulkLocationName === "" || isEmpty(this.state.bulkLocationType)) {
      return;
    }

    // replace $ with {}
    let name = this.state.bulkLocationName.replace("$", "{}");

    let body = {
      startIndex: 1,
      endIndex: this.state.bulkLocationCount,
      digits: this.state.bulkLocationDigits ?? 1,
      template: {
        name,
        type: this.state.bulkLocationType
      }
    };

    // If in root - do not add a parent id
    if (this.props.locationId !== "root") {
      body.template.parentIds = [this.props.locationId];
    }

    this.setState({ showBulkCreateModal: false, bulkLocationCount: 0, bulkLocationName: "", bulkLocationType: "", bulkLocationDigits: 1 });

    const { push } = this.props.history;
    this.props.bulkCreateLocations(body, this.props.locationId, push);
  }

  onNewLocationClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/new-location`);
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder, offset: 0 }, this.fetchData);
  }

  onOffsetChange(offset) {
    this.setState({ offset }, this.fetchData);
  }

  render() {
    // console.log("---- Locations.render()", this.state);
    const { isLoading } = this.props;
    const canCreateLocation = this.props.auth.hasSupportRole;
    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("location");

    const heading = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => this.setState({ searchText: "" }, this.onSearchClick)}
            inListView
          />
        )}
        buttons={[
          <SmallButton key="b1" text="New location" onClick={this.onNewLocationClick} disabled={!canCreateLocation || isEmpty(getSupportedLocationTypes(this.props.location.type))} singleLine noMargin />,
          canCreateLocation && <SmallButton key="b2" text="Bulk create" onClick={this.onShowBulkCreate} disabled={isEmpty(getSupportedLocationTypes(this.props.location.type))} singleLine onlyLeftMargin />
        ]}
      />
    );
    
    const tableElement = (
      <Table
        data={this.props.children.locations}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.children.count}
        onSortedChange={this.onSortedChange}
        onOffsetChange={this.onOffsetChange}
        noDataText={isLoading ? "" : "No locations found"}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={(this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id])}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
          },
          {
            header: "Type",
            accessorKey: "type",
            minWidth: 50,
            maxWidth: 200,
            cell: ({ row }) => {
              let type = locationTypes.find((type) => (type.id === row.original.type)) || { name: `<${row.original.type}>` };
              return (<span title={type.name}>{type.name}</span>);
            }
          },
          {
            id: "children",
            header: "",
            accessorKey: "children",
            sortable: false,
            width: 40,
            cell: ({ row }) => {
              if (row.original.children.length > 0) {
                return <RowIcon tooltip={`${row.original.children.length} locations`} bgColor={BROWN} icon={faSitemap} />
              }
              
              return <RowIcon tooltip="no locations" bgColor={GREY} icon={faSitemap} />
            }
          },
          {
            id: "sensors",
            header: "",
            accessorKey: "sensors",
            sortable: false,
            width: 40,
            cell: ({ row }) => {
              if (row.original.sensors.length > 0) {
                return <RowIcon tooltip={`${row.original.sensors.length} sensors`} bgColor={GREEN} icon={faMicrochip} />
              }
              
              return <RowIcon tooltip="no sensors" bgColor={GREY} icon={faMicrochip} />
            }
          },
          {
            id: "gateways",
            header: "",
            accessorKey: "gateways",
            sortable: false,
            width: 40,
            cell: ({ row }) => {
              if (row.original.gateways && row.original.gateways.length > 0) {
                return <RowIcon tooltip={`${row.original.gateways.length} gateways`} bgColor={PURPLE} icon={faHdd} />
              }
              
              return <RowIcon tooltip="no gateways" bgColor={GREY} icon={faHdd} />
            }
          },
          {
            id: "inMap",
            header: "",
            accessorKey: "geoJsonFeature",
            sortable: false,
            width: 40,
            cell: ({ row }) => {
              if (get(row, "original.geoJsonFeature.geometry", false)) {
                return <RowIcon tooltip="in map" bgColor={BLUE} icon={faMapMarkerAlt} />
              }
              
              return <RowIcon tooltip="not in map" bgColor={GREY} icon={faMapMarkerAlt} />
            }
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable setMaxHeigth"
        loading={isLoading}
      />
    );

    const slimTableElement = (
      <Table
        data={this.props.children.locations}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.children.count}
        onSortedChange={this.onSortedChange}
        onOffsetChange={this.onOffsetChange}
        noDataText={isLoading ? "" : "No locations found"}
        columns={[
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
          },
          {
            header: "Type",
            accessorKey: "type",
            minWidth: 50,
            maxWidth: 200
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable setMaxHeigth"
        loading={isLoading}
      />
    );

    return (
      <>
        <Hidden xs sm md>
          <div className={style.scroll}>
            { heading }
            { tableElement }
            <div style={{ paddingTop: "40px" }} />
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.slimScroll}>
            { heading }
            { slimTableElement }
            <div style={{ paddingTop: "40px" }} />
          </div>
        </Visible>

        <Modal
          show={this.state.showBulkCreateModal}
          onHide={() => this.setState({ showBulkCreateModal: false })}
          title="Bulk create locations"
          text={`Create multiple locations in one go. Include "$" in the name to add sequential numbers (1, 2, 3 etc) to the names.`}
          primaryBtn={{
            text: "Create",
            disabled: this.state.bulkLocationCount <= 0 || this.state.bulkLocationName === "" || isEmpty(this.state.bulkLocationType),
            onClick: this.onBulkCreateClick
          }}
          secondaryBtn={{
            text: "Cancel",
            onClick: () => this.setState({ showBulkCreateModal: false, bulkLocationName: "", bulkLocationCount: 0, bulkLocationType: "" })
          }}
        >
          <div>
            <div style={{ display: "flex", alignItems: "start", marginTop: "20px", marginBottom: "0px" }}>
              <DropdownSelection
                label="Location type"
                options={getSupportedLocationTypes(this.props.location.type)}
                onChange={(event) => this.setState({ bulkLocationType: event.target.value })}
                style={{ textAlign: "left", marginRight: "20px"  }}
              />
              <InputBox
                label="Location name"
                placeholder="ex. Meeting room $"
                onChange={(event) => this.setState({ bulkLocationName: event.target.value })}
                style={{ width: "400px", maxWidth: "100%", marginRight: "20px" }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "start", marginTop: "0px", marginBottom: "40px" }}>
              <InputBox
                label="How many?"
                placeholder="ex. 7"
                type="number"
                valid={this.state.bulkLocationCount > 0}
                onChange={(event) => {
                  let count = parseInt(event.target.value);
                  this.setState({ bulkLocationCount: count });
                }}
                style={{ width: "120px", maxWidth: "100%", marginRight: "20px" }}
              />
              <DropdownSelection
                label="Number of digits"
                defaultValue={1}
                options={[
                  { id: 1, name: "1 digit (ex. 7)" },
                  { id: 2, name: "2 digits (ex. 07)" },
                  { id: 3, name: "3 digits (ex. 007)" },
                  { id: 4, name: "4 digits (ex. 0007)" },
                ]}
                onChange={(event) => {
                  const intValue = parseInt(event.target.value);
                  if (!isNaN(intValue)) {
                    this.setState({ bulkLocationDigits: intValue })
                  }
                }}
                style={{ textAlign: "left" }}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    floorMap: state.location.floorMap,
    geoJsonFeature: state.location.geoJsonFeature,
    children: state.locations.data,
    isLoading: state.loading.locations,
    selectedLocations: state.selected.locations,
    selectedTypes: state.selected.allTypes,
    location: state.location,
    companyMap: state.auth.map,
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getLocationChildren: locationActions.getLocationChildren,
    getRootLocations: locationActions.getRootLocations,
    changeLocationsQuery: locationActions.changeLocationsQuery,
    editLocation: locationActions.editLocation,
    setSelectedFeatures: selectedActions.setSelectedFeatures,
    selectLocation: selectedActions.selectLocation,
    deselectLocation: selectedActions.deselectLocation,
    bulkCreateLocations: locationActions.bulkCreateLocations,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Locations);


