import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import style from "./style.module.scss";
import styled from "styled-components";

class SegmentedControl extends Component {
  constructor(props) {
    super(props);

    let upperIndex;
    let lowerIndex;

    // Check if we need to scroll because of too many options
    if (props.options.length > 7) {
      const selectedOptionIndex = props.options.findIndex(option => option.value === props.value);
      // Put selected option in the middle
      if (selectedOptionIndex < 3) {
        upperIndex = 0;
        lowerIndex = 4;
      } else if (selectedOptionIndex > props.options.length - 4) {
        upperIndex = props.options.length - 5;
        lowerIndex = props.options.length - 1;
      } else {
        upperIndex = selectedOptionIndex - 2;
        lowerIndex = selectedOptionIndex + 2;
      }
    }

    this.state = {
      upperIndex,
      lowerIndex
    };

    this.handleChange = this.handleChange.bind(this);
    this.onUpClicked = this.onUpClicked.bind(this);
    this.onDownClicked = this.onDownClicked.bind(this);
  }

  handleChange(value) {
    this.props.onChange(value);
  }

  onUpClicked() {
    if (this.state.upperIndex > 0) {
      this.setState(prevState => ({ lowerIndex: prevState.lowerIndex - 1, upperIndex: prevState.upperIndex - 1 }));
    }
  }

  onDownClicked() {
    if (this.state.lowerIndex < this.props.options.length - 1) {
      this.setState(prevState => ({ lowerIndex: prevState.lowerIndex + 1, upperIndex: prevState.upperIndex + 1 }));
    }
  }

  render() {
    const limitedOptions = this.props.options.filter((_, index) => {
      if (this.state.upperIndex === undefined) {
        return true;
      }

      if (this.state.lowerIndex === undefined) {
        return true;
      }

      return index >= this.state.upperIndex && index <= this.state.lowerIndex;
    });

    return (
      <>
        {
          !isEmpty(this.props.title) && (
            <Title>{this.props.title}</Title>
          )
        }
        {
          !isEmpty(this.props.label) && (
            <Label>{this.props.label}</Label>
          )
        }
        <Container $vertical={!!this.props.vertical} $lightBg={!!this.props.lightBg}>
          {this.state.upperIndex !== undefined && (
            <OptionButton key={`sc-up`} $vertical={!!this.props.vertical} $left={true} $right={false} onClick={this.onUpClicked} $disabled={this.state.upperIndex === 0}>
              {!!this.props.vertical ? "▲" : "◄"}
            </OptionButton>
          )}
          {limitedOptions.map((option, index) => {
            const selected = option.value === this.props.value;
            return (
              <OptionButton
                key={`sc-${this.props.name}${option.value}`}
                $checked={selected}
                $value={option.value}
                $vertical={!!this.props.vertical}
                $left={this.state.upperIndex === undefined && index === 0}
                $right={this.state.lowerIndex === undefined && index === limitedOptions.length - 1}
                $color={option.customColor || option.color || "blue"}
                $minwidth={this.props.minwidth}
                onClick={() => this.handleChange(option.value)}
              >
                {option.label}
              </OptionButton>
            );
          })}
          {this.state.lowerIndex !== undefined && (
            <OptionButton key={`sc-down`} $vertical={!!this.props.vertical} $left={false} $right={true} onClick={this.onDownClicked} $disabled={this.state.lowerIndex === this.props.options.length - 1}>
              {!!this.props.vertical ? "▼" : "►"}
            </OptionButton>
          )}
        </Container>
      </>
    );
  }
}

export default SegmentedControl;

const Container = styled.div`
  display: inline-flex;
  cursor: pointer;
  border-radius: 4px;
  border-color: #bbb;
  border-width: 1px;
  border-style: solid;
  
  ${props => props.$vertical && `
    display: block;
  `}

  ${props => !props.$vertical && `
    height: 33px;
  `}

  ${props => props.$lightBg && `
    border-color: #ddd;
  `}
`;

const OptionButton = styled.div`
  background-color: white;
  padding: 7px 10px;
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  text-align: center;
  white-space: nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */

  color: ${props => props.$disabled ? "#bbb" : (props.$checked ? "white" : "#444")};
  background-color: ${props => {
    if (props.$checked) {
      if (props.$color === "blue") {
        return "#1C4D82";
      }
      if (props.$color === "green") {
        return "#26BE3E";
      }
      if (props.$color === "red") {
        return "darkred";
      }
      return props.$color;
    }
    return "white";
  }};

  ${props => props.$left && `
    border-radius: 4px 0px 0px 4px;
  `}
  ${props => props.$right && `
    border-radius: 0px 4px 4px 0px;
  `}
  ${props => props.$left && props.$right && `
    border-radius: 4px;
  `}
  ${props => props.$vertical && props.$left && `
    border-radius: 4px 4px 0px 0px;
  `}
  ${props => !props.$vertical && !props.$left && `
    border-left: rgba(0,0,0,.2);
    border-left-width: 1px;
    border-left-style: solid;
  `}
  ${props => props.$vertical && !props.$left && `
    border-top: rgba(0,0,0,.2);
    border-top-width: 1px;
    border-top-style: solid;
  `}
  ${props => props.$vertical && props.$right && `
    border-radius: 0px 0px 4px 4px;
  `}
  ${props => !props.$vertical && props.$minwidth && `
    min-width: 20px;
  `}
`;

const Title = styled.label`
  display: block;
  margin-bottom: 7px;
  font-size: 18px;
  color: #222222;
`;

const Label = styled.label`
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 5px;
`;