import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { get, isEmpty } from "lodash";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import SelectionFooterCell from "../../components/SelectionFooterCell";
import * as selectedActions from "../../actions/selected";
import * as locationActions from "../../actions/locations";
import * as sensorActions from "../../actions/sensors";
import * as gatewayActions from "../../actions/gateways";
import * as locationTagActions from "../../actions/locationTags";
import * as sensorTagActions from "../../actions/sensorTags";
import * as featureActions from "../../actions/features";
import * as contentActions from "../../actions/content";
import * as userGroupActions from "../../actions/userGroups";
import * as userActions from "../../actions/users";
import * as customTagActions from "../../actions/customTags";
import * as unregisteredSensorActions from "../../actions/unregisteredSensors";
import * as API from "../../ApiTypes";
import { getCompanyId } from "../../helpers";
import style from "./style.module.scss";
import { MapMode } from "../../constants/stateTypes";

class SelectionFooter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showMoveModal: false,
      showAddAtomicSensorModal: false,
      showRemoveAtomicSensorModal: false,
      showDeleteSensorModal: false,
      accessLevel: "user",
      copyText: "Copy"
    };
    this.onDeselectSensor = this.onDeselectSensor.bind(this);
    this.onDeselectAtomicSensor = this.onDeselectAtomicSensor.bind(this);
    this.onDeselectGateway = this.onDeselectGateway.bind(this);
    this.onDeselectLocation = this.onDeselectLocation.bind(this);
    this.onDeselectCompany = this.onDeselectCompany.bind(this);
    this.onDeselectLocationTag = this.onDeselectLocationTag.bind(this);
    this.onDeselectSensorTag = this.onDeselectSensorTag.bind(this);
    this.onMoveClicked = this.onMoveClicked.bind(this);
    this.onMoveAndKeep = this.onMoveAndKeep.bind(this);
    this.onMoveAndClear = this.onMoveAndClear.bind(this);
    this.onRemoveSensors = this.onRemoveSensors.bind(this);
    this.onDeleteLocations = this.onDeleteLocations.bind(this);
    this.onDeleteSensor = this.onDeleteSensor.bind(this);
    this.onDeleteSensorWithOptions = this.onDeleteSensorWithOptions.bind(this);
    this.onDeleteLocationTag = this.onDeleteLocationTag.bind(this);
    this.onDeleteSensorTag = this.onDeleteSensorTag.bind(this);
    this.onDeleteFeatures = this.onDeleteFeatures.bind(this);
    this.onDeleteCompanyFeatures = this.onDeleteCompanyFeatures.bind(this);
    this.onDeleteContent = this.onDeleteContent.bind(this);
    this.onDeleteInvite = this.onDeleteInvite.bind(this);
    this.onDeleteUser = this.onDeleteUser.bind(this);
    this.onDeleteUserGroups = this.onDeleteUserGroups.bind(this);
    this.onDeleteCustomTags = this.onDeleteCustomTags.bind(this);
    this.onMergeCustomTags = this.onMergeCustomTags.bind(this);
    this.onSaveCustomTag = this.onSaveCustomTag.bind(this);
    this.onDeleteCustomTagLocations = this.onDeleteCustomTagLocations.bind(this);
    this.onAddUserGroupsToContent = this.onAddUserGroupsToContent.bind(this);
    this.onRemoveUserGroupsFromContent = this.onRemoveUserGroupsFromContent.bind(this);
    this.onAddUserGroupToSelectedContent = this.onAddUserGroupToSelectedContent.bind(this);
    this.onRemoveUserGroupFromSelectedContent = this.onRemoveUserGroupFromSelectedContent.bind(this);
    this.onAddLocationToSelectedContent = this.onAddLocationToSelectedContent.bind(this);
    this.onRemoveLocationFromSelectedContent = this.onRemoveLocationFromSelectedContent.bind(this);
    this.onAddLocationsToContent = this.onAddLocationsToContent.bind(this);
    this.onRemoveLocationsFromContent = this.onRemoveLocationsFromContent.bind(this);
    this.onAddContentToUserGroup = this.onAddContentToUserGroup.bind(this);
    this.onRemoveContentFromUserGroup = this.onRemoveContentFromUserGroup.bind(this);
    this.onAddLocationsToUserGroup = this.onAddLocationsToUserGroup.bind(this);
    this.onRemoveLocationsFromUserGroup = this.onRemoveLocationsFromUserGroup.bind(this);
    this.onUpdateUserGroup = this.onUpdateUserGroup.bind(this);
    this.onUpdateContent = this.onUpdateContent.bind(this);
    this.onAddLocationToUserGroups = this.onAddLocationToUserGroups.bind(this);
    this.onRemoveLocationFromUserGroups = this.onRemoveLocationFromUserGroups.bind(this);
    this.onAddCustomTagsToLocation = this.onAddCustomTagsToLocation.bind(this);
    this.onRemoveCustomTagsFromLocation = this.onRemoveCustomTagsFromLocation.bind(this);
    this.onConnectLocationToFeature = this.onConnectLocationToFeature.bind(this);
    this.onConnectThisLocationToFeature = this.onConnectThisLocationToFeature.bind(this);
    this.onDisconnectLocationsFromFeatures = this.onDisconnectLocationsFromFeatures.bind(this);
    this.onSaveEditedMap = this.onSaveEditedMap.bind(this);
    this.onSaveEditedCompanyMap = this.onSaveEditedCompanyMap.bind(this);
    this.onCreateLocationInMap = this.onCreateLocationInMap.bind(this);
    this.onPlaceSensorInMap = this.onPlaceSensorInMap.bind(this);
    this.onSaveSensorToPoint = this.onSaveSensorToPoint.bind(this);
    this.onPlaceGatewayInMap = this.onPlaceGatewayInMap.bind(this);
    this.onSaveGatewayToPoint = this.onSaveGatewayToPoint.bind(this);
    this.onSaveCreatedFeature = this.onSaveCreatedFeature.bind(this);
    this.onSaveCreatedCompanyFeature = this.onSaveCreatedCompanyFeature.bind(this);
    this.onCreateUnregisteredSensor = this.onCreateUnregisteredSensor.bind(this);
    this.onEditMultipleUnregisteredSensors = this.onEditMultipleUnregisteredSensors.bind(this);
    this.onFindLocationForUnregisteredSensors = this.onFindLocationForUnregisteredSensors.bind(this);
    this.onMoveSensorsToGateway = this.onMoveSensorsToGateway.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.viewingSubGroupOfSelectedGroup = this.viewingSubGroupOfSelectedGroup.bind(this);
    this.viewingRootWithSensorsSelected = this.viewingRootWithSensorsSelected.bind(this);
    this.onAccessLevelSelectionChange = this.onAccessLevelSelectionChange.bind(this);
    this.onAddUsersToUserGroup = this.onAddUsersToUserGroup.bind(this);
    this.onRemoveUsersFromUserGroup = this.onRemoveUsersFromUserGroup.bind(this);
    this.onAddAtomicSensorsToLocation = this.onAddAtomicSensorsToLocation.bind(this);
    this.onRemoveAtomicSensorsFromLocation = this.onRemoveAtomicSensorsFromLocation.bind(this);
    this.onAddLocationsToAtomicSensor = this.onAddLocationsToAtomicSensor.bind(this);
    this.onRemoveLocationsFromAtomicSensor = this.onRemoveLocationsFromAtomicSensor.bind(this);
    this.onAddUserToUserGroups = this.onAddUserToUserGroups.bind(this);
    this.onRemoveUserFromUserGroups = this.onRemoveUserFromUserGroups.bind(this);
    this.onGrantRoleToUser = this.onGrantRoleToUser.bind(this);
  }

  onDeselectSensor(id) {
    this.props.deselectSensor(id);
  }

  onDeselectAtomicSensor(id) {
    this.props.deselectAtomicSensor(id);
  }

  onDeselectGateway(id) {
    this.props.deselectGateway(id);
  }

  onDeselectLocation(id) {
    this.props.deselectLocation(id);
  }

  onDeselectCompany(id) {
    this.props.deselectCompany(id);
  }

  onDeselectLocationTag(id) {
    this.props.deselectLocationTag(id);
  }

  onDeselectSensorTag(id) {
    this.props.deselectSensorTag(id);
  }

  onDeselectContent(id) {
    this.props.deselectContent(id);
  }

  onDeselectUserGroup(id) {
    this.props.deselectUserGroup(id);
  }

  onDeselectUser(id) {
    this.props.deselectUser(id);
  }

  onDeselectUnregisteredSensor(id) {
    this.props.deselectUnregisteredSensors([id]);
  }

  onMoveClicked() {
    const gatewayIds = Object.keys(this.props.selectedGateways);
    const sensorIds = Object.keys(this.props.selectedSensors);
    const locationIds = Object.keys(this.props.selectedLocations);

    if (gatewayIds.length > 0) {
      this.props.moveGateways(gatewayIds, this.props.currentLocation.id);
    }
    else if (locationIds.length > 0) {
      this.props.moveLocations(this.props.currentLocation.id, locationIds);
    }
    else if (sensorIds.length > 0) {
      this.setState({ showMoveModal: true });
    }
  }

  onMoveAndKeep() {
    const sensorIds = Object.keys(this.props.selectedSensors);
    this.props.moveSensors(sensorIds, this.props.currentLocation.id, true);
    this.setState({ showMoveModal: false });
  }

  onMoveAndClear() {
    const sensorIds = Object.keys(this.props.selectedSensors);
    this.props.moveSensors(sensorIds, this.props.currentLocation.id, false);
    this.setState({ showMoveModal: false });
  }

  onRemoveSensors() {
    const sensorIds = Object.keys(this.props.selectedSensors);
    this.props.removeSensors(sensorIds, this.props.currentLocation.id);
  }

  onDeleteLocations() {
    const includeChildren = false;
    this.props.deleteLocations(Object.keys(this.props.selectedLocations), this.props.currentLocation.id, includeChildren);
  }

  onDeleteSensor() {
    const sensorIds = Object.keys(this.props.selectedSensors);
    const sensorId = get(sensorIds, "[0]", null);

    if (sensorId) {
      this.setState({ showDeleteSensorModal: true });
    }
  }

  onDeleteSensorWithOptions(keepSampleHistory) {
    const sensorIds = Object.keys(this.props.selectedSensors);
    const sensorId = get(sensorIds, "[0]", null);

    if (sensorId) {
      this.props.deleteSensor(sensorId, this.props.currentLocation.id, keepSampleHistory);
      this.setState({ showDeleteSensorModal: false });
    }
  }

  onDeleteLocationTag() {
    const tagIds = Object.keys(this.props.selectedLocationTags);
    const tagId = get(tagIds, "[0]", null);

    if (tagId) {
      this.props.deleteLocationTag(tagId);
    }
  }

  onDeleteSensorTag() {
    const tagIds = Object.keys(this.props.selectedSensorTags);
    const tagId = get(tagIds, "[0]", null);

    if (tagId) {
      this.props.deleteSensorTag(tagId);
    }
  }

  onConnectLocationToFeature() {
    const locationId = Object.keys(this.props.selectedLocations)[0];
    const feature = this.props.selectedFeatures[0];
    this.props.mapFeatureToLocation(feature.id, locationId, this.props.currentLocation.id, this.props.currentLocation.type);
  }

  onConnectThisLocationToFeature() {
    const feature = this.props.selectedFeatures[0];
    this.props.mapFeatureToLocation(feature.id, this.props.currentLocation.id, this.props.currentLocation.id, this.props.currentLocation.type);
  }

  onDisconnectLocationsFromFeatures() {
    const locationIds = Object.keys(this.props.selectedLocations);
    this.props.removeFeaturesFromLocations(locationIds, this.props.currentLocation.id);
  }

  onCreateLocationInMap() {
    this.props.setViewMapMode(MapMode.draw_polygon);
  }

  onSaveCreatedCompanyFeature() {
    if (this.props.createdFeature) {
      const companyId = getCompanyId();
      this.props.registerCompanyFeature(this.props.createdFeature, companyId);
    }
  }

  onSaveCreatedFeature() {
    console.log("onSaveCreatedFeature()");
    // const locationId = Object.keys(this.props.selectedLocations)[0];
    const parentIndex = get(this.props.currentLocation, "geoJsonFeature.properties.zIndex", -1);
    const floorLocationId = get(this.props.currentLocation, "floorMap.features[0].properties.floorId", null);
    console.log("this.props.createdFeature", this.props.createdFeature);
    console.log("parentIndex >= 0", parentIndex >= 0);
    console.log("floorLocationId", floorLocationId);
    console.log("this.props.currentLocation", this.props.currentLocation);
    if (this.props.createdFeature) {

      // Create feature in floor map or in company map
      if (this.props.currentLocation.type.match(/^(root|region|building)$/)) {
        const companyId = getCompanyId();
        this.props.registerCompanyFeature(this.props.createdFeature, companyId);
      }
      else if (floorLocationId) {
        this.props.registerFeature(this.props.createdFeature, parentIndex, floorLocationId, this.props.currentLocation.id);
      }
      else {
        this.props.registerFeature(this.props.createdFeature, 0, this.props.currentLocation.id, this.props.currentLocation.id);
      }
    }
  }

  onSaveEditedMap() {
    const floorLocationId = get(this.props.editedFeatures, "[0].properties.floorId", null);
    const floorMapFeatures = get(this.props.currentLocation, "floorMap.features", null);

    if (floorLocationId === null || floorMapFeatures === null) {
      return;
    }
    
    // Find changed features and sensors
    const changedLocations = [];
    const changedSensors = [];
    const changedGateways = [];
    this.props.editedFeatures.forEach(editedFeature => {
      floorMapFeatures.forEach(feature => {
        if (feature.id === editedFeature.id) {
          // Check coordinates
          if (feature.geometry.type === "Point") {
            if (get(feature, "geometry.coordinates.length", 0) !== get(editedFeature, "geometry.coordinates.length", 0)) {
              if (!isEmpty(feature.properties.sensor)) {
                changedSensors.push(editedFeature);
              }
              else if (!isEmpty(feature.properties.gateway)) {
                changedGateways.push(editedFeature);
              }
            }
            else {
              feature.geometry.coordinates.some((coordinate, index) => {
                const editedCoordinate = editedFeature.geometry.coordinates[index];
                if (coordinate !== editedCoordinate) {
                  if (!isEmpty(feature.properties.sensor)) {
                    changedSensors.push(editedFeature);
                  }
                  else if (!isEmpty(feature.properties.gateway)) {
                    changedGateways.push(editedFeature);
                  }
                  return true;
                }
                return false;
              });
            }
          }
          else if (get(feature, "geometry.coordinates[0].length", 0) !== get(editedFeature, "geometry.coordinates[0].length", 0)) {
            // TODO: Fix check for multi-polygon
            changedLocations.push(editedFeature);
          }
          else {
            get(feature, "geometry.coordinates[0]", []).some((coordinate, index) => {
              const editedCoordinate = editedFeature.geometry.coordinates[0][index];
              if (coordinate[0] !== editedCoordinate[0] || coordinate[1] !== editedCoordinate[1]) {
                changedLocations.push(editedFeature);
                return true;
              }
              return false;
            });
          }
        }
      });
    });
    
    // console.log("changedLocations", changedLocations);
    // console.log("changedSensors", changedSensors);

    this.props.changeFeatures(changedLocations, changedSensors, changedGateways, floorLocationId, this.props.currentLocation.id);
  }

  onSaveEditedCompanyMap() {
    const companyMapFeatures = get(this.props.companyMap, "features", []);

    // Find changed features
    const changedLocations = [];
    this.props.editedFeatures.forEach(editedFeature => {
      companyMapFeatures.forEach(feature => {
        if (feature.id === editedFeature.id) {
          // Check coordinates
          if (feature.geometry.type === "Point") {
            // return;
          }
          else if (get(feature, "geometry.coordinates[0].length", 0) !== get(editedFeature, "geometry.coordinates[0].length", 0)) {
            // TODO: Fix check for multi-polygon
            changedLocations.push(editedFeature);
          }
          else {
            get(feature, "geometry.coordinates[0]", []).some((coordinate, index) => {
              const editedCoordinate = editedFeature.geometry.coordinates[0][index];
              if (coordinate[0] !== editedCoordinate[0] || coordinate[1] !== editedCoordinate[1]) {
                changedLocations.push(editedFeature);
                return true;
              }
              return false;
            });
          }
        }
      });
    });
    
    // console.log("changedLocations", changedLocations);

    this.props.changeCompanyFeatures(changedLocations);
  }

  onPlaceSensorInMap() {
    this.props.setMapMode(MapMode.draw_point);
  }

  onSaveSensorToPoint() {
    const sensorId = Object.keys(this.props.selectedSensors)[0];
    const feature = this.props.selectedFeatures[0];
    const geometry = get(feature, "geometry", {});
    if (geometry.type === "Point") {
      this.props.updateSensor(sensorId, { geoJsonFeature: feature });
    }
  }

  onPlaceGatewayInMap() {
    this.props.setMapMode(MapMode.draw_point);
  }

  onSaveGatewayToPoint() {
    const gatewayId = Object.keys(this.props.selectedGateways)[0];
    const feature = this.props.selectedFeatures[0];
    const geometry = get(feature, "geometry", {});
    if (geometry.type === "Point") {
      this.props.updateGateway(gatewayId, { geoJsonFeature: feature });
    }
  }

  onDeleteCompanyFeatures() {
    const features = this.props.selectedFeatures;
    if (features) {
      this.props.deleteCompanyFeatures(features, this.props.currentLocation.id);
    } 
  }

  onDeleteFeatures() {
    const features = this.props.selectedFeatures;
    const floorLocationId = get(this.props.currentLocation, "floorMap.features[0].properties.floorId", null);
    if (features) {
      this.props.deleteFeatures(features, floorLocationId, this.props.currentLocation.id);
    } 
  }

  onUpdateContent() {
    this.props.updateContent(this.props.currentContent.id, this.props.currentContent.draft);
  }

  onDeleteContent() {
    this.props.deleteContent(Object.keys(this.props.selectedContent));
  }

  onAddUserGroupsToContent() {
    const newUserGroupIds = Object.keys(this.props.selectedUserGroups);
    var usergroups = this.props.currentContent.userGroups.map(element => element._id);
    usergroups = usergroups.concat(newUserGroupIds);
    var newContent = { usergroups };

    this.props.updateContent(this.props.currentContent.id, newContent);
  }

  onRemoveUserGroupsFromContent() {
    const removableUserGroupIds = Object.keys(this.props.selectedUserGroups);
    const usergroups = this.props.currentContent.userGroups.filter(element => !removableUserGroupIds.includes(element._id)).map(element => element._id);
    var newContent = { usergroups };

    this.props.updateContent(this.props.currentContent.id, newContent);
  }

  onAddUserGroupToSelectedContent() {
    const selectedContentIds = Object.keys(this.props.selectedContent);
    this.props.addUserGroupToContent(this.props.currentUserGroup.id, selectedContentIds);
  }

  onRemoveUserGroupFromSelectedContent() {
    const selectedContentIds = Object.keys(this.props.selectedContent);
    this.props.removeUserGroupFromContent(this.props.currentUserGroup.id, selectedContentIds);
  }

  onAddLocationToSelectedContent() {
    const selectedContentIds = Object.keys(this.props.selectedContent);
    this.props.addLocationToContent(this.props.currentLocation.id, selectedContentIds);
  }

  onRemoveLocationFromSelectedContent() {
    const selectedContentIds = Object.keys(this.props.selectedContent);
    this.props.removeLocationFromContent(this.props.currentLocation.id, selectedContentIds);
  }

  onAddLocationsToContent() {
    const newLocationIds = Object.keys(this.props.selectedLocations);
    var locations = this.props.currentContent.locations.map(element => element._id);
    locations = locations.concat(newLocationIds);
    var newContent = { locations };

    this.props.updateContent(this.props.currentContent.id, newContent);
  }

  onRemoveLocationsFromContent() {
    const removableLocationIds = Object.keys(this.props.selectedLocations);
    const locations = this.props.currentContent.locations.filter(element => !removableLocationIds.includes(element._id)).map(element => element._id);
    var newContent = { locations };

    this.props.updateContent(this.props.currentContent.id, newContent);
  }

  onAddContentToUserGroup() {
    const newContentIds = Object.keys(this.props.selectedContent);
    var applications = this.props.currentUserGroup.content.map(element => element._id);
    applications = applications.concat(newContentIds);
    var newUserGroup = { applications };

    this.props.updateUserGroup(this.props.currentUserGroup.id, newUserGroup);
  }

  onRemoveContentFromUserGroup() {
    const removableContentIds = Object.keys(this.props.selectedContent);
    const applications = this.props.currentUserGroup.content.filter(element => !removableContentIds.includes(element._id)).map(element => element._id);
    var newUserGroup = { applications };

    this.props.updateUserGroup(this.props.currentUserGroup.id, newUserGroup);
  }
  
  onAddLocationsToUserGroup() {
    const newLocationIds = Object.keys(this.props.selectedLocations);
    var locations = this.props.currentUserGroup.locations.map(element => element._id);
    locations = locations.concat(newLocationIds);
    var newUserGroup = { locations };

    this.props.updateUserGroup(this.props.currentUserGroup.id, newUserGroup);
  }

  onRemoveLocationsFromUserGroup() {
    const removableLocationIds = Object.keys(this.props.selectedLocations);
    const locations = this.props.currentUserGroup.locations.filter(element => !removableLocationIds.includes(element._id)).map(element => element._id);
    var newUserGroup = { locations };

    this.props.updateUserGroup(this.props.currentUserGroup.id, newUserGroup);
  }

  onUpdateUserGroup() {
    this.props.updateUserGroup(this.props.currentUserGroup.id, this.props.currentUserGroup.draft);
  }

  onAddLocationToUserGroups() {
    const selectedUserGroupsIds = Object.keys(this.props.selectedUserGroups);
    this.props.addLocationToUserGroups(this.props.currentLocation.id, selectedUserGroupsIds);
    // this.props.addLocationToUserGroups(this.props.currentLocation.id, this.props.selectedUserGroups);
  }

  onRemoveLocationFromUserGroups() {
    const selectedUserGroupsIds = Object.keys(this.props.selectedUserGroups);
    this.props.removeLocationFromUserGroups(this.props.currentLocation.id, selectedUserGroupsIds);
    // this.props.removeLocationFromUserGroups(this.props.currentLocation.id, this.props.selectedUserGroups);
  }

  onAddUsersToUserGroup() {
    const userIds = Object.keys(this.props.selectedUsers);
    this.props.addUsersToUserGroup(this.props.currentUserGroup.id, userIds);
  }
  
  onRemoveUsersFromUserGroup() {
    const userIds = Object.keys(this.props.selectedUsers);
    this.props.deleteUsersFromUserGroup(this.props.currentUserGroup.id, userIds);
  }

  onAddUserToUserGroups() {
    const selectedUserGroupsIds = Object.keys(this.props.selectedUserGroups);
    this.props.addUserGroupsToUser(this.props.currentUser.id, selectedUserGroupsIds);
  }

  onRemoveUserFromUserGroups() {
    const selectedUserGroupsIds = Object.keys(this.props.selectedUserGroups);
    this.props.removeUserGroupsFromUser(this.props.currentUser.id, selectedUserGroupsIds);
  }

  onGrantRoleToUser() {
    // const userIds = Object.keys(this.props.selectedUsers);
    const userId = this.props.currentUser.id;
    const role = this.props.currentUser.draft.role;

    var path = this.props.location.pathname;
    const inUsersView = path.includes("/org/users");
    const inSupportUsersView = path.includes("/org/support");
    const inAdminsView = path.includes("/org/admins");

    this.props.grantRoleToUsers([userId], role, inUsersView, inAdminsView, inSupportUsersView);
  }

  onDeleteInvite() {
    const inviteId = Object.keys(this.props.selectedInvites)[0];
    this.props.deleteInvite(inviteId);
  }

  onDeleteUser() {
    const userId = Object.keys(this.props.selectedUsers)[0];
    this.props.deleteUser(userId);
  }

  onDeleteUserGroups() {
    const userGroupId = Object.keys(this.props.selectedUserGroups)[0];
    this.props.deleteUserGroup(userGroupId);
  }

  onAccessLevelSelectionChange(event) {
    this.setState({ accessLevel: event.target.value });
  }

  onSaveCustomTag() {
    let updatedTag = { ...this.props.currentCustomTag, ...this.props.currentCustomTagDraft };

    const selectedLocationIds = Object.keys(this.props.selectedLocations);
    const oldLocationIds = updatedTag.locations ? updatedTag.locations.map(l => l.id) : [];
    if (selectedLocationIds.length > 0) {
      const newLocationIds = [...new Set([...oldLocationIds, ...selectedLocationIds])];
      updatedTag.locations = newLocationIds;
    }
    else {
      updatedTag.locations = oldLocationIds;
    }

    if (updatedTag.description === null) {
      delete updatedTag.description;
    }

    var customTagId = updatedTag.id;
    delete updatedTag.id;
    
    this.props.updateCustomTag(customTagId, updatedTag);
  }

  onAddCustomTagsToLocation() {
    const selectedCustomTags = Object.keys(this.props.selectedCustomTags);
    const newCustomTagsIds = [...new Set([...this.props.currentLocation.customTags, ...selectedCustomTags])];
    
    const body = {
      customTags: newCustomTagsIds
    }

    this.props.editLocation(this.props.currentLocation, body);
  }

  onRemoveCustomTagsFromLocation() {
    const selectedCustomTags = Object.keys(this.props.selectedCustomTags);
    const newCustomTagsIds = this.props.currentLocation.customTags.filter(tag => !selectedCustomTags.includes(tag.id)).map(tag => tag.id);

    const body = {
      customTags: newCustomTagsIds
    }

    this.props.editLocation(this.props.currentLocation, body);
  }

  onDeleteCustomTagLocations() {
    let updatedTag = { ...this.props.currentCustomTag, ...this.props.currentCustomTagDraft };

    const selectedLocationIds = Object.keys(this.props.selectedLocations);
    const oldLocationIds = updatedTag.locations ? updatedTag.locations.map(l => l.id) : [];
    if (selectedLocationIds.length > 0) {
      updatedTag.locations = oldLocationIds.filter(id => !selectedLocationIds.includes(id));
    }
    else {
      updatedTag.locations = oldLocationIds;
    }

    if (updatedTag.description === null) {
      delete updatedTag.description;
    }

    var customTagId = updatedTag.id;
    delete updatedTag.id;
    
    this.props.updateCustomTag(customTagId, updatedTag);
  }

  onMergeCustomTags() {
    
  }

  onDeleteCustomTags() {
    const customTagIds = Object.keys(this.props.selectedCustomTags);
    this.props.deleteCustomTags(customTagIds);
  }

  onAddAtomicSensorsToLocation() {
    const atomicSensorIds = Object.keys(this.props.selectedAtomicSensors);
    const newAtomicSensorIds = [...new Set([...this.props.currentLocation.atomicSensors, ...atomicSensorIds])];

    const body = {
      atomicSensors: newAtomicSensorIds
    }
    
    this.props.editLocation(this.props.currentLocation, body);
    this.setState({ showAddAtomicSensorModal: false });
  }

  onRemoveAtomicSensorsFromLocation() {
    const atomicSensorIds = Object.keys(this.props.selectedAtomicSensors);
    const newAtomicSensorIds = this.props.currentLocation.atomicSensors.filter(asid => !atomicSensorIds.includes(asid));

    const body = {
      atomicSensors: newAtomicSensorIds
    }

    this.props.editLocation(this.props.currentLocation, body);
    this.setState({ showRemoveAtomicSensorModal: false });
  }

  onAddLocationsToAtomicSensor() {
    const locationIds = Object.keys(this.props.selectedLocations);

    // Get sensorId and atomicSensorId from URL safely (.../companies/5c6e52c783bd8000084972da/sensors/5e8585a12c97a400067e3198/atomic-sensors/6eaa85a13c97a423467e3191/locations)
    const { sensorId, atomicSensorId } = getSensorIdAndAtomicSensorIdBasedOnURL();
    const atomicSensor = this.props.atomicSensors.find(s => s._id === atomicSensorId);

    if (isEmpty(atomicSensor)) {
      return;
    }

    let allLocationIds = [...new Set([...atomicSensor.locations.map(l=>l._id), ...locationIds])];
    const body = { locations: allLocationIds };    
    this.props.updateAtomicSensor(sensorId, atomicSensorId, body)
  }

  onRemoveLocationsFromAtomicSensor() {
    const locationIds = Object.keys(this.props.selectedLocations);

    // Get sensorId and atomicSensorId from URL safely (.../companies/5c6e52c783bd8000084972da/sensors/5e8585a12c97a400067e3198/atomic-sensors/6eaa85a13c97a423467e3191/locations)
    const { sensorId, atomicSensorId } = getSensorIdAndAtomicSensorIdBasedOnURL();
    const atomicSensor = this.props.atomicSensors.find(s => s._id === atomicSensorId);

    if (isEmpty(atomicSensor)) {
      return;
    }

    let allLocationIds = atomicSensor.locations.map(l=>l._id).filter(lid => !locationIds.includes(lid));
    const body = { locations: allLocationIds };    
    this.props.updateAtomicSensor(sensorId, atomicSensorId, body)
  }

  onCreateUnregisteredSensor() {
    const unregisteredSensors = Object.keys(this.props.selectedUnregisteredSensors);
    
    this.props.createUnregisteredSensors(unregisteredSensors, this.props.history);
  }

  onEditMultipleUnregisteredSensors() {
    this.props.history.push({ pathname: `/companies/${this.props.selectedCompany._id}/installation/unregistered-sensors/edit-multiple-sensors` });
  }

  onFindLocationForUnregisteredSensors() {
    const unregisteredSensors = Object.keys(this.props.selectedUnregisteredSensors);
    console.log("unregisteredSensors", unregisteredSensors);
    
    if (unregisteredSensors.length === 0) {
      return;
    }

    const sensorId = unregisteredSensors[0];
    const sensor = this.props.selectedUnregisteredSensors[sensorId];

    console.log("sensor", sensor);

    if (sensor.locationId) {
      this.props.history.push(`/companies/${this.props.selectedCompany._id}/locations/${sensor.locationId}/locations`);
    }
    else {
      this.props.history.push(`/companies/${this.props.selectedCompany._id}/locations/root/locations`);
    }
  }

  onMoveSensorsToGateway() {
    const sensorIds = Object.keys(this.props.selectedSensors);
    const gatewayId = this.props.currentGateway.id;
    this.props.moveSensorsToGateway(sensorIds, gatewayId);
  }

  onCancel() {
    this.props.clearSelection();
  }

  getHoverText() {
    if (this.props.currentLocation.id === undefined) {
      return "Can't move location into sensors";
    }
    
    if (this.viewingSelectedGroup() || this.viewingSubGroupOfSelectedGroup()) {
      return "Can't move location into itself";
    }

    if (this.viewingParentGroup()) {
      return "A location is already in this location";
    }

    return "";
  }

  viewingParentGroup() {
    return Object.keys(this.props.selectedLocations).find(selectionId => {
      const parents = get(this.props.selectedLocations[selectionId], "parents", []);
      if (isEmpty(parents) && this.props.currentLocation.id === "root") {
        return true;
      }
      return parents.find(parentId => (parentId === this.props.currentLocation.id)) !== undefined;
    }) !== undefined;
  }

  viewingSensorParentGroup() {
    return Object.keys(this.props.selectedSensors).find(selectionId => {
      const parents = get(this.props.selectedSensors[selectionId], "properties.metadata.locationIds", []);
      return parents.find(parentId => (parentId === this.props.currentLocation.id)) !== undefined;
    }) !== undefined;
  }

  viewingGatewayParentGroup() {
    return Object.keys(this.props.selectedGateways).find(selectionId => {
      // console.log("this.props.selectedGateways[selectionId]", this.props.selectedGateways[selectionId]);
      const parents = get(this.props.selectedGateways[selectionId], "properties.locationIds", []);
      return parents.find(parentId => (parentId === this.props.currentLocation.id)) !== undefined;
    }) !== undefined;
  }

  viewingSelectedGroup() {
    return this.props.selectedLocations[this.props.currentLocation.id] !== undefined;
  }

  viewingSubGroupOfSelectedGroup() {

    // On the top level
    if (this.props.currentLocation.breadcrumbs === undefined) {
      return false;
    }

    // Find out if a selected group is a child of a group in the bread crumbs
    return this.props.currentLocation.breadcrumbs.some((crumb) => (
      this.props.selectedLocations[crumb.id || crumb._id] !== undefined
    ));
  }

  viewingRootWithSensorsSelected() {
    return this.props.currentLocation.breadcrumbs === undefined && !isEmpty(this.props.selectedSensors);
  }

  render() {
    // console.log("SelectionFooter.render", this.props);

    const isLoading = this.props.isLoadingLocations || 
      this.props.isLoadingSensors || 
      this.props.isLoadingLocation ||
      this.props.isLoadingSensor ||
      this.props.isLoadingGateways ||
      this.props.isLoadingLocationTags ||
      this.props.isLoadingSensorTags ||
      this.props.isLoadingContent ||
      this.props.isLoadingUsers ||
      this.props.isLoadingAdmins ||
      this.props.isLoadingSupportUsers ||
      this.props.isLoadingInvites ||
      this.props.isLoadingCustomTags ||
      this.props.isLoadingUserGroup ||
      this.props.isLoadingUserGroups ||
      this.props.isLoadingUnregisteredSensors;

    // Trim company id from path
    var path = this.props.location.pathname;
    if (path.indexOf("companies") !== -1) {
      path = path.replace("/companies/", "");
      path = path.substring(path.indexOf("/"));
    }

    const inMainLocationsViews = path.startsWith("/locations");
    const inNewLocationView = path.includes("/new-location");
    const inEditLocationView = path.includes("/edit-location");
    const inNewSensorView = path.includes("/new-hono-sensor") || path.includes("/new-yanzi-sensor") || path.includes("/new-webex-sensor");
    const inNewGatewayView = path.includes("/new-gateway");
    const inNewMeshNodeView = path.includes("/new-mesh-node");
    const inNewLocationTagView = path.includes("/new-location-tag");
    const inNewSensorTagView = path.includes("/new-sensor-tag");
    const inGatewayView = path.includes("/gateways/");
    const inDashboardView = path.includes("/history") || path.includes("/home");
    const inLocationsView = (path.endsWith("/locations/") || path.endsWith("/locations")) && !path.includes("/content") && !path.includes("/org/groups") && !path.includes("/atomic-sensors");
    const inLocationsListView = (path.endsWith("/locations/") || path.endsWith("/locations")) && !path.includes("/atomic-sensors");
    const inEditMapView = path.includes("/edit-map");
    const inContentView = path.includes("/content") && !path.includes("/org/groups") && !path.includes("/locations/");
    const inLocationContentView = path.includes("/content") && path.includes("/locations/");
    const inLocationCustomTagsView = path.includes("/custom-tags") && path.includes("/locations/");
    const inGroupsView = path.includes("/org/groups");
    const inUsersView = path.includes("/org/users");
    const inSupportUsersView = path.includes("/org/support");
    const inAdminsView = path.includes("/org/admins");
    const inGroupContentView = path.includes("/org/groups") && path.endsWith("/content");
    const inInstallationSensorsView = path.includes("/installation/sensors");
    const inInstallationGatewaysView = path.includes("/installation/gateways");
    const inInstallationSensorTagsView = path.includes("/installation/sensor-tags");
    const inInstallationJobsView = path.includes("/installation/job-groups");
    const inInstallationUnregisteredView = path.includes("/installation/unregistered-sensors");
    const inAtomicSensorsViews = path.includes("/atomic-sensors");
    const inAtomicSensorView = path.includes("/atomic-sensors/") && (path.endsWith("/locations") || path.endsWith("/samples"));
    const inServiceAccountsView = path.includes("/account/service-accounts");

    const sensorIds = Object.keys(this.props.selectedSensors);
    const atomicSensorIds = Object.keys(this.props.selectedAtomicSensors);
    const unregisteredSensorIds = Object.keys(this.props.selectedUnregisteredSensors);
    const locationIds = Object.keys(this.props.selectedLocations);
    const gatewayIds = Object.keys(this.props.selectedGateways);
    const locationTagIds = Object.keys(this.props.selectedLocationTags);
    const sensorTagIds = Object.keys(this.props.selectedSensorTags);
    const companyIds = Object.keys(this.props.selectedCompanies);
    const editedFeatureIds = Object.keys(this.props.editedFeatures);
    const contentIds = Object.keys(this.props.selectedContent);
    const userGroupIds = Object.keys(this.props.selectedUserGroups);
    const userIds = Object.keys(this.props.selectedUsers);
    const inviteIds = Object.keys(this.props.selectedInvites);
    const customTagIds = Object.keys(this.props.selectedCustomTags);
    let featureIds = Object.keys(this.props.selectedFeatures);

    // Only count features as selected if in edit map view or map mode is connect
    const isConnectingToMap = path.startsWith("/locations") && this.props.viewMapMode === MapMode.connect;
    const isEditingMap = path.startsWith("/edit-map");
    if (!isConnectingToMap && !isEditingMap) {
      featureIds = [];
    }

    const noSelection = isEmpty(sensorIds) && isEmpty(atomicSensorIds) && isEmpty(unregisteredSensorIds) && isEmpty(locationIds) && isEmpty(gatewayIds) && isEmpty(locationTagIds) && isEmpty(sensorTagIds) && isEmpty(companyIds) && isEmpty(featureIds) && isEmpty(editedFeatureIds) && isEmpty(contentIds) && isEmpty(userGroupIds) && isEmpty(userIds) && isEmpty(inviteIds) && isEmpty(customTagIds);

    const oneSensor = Object.keys(this.props.selectedSensors).length === 1;
    const oneLocation = Object.keys(this.props.selectedLocations).length === 1;
    const oneGateway = Object.keys(this.props.selectedGateways).length === 1;

    const canMoveLocation = this.props.auth.hasSupportRole;
    const canViewLocation = this.props.auth.hasAdminRole;
    const canDeleteLocation = this.props.auth.hasSupportRole;
    const canUpdateLocation = this.props.auth.hasSupportRole;
    const canUpdateSensor = this.props.auth.hasSupportRole;
    const canDeleteSensor = this.props.auth.hasSupportRole;
    const canUpdateGateway = this.props.auth.hasSupportRole;
    const canDemoteUser = this.props.auth.hasITAdminRole;
    const canInviteUser = this.props.auth.hasITAdminRole;
    const canCreateCustomTag = this.props.auth.hasAdminRole;
    const canUpdateCustomTag = this.props.auth.hasAdminRole;
    const canDeleteCustomTag = this.props.auth.hasAdminRole;

    // Check if user can update some groups also
    let canUpdateGroups = this.props.auth.hasAdminRole;

    // Check if user can update some content also
    let canUpdateContent = this.props.auth.hasAdminRole;
  
    // Hide footer if in new location creation or new sensor creation or new gateway creation
    if (inNewLocationView || inNewSensorView || inNewGatewayView || inNewMeshNodeView || inNewLocationTagView || inNewSensorTagView || inEditLocationView) {
      return null;
    }

    // Hide footer if in a dashboard view
    if (inDashboardView) {
      return null;
    }
    
    if (inMainLocationsViews && 
      sensorIds.length === 0 && 
      atomicSensorIds.length === 0 && 
      locationIds.length === 0 && 
      gatewayIds.length === 0 && 
      locationTagIds.length === 0 && 
      sensorTagIds.length === 0 && 
      featureIds.length === 0 && 
      editedFeatureIds.length === 0 && 
      contentIds.length === 0 && 
      userGroupIds.length === 0 && 
      customTagIds.length === 0 &&
      unregisteredSensorIds.length === 0) {
      return null;
    }

    if (inInstallationSensorsView && sensorIds.length === 0) {
      return null;
    }

    if (inInstallationGatewaysView && gatewayIds.length === 0) {
      return null;
    }

    if (inInstallationSensorTagsView && sensorIds.length === 0 && sensorTagIds.length === 0) {
      return null;
    }

    if (inInstallationUnregisteredView && unregisteredSensorIds.length === 0) {
      return null;
    }

    if (inContentView && contentIds.length === 0 && locationIds.length === 0 && userGroupIds.length === 0 && isEmpty(this.props.currentContent.draft)) {
      return null;
    }

    if (inGroupsView && contentIds.length === 0 && locationIds.length === 0 && userGroupIds.length === 0 && userIds.length === 0 && isEmpty(this.props.currentUserGroup.draft)) {
      return null;
    }

    if ((inUsersView || inSupportUsersView || inAdminsView) && userIds.length === 0 && inviteIds.length === 0 && userGroupIds.length === 0 && isEmpty(this.props.currentUser.draft)) {
      return null;
    }

    if (inLocationCustomTagsView && !isEmpty(this.props.currentCustomTagDraft)) {
      return null;
    }

    // if (inServiceAccountsView && !isEmpty(this.props.currentServiceAccountDraft)) {
    //   return null;
    // }
    
    if (!inMainLocationsViews && 
        !inAtomicSensorsViews && 
        !inContentView && 
        !inUsersView && 
        !inSupportUsersView &&
        !inAdminsView &&
        !inGroupsView && 
        !inInstallationSensorsView && 
        !inInstallationGatewaysView && 
        !inInstallationSensorTagsView &&
        !inInstallationUnregisteredView &&
        !inGatewayView) {
      return null;
    }

    // If use has not selected sensors, do not show footer in gateway view
    if (inGatewayView && (!canUpdateSensor || JSON.stringify(this.props.allSelectedTypes) !== JSON.stringify(["sensor"]))) {
      return null;
    }

    const options = [];
    if (inServiceAccountsView) {
      if (contentIds.length > 0 && locationIds.length > 0 || 
          contentIds.length > 0 && userGroupIds.length > 0 ||
          locationIds.length > 0 && userGroupIds.length > 0) {
        // TODO: Add explaination to user that we cannot add multiple things
      }
      // else if (!isEmpty(this.props.currentServiceAccountDraft)) {

      // }
      else if (!isEmpty(this.props.currentServiceAccount.id)) {
        if (canViewLocation && locationIds.length > 0) {
          if (locationIds.every(id => this.props.currentServiceAccount.locations.find(location => location.id === id))) {
            options.push(
              <Button text="Remove location(s)" onClick={this.onRemoveLocationsFromServiceAccount} color="red" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_SERVICE_ACCOUNT]} />
            );
          }
          else if (!this.props.currentContent.locations.some(location => locationIds.includes(location._id))) {
            options.push(
              <Button text="Add location(s)" onClick={this.onAddLocationsToServiceAccount} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_SERVICE_ACCOUNT]} />
            );
          }
        }
        // else if (!isEmpty(this.props.currentServiceAccountDraft)) {
        //   options.push(
        //     <Button text="Save" onClick={this.onUpdateContent} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_SERVICE_ACCOUNT]} />
        //   );
        // }
      }
    }
    else if (inContentView) {
      if (contentIds.length > 0 && locationIds.length > 0 || 
          contentIds.length > 0 && userGroupIds.length > 0 ||
          locationIds.length > 0 && userGroupIds.length > 0) {
        // TODO: Add explaination to user that we cannot add multiple things
      }
      else if (canUpdateContent && contentIds.length > 0) {
        // Do not show any options here
      }
      else if (!isEmpty(this.props.currentContent.id)) {
        if (canViewLocation && locationIds.length > 0) {
          if (locationIds.every(id => this.props.currentContent.locations.find(location => location._id === id))) {
            options.push(
              <Button text="Remove" onClick={this.onRemoveLocationsFromContent} color="red" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_CONTENT]} />
            );
          }
          else if (!this.props.currentContent.locations.some(location => locationIds.includes(location._id))) {
            options.push(
              <Button text="Add" onClick={this.onAddLocationsToContent} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_CONTENT]} />
            );
          }
        }
        else if (canUpdateGroups && userGroupIds.length > 0) {
          if (userGroupIds.every(id => this.props.currentContent.userGroups.find(userGroup => userGroup._id === id))) {
            options.push(
              <Button text="Remove" onClick={this.onRemoveUserGroupsFromContent} color="red" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_CONTENT]} />
            );
          }
          else if (!this.props.currentContent.userGroups.some(userGroup => userGroupIds.includes(userGroup._id))) {
            options.push(
              <Button text="Add" onClick={this.onAddUserGroupsToContent} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_CONTENT]} />
            );
          }
        }
        else if (!isEmpty(this.props.currentContent.draft)) {
          options.push(
            <Button text="Save" onClick={this.onUpdateContent} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_CONTENT]} />
          );
        }
      }
    }
    // else if (canUpdateGroups && inGroupContentView) {
    //   if (contentIds.length > 0) {
    //     options.push(
    //       <Button text="Delete" onClick={this.onDeleteContent} color="red" disabled={isLoading} />
    //     );
    //   }
    // }
    // else if (canDemoteUser && inUsersView) {
    //   if (inviteIds.length === 1) {
    //     let user = this.props.selectedInvites[inviteIds[0]];
    //     if (user.inviteId !== undefined) {
    //       options.push(
    //         <Button text="Delete invite" onClick={this.onDeleteInvite} color="red" disabled={isLoading} isLoading={this.props.loading[API.DELETE_INVITE]} />
    //       );
    //     }
    //   }
    //   else if (userIds.length === 1) {
    //     let user = this.props.selectedUsers[userIds[0]];
    //     if (user.managedByScim === false) {
    //       options.push(
    //         <Button text="Delete user" onClick={this.onDeleteUser} color="red" disabled={isLoading} isLoading={this.props.loading[API.DELETE_USER]} />
    //       );
    //     }
    //   }
    // }
    // Add/remove content in User group
    else if (inGroupsView && contentIds.length > 0 && !isEmpty(this.props.currentUserGroup.id)) {
      if (canUpdateGroups) {
        if (contentIds.every(id => this.props.currentUserGroup.content.find(content => content._id === id))) {
          options.push(
            <Button text="Remove content" onClick={this.onRemoveContentFromUserGroup} color="red" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_USER_GROUP]} />
          );
        }
        else if (!this.props.currentUserGroup.content.some(content => contentIds.includes(content._id))) {
          options.push(
            <Button text="Add content" onClick={this.onAddContentToUserGroup} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_USER_GROUP]} />
          );
        }
      }
      else if (canUpdateContent) {
        if (this.props.currentUserGroup.content.some(content => contentIds.includes(content._id))) {
          options.push(
            <Button text="Remove content" onClick={this.onRemoveUserGroupFromSelectedContent} color="red" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_CONTENT]} />
          );
        }
        if (!contentIds.every(id => this.props.currentUserGroup.content.find(content => content._id === id))) {
          options.push(
            <Button text="Add content" onClick={this.onAddUserGroupToSelectedContent} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_CONTENT]} />
          );
        }
      }
    }
    // Add/remove content in Location
    else if (canUpdateContent && inMainLocationsViews && contentIds.length > 0 && !isEmpty(this.props.currentLocation.id)) {
      if (this.props.currentLocation.content.some(content => contentIds.includes(content._id))) {
        options.push(
          <Button text="Remove content" onClick={this.onRemoveLocationFromSelectedContent} color="red" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_CONTENT]} />
        );
      }
      if (!contentIds.every(id => this.props.currentLocation.content.find(content => content._id === id))) {
        options.push(
          <Button text="Add content" onClick={this.onAddLocationToSelectedContent} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_CONTENT]} />
        );
      }
    }
    else if (canUpdateGroups && inGroupsView) {
      if (contentIds.length > 0 && locationIds.length > 0 || 
          contentIds.length > 0 && userGroupIds.length > 0 ||
          locationIds.length > 0 && userGroupIds.length > 0) {
        // TODO: Add explaination to user that we cannot add multiple things
      }
      else if (userGroupIds.length > 0) {

        // Disable buttons if any selected user groups has !canDelete
        const cannotDelete = userGroupIds.some(userGroupId => !this.props.selectedUserGroups[userGroupId].canDelete);

        options.push(
          <Button text="Delete group(s)" onClick={this.onDeleteUserGroups} color="red" disabled={isLoading || cannotDelete} isLoading={this.props.loading[API.DELETE_USER_GROUP]} />
        );
      }
      else if (!isEmpty(this.props.currentUserGroup.id)) {
        if (canViewLocation && locationIds.length > 0) {
          if (locationIds.every(id => this.props.currentUserGroup.locations.find(location => location._id === id))) {
            options.push(
              <Button text="Remove location" onClick={this.onRemoveLocationsFromUserGroup} color="red" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_USER_GROUP]} />
            );
          }
          else if (!this.props.currentUserGroup.locations.some(location => locationIds.includes(location._id))) {
            options.push(
              <Button text="Add location" onClick={this.onAddLocationsToUserGroup} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_USER_GROUP]} />
            );
          }
        }
        else if (userIds.length > 0) {

          // Disable buttons if the current user group is everyone group
          const cannotRemove = this.props.currentUserGroup.isEveryoneGroup;

          if (userIds.every(id => this.props.currentUserGroup.users.find(user => user._id === id))) {
            options.push(
              <Button text="Remove from group" onClick={this.onRemoveUsersFromUserGroup} color="red" disabled={isLoading || !this.props.currentUserGroup.canEditMembers || cannotRemove} isLoading={this.props.loading[API.DELETE_USER_GROUP_USERS]} />
            );
          }
          else {
            options.push(
              <Button text="Add to group" onClick={this.onAddUsersToUserGroup} color="blue" disabled={isLoading || !this.props.currentUserGroup.canEditMembers} isLoading={this.props.loading[API.ADD_USER_GROUP_USERS]} />
            );
          }
        }
        else if (!isEmpty(this.props.currentUserGroup.draft)) {
          options.push(
            <Button text="Cancel" white onClick={this.onCancel} color="clear" />
          );
          options.push(
            <Button text="Save" onClick={this.onUpdateUserGroup} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_USER_GROUP]} />
          );
        }
      }
    }

    // Add/remove User groups from User
    else if ((inUsersView || inAdminsView || inSupportUsersView) && !isEmpty(this.props.currentUser.id) && userGroupIds.length > 0 && userIds.length === 0) {

      // Disable buttons if any selected user groups is managedByScim or isEveryoneGroup
      const includeScimGroup = userGroupIds.some(userGroupId => this.props.currentUser.userGroups.find(ug => ug.id === userGroupId)?.managedByScim);
      const cannotRemove = userGroupIds.some(userGroupId => this.props.currentUser.userGroups.find(ug => ug.id === userGroupId)?.isEveryoneGroup);

      if (userGroupIds.length > 1) {
        if (this.props.currentUser.userGroups.some(usergroup => userGroupIds.includes(usergroup.id))) {
          options.push(
            <Button text="Remove groups" onClick={this.onRemoveUserFromUserGroups} color="red" disabled={isLoading || includeScimGroup || cannotRemove} isLoading={this.props.loading[API.UPDATE_USER_GROUP]} />
          );
        }
        if (!userGroupIds.every(id => this.props.currentUser.userGroups.find(usergroup => usergroup.id === id))) {
          options.push(
            <Button text="Add groups" onClick={this.onAddUserToUserGroups} color="blue" disabled={isLoading || includeScimGroup} isLoading={this.props.loading[API.UPDATE_USER_GROUP]} />
          );
        }
      }
      else {
        if (this.props.currentUser.userGroups.some(usergroup => userGroupIds.includes(usergroup.id))) {
          options.push(
            <Button text="Remove group" onClick={this.onRemoveUserFromUserGroups} color="red" disabled={isLoading || includeScimGroup || cannotRemove} isLoading={this.props.loading[API.UPDATE_USER_GROUP]} />
          );
        }
        if (!userGroupIds.every(id => this.props.currentUser.userGroups.find(usergroup => usergroup.id === id))) {
          options.push(
            <Button text="Add group" onClick={this.onAddUserToUserGroups} color="blue" disabled={isLoading || includeScimGroup} isLoading={this.props.loading[API.UPDATE_USER_GROUP]} />
          );
        }
      }
    }

    else if ((inUsersView || inAdminsView || inSupportUsersView) && !isEmpty(this.props.currentUser.id) && userGroupIds.length === 0 && userIds.length === 0) {
      if (!isEmpty(this.props.currentUser.draft)) {
        options.push(
          <Button text="Cancel" white onClick={this.onCancel} color="clear" />
        );
        options.push(
          <Button text="Save" onClick={this.onGrantRoleToUser} color="blue" disabled={isLoading} isLoading={this.props.loading[API.GRANT_ROLE]} />
        );
      }
    }

    // Add/remove User groups from Location
    else if (canUpdateGroups && inMainLocationsViews && !isEmpty(this.props.currentLocation.id) && this.props.currentLocation.id !== "root" && userGroupIds.length > 0 && locationIds.length === 0) {
      if (userGroupIds.length > 1) {
        if (this.props.currentLocation.usergroups.some(usergroup => userGroupIds.includes(usergroup._id))) {
          options.push(
            <Button text="Remove groups" onClick={this.onRemoveLocationFromUserGroups} color="red" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_USER_GROUP]} />
          );
        }
        if (!userGroupIds.every(id => this.props.currentLocation.usergroups.find(usergroup => usergroup._id === id))) {
          options.push(
            <Button text="Add groups" onClick={this.onAddLocationToUserGroups} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_USER_GROUP]} />
          );
        }
      }
      else {
        if (this.props.currentLocation.usergroups.some(usergroup => userGroupIds.includes(usergroup._id))) {
          options.push(
            <Button text="Remove group" onClick={this.onRemoveLocationFromUserGroups} color="red" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_USER_GROUP]} />
          );
        }
        if (!userGroupIds.every(id => this.props.currentLocation.usergroups.find(usergroup => usergroup._id === id))) {
          options.push(
            <Button text="Add group" onClick={this.onAddLocationToUserGroups} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_USER_GROUP]} />
          );
        }
      }
    }
    else if (inEditMapView) {
      return null;
      // if (this.props.currentLocation.id === "root") {
      //   // console.log("canUpdateLocation && inEditMapView && root");
      //   if (this.props.createdFeature !== null) {
      //     options.push(
      //       <Button text="Save created feature" onClick={this.onSaveCreatedCompanyFeature} disabled={isLoading} isLoading={this.props.loading[API.REGISTER_COMPANY_FEATURE]} />
      //     );
      //   }
      //   else if (editedFeatureIds.length > 0) {
      //     options.push(
      //       <Button text="Save edited features" onClick={this.onSaveEditedCompanyMap} disabled={isLoading} isLoading={this.props.loading[API.UPDATE_FEATURES]} />
      //     );
      //   }
        
      //   // Allow delete features even if other features has been edited (moved out of the way)
      //   if (featureIds.length > 0) {
      //     options.push(
      //       <Button text="Delete features" onClick={this.onDeleteCompanyFeatures} color="red" disabled={isLoading} isLoading={this.props.loading[API.DELETE_FEATURES]} />
      //     );
      //   }
      // }
      // else {
      //   // console.log("canUpdateLocation && inEditMapView");
      //   if (this.props.createdFeature !== null) {
      //     options.push(
      //       <Button text="Save created location" onClick={this.onSaveCreatedFeature} disabled={isLoading} isLoading={this.props.loading[API.EDIT_LOCATION] || this.props.loading[API.REGISTER_COMPANY_FEATURE]} />
      //     );
      //   }
      //   else if (editedFeatureIds.length > 0) {
      //     options.push(
      //       <Button text="Save edited map" onClick={this.onSaveEditedMap} disabled={isLoading} isLoading={this.props.loading[API.UPDATE_FEATURES]} />
      //     );
      //   }
      //   else if (oneLocation) {
      //     const locationId = locationIds[0];
      //     const geoJsonFeatureId = get(this.props.selectedLocations, `[${locationId}].geoJsonFeature._id`, null);
      //     const locationType = get(this.props.selectedLocations, `[${locationId}].type`, "");
      //     const canConnectLocationTypeToMap = (locationType !== "region" && locationType !== "floor");

      //     // if (this.props.createdFeature !== null) {
      //     //   options.push(
      //     //     <Button text="Save created location" onClick={this.onSaveCreatedFeature} disabled={isLoading} />
      //     //   );
      //     // }
      //     // else 
      //     if (geoJsonFeatureId) {
      //       options.push(
      //         <Button text="Disconnect from map" onClick={this.onDisconnectLocationsFromFeatures} color="red" disabled={isLoading} isLoading={this.props.loading[API.REMOVE_FEATURES_FROM_LOCATIONS]} />
      //       );
      //     }
      //     else if (featureIds.length === 1 && canConnectLocationTypeToMap) {
      //       options.push(
      //         <Button text="Connect to map" onClick={this.onConnectLocationToFeature} disabled={isLoading} isLoading={this.props.loading[API.MAP_FEATURE_TO_LOCATION]} />
      //       );
      //     }
      //     // else {
      //     //   options.push(
      //     //     <Button text="Create location in map" onClick={this.onCreateLocationInMap} disabled={isLoading || this.props.mapMode === "draw_rectangle"} />
      //     //   );
      //     // }
      //   }
      //   else if (canUpdateSensor && oneSensor && featureIds.length === 0) {
      //     options.push(
      //       <Button text="Place in map" onClick={this.onPlaceSensorInMap} disabled={isLoading || this.props.mapMode === "draw_point"} />
      //     );
      //   }
      //   else if (canUpdateSensor && oneSensor && featureIds.length === 1) {
      //     options.push(
      //       <Button text="Save sensor at point" onClick={this.onSaveSensorToPoint} disabled={isLoading} isLoading={this.props.loading[API.UPDATE_SENSOR]} />
      //     );
      //   }
      //   else if (canUpdateGateway && oneGateway && featureIds.length === 0) {
      //     options.push(
      //       <Button text="Place in map" onClick={this.onPlaceGatewayInMap} disabled={isLoading || this.props.mapMode === "draw_point"} />
      //     );
      //   }
      //   else if (canUpdateGateway && oneGateway && featureIds.length === 1) {
      //     options.push(
      //       <Button text="Save gateway at point" onClick={this.onSaveGatewayToPoint} disabled={isLoading} isLoading={this.props.loading[API.UPDATE_GATEWAY]} />
      //     );
      //   }
        
      //   // Allow delete features even if other features has been edited (moved out of the way)
      //   // if (featureIds.length > 0) {
      //   //   options.push(
      //   //     <Button text="Delete features" onClick={this.onDeleteFeatures} color="red" disabled={isLoading} isLoading={this.props.loading[API.DELETE_FEATURES]} />
      //   //   );
      //   // }
      // }
    }
    else if (canUpdateLocation && inLocationsListView && locationIds.length > 0) {
      const locationId = locationIds[0];
      const geoJsonFeatureId = get(this.props.selectedLocations, `[${locationId}].geoJsonFeature._id`, null);
      const locationType = get(this.props.selectedLocations, `[${locationId}].type`, "");
      const canConnectLocationTypeToMap = (locationType !== "region"); //  && locationType !== "floor"
      
      let singleSelectedPolygon = null;
      if (this.props.viewMapMode === MapMode.connect && this.props.selectedFeatures.length > 0) {
        const firstSelectedFeature = this.props.selectedFeatures[0];
        const firstSelectedFeatureType = firstSelectedFeature?.geometry?.type;
        if (firstSelectedFeatureType === "Polygon") {
          singleSelectedPolygon = firstSelectedFeature;
        }
        else if (firstSelectedFeatureType === "g") { // SVG location group
          singleSelectedPolygon = firstSelectedFeature;
        }
      }

      if (oneLocation && canConnectLocationTypeToMap) {
        if (this.props.createdFeature !== null) {
          // console.log("canUpdateLocation  && inLocationsListView && locationIds.length > 0 && oneLocation && this.props.createdFeature !== null");
          options.push(
            <Button text="Save created location" onClick={this.onSaveCreatedFeature} disabled={isLoading} isLoading={this.props.loading[API.EDIT_LOCATION] || this.props.loading[API.REGISTER_COMPANY_FEATURE]} />
          );
        }
        else if (!geoJsonFeatureId) {
          options.push(
            <Button text="Connect to map" onClick={this.onConnectLocationToFeature} disabled={isLoading || !singleSelectedPolygon} isLoading={this.props.loading[API.MAP_FEATURE_TO_LOCATION]} />
          );
        }
        else {
          options.push(
            <Button text="Disconnect from map" onClick={this.onDisconnectLocationsFromFeatures} color="red" disabled={isLoading} isLoading={this.props.loading[API.REMOVE_FEATURES_FROM_LOCATIONS]} />
          );
        }
      }
    }
    // Manage custom tags when in root location
    else if (canUpdateCustomTag && inMainLocationsViews && !isEmpty(this.props.currentLocation.id) && this.props.currentLocation.id === "root") {
      if (customTagIds.length > 0) {
        options.push(
          <Button text="Delete tag(s)" onClick={this.onDeleteCustomTags} color="red" disabled={isLoading} isLoading={this.props.loading[API.DELETE_CUSTOM_TAG]} />
        );
      }
      else if (!isEmpty(this.props.currentCustomTag) && locationIds.length > 0) {
        if (this.props.currentCustomTag.locations && this.props.currentCustomTag.locations.find(location => locationIds.includes(location.id))) {
          options.push(
            <Button text="Remove location(s)" onClick={this.onDeleteCustomTagLocations} color="red" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_CUSTOM_TAG]} />
          );
        }
        else {
          options.push(
            <Button text="Add location(s)" onClick={this.onSaveCustomTag} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_CUSTOM_TAG]} />
          );
        }
      }
    }
    // Manage custom tags when in other locations
    else if (canUpdateCustomTag && inLocationsListView && customTagIds.length > 0) {
      let locationHasACustomTag = false;
      let locationIsMissingACustomTag = this.props.currentLocation.customTags.length > 0 ? false : true;

      // Check if the current location has a custom tag
      for (let i = 0; i < this.props.currentLocation.customTags.length; i++) {
        if (customTagIds.includes(this.props.currentLocation.customTags[i].id)) {
          locationHasACustomTag = true;
          break;
        }
      }
      
      // Check if the current location is missing a custom tag
      for (let i = 0; i < customTagIds.length; i++) {
        if (!this.props.currentLocation.customTags.find(tag => tag.id === customTagIds[i])) {
          locationIsMissingACustomTag = true;
          break;
        }
      }

      if (locationHasACustomTag) {
        options.push(
          <Button text="Remove custom tag(s)" onClick={this.onRemoveCustomTagsFromLocation} color="red" disabled={isLoading} isLoading={this.props.loading[API.EDIT_LOCATION]} />
        );
      }

      if (locationIsMissingACustomTag) {
        options.push(
          <Button text="Add custom tag(s)" onClick={this.onAddCustomTagsToLocation} color="blue" disabled={isLoading} isLoading={this.props.loading[API.EDIT_LOCATION]} />
        );
      }
    }
    else if (this.props.currentLocation.id === "root") {
      // If one sensor that can be deleted or unassigned
      if (get(this.props.user, "isSudo", false) && sensorIds.length === 1 && gatewayIds.length === 0 && locationIds.length === 0) {
        options.push(<Button text="Delete" onClick={this.onDeleteSensor} color="red" disabled={isLoading} isLoading={this.props.loading[API.DELETE_SENSOR]} />);
      }
    }
    else if (inMainLocationsViews && sensorIds.length > 0 && gatewayIds.length === 0 && locationIds.length === 0) {
      // console.log("currentLocation", this.props.currentLocation);
      if (canUpdateSensor && !isEmpty(this.props.currentLocation.id)) {
        options.push(
          <Button 
            text={`Assign to ${this.props.currentLocation.name}`}
            onClick={this.onMoveClicked} 
            hoverText={this.getHoverText()}
            disabled={
              isLoading || 
              this.props.currentLocation.id === undefined || 
              this.viewingSelectedGroup() || 
              this.viewingSubGroupOfSelectedGroup() || 
              this.viewingParentGroup() || 
              this.viewingSensorParentGroup() || 
              this.viewingGatewayParentGroup() || 
              this.viewingRootWithSensorsSelected()
            } 
            singleLine
            isLoading={this.props.loading[API.MOVE_LOCATIONS] || this.props.loading[API.MOVE_GATEWAYS]}
          />
        );
      }
      
      // If one sensor that can be deleted or unassigned
      if (sensorIds.length === 1) {
        if (canDeleteSensor) {
          options.push(<Button text="Delete" onClick={this.onDeleteSensor} color="red" disabled={isLoading} isLoading={this.props.loading[API.DELETE_SENSOR]} />);
        }
        
        if (canUpdateSensor && get(this.props.selectedSensors, `[${sensorIds[0]}].properties.metadata.locationIds[0]`, null)) {
          options.push(<Button text="Unassign" onClick={this.onRemoveSensors} color="red" disabled={isLoading} isLoading={this.props.loading[API.MOVE_SENSORS]} />);
        }
      }
    }
    else if (inGatewayView && JSON.stringify(this.props.allSelectedTypes) === JSON.stringify(["sensor"])) {
      if (canUpdateSensor) {

        let allSensorsAlreadyAssignedToGateway = false;
        let allSensorsAssignedToSameGatewayVendor = true;
        let allSensorsAssignedToHonoGateway = true;

        for (let i = 0; i < sensorIds.length; i++) {
          const sensor = this.props.selectedSensors[sensorIds[i]];

          // Check if sensor is of type Hono
          if (["yanzi", "webex"].includes(get(sensor, "properties.metadata.vendorInfo.vendor", "").toLowerCase())) {
            allSensorsAssignedToHonoGateway = false;
          }

          // Check if sensor vendor is the same as the gateway vendor
          if (get(sensor, "properties.metadata.vendorInfo.vendor", "").toLowerCase() !== get(this.props.currentGateway, "properties.vendorInfo.vendor", "").toLowerCase()) {
            allSensorsAssignedToSameGatewayVendor = false;
          }

          // Check if sensor is already assigned to the gateway
          // if (sensor.properties.metadata.gatewayIds && sensor.properties.metadata.gatewayIds.includes(this.props.currentGateway.id)) {
          //   allSensorsAlreadyAssignedToGateway = true;
          // }
        }

        options.push(<Button text="Move sensors" onClick={this.onMoveSensorsToGateway} disabled={isLoading || !allSensorsAssignedToHonoGateway || !allSensorsAssignedToSameGatewayVendor} isLoading={this.props.loading[API.MOVE_SENSORS_TO_GATEWAY]} />);
      }
    }
    else if (inMainLocationsViews && gatewayIds.length > 0 && sensorIds.length === 0 && locationIds.length === 0) {
      if (canUpdateGateway && !isEmpty(this.props.currentLocation.id)) {
        options.push(
          <Button 
            text={`Assign to ${this.props.currentLocation.name}`}
            onClick={this.onMoveClicked} 
            hoverText={this.getHoverText()}
            disabled={
              isLoading || 
              this.props.currentLocation.id === undefined || 
              this.viewingSelectedGroup() || 
              this.viewingSubGroupOfSelectedGroup() || 
              this.viewingParentGroup() || 
              this.viewingSensorParentGroup() || 
              this.viewingGatewayParentGroup() || 
              this.viewingRootWithSensorsSelected()
            } 
            singleLine
            isLoading={this.props.loading[API.MOVE_LOCATIONS] || this.props.loading[API.MOVE_GATEWAYS]}
          />
        );
      }
    }
    else if (inMainLocationsViews && canUpdateSensor && atomicSensorIds.length > 0) {

      let locationHasAnAtomicSensor = false;
      let locationIsMissingAnAtomicSensor = this.props.currentLocation.atomicSensors.length > 0 ? false : true;

      // Check if the current location has an atomic sensor
      for (let i = 0; i < this.props.currentLocation.atomicSensors.length; i++) {
        if (atomicSensorIds.includes(this.props.currentLocation.atomicSensors[i])) {
          locationHasAnAtomicSensor = true;
          break;
        }
      }
      
      // Check if the current location is missing an atomic sensor
      for (let i = 0; i < atomicSensorIds.length; i++) {
        if (!this.props.currentLocation.atomicSensors.includes(atomicSensorIds[i])) {
          locationIsMissingAnAtomicSensor = true;
          break;
        }
      }
      
      if (locationIsMissingAnAtomicSensor) {
        options.push(<Button text="Add atomic sensor(s)" onClick={() => this.setState({ showAddAtomicSensorModal: true })} color="blue" disabled={isLoading} isLoading={this.props.loading[API.EDIT_LOCATION]} />)
      }

      if (locationHasAnAtomicSensor) {
        options.push(<Button text="Remove atomic sensor(s)" onClick={() => this.setState({ showRemoveAtomicSensorModal: true })} color="red" disabled={isLoading} isLoading={this.props.loading[API.EDIT_LOCATION]} />);
      }
    }
    else if (inAtomicSensorView && canUpdateSensor && locationIds.length > 0) {

      const { atomicSensorId } = getSensorIdAndAtomicSensorIdBasedOnURL();
      const atomicSensor = this.props.atomicSensors.find(s => s._id === atomicSensorId);

      if (atomicSensor) {
        let atomicSensorHasALocation = false;
        let atomicSensorIsMissingALocation = atomicSensor.locations.length > 0 ? false : true;

        // Check if the current atomicSensor has a location
        for (let i = 0; i < atomicSensor.locations.length; i++) {
          if (locationIds.includes(atomicSensor.locations[i]._id)) {
            atomicSensorHasALocation = true;
            break;
          }
        }
        
        // Check if the current atomicSensor is missing a location
        for (let i = 0; i < locationIds.length; i++) {
          if (!atomicSensor.locations.map(l=>l._id).includes(locationIds[i])) {
            atomicSensorIsMissingALocation = true;
            break;
          }
        }
        
        if (atomicSensorIsMissingALocation) {
          options.push(<Button text="Add location(s)" onClick={this.onAddLocationsToAtomicSensor} color="blue" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_ATOMIC_SENSOR]} />);
        }

        if (atomicSensorHasALocation) {
          options.push(<Button text="Remove location(s)" onClick={this.onRemoveLocationsFromAtomicSensor} color="red" disabled={isLoading} isLoading={this.props.loading[API.UPDATE_ATOMIC_SENSOR]} />);
        }
      }
    }
    // else if (inMainLocationsViews && canUpdateSensor && unregisteredSensorIds.length > 0) {
    //   options.push(<Button text="Register sensor(s)" onClick={this.onCreateUnregisteredSensor} color="blue" disabled={isLoading} />);
    // }
    else if (inInstallationUnregisteredView && canUpdateSensor) {
      
      if (!isEmpty(this.props.unregisteredSensorDraft)) {
        options.push(<Button text="Undo changes" onClick={this.props.clearUnregisteredSensorDraft} color="white" />);
        options.push(<Button text="Save changes" onClick={() => this.props.updateUnregisteredSensors(this.props.unregisteredSensorDraft, this.props.selectedUnregisteredSensors)} color="blue" disabled={isLoading} isLoading={this.props.isLoadingUnregisteredSensors} />);
      }

      if (unregisteredSensorIds.length > 0) {
        const selectedUnregisteredSensorsArray = Object.keys(this.props.selectedUnregisteredSensors).map(id => this.props.selectedUnregisteredSensors[id]);
        const allUnregisteredSensorsCanRegister = selectedUnregisteredSensorsArray.every(sensor => sensor.vortoId && !isEmpty(sensor.locationIds));
        if (allUnregisteredSensorsCanRegister) {
          options.push(<Button text="Register sensor(s)" onClick={this.onCreateUnregisteredSensor} color="blue" disabled={isLoading} isLoading={this.props.isLoadingUnregisteredSensors} />);
        }
      }
    }
    
    // If you want to connect selected feature to current location
    const canConnectThisLocationTypeToMap = (this.props.currentLocation.type !== "root" && this.props.currentLocation.type !== "region"); // && this.props.currentLocation.type !== "floor"
    const onlySelectedOneFeature = featureIds.length > 0 && sensorIds.length === 0 && gatewayIds.length === 0 && locationIds.length === 0 && locationTagIds.length === 0 && sensorTagIds.length === 0;
    if (!inEditMapView && this.props.viewMapMode === MapMode.connect && onlySelectedOneFeature && canConnectThisLocationTypeToMap) {
      options.push(
        <Button text="Connect to map" onClick={this.onConnectThisLocationToFeature} disabled={isLoading} isLoading={this.props.loading[API.EDIT_LOCATION]} />
      );
    }

    // If one location tag that can be deleted is selected
    if (sensorIds.length === 0 && gatewayIds.length === 0 && locationIds.length === 0 && locationTagIds.length === 1 && sensorTagIds.length === 0) {
      options.push(<Button text="Delete" onClick={this.onDeleteLocationTag} color="red" disabled={isLoading} isLoading={this.props.loading[API.DELETE_LOCATION_TAG]} />);
    }

    // If one sensor tag that can be deleted is selected
    if (sensorIds.length === 0 && gatewayIds.length === 0 && locationIds.length === 0 && locationTagIds.length === 0 && sensorTagIds.length === 1) {
      options.push(<Button text="Delete" onClick={this.onDeleteSensorTag} color="red" disabled={isLoading} isLoading={this.props.loading[API.DELETE_SENSOR_TAG]} />);
    }

    if (canMoveLocation && inLocationsView &&
      sensorIds.length === 0 && gatewayIds.length === 0 && locationIds.length > 0 && 
      !this.viewingSelectedGroup() && 
      !this.viewingSubGroupOfSelectedGroup() && 
      !this.viewingParentGroup()) {
      options.push(
        <Button 
          text={`Move to ${this.props.currentLocation.name ?? "top"}`}
          onClick={this.onMoveClicked} 
          hoverText={this.getHoverText()}
          disabled={isLoading}
          singleLine
          isLoading={this.props.loading[API.MOVE_LOCATIONS] || this.props.loading[API.MOVE_GATEWAYS]}
        />
      );
    }

    let selectionList = [];
    
    // List selected locations
    if (!isEmpty(this.props.selectedLocations)) {
      selectionList.push(...Object.keys(this.props.selectedLocations).map((selectionId) => (
        <SelectionFooterCell id={selectionId} key={selectionId} text={this.props.selectedLocations[selectionId].name} onClick={() => this.onDeselectLocation(selectionId)} />
      )));
    }

    // List selected sensors
    if (!isEmpty(this.props.selectedSensors)) {
      selectionList.push(...Object.keys(this.props.selectedSensors).map((selectionId) => (
        <SelectionFooterCell key={`${selectionId}-cell`} id={selectionId} text={this.props.selectedSensors[selectionId].name ?? selectionId} color="green" onClick={() => this.onDeselectSensor(selectionId)} />
      )));
    }

    // List selected atomic sensors
    if (!isEmpty(this.props.selectedAtomicSensors)) {
      selectionList.push(...Object.keys(this.props.selectedAtomicSensors).map((selectionId) => (
        <SelectionFooterCell key={`${selectionId}-cell`} id={selectionId} text={this.props.selectedAtomicSensors[selectionId].displayName ?? this.props.selectedAtomicSensors[selectionId].type} color="green" onClick={() => this.onDeselectAtomicSensor(selectionId)} />
      )));
    }

    // List selected auto enrolled sensors
    if (!isEmpty(this.props.selectedUnregisteredSensors)) {
      selectionList.push(...Object.keys(this.props.selectedUnregisteredSensors).map((selectionId) => (
        <SelectionFooterCell key={`${selectionId}-cell`} id={selectionId} text={selectionId} color="green" onClick={() => this.onDeselectUnregisteredSensor(selectionId)} />
      )));
    }

    // List selected gateways
    if (!isEmpty(this.props.selectedGateways)) {
      selectionList.push(...Object.keys(this.props.selectedGateways).map((selectionId) => (
        <SelectionFooterCell key={selectionId} id={selectionId} text={this.props.selectedGateways[selectionId].name} color="purple" onClick={() => this.onDeselectGateway(selectionId)} />
      )));
    }

    // List selected location tags
    if (!isEmpty(this.props.selectedLocationTags)) {
      selectionList.push(...Object.keys(this.props.selectedLocationTags).map((selectionId) => (
        <SelectionFooterCell key={selectionId} id={selectionId} text={this.props.selectedLocationTags[selectionId].name} color="orange" onClick={() => this.onDeselectLocationTag(selectionId)} />
      )));
    }

    // List selected sensor tags
    if (!isEmpty(this.props.selectedSensorTags)) {
      selectionList.push(...Object.keys(this.props.selectedSensorTags).map((selectionId) => (
        <SelectionFooterCell key={selectionId} id={selectionId} text={this.props.selectedSensorTags[selectionId].name} color="orange" onClick={() => this.onDeselectSensorTag(selectionId)} />
      )));
    }

    // List selected content
    if (!isEmpty(this.props.selectedContent)) {
      selectionList.push(...Object.keys(this.props.selectedContent).map((selectionId) => (
        <SelectionFooterCell key={selectionId} id={selectionId} text={this.props.selectedContent[selectionId].title} color="blue" onClick={() => this.onDeselectContent(selectionId)} />
      )));
    }

    // List selected user groups
    if (!isEmpty(this.props.selectedUserGroups)) {
      selectionList.push(...Object.keys(this.props.selectedUserGroups).map((selectionId) => (
        <SelectionFooterCell key={selectionId} id={selectionId} text={this.props.selectedUserGroups[selectionId].name} color="green" onClick={() => this.onDeselectUserGroup(selectionId)} />
      )));
    }

    // List selected users (green)
    if (!isEmpty(this.props.selectedUsers)) {
      selectionList.push(...Object.keys(this.props.selectedUsers).map((selectionId) => (
        <SelectionFooterCell key={selectionId} id={selectionId} text={this.props.selectedUsers[selectionId].name || this.props.selectedUsers[selectionId].email || this.props.selectedUsers[selectionId].phone_number} color="green" onClick={() => this.onDeselectUser(selectionId)} />
      )));
    }

    // List selected custom tags
    if (!isEmpty(this.props.selectedCustomTags)) {
      selectionList.push(...Object.keys(this.props.selectedCustomTags).map((selectionId) => (
        <SelectionFooterCell key={selectionId} id={selectionId} text={this.props.selectedCustomTags[selectionId].name} color="purple" onClick={() => this.onDeselectCustomTag(selectionId)} />
      )));
    }

    // Do not show footer if no selection and no options
    if (noSelection && options.length === 0) {
      return null;
    }
    
    return (
      <div className={style.footer}>
        <div className={style.footerContent}>
          <div className={style.selections}>
            { selectionList }
          </div>
          <div className={style.buttons}>
            {
              !noSelection && (
                <div className={style.simple}>
                  <Button text="Cancel" white onClick={this.onCancel} color="clear" />
                </div>
              )
            }
            {
              canDeleteLocation && isEmpty(this.props.selectedSensors) && this.viewingParentGroup() && inLocationsView && (
                <div className={style.simple}>
                  <Button text="Delete" onClick={this.onDeleteLocations} color="red" disabled={isLoading} isLoading={this.props.loading[API.DELETE_LOCATIONS]} />
                </div>
              )
            }
            { 
              options.map((option, index) => (
                <div className={style.simple} key={`option-${index}`}>
                  { option }
                </div>
              ))
            }
          </div>
        </div>

        <Modal
          show={this.state.showMoveModal}
          onHide={() => this.setState({ showMoveModal: false })}
          title="Move the sample history?" 
          text="This will move all the sample history from the previous location to this new location. This is useful if the sensor was placed correctly in the real world, but incorrectly in the location hierarchy. Not moving the history will keep the old data in the previous location and let you start with a clean slate in this new location."
          // text="This will archive the sensor data history. Do this if you want to analyse sensor data starting from today at the new location. "
          primaryBtn={{
            text: "Move history",
            onClick: this.onMoveAndKeep
          }}
          secondaryBtn={{
            text: "Archive history",
            onClick: this.onMoveAndClear
          }}
        />

        <Modal
          show={this.state.showAddAtomicSensorModal}
          onHide={() => this.setState({ showAddAtomicSensorModal: false })}
          title="Add the atomic sensor?" 
          text="Adding an atomic sensor will copy all of its sample history into the location."
          primaryBtn={{
            text: "Add",
            onClick: this.onAddAtomicSensorsToLocation
          }}
          secondaryBtn={{
            text: "Cancel",
            onClick: () => this.setState({ showAddAtomicSensorModal: false })
          }}
        />

        <Modal
          show={this.state.showRemoveAtomicSensorModal}
          onHide={() => this.setState({ showRemoveAtomicSensorModal: false })}
          title="Remove the atomic sensor?" 
          text="Removing an atomic sensor will delete all of its sample history from the location."
          primaryBtn={{
            text: "Remove",
            color: "red",
            onClick: this.onRemoveAtomicSensorsFromLocation
          }}
          secondaryBtn={{
            text: "Cancel",
            onClick: () => this.setState({ showRemoveAtomicSensorModal: false })
          }}
        />

        <Modal
          show={this.state.showDeleteSensorModal}
          onHide={() => this.setState({ showDeleteSensorModal: false })}
          title="Keep or delete history?" 
          text="By choosing to keep the history, sensor data will be preserved for later inspection. Opting to delete the sample history will permanently remove the sensor data."
          primaryBtn={{
            text: "Keep history",
            onClick: () => this.onDeleteSensorWithOptions(true)
          }}
          secondaryBtn={{
            text: "Delete history",
            color: "red",
            onClick: () => this.onDeleteSensorWithOptions(false)
          }}
        />

      </div>
    ); // eslint-disable-line
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    currentLocation: state.location,
    currentUser: state.user,
    currentUserGroup: state.userGroup,
    currentContent: state.content,
    currentCustomTag: state.customTag.data,
    currentCustomTagDraft: state.customTag.draft,
    currentGateway: state.gateway,
    currentServiceAccount: state.serviceAccount.data,
    currentServiceAccountDraft: state.serviceAccount.draft,
    allSelectedTypes: state.selected.allTypes,
    atomicSensors: state.sensor.atomicSensors,
    locationsBeingViewed: state.location.children,
    selectedLocations: state.selected.locations,
    selectedSensors: state.selected.sensors,
    selectedAtomicSensors: state.selected.atomicSensors,
    selectedGateways: state.selected.gateways,
    selectedLocationTags: state.selected.locationTags,
    selectedSensorTags: state.selected.sensorTags,
    selectedCompanies: state.selected.companies,
    selectedFeatures: state.selected.features,
    editedFeatures: state.selected.editedFeatures,
    createdFeature: state.selected.createdFeature,
    selectedContent: state.selected.content,
    selectedUsers: state.selected.users,
    selectedUserGroups: state.selected.userGroups,
    selectedInvites: state.selected.invites,
    selectedCustomTags: state.selected.customTags,
    selectedUnregisteredSensors: state.selected.unregisteredSensors,
    unregisteredSensorDraft: state.unregisteredSensors.draft,
    viewMapMode: state.selected.viewMapMode,
    companyMap: state.auth.map,
    isLoadingLocation: state.loading.location,
    isLoadingLocations: state.loading.locations,
    isLoadingSensor: state.loading.sensor,
    isLoadingSensors: state.loading.sensors,
    isLoadingGateways: state.loading.gateways,
    isLoadingLocationTags: state.loading.locationTags,
    isLoadingSensorTags: state.loading.sensorTags,
    isLoadingContent: state.loading.content,
    isLoadingUsers: state.loading.users,
    isLoadingCustomTags: state.loading.customTags,
    isLoadingUserGroup: state.loading.userGroup,
    isLoadingUserGroups: state.loading.userGroups,
    isLoadingUnregisteredSensors: state.loading.unregisteredSensors,
    loading: state.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectSensor: selectedActions.selectSensor,
    deselectSensor: selectedActions.deselectSensor,
    deselectAtomicSensor: selectedActions.deselectAtomicSensor,
    deselectGateway: selectedActions.deselectGateway,
    deselectLocation: selectedActions.deselectLocation,
    deselectCompany: selectedActions.deselectCompany,
    deselectLocationTag: selectedActions.deselectLocationTag,
    deselectSensorTag: selectedActions.deselectSensorTag,
    deselectContent: selectedActions.deselectContent,
    deselectUser: selectedActions.deselectUser,
    deselectUserGroup: selectedActions.deselectUserGroup,
    deselectUnregisteredSensors: selectedActions.deselectUnregisteredSensors,
    clearSelection: selectedActions.clearSelection,
    editLocation: locationActions.editLocation,
    moveLocations: locationActions.moveLocations,
    moveSensors: sensorActions.moveSensors,
    moveGateways: gatewayActions.moveGateways,
    removeSensors: sensorActions.removeSensors,
    updateSensor: sensorActions.updateSensor,
    moveSensorsToGateway: sensorActions.moveSensorsToGateway,
    createSensor: sensorActions.createSensor,
    updateAtomicSensor: sensorActions.updateAtomicSensor,
    updateGateway: gatewayActions.updateGateway,
    createGateway: gatewayActions.createGateway,
    deleteLocations: locationActions.deleteLocations,
    deleteSensor: sensorActions.deleteSensor,
    deleteLocationTag: locationTagActions.deleteLocationTag,
    deleteSensorTag: sensorTagActions.deleteSensorTag,
    deleteInvite: userActions.deleteInvite,
    deleteUser: userActions.deleteUser,
    deleteContent: contentActions.deleteContent,
    deleteFeatures: featureActions.deleteFeatures,
    deleteUserGroup: userGroupActions.deleteUserGroup,
    updateUserGroup: userGroupActions.updateUserGroup,
    updateContent: contentActions.updateContent,
    addLocationToUserGroups: userGroupActions.addLocationToUserGroups,
    removeLocationFromUserGroups: userGroupActions.removeLocationFromUserGroups,
    addUserGroupToContent: contentActions.addUserGroupToContent,
    removeUserGroupFromContent: contentActions.removeUserGroupFromContent,
    addLocationToContent: contentActions.addLocationToContent,
    removeLocationFromContent: contentActions.removeLocationFromContent,
    mapFeatureToLocation: locationActions.mapFeatureToLocation,
    removeFeaturesFromLocations: locationActions.removeFeaturesFromLocations,
    addUserGroupsToUser: userActions.addUserGroupsToUser,
    removeUserGroupsFromUser: userActions.removeUserGroupsFromUser,
    setViewMapMode: selectedActions.setViewMapMode,
    setEditMapMode: selectedActions.setEditMapMode,
    registerFeature: featureActions.registerFeature,
    changeFeatures: featureActions.changeFeatures,
    registerCompanyFeature: featureActions.registerCompanyFeature,
    changeCompanyFeatures: featureActions.changeCompanyFeatures,
    deleteCompanyFeatures: featureActions.deleteCompanyFeatures,
    addUsersToUserGroup: userGroupActions.addUsersToUserGroup,
    deleteUsersFromUserGroup: userGroupActions.deleteUsersFromUserGroup,
    updateCustomTag: customTagActions.updateCustomTag,
    deleteCustomTags: customTagActions.deleteCustomTags,
    grantRoleToUsers: userActions.grantRoleToUsers,
    updateUnregisteredSensors: unregisteredSensorActions.updateUnregisteredSensors,
    clearUnregisteredSensorDraft: unregisteredSensorActions.clearUnregisteredSensorDraft,
    createUnregisteredSensors: unregisteredSensorActions.createUnregisteredSensors,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectionFooter));


function getSensorIdAndAtomicSensorIdBasedOnURL() {

  const url = window.location.href;
  const sensorsSplit = url.split('/sensors/');
      
  if (sensorsSplit.length < 2) {
    return {};
  }

  const atomicSensorsSplit = sensorsSplit[1].split('/atomic-sensors/');

  if (atomicSensorsSplit.length < 2) {
    return {};
  }

  const sensorId = atomicSensorsSplit[0];
  const atomicSensorId = atomicSensorsSplit[1].split('/')[0];
  return { sensorId, atomicSensorId };
}