import React, { useRef, useState, useEffect, useMemo } from "react";
import { SVG } from "@svgdotjs/svg.js";
import { geoMercator, geoPath } from "d3-geo";
import { geoBounds } from "d3-geo";
import styled from "styled-components";
import { attachEventHandlers } from "./interactionHandler";
import { renderFeature, updateElementColor } from "./renderHandler";
import { ControlledDropdownSelection } from "../../../components/DropdownSelection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointer } from "@fortawesome/free-regular-svg-icons";
import { faDrawSquare, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { getMapBounds, MapColors } from "../helpers";
import { MapMode } from "../../../constants/stateTypes";
import { MapModeColors } from "../../../constants/colors";

// Import SVG plugins
import "@svgdotjs/svg.select.js";
import "@svgdotjs/svg.resize.js";
import "@svgdotjs/svg.draw.js";
import "@svgdotjs/svg.draggable.js";
import "@svgdotjs/svg.panzoom.js";

const SVGMap = ({
  mode,
  isFloorMap = false,
  currentLocationId,
  currentSensorId,
  currentGatewayId,
  selectedFeatures,
  setSelectedFeatures,
  highlightedFeatureId,
  setHighlightedFeatureId,
  geojsonData,
  imdfData,
  svgData,
  locations,
  createdFeatures,
  addCreatedFeature,
  canNavigate = false,
  canConnect = false,
  canEdit = false,
  canInspect = false,
  canSelectLocation = false,
  canSelectSensor = false,
  canSelectGateway = false,
  canHighlightLocation = false,
  canHighlightSensor = false,
  canHighlightGateway = false,
  children
}) => {
  const containerRef = useRef(null);
  const drawRef = useRef(null);

  // State for container dimensions.
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Extract the boundng feature (for the projection) from geojsonData.
  const boundingFeature = useMemo(() => {

    if (isFloorMap) {
      if (geojsonData && geojsonData.features && geojsonData.features.length > 0) {
        return geojsonData.features[0];
      }

      if (imdfData && imdfData.level && imdfData.level.features && imdfData.level.features.length > 0) {
        return imdfData.level.features[0];
      }
    }
    else {
      // Combine all features into a single bounding feature
      let allFeatures = [];
      if (geojsonData) {
        allFeatures.push(...geojsonData.features);
      }

      if (imdfData) {
        if (imdfData.level) {
          allFeatures.push(...imdfData.level.features);
        }
      }

      // Remove point features from the bounding feature
      allFeatures = allFeatures.filter(feature => feature.geometry.type !== "Point" && !feature.properties.disabled);

      return {
        type: "FeatureCollection",
        features: allFeatures
      };
    }

    return null;
  }, [geojsonData, imdfData]);

  // Create a projection that fits the floorFeature into the available space
  const projection = useMemo(() => {
    if (!boundingFeature || dimensions.width === 0 || dimensions.height === 0) {
      return null;
    }

    // Padding around the features
    const padding = 20;

    // Create a projection that fits the bounding feature into the available space
    const fittedProjection = geoMercator().fitExtent(
      [[padding, padding], [dimensions.width - padding, dimensions.height - padding]],
      boundingFeature
    );

    // Check if the scale is too small
    if (fittedProjection.scale() < 100000) {
      console.warn("Projection scale is too small", fittedProjection.scale());
    }

    return fittedProjection;

  }, [boundingFeature, dimensions.width, dimensions.height]);

  // Initialization - create the SVG once when container and dimensions are available
  useEffect(() => {
    console.log("Initializing SVG", containerRef.current, dimensions.width, dimensions.height);
    console.log("svgData", svgData);
    console.log("geojsonData", geojsonData);
    console.log("imdfData", imdfData);
    console.log("permissions", canNavigate, canConnect, canEdit, canInspect);
    console.log("mode", mode);
    console.log("currentLocationId", currentLocationId);
    console.log("currentSensorId", currentSensorId);
    console.log("currentGatewayId", currentGatewayId);
    console.log("selectedFeatures", selectedFeatures);
    console.log("highlightedFeatureId", highlightedFeatureId);
    // If already initialized or not ready, do nothing
    if (drawRef.current || !containerRef.current || dimensions.width === 0 || dimensions.height === 0) return;

    const draw = SVG()
      .addTo(containerRef.current)
      .size(dimensions.width, dimensions.height);
    
    if (geojsonData || imdfData) {
      draw.viewbox(0, 0, dimensions.width, dimensions.height);
    }
    else if (svgData) {
      // Transfer viewBox attribute from the <svg> element to the draw instance
      const padding = 20; // pixels of padding on each side
      const viewBoxMatch = svgData.match(/viewBox="([^"]*)"/);
      if (viewBoxMatch) {
        const [minX, minY, width, height] = viewBoxMatch[1].split(" ").map(Number);
        console.log("ViewBox base", minX, minY, width, height);
        console.log("ViewBox calc", minX - padding, minY - padding, width + padding * 2, height + padding * 2);
        draw.viewbox(minX - padding, minY - padding, width + padding * 2, height + padding * 2);

        //-20 -20 534.6 1092.3
      }
      else {
        console.warn("No viewBox attribute found in SVG data");
        draw.viewbox(0, 0, dimensions.width, dimensions.height);
      }
    }

    draw.panZoom({ zoomMin: 0.5, zoomMax: 5 });

    drawRef.current = draw;
    console.log("Initialized SVG");

  }, [containerRef.current, dimensions.width, dimensions.height]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      console.log("Clean up SVG on unmount");
      drawRef?.current?.clear();
      drawRef?.current?.remove();
      drawRef.current = null;
    };
  }, []);

  // Update dimensions when the container resizes
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setDimensions({ width, height });
      }
    });
    observer.observe(container);
    return () => observer.disconnect();
  }, []);

  // Effect to update the SVG size when dimensions change.
  useEffect(() => {
    const draw = drawRef.current;
    if (draw) {
      if (geojsonData || imdfData) {
        draw.size(dimensions.width, dimensions.height);
        draw.viewbox(0, 0, dimensions.width, dimensions.height);
      }
      else if (svgData) {
        // Transfer viewBox attribute from the <svg> element to the draw instance
        const padding = 20; // pixels of padding on each side
        const viewBoxMatch = svgData.match(/viewBox="([^"]*)"/);
        if (viewBoxMatch) {
          const [minX, minY, width, height] = viewBoxMatch[1].split(" ").map(Number);
          draw.viewbox(minX - padding, minY - padding, width + padding * 2, height + padding * 2);
        }
        else {
          console.warn("No viewBox attribute found in SVG data");
          draw.viewbox(0, 0, dimensions.width, dimensions.height);
        }
      }
    }
  }, [dimensions]);

  // Update view when mode changes
  useEffect(() => {
    if (mode === MapMode.view) {
      setSelectedFeatures && setSelectedFeatures([]);
    }
  }, [mode]);

  // Do not show selected features in view mode
  // Do only show one selected feature in connect mode
  let validSelectedFeatures = selectedFeatures;
  if (mode === MapMode.view || mode === MapMode.draw_point || mode === MapMode.draw_polygon) {
    validSelectedFeatures = [];
  }
  else if (mode === MapMode.connect && selectedFeatures.length > 1) {
    validSelectedFeatures = [selectedFeatures[0]];
  }

  // Render GeoJSON data
  const renderGeoJSON = (draw) => {
    if (!geojsonData || !projection) return;

    const darkBgColor = "#CCC8C2";
    const defaultColor = "#E0DDD5";
    const lightBgColor = "#FFFCFC"; //"#F2ECE6";

    geojsonData.features.forEach((feature) => {
      const { type } = feature.geometry;

      let fillColor = defaultColor;
      let strokeColor = "#888";
      let strokeWidth = 0.5;

      if (type === "Polygon") {
        // Set fill color based on feature properties
        if (feature.properties?.style?.fillColor) {
          // Override fill color if specified in feature properties
          fillColor = feature.properties.style.fillColor;

          // TODO: Standardise "walkway" color
          if (fillColor === "#F2EEE5") {
            fillColor = lightBgColor;
          }
        }
        else if (feature.properties?.disabled) {
          fillColor = "#e2e2e2";
          strokeColor = "#e2e2e2";
        }
        else if (feature.properties?.locationId && locations) {
          // Set fill color based on location type
          const location = locations[feature.properties.locationId];
          switch (true) {
            case location?.type === "building":
              fillColor = darkBgColor;
              strokeColor = "#333";
              break;
            case location?.type === "floor":
              fillColor = darkBgColor;
              strokeColor = "#333";
              break;
            case location?.type === "corridor":
              fillColor = lightBgColor;
              break;
            case location?.type.includes("zone"):
              fillColor = lightBgColor;
              break;
            case location?.type.includes("toilet"):
              fillColor = "#E4F5F2";
              break;
            case location?.type.includes("elevator"):
            case location?.type.includes("escalator"):
            case location?.type.includes("stairs"):
              fillColor = "#C3DEB4";
              break;
            case location?.type.includes("quiet"):
              fillColor = "#dad7cd";
              break;
            case location?.type.includes("room"):
              fillColor = "#F5E2CE";
              break;
            default:
              fillColor = "#E0DCDC";

              if (!location) {
                console.log("No location found for", feature.properties.locationId);
              }
          }
        }

        renderFeature(draw, feature, projection, fillColor, strokeColor, strokeWidth);
      }
      else if (type === "Point") {
        fillColor = "#81C97E";
        strokeColor = "#333";

        if (feature.properties?.gateway) {
          fillColor = "#A98567";
        }

        renderFeature(draw, feature, projection, fillColor, strokeColor, strokeWidth);
      }
    });

    console.log("created features", createdFeatures);
    // remove null values
    createdFeatures = createdFeatures.filter(feature => feature !== null);
    createdFeatures.forEach(createdFeature => {
      console.log("created feature", createdFeature);
      // Add the drawn feature to the SVG drawing
      if (createdFeature.geometry?.type === "Point") {
        let fillColor = "#81C97E";
        let strokeColor = "#333";
        let strokeWidth = 0.5;

        if (createdFeature.properties?.gateway) {
          fillColor = "#A98567";
        }

        renderFeature(draw, createdFeature, projection, fillColor, strokeColor, strokeWidth);
      }
    });

    // Highlight the current location (TODO: Replace with currentFeatureId)
    if (currentLocationId) {
      const locationFeatureId = geojsonData.features.find(feature => feature.properties?.locationId === currentLocationId)?.id;
      const locationFeature = draw.findOne(`[id="${locationFeatureId}"]`);
      console.log("locationFeature", locationFeature);
      if (locationFeature) {
        locationFeature.stroke({ color: "#777", width: 2 });
        // locationFeature.fill("#ddd");
      }
    }

    // Do not show highlighted/selected features in draw modes
    if (mode === MapMode.draw_point || mode === MapMode.draw_polygon) {
      return;
    }

    // Update highlighted feature
    if (highlightedFeatureId && !validSelectedFeatures.find(feature => feature.id === highlightedFeatureId)) {
      const highlightedFeature = draw.findOne(`[id="${highlightedFeatureId}"]`);
      if (highlightedFeature) {

        // Take the current fill and make it a bit darker
        // const currentFill = highlightedFeature.attr("fill");
        // let fill = currentFill === "none" ? "#999" : currentFill;
        // let stroke = highlightedFeature.attr("stroke");
        // console.log("Highlighting feature1", highlightedFeatureId, fill, stroke);

        // // Darken
        // fill = shade(0.1, fill);
        // stroke = shade(0.1, stroke);

        // console.log("Highlighting feature2", highlightedFeatureId, fill, stroke);

        // highlightedFeature.animate(100, 50, "now").attr({ fill: "#d4d0f1" });
        if (highlightedFeature.type === "circle") {
          highlightedFeature.radius(4);
          highlightedFeature.fill("#EC84E5");
          highlightedFeature.stroke({ color: "#000", width: 1.2 });
          highlightedFeature.css({ cursor: "pointer" });
          // highlightedFeature.animate(100, 50, "now").attr({ fill: "#7162E7", stroke: "#999" });
          // highlightedFeature.animate(150, 50, "now").attr({ fill: "#EC84E5", stroke: { color: "#000", width: 1.2 } });
        }
        else {
          // highlightedFeature.fill("#d4d0f1");
          highlightedFeature.css({ cursor: "pointer" });
          highlightedFeature.animate(150, 50, "now").attr({ fill: MapModeColors[mode].highlightFillColor });
        }
      }
    }

    // Update selected feature
    if (validSelectedFeatures) {
      validSelectedFeatures.forEach(feature => {
        const selectedFeature = draw.findOne(`[id="${feature.id}"]`);
        if (selectedFeature) {
          if (selectedFeature.type === "circle") {
            selectedFeature.radius(4);
            selectedFeature.fill("#F0ACEB");
            selectedFeature.stroke({ color: "#000", width: 1.2 });
            selectedFeature.css({ cursor: "pointer" });
          }
          else {
            selectedFeature.css({ cursor: "pointer" });
            selectedFeature.fill(MapModeColors[mode].selectionFillColor);
            // selectedFeature.stroke({ color: selectionStrokeColor, width: selectionStrokeWidth });
          }
        }
      });
    }
  };

  // Render the IMDF data as GeoJSON.
  const renderIMDF = (draw) => {
    if (!imdfData || !projection) return;

    const darkBgColor = "#CCC8C2";
    const defaultColor = "#E0DDD5";
    const lightBgColor = "#F2ECE6";

    imdfData.level?.features.forEach((feature) => {
      // const fillColor = "#FEFBFB";
      const fillColor = "#FFFCFC";
      const strokeColor = "#333";
      const strokeWidth = 0.5;

      renderFeature(draw, feature, projection, fillColor, strokeColor, strokeWidth);
    });

    imdfData.section?.features.forEach((feature) => {
      let fillColor = "#FFF";
      let strokeColor = "#888";
      let strokeWidth = 0.5;

      switch (feature.properties.category) {
        case "eatingdrinking":
          fillColor = "#F00";
          break;
        default:
          strokeColor = "#000";
          strokeWidth = 0;

          console.log("Unknown section feature", feature);
          break;
      }

      // TODO: In our example the section overlaps with the unit,
      // so it was not visible. We need to look into if sections always
      // have a unit that they belong to, and if we should render them
      // in another way.

      renderFeature(draw, feature, projection, fillColor, strokeColor, strokeWidth);
    });

    imdfData.unit?.features.forEach((feature) => {
      let fillColor = "#FFF";
      let strokeColor = "#888";
      let strokeWidth = 0.5;

      switch (feature.properties.category) {
        case "room":
        case "foodservice":
          fillColor = "#F5E2CE";
          break;
        case "nonpublic":
          fillColor = "#E0DCDC";
          break;
        case "restroom.male":
          fillColor = "#E4F5F2";
          break;
        case "restroom.female":
        case "mothersroom":
          fillColor = "#E4F5F2";
          break;
        case "restroom":
        case "restroom.unisex":
        case "restroom.family":
          fillColor = "#E4F5F2";
          break;
        case "stairs":
        case "escalator":
        case "elevator":
          fillColor = "#C3DEB4";
          break;
        case "structure":
          fillColor = "#dad7cd";
          break;
        case "walkway":
          fillColor = "none";
          strokeColor = "#000";
          strokeWidth = 0;
          break;
        default:
          strokeColor = "#000";
          strokeWidth = 0;

          console.log("Unknown unit feature", feature);
          break;
      }

      // Skip features with no fill or stroke
      if (fillColor === "none" && strokeWidth === 0) {
        return;
      }

      renderFeature(draw, feature, projection, fillColor, strokeColor, strokeWidth);
    });

    imdfData.opening?.features.forEach((feature) => {
      const fillColor = "none";
      let strokeColor = "#ddd";
      let strokeWidth = 0;
      if (feature.properties.door) {
        strokeColor = "#888";
        strokeWidth = 0.5;
      }

      renderFeature(draw, feature, projection, fillColor, strokeColor, strokeWidth);
    });

    // Highlight the current location
    if (currentLocationId) {
      const locationFeatureId = geojsonData.features.find(feature => feature.properties?.locationId === currentLocationId)?.id;
      const locationFeature = draw.findOne(`[id="${locationFeatureId}"]`);
      console.log("locationFeature", locationFeature);
      if (locationFeature) {
        locationFeature.stroke({ color: "#777", width: 2 });
        // locationFeature.fill("#ddd");
      }
    }
    
    // Do not show highlighted/selected features in draw modes
    if (mode === MapMode.draw_point || mode === MapMode.draw_polygon) {
      return;
    }

    // Update highlighted feature
    if (highlightedFeatureId && !validSelectedFeatures.find(feature => feature.id === highlightedFeatureId)) {
      const highlightedFeature = draw.findOne(`[id="${highlightedFeatureId}"]`);
      if (highlightedFeature) {
        highlightedFeature.css({ cursor: "pointer" });
        highlightedFeature.animate(150, 50, "now").attr({ fill: MapModeColors[mode].highlightFillColor });
      }
    }

    // Update selected features
    if (validSelectedFeatures) {
      validSelectedFeatures.forEach(feature => {
        const selectedFeature = draw.findOne(`[id="${feature.id}"]`);
        if (selectedFeature) {
          selectedFeature.css({ cursor: "pointer" });
          selectedFeature.fill(MapModeColors[mode].selectionFillColor);
        }
      });
    }
  };

  // Render the svgData into a group.
  const renderSVG = (draw) => {
    if (!svgData) return;

    // Remove the outer <svg> container if it exists
    const svgContainer = svgData.match(/<svg[^>]*>(.*)<\/svg>/s);
    if (svgContainer) {
      svgData = svgContainer[1];
    }

    // Draw the SVG data
    draw.svg(svgData);
    drawnSVGShapes.forEach(shape => {
      draw.add(shape);
    });

    // Highlight the current location
    if (currentLocationId) {
      // Highlight the current location...?
    }

    // Update highlighted feature
    if (highlightedFeatureId && !validSelectedFeatures.find(feature => feature.id === highlightedFeatureId)) {
      const highlightedFeature = draw.findOne(`[id="${highlightedFeatureId}"]`);
      if (highlightedFeature) {
        updateElementColor(highlightedFeature.node, MapModeColors[mode].highlightFillColor);
        highlightedFeature.css({ cursor: "pointer" });
      }
    }

    // Update all selected feature in Pretty SVG
    if (validSelectedFeatures) {
      validSelectedFeatures.forEach(feature => {
        const selectedFeature = draw.findOne(`[id="${feature.id}"]`);
        if (selectedFeature) {
          updateElementColor(selectedFeature.node, MapModeColors[mode].selectionFillColor);
          selectedFeature.css({ cursor: "pointer" });
        }
      });
    }
  };

  useEffect(() => {
    const draw = drawRef.current;
    if (!draw) {
      console.log("Draw not ready");
      return;
    }

    // Clear the SVG before redrawing
    draw.clear()

    // Redraw your objects into panZoomLayer
    if (geojsonData) {
      renderGeoJSON(draw);
    } 
    else if (imdfData) {
      renderIMDF(draw);
    } 
    else if (svgData) {
      renderSVG(draw);
    }

    // You can also update event handlers as needed without reinitializing the SVG entirely
    // For example, you might reattach interactions specific to the new content
    const options = {
      draw: draw,
      projection,
      geojsonData,
      imdfData,
      svgData,
      setHighlightedFeatureId,
      setSelectedFeatures,
      canSelectLocation,
      canSelectSensor,
      canSelectGateway,
      canSelectUndefined: mode === MapMode.connect,
      canSelectAny: mode === MapMode.inspect || mode === MapMode.edit,
      canHighlightLocation,
      canHighlightSensor,
      canHighlightGateway,
      canHighlightUndefined: mode === MapMode.connect,
      canHighlightAny: mode === MapMode.inspect || mode === MapMode.edit,
    };

    if (mode === MapMode.inspect || mode === MapMode.connect) {
      options.selectedFeatures = selectedFeatures;
    }
    if (mode === MapMode.inspect) {
      options.canMultiSelect = true;
    }
    if (mode === MapMode.draw_point) {
      options.drawPoint = true;
      options.createdFeatures = createdFeatures;
      options.addCreatedFeature = addCreatedFeature;
    }
    if (mode === MapMode.draw_polygon) {
      options.drawPolygon = true;
      options.createdFeatures = createdFeatures;
      options.addCreatedFeature = addCreatedFeature;
    }

    attachEventHandlers(options);

  }, [dimensions, projection, geojsonData, svgData, imdfData, selectedFeatures, highlightedFeatureId, createdFeatures]);

  // Render the map with children views
  return (
    <MapContainer ref={containerRef} $drawPoint={mode === MapMode.draw_point || mode === MapMode.draw_polygon}>
      {children}
    </MapContainer>
  );
};

export default SVGMap;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #F6F6F6;
  cursor: grab;

  ${props => props.$drawPoint && `
    cursor: crosshair;
  `}
`;